import { SIZE } from './AttachmentListPreview';
import useAttachment, { Attachment } from '../../hooks/useAttachment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ThumbnailFileDisplayProps {
  attachment: Attachment | undefined;
  size: keyof typeof SIZE;
}

const ThumbnailFileDisplay = ({ attachment, size }: ThumbnailFileDisplayProps) => {
  const { downloadAttachment } = useAttachment({ attachment });
  return (
    <div className={`flex items-center justify-center mx-1 ${SIZE[size]}`}>
      <FontAwesomeIcon
        className="h-full cursor-pointer text-blue-500 hover:text-blue-800"
        icon="file-arrow-down"
        onClick={downloadAttachment}
      />
    </div>
  );
};

export default ThumbnailFileDisplay;
