interface SubstepNumberProps {
  blockLabel?: string;
  isHidden?: boolean; // deprecate this.  Only for to the side images it seems.
  hasExtraVerticalSpacing?: boolean;
  fixedWidth?: boolean;
}

const SubstepNumber = ({ blockLabel, isHidden, hasExtraVerticalSpacing, fixedWidth = true }: SubstepNumberProps) => {
  return (
    <div>
      {blockLabel && (
        <div className={`${isHidden ? 'hidden' : ''}`}>
          <div
            className={`${fixedWidth ? 'w-12' : 'w-fit'} text-xs text-gray-400  ${
              hasExtraVerticalSpacing ? 'mt-3.5' : 'mt-1'
            }`}
          >
            {blockLabel}
          </div>
        </div>
      )}
    </div>
  );
};

export default SubstepNumber;
