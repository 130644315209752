import React, { useCallback, useState } from 'react';
import { getInitialFormValues } from './Blocks/BlockTypes';
import FieldSetCheckbox from './FieldSetCheckbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FieldInputFieldSet from './Blocks/FieldInputFieldSet';
import { DraftFieldInputTableBlock } from 'shared/lib/types/views/procedures';
import { FormikHelpers, FormikValues } from 'formik';
import { FieldInputBlockContentErrors } from '../lib/types';
import Button from './Button';
import { isString } from 'lodash';
import { useRunContext } from '../contexts/RunContext';
import SubstepNumber from './SubstepNumber';

const allowedInputTypes = ['text', 'number', 'checkbox', 'timestamp', 'list', 'select'];

export interface FieldInputTableEditProps {
  path: string;
  content: DraftFieldInputTableBlock;
  contentErrors?: {
    name?: string;
    fields?: {
      [key: number]: {
        [K in keyof FieldInputBlockContentErrors]?: string;
      };
    };
  };
  setFieldValue?: FormikHelpers<FormikValues>['setFieldValue'];
  canModifyContentStructure?: boolean;
}

const isDigitsOnly = (value: string): boolean => {
  return isString(value) && value.split('').every((char) => char >= '0' && char <= '9');
};

const FieldInputTableEdit = ({
  content,
  contentErrors,
  path,
  setFieldValue,
  canModifyContentStructure = true,
}: FieldInputTableEditProps) => {
  const { isRun } = useRunContext();

  const [tempFieldsPerRow, setTempFieldsPerRow] = useState<string>(content.fieldsPerRow.toString());
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const handleFieldsPerRowChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isDigitsOnly(value)) {
      setTempFieldsPerRow(value);
    }
  };

  const handleFieldsPerRowBlur = () => {
    const value = Math.max(1, Math.min(6, Number(tempFieldsPerRow) || 1));
    setFieldValue?.(`${path}.fieldsPerRow`, value);
    setTempFieldsPerRow(value.toString());
  };

  const addFieldInput = useCallback(() => {
    const newField = getInitialFormValues('input');
    setFieldValue?.(`${path}.fields`, [...content.fields, newField]);
  }, [setFieldValue, path, content.fields]);

  const removeFieldInput = useCallback(
    (index: number) => {
      const updatedFields = content.fields.filter((_, i) => i !== index);
      setFieldValue?.(`${path}.fields`, updatedFields);
    },
    [setFieldValue, path, content.fields]
  );
  return (
    <div className="relative flex flex-col max-w-full w-full">
      <div className="field-title">Field Input Table</div>
      <div className="relative">
        <div className="absolute top-0 bottom-0 w-0.5 bg-gray-300" style={{ left: '0.5rem' }}></div>
        <div className="pl-4">
          <div className="flex items-center mb-2">
            <div className="flex items-center mr-4">
              <input
                type="number"
                id="columns"
                className="border rounded p-2 w-12 text-sm"
                value={tempFieldsPerRow}
                min={1}
                max={6}
                onChange={handleFieldsPerRowChange}
                onBlur={handleFieldsPerRowBlur}
              />
              <label htmlFor="columns" className="ml-2">
                Columns
              </label>
            </div>
            <div className="flex items-center">
              <FieldSetCheckbox
                text="Include in Summary"
                fieldName={path ? `${path}.include_in_summary` : 'include_in_summary'}
                setFieldValue={setFieldValue}
                isDisabled={isRun}
              />
            </div>
          </div>
          {contentErrors && contentErrors.name && <div className="text-red-700">{contentErrors.name}</div>}
          <div className="w-full">
            <div className="grid gap-1">
              {content.fields.map((field, index) => (
                <div
                  key={`field-input-table-${index}`}
                  className="p-2 flex items-center relative"
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  <div className="flex flex-row self-start justify-end min-w-4 mt-5 mr-1">
                    <SubstepNumber blockLabel={`${index + 1}`} fixedWidth={false} />
                  </div>
                  <FieldInputFieldSet
                    content={field}
                    path={`${path}.fields[${index}]`}
                    contentErrors={contentErrors && contentErrors[index]}
                    setFieldValue={setFieldValue}
                    includeSummaryButton={false}
                    allowedInputTypes={allowedInputTypes}
                  />
                  {canModifyContentStructure && (
                    <Button
                      type="tertiary"
                      onClick={() => removeFieldInput(index)}
                      title="Remove Step Content"
                      ariaLabel="Remove Step Content"
                      isDisabled={hoveredIndex !== index}
                    >
                      <FontAwesomeIcon
                        icon="times-circle"
                        className={`text-gray-400 hover:text-gray-500 ${hoveredIndex === index ? '' : 'opacity-0'}`}
                      />
                    </Button>
                  )}
                </div>
              ))}
            </div>
          </div>
          {canModifyContentStructure && (
            <div className="flex items-center mt-4">
              <div className="cursor-pointer" onClick={addFieldInput}>
                <FontAwesomeIcon className="text-blue-500 opacity-100" icon="circle-plus" />
                <span className="text-sm font-semibold ml-1">Field Input</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FieldInputTableEdit;
