import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { IntercomProvider } from 'react-use-intercom';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { INTERCOM_APP_ID, MIXPANEL_TOKEN } from './config';
import Login from './screens/Login';
import LoginSSO from './screens/LoginSSO';
import LoginOauth from './screens/LoginOauth';
import ChangePassword from './screens/ChangePassword';
import ForgotPassword from './screens/ForgotPassword';
import ResetPassword from './screens/ResetPassword';
import Register from './screens/Register';
import Procedure from './screens/Procedure';
import ProjectSettings from './screens/ProjectSettings';
import ProcedurePending from './screens/ProcedurePending';
import ProcedureEdit from './screens/ProcedureEdit';
import Profile from './screens/Profile';
import Review from './screens/Review';
import Run from './screens/Run';
import RunSummary from './screens/RunSummary';
import { DatabaseProvider } from './contexts/DatabaseContext';
import { TeamsProvider } from './contexts/TeamsContext';
import { UserProvider } from './contexts/UserContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { ServiceWorkerProvider } from './contexts/ServiceWorkerContext';
import { AuthProvider } from './contexts/AuthContext';
import { MixpanelProvider } from './contexts/MixpanelContext';
import { PERM } from './lib/auth';
import printAsciiArt from './lib/asciiArt';
import NoTeamAccess from './screens/NoTeamAccess';
import ProjectPermissionsRoute from './screens/ProjectPermissionsRoute';
import TeamUrlRedirect from './components/TeamUrlRedirect';
import {
  homePath,
  operationsDashboardPath,
  operationViewPath,
  procedureEditPath,
  procedureImportPath,
  procedurePendingPath,
  procedureReviewPath,
  procedureRunsPath,
  procedureViewPath,
  projectSettingsPath,
  runSummaryPath,
  runViewPath,
  teamSettingsPath,
  orgSettingsPath,
  testingPath,
  manufacturingPath,
  schedulePath,
  storagePath,
  eventPath,
  legacyEventPath,
  insightsPath,
  issuesPath,
  issuesSettingsPath,
  notificationsPath,
  issuePath,
  procedureViewVersionPath,
  proceduresPath,
  procedureDiffPath,
  runsPath,
  snippetsPath,
  snippetViewPath,
  risksPath,
  riskPath,
  risksSettingsPath,
  searchPath,
} from './lib/pathUtil';
import NotificationsList from './notifications/screens/NotificationsList';
import Landing from './screens/Landing';
import ProcedureList from './screens/ProcedureList';
import Runs from './screens/RunList';
import Diff from './screens/Diff';
import SnippetList from './components/Snippet/SnippetList';
import Search from './screens/Search';
import SnippetDetail from './components/Snippet/SnippetDetail';
printAsciiArt(console.log); // eslint-disable-line no-console

export default async function loadApp() {
  const library = (await import('@fortawesome/fontawesome-svg-core')).library;
  const fas = (await import('@fortawesome/free-solid-svg-icons')).fas;
  const far = (await import('@fortawesome/free-regular-svg-icons')).far;
  const fab = (await import('@fortawesome/free-brands-svg-icons')).fab;
  const Base = (await import('./screens/Base')).default;
  const AppRoute = (await import('./screens/AppRoute')).default;
  const ProcedureImport = (await import('./screens/ProcedureImport')).default;
  const Operations = (await import('./screens/Operations')).default;
  const OperationDetail = (await import('./screens/OperationDetail')).default;
  const Settings = (await import('./screens/Settings')).default;
  const TestingRoute = (await import('./testing/TestingRoute')).default;
  const ManufacturingRoute = (await import('./manufacturing/ManufacturingRoute')).default;
  const Schedule = (await import('./schedule/screens/Schedule')).default;
  const EventDetails = (await import('./schedule/screens/EventDetails')).default;
  const Storage = (await import('./storage/screens/Storage')).default;
  const Insights = (await import('./insights/screens/Insights')).default;
  const Issues = (await import('./issues/screens/Issues')).default;
  const IssuesSettings = (await import('./issues/screens/IssuesSettings')).default;
  const IssueDetail = (await import('./issues/screens/IssueDetail')).default;
  const OrgSettings = (await import('./screens/OrgSettings')).default;
  const Risks = (await import('./risks/screens/RisksList')).default;
  const RisksSettings = (await import('./risks/screens/RisksSettings')).default;
  const RiskDetail = (await import('./risks/screens/RiskDetail')).default;
  library.add(fas, far, fab);
  const MainApp = () => (
    <>
      {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
      <Switch>
        <AppRoute exact path={orgSettingsPath(':orgId')} component={OrgSettings} />
        <AppRoute exact path="/procedures/import/:importUUID" component={TeamUrlRedirect} />
        <AppRoute exact path="/procedures/:id/version/:version" component={TeamUrlRedirect} />
        <AppRoute exact path="/procedures/:id" component={TeamUrlRedirect} />
        <AppRoute exact path="/procedures/:id/edit" component={TeamUrlRedirect} />
        <AppRoute exact path="/procedures/:id/pending" component={TeamUrlRedirect} />
        <AppRoute exact path="/procedures/:id/review" component={TeamUrlRedirect} />
        <AppRoute exact path="/procedures/:id/diff" component={TeamUrlRedirect} />
        <AppRoute exact path="/procedures/:id/runs" component={TeamUrlRedirect} />
        <AppRoute exact path="/operations" component={TeamUrlRedirect} />
        <AppRoute exact path="/operations/:id" component={TeamUrlRedirect} />
        <AppRoute exact path="/runs" component={TeamUrlRedirect} />
        <AppRoute exact path="/projects/:id/settings" component={TeamUrlRedirect} />
        {/* Instead of fighting reusable screen components, just render from scratch with `key` */}
        <AppRoute exact path="/runs/:id" component={TeamUrlRedirect} />
        <AppRoute exact path="/runs/:id/summary" component={TeamUrlRedirect} />
        <AppRoute exact path="/settings" component={TeamUrlRedirect} />
        <AppRoute exact path="/projects/:id/settings" component={TeamUrlRedirect} />
        <AppRoute
          exact
          path={procedureImportPath(':teamId', ':importUUID')}
          component={(props) => (
            <ProjectPermissionsRoute {...props} permission={PERM.PROCEDURES_EDIT}>
              <ProcedureImport />
            </ProjectPermissionsRoute>
          )}
        />
        <AppRoute exact path={procedureViewPath(':teamId', ':id')} component={Procedure} defaultLayout={false} />
        <AppRoute
          path={procedureEditPath(':teamId', ':id')}
          component={(props) => (
            <ProjectPermissionsRoute {...props} permission={PERM.PROCEDURES_EDIT}>
              <ProcedureEdit />
            </ProjectPermissionsRoute>
          )}
        />
        <AppRoute
          exact
          path={procedureViewVersionPath(':teamId', ':id', ':version')}
          component={Procedure}
          defaultLayout={false}
        />
        <AppRoute
          exact
          path={procedurePendingPath(':teamId', ':id')}
          component={ProcedurePending}
          defaultLayout={false}
        />
        <AppRoute path={procedureReviewPath(':teamId', ':id')} component={Review} defaultLayout={false} />
        <AppRoute path={procedureDiffPath(':teamId')} component={Diff} defaultLayout={false} />
        <AppRoute
          exact
          path={operationsDashboardPath(':teamId')}
          permission={PERM.VIEW_OPERATIONS}
          component={Operations}
        />
        <AppRoute
          exact
          path={operationViewPath(':teamId', ':id', false)}
          permission={PERM.VIEW_OPERATIONS}
          component={OperationDetail}
        />
        <AppRoute exact path={procedureRunsPath(':teamId', ':id')} component={Insights} />
        <AppRoute
          exact
          path={projectSettingsPath(':teamId', ':id')}
          component={(props) => (
            <ProjectPermissionsRoute {...props} permission={PERM.SETTINGS_EDIT}>
              {' '}
              <ProjectSettings />{' '}
            </ProjectPermissionsRoute>
          )}
        />
        {/* Instead of fighting reusable screen components, just render from scratch with `key` */}
        <AppRoute
          exact
          path={runViewPath(':teamId', ':id')}
          component={(props) => <Run {...props} key={window.location.pathname} />}
        />
        <AppRoute
          exact
          path={runSummaryPath(':teamId', ':id')}
          component={(props) => <RunSummary {...props} key={window.location.pathname} />}
        />
        <AppRoute exact path={proceduresPath(':teamId')} component={ProcedureList} />
        <AppRoute exact path={snippetsPath(':teamId')} component={SnippetList} />
        <AppRoute exact path={snippetViewPath(':teamId', ':id')} component={SnippetDetail} />
        <AppRoute exact path={runsPath(':teamId')} component={Runs} />

        <AppRoute exact path={teamSettingsPath(':teamId')} component={Settings} />
        <AppRoute path={testingPath(':teamId')} component={TestingRoute} />
        <AppRoute path={manufacturingPath(':teamId')} component={ManufacturingRoute} />
        <AppRoute exact path={schedulePath(':teamId')} component={Schedule} />
        <AppRoute exact path={legacyEventPath(':teamId', ':id')} component={EventDetails} />
        <AppRoute exact path={eventPath(':teamId', ':id')} component={EventDetails} />
        <AppRoute exact path={storagePath(':teamId')} component={Storage} />
        <AppRoute exact path={insightsPath(':teamId')} component={Insights} />
        <AppRoute exact path={issuesPath(':teamId')} component={Issues} />
        <AppRoute exact path={issuesSettingsPath(':teamId')} component={IssuesSettings} />
        <AppRoute exact path={notificationsPath(':teamId')} component={NotificationsList} />
        <AppRoute exact path={issuePath(':teamId', ':id')} component={IssueDetail} />
        <AppRoute exact path={risksPath(':teamId')} component={Risks} />
        <AppRoute exact path={risksSettingsPath(':teamId')} component={RisksSettings} />
        <AppRoute exact path={riskPath(':teamId', ':id')} component={RiskDetail} />

        <AppRoute exact path="/password-change" component={ChangePassword} />
        <AppRoute exact path="/profile" component={Profile} />
        <Route exact path="/password-reset/:token">
          <Base>
            <ResetPassword />
          </Base>
        </Route>
        <Route exact path="/forgot-password">
          <Base>
            <ForgotPassword />
          </Base>
        </Route>
        <Route exact path="/register/:token">
          <Base>
            <Register />
          </Base>
        </Route>
        <Route exact path="/oauth/login">
          <Base>
            <LoginOauth />
          </Base>
        </Route>
        <Route exact path="/login">
          <Base>
            <Login />
          </Base>
        </Route>
        <Route exact path="/sso/:action">
          <Base>
            <LoginSSO />
          </Base>
        </Route>
        <Route exact path="/no-team-access">
          <Base>
            <NoTeamAccess />
          </Base>
        </Route>
        <AppRoute exact path={searchPath(':teamId')} component={Search} />
        <AppRoute exact path={homePath(':teamId')} component={Landing} />
        <AppRoute path="/" component={Landing} />
      </Switch>
    </>
  );

  const queryClient = new QueryClient();

  return () => (
    <HelmetProvider>
      <MixpanelProvider token={MIXPANEL_TOKEN}>
        <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
          <UserProvider>
            <Router>
              <DatabaseProvider>
                <TeamsProvider>
                  <SettingsProvider>
                    <AuthProvider>
                      <ServiceWorkerProvider>
                        <QueryClientProvider client={queryClient}>
                          <MainApp />
                        </QueryClientProvider>
                      </ServiceWorkerProvider>
                    </AuthProvider>
                  </SettingsProvider>
                </TeamsProvider>
              </DatabaseProvider>
            </Router>
          </UserProvider>
        </IntercomProvider>
      </MixpanelProvider>
    </HelmetProvider>
  );
}
