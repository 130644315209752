import { ProcedureFilterProvider } from '../../contexts/ProcedureFilterContext';
import { ProcedureContextProvider } from '../../contexts/ProcedureContext';
import RunProcedureVariables from '../RunProcedureVariables';
import SummarySection from './SummarySection';
import runUtil from '../../lib/runUtil';
import React, { useCallback, useMemo } from 'react';
import { useSettings } from '../../contexts/SettingsContext';
import useRun from '../../hooks/useRun';
import { Run } from 'shared/lib/types/views/procedures';
import useLocationParams from '../../hooks/useLocationParams';
import useUrlScrollTo from '../../hooks/useUrlScrollTo';
import { useLocation } from 'react-router-dom';

interface RunSummaryContentProps {
  run: Run;
  filters: { activity: boolean; data: boolean };
}

const RunSummaryContent = ({ run, filters }: RunSummaryContentProps) => {
  const { getSetting } = useSettings();
  const { displaySections } = useRun({ run });
  const location = useLocation();
  const { searchParams } = useLocationParams(location);

  const { onScrollToRefChanged, onScrollToId } = useUrlScrollTo({
    procedure: run,
    searchParams,
  });

  /**
   * True if there is any summary content to render.
   *
   * Checks for content marked "include in summary", any step comments, and any
   * end of run comments.
   */
  const hasDisplayContent = useMemo(() => {
    if (!displaySections) {
      return false;
    }
    for (const sectionData of displaySections) {
      for (const step of sectionData[0].steps) {
        for (const content of step.content) {
          if (content.include_in_summary) {
            return true;
          }
        }
        if (step.comments) {
          return true;
        }
      }
    }
    return false;
  }, [displaySections]);

  // Returns true if there are no summary items or end of run comments
  const isSummaryEmpty = useMemo(() => {
    if (!run) {
      return true;
    }
    return !hasDisplayContent && !run.comments;
  }, [hasDisplayContent, run]);

  const scrollTo = useCallback(
    ({ sectionId, stepId, stepHeaderId, contentId }) => {
      const scrollToId = contentId || stepHeaderId || stepId || sectionId;
      onScrollToId({
        sectionId,
        stepId,
        scrollToId,
      });
    },
    [onScrollToId]
  );

  return (
    <ProcedureFilterProvider displaySections={displaySections} filters={filters}>
      <ProcedureContextProvider procedure={run} scrollTo={scrollTo}>
        <RunProcedureVariables
          isEnabled={false}
          variables={run.variables ?? []}
          onRefChanged={onScrollToRefChanged}
          scrollMarginTopValueRem={0}
        />
        {/* Grid tracks: [section, bullet and content 1, content 2 and checkbox] */}
        <table cellSpacing="0" cellPadding="0" border={0}>
          <thead>
            <tr>
              <th className="w-8"></th>
              <th className="w-full"></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {/* Show empty summary message */}
            {isSummaryEmpty && (
              <tr>
                <td className="pt-4 italic whitespace-nowrap">
                  This summary has no content. Select content in the "Edit Procedure" page to show in the summaries of
                  future runs.
                </td>
              </tr>
            )}
            {/* Show summary content */}
            {!isSummaryEmpty &&
              displaySections.map(([section, sectionIndex]) => (
                <SummarySection
                  key={`section.${sectionIndex}`}
                  section={section}
                  sectionKey={runUtil.displaySectionKey(
                    run.sections,
                    sectionIndex,
                    getSetting('display_sections_as', 'letters')
                  )}
                  stepKeyFormatter={(stepIndex) =>
                    runUtil.displaySectionStepKey(
                      run.sections,
                      sectionIndex,
                      stepIndex,
                      getSetting('display_sections_as', 'letters')
                    )
                  }
                  repeatKey={runUtil.displayRepeatKey(run.sections, sectionIndex)}
                  onRefChanged={onScrollToRefChanged}
                />
              ))}
          </tbody>
        </table>
      </ProcedureContextProvider>
    </ProcedureFilterProvider>
  );
};

export default RunSummaryContent;
