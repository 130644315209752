import { useMemo } from 'react';
import { snippetsPath, testingSnippetListPath } from '../../lib/pathUtil';
import { Snippet } from 'shared/lib/types/views/procedures';
import Button from '../Button';
import { MenuContextAction } from '../MenuContext';
import BackToLink from '../Toolbar/BackToLink';
import Divider from '../Toolbar/Divider';
import LeftSide from '../Toolbar/LeftSide';
import MoreActionsMenu from '../Toolbar/MoreActionsMenu';
import RightSide from '../Toolbar/RightSide';
import SaveAndCancelButtons from '../Toolbar/SaveAndCancelButtons';
import Title from '../Toolbar/Title';
import Toolbar from '../Toolbar/Toolbar';

interface SnippetToolbarProps {
  teamId: string;
  snippet: Snippet;
  onSave: () => Promise<void>;
  hasChanges: boolean;
  isEditing: boolean;
  canEdit: boolean;
  toggleEdit: () => void;
  onCancelEdit: () => void;
  menuActions: Array<MenuContextAction>;
  showSubmitError: boolean;
  isTestingModule: boolean;
}

const SnippetToolbar = ({
  teamId,
  snippet,
  onSave,
  hasChanges,
  isEditing,
  canEdit,
  toggleEdit,
  onCancelEdit,
  menuActions,
  showSubmitError,
  isTestingModule,
}: SnippetToolbarProps) => {
  const backToLink = useMemo(
    () => (isTestingModule ? testingSnippetListPath(teamId) : snippetsPath(teamId)),
    [isTestingModule, teamId]
  );
  return (
    <Toolbar>
      <LeftSide>
        <BackToLink to={backToLink} name="Snippets" />
        <Divider />
        <Title title={snippet.name} />
      </LeftSide>
      <RightSide visible={canEdit}>
        {!isEditing && (
          <>
            <Button type="primary" leadingIcon="pencil" onClick={toggleEdit} size="sm">
              Edit
            </Button>
            <MoreActionsMenu actions={menuActions} label="Snippet Menu" />
          </>
        )}
        {isEditing && (
          <>
            {showSubmitError && (
              <div className="text-red-600 text-sm mr-4">Please resolve all errors before saving.</div>
            )}
            <SaveAndCancelButtons isSaveDisabled={!hasChanges} onSave={onSave} onCancel={onCancelEdit} />
          </>
        )}
      </RightSide>
    </Toolbar>
  );
};

export default SnippetToolbar;
