import { useState, useCallback, useImperativeHandle, forwardRef, useEffect } from 'react';
import { Attachment, SourceType } from 'shared/lib/types/attachments';
import AttachmentsEdit from './Attachments/AttachmentsEdit';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { DatabaseServices } from '../contexts/proceduresSlice';
import { notEmpty } from 'shared/lib/collections';

export type FileStagingManagerRef = {
  uploadFiles: () => Promise<Attachment[]>;
  clearAttachments: () => void;
  getCurrentFles: () => File[];
};

type FileStagingManagerProps = {
  initialAttachments: Attachment[];
  acceptedTypes?: string[];
  onAttachmentChange?: (state: boolean) => void;
  maxFiles?: number;
  source: SourceType;
};

const FileStagingManager = forwardRef<FileStagingManagerRef, FileStagingManagerProps>(
  ({ initialAttachments, acceptedTypes, onAttachmentChange, maxFiles, source }, ref) => {
    const [stagedFiles, setStagedFiles] = useState<File[]>([]);
    const [attachments, setAttachments] = useState(initialAttachments);
    const { services }: { services: DatabaseServices } = useDatabaseServices();

    const stageFilesForUpload = useCallback((files: Iterable<File>) => {
      setStagedFiles((currentFiles) => [...currentFiles, ...Array.from(files)]);
    }, []);

    const removeStageFile = useCallback((fileToRemove: File) => {
      setStagedFiles((currentFiles) =>
        currentFiles.filter(
          (file) =>
            file.name !== fileToRemove.name ||
            file.size !== fileToRemove.size ||
            file.lastModified !== fileToRemove.lastModified
        )
      );
    }, []);

    const clearAttachments = useCallback(() => {
      setStagedFiles([]);
      setAttachments([]);
    }, []);

    const getCurrentFles = useCallback(() => {
      return stagedFiles;
    }, [stagedFiles]);

    const uploadFiles = useCallback(async () => {
      const updatedAttachments = [...attachments];
      for (const file of stagedFiles) {
        const response = await services.attachments.uploadFile(file, source);
        if (response) {
          updatedAttachments.push({
            ...response,
            source,
            _attachments: {
              [file.name]: {
                data: file,
              },
            },
          });
        }
      }

      const initialAttachmentIds = initialAttachments.map((a) => a.attachment_id);
      const attachmentIds = attachments.map((a) => a.attachment_id);

      const attachmentsToDelete = initialAttachmentIds.filter((id) => !attachmentIds.includes(id)).filter(notEmpty);

      for (const attachmentId of attachmentsToDelete) {
        await services.attachments.deleteAttachment(attachmentId);
      }

      setAttachments(updatedAttachments);
      setStagedFiles([]);
      return updatedAttachments;
    }, [attachments, initialAttachments, stagedFiles, services.attachments, source]);

    const hasAttachmentsChanged = useCallback(() => {
      if (stagedFiles.length > 0) {
        return true;
      }

      if (attachments.length !== initialAttachments.length) {
        return true;
      }

      for (let i = 0; i < attachments.length; i++) {
        if (attachments[i] !== initialAttachments[i]) {
          return true;
        }
      }

      return false;
    }, [attachments, initialAttachments, stagedFiles]);

    useEffect(() => {
      if (onAttachmentChange) {
        onAttachmentChange(hasAttachmentsChanged());
      }
    }, [attachments, stagedFiles, hasAttachmentsChanged, onAttachmentChange]);

    useImperativeHandle(ref, () => ({
      uploadFiles,
      clearAttachments,
      getCurrentFles,
    }));

    return (
      <AttachmentsEdit
        attachments={attachments}
        setAttachments={setAttachments}
        stagedFiles={stagedFiles}
        setStagedFiles={stageFilesForUpload}
        onRemoveStagedFile={removeStageFile}
        acceptedFileTypes={acceptedTypes ? acceptedTypes : undefined}
        maxFiles={maxFiles}
      />
    );
  }
);

export default FileStagingManager;
