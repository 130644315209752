import Button from '../Button';
import Divider from './Divider';

type SaveAndCancelButtonsProps = {
  isSaveDisabled?: boolean;
  onSave: () => void;
  onCancel: () => void;
};

const SaveAndCancelButtons = ({ isSaveDisabled = false, onSave, onCancel }: SaveAndCancelButtonsProps) => (
  <>
    <Button type="tertiary" ariaLabel="Cancel" leadingIcon="xmark" onClick={onCancel} iconTextColor="text-gray-500" />
    <Divider ml={1} mr={1} />
    <Button
      type="tertiary"
      size="sm"
      ariaLabel="Save"
      isDisabled={isSaveDisabled}
      onClick={onSave}
      leadingIcon="floppy-disk"
    />
  </>
);

export default SaveAndCancelButtons;
