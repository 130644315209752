import React, { useCallback, useMemo } from 'react';
import { Field, Formik } from 'formik';
import TextLinkify from '../../TextLinkify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AttachmentPreview from '../../Attachments/AttachmentPreview';
import FieldSetMultiSelectCreatable from '../../FieldSetMultiSelectCreatable';
import RadioGroupFieldSetReview from './RadioGroupFieldSetReview';
import { isEmptyValue } from 'shared/lib/text';
import { useSettings } from '../../../contexts/SettingsContext';
import FieldInputExternalItemReview from './FieldInputExternalItemReview';
import FieldInputExternalSearchReview from './FieldInputExternalSearchReview';
import { ExternalDataItem, RecordedString, RecordedValue } from '../../Blocks/BlockTypes';
import {
  ExternalDataValue,
  FieldInputBlockDiffElement,
  FieldInputCustomListBlockDiffElement,
  FieldInputExternalDataBlock,
  FieldInputExternalSearchBlock,
  FieldInputMultipleChoiceBlockDiffElement,
  RunFieldInputRecordedValue,
  RunFieldInputTimestampBlock,
  SketchValue,
} from 'shared/lib/types/views/procedures';
import isEqual from 'lodash.isequal';
import Button from '../../Button';
import diffUtil from '../../../lib/diffUtil';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import ProcedureDiffText from '../../ProcedureDiffText';
import DiffContainer from '../../Diff/DiffContainer';
import TimestampFieldInputDisplay from '../../Blocks/FieldInput/TimestampFieldInputDisplay';
import ReviewNumberFieldInput from './FieldInput/ReviewNumberFieldInput';

/*
 * Component for rendering a Block of type FieldInputReview.
 * Conforms to TypedBlockInterface, see comments in useBlockComponents.js
 */
interface FieldInputReviewProps {
  block: FieldInputBlockDiffElement;
  isEnabled: boolean;
  recorded?: { value?: RunFieldInputRecordedValue };
  onRecordValuesChanged?: (recorded: { value?: RunFieldInputRecordedValue }) => void;
  onContentRefChanged?: (id: string, element: HTMLElement) => void;
  scrollMarginTopValueRem?: number;
  blockId?: string;
}

const FieldInputReview = React.memo(
  ({
    block,
    recorded,
    isEnabled,
    onRecordValuesChanged,
    onContentRefChanged,
    scrollMarginTopValueRem,
    blockId = block.id,
  }: FieldInputReviewProps) => {
    const { getListValues } = useSettings();

    const isAttachmentType = useMemo(() => {
      return block.inputType === 'attachment';
    }, [block]);

    const isSketchType = useMemo(() => {
      return block.inputType === 'sketch';
    }, [block]);

    const hasRecordedAttachment = useMemo(() => {
      return Boolean(isAttachmentType && recorded && recorded.value);
    }, [isAttachmentType, recorded]);

    const hasRecordedSketch = useMemo(() => {
      return Boolean(isSketchType && recorded && (recorded.value as SketchValue)?.attachment_id);
    }, [isSketchType, recorded]);

    const isSelectType = useMemo(() => {
      // TODO (jon): EPS-1471 create constants/enums for all field input types
      return block.inputType === 'select' || block.inputType === 'list';
    }, [block]);

    const isMultipleChoiceType = useMemo(() => {
      return block.inputType === 'multiple_choice';
    }, [block]);

    const selectOptionsList = useMemo(() => {
      if (block.inputType === 'select') {
        return block.options
          ? block.options
              .map((option) => sharedDiffUtil.getDiffValue({ option }, 'option', 'new'))
              .filter((option) => option !== '')
          : [];
      } else if (block.inputType === 'list') {
        return getListValues(sharedDiffUtil.getDiffValue(block, 'list', 'new'));
      }
    }, [block, getListValues]);

    const selectOptions = useMemo(() => {
      if (!Array.isArray(selectOptionsList)) {
        return [];
      }

      return selectOptionsList.map((value) => ({
        value,
        label: value,
      }));
    }, [selectOptionsList]);

    const setRecordedValue = useCallback(
      (value) => {
        if (onRecordValuesChanged) {
          const _recorded = { value };
          if (!isEqual(recorded, _recorded)) {
            onRecordValuesChanged(_recorded);
          }
        }
      },
      [onRecordValuesChanged, recorded]
    );

    const onFieldInputRefChanged = useCallback(
      (element) => {
        return typeof onContentRefChanged === 'function' && onContentRefChanged(blockId, element);
      },
      [blockId, onContentRefChanged]
    );

    const getDiffChangeState = () =>
      sharedDiffUtil.isChanged(block, 'list') ||
      ((block as FieldInputCustomListBlockDiffElement).options &&
        (block as FieldInputCustomListBlockDiffElement).options.some((option) =>
          sharedDiffUtil.isChanged({ option }, 'option')
        ))
        ? ARRAY_CHANGE_SYMBOLS.MODIFIED
        : ARRAY_CHANGE_SYMBOLS.UNCHANGED;

    if (block.inputType === 'external_item') {
      return (
        <FieldInputExternalItemReview
          block={block as FieldInputExternalDataBlock}
          recorded={recorded as { value: ExternalDataValue }}
          isEnabled={isEnabled}
          onRecordValuesChanged={onRecordValuesChanged as (recorded: RecordedValue<ExternalDataItem>) => void}
          onContentRefChanged={onContentRefChanged}
          scrollMarginTopValueRem={scrollMarginTopValueRem}
        />
      );
    }

    return (
      <div
        ref={(element) => onFieldInputRefChanged(element)}
        style={{ scrollMarginTop: `${scrollMarginTopValueRem}rem` }}
        className="grow"
      >
        <div className="flex items-start w-full py-1 gap-x-2">
          {block.inputType === 'timestamp' && (
            <DiffContainer
              label="Date/time selection"
              diffChangeState={diffUtil.getDiffChangeStateForChangesOnly(block, 'dateTimeType')}
              isTextSticky={false}
            >
              <div className="flex flex-row gap-x-2">
                <TimestampFieldInputDisplay
                  type={sharedDiffUtil.getDiffValue(block, 'dateTimeType', 'new')}
                  recorded={recorded as RunFieldInputTimestampBlock['recorded']}
                >
                  <TextLinkify>
                    <div className="flex self-center max-w-max">
                      <ProcedureDiffText diffValue={block.name} useMarkdownWhenNoDiff={true} />
                    </div>
                  </TextLinkify>
                </TimestampFieldInputDisplay>
              </div>
            </DiffContainer>
          )}
          {block.inputType === 'checkbox' && (
            <div className="flex items-start">
              <input
                type="checkbox"
                className="w-6 h-6 mt-1.5 border text-gray-500 bg-gray-300 rounded-sm"
                checked={!!recorded?.value}
                disabled
              />
            </div>
          )}

          {/* Using flex: 2 for the label and flex: 1 for the value, allows us to give priority to the label before wrapping. */}
          {block.inputType !== 'timestamp' && block.inputType !== 'number' && (
            <TextLinkify>
              <div className="flex self-start mt-1.5 max-w-max">
                <ProcedureDiffText diffValue={block.name} useMarkdownWhenNoDiff={true} />
              </div>
            </TextLinkify>
          )}

          {block.inputType === 'text' && (
            <>
              <div className="self-start mt-1.5">=</div>
              <div style={{ flex: '1' }} className="flex flex-nowrap flex-grow justify-between">
                <div className="flex flex-nowrap">
                  {(!recorded || isEmptyValue(recorded.value)) && (
                    // hardcoded width and height to match TextAreaAutoHeight styling
                    <div className="w-48 h-[38px] border border-gray-400 rounded bg-gray-300 bg-opacity-50"></div>
                  )}
                  {recorded && !isEmptyValue(recorded.value) && (
                    <div
                      style={{ minWidth: '12rem' }}
                      className="text-sm p-2 border border-gray-400 rounded bg-gray-300 bg-opacity-50 whitespace-pre-wrap"
                    >
                      {recorded?.value as RecordedString}
                    </div>
                  )}
                  {block.units && (
                    <span className="ml-2 self-center whitespace-pre-wrap">
                      <ProcedureDiffText diffValue={block.units} />
                    </span>
                  )}
                </div>
              </div>
            </>
          )}

          {block.inputType === 'number' && <ReviewNumberFieldInput block={block} />}

          {isSelectType && (
            <div className="flex flex-none max-w-full pr-14 w-64">
              <DiffContainer
                label={{ list: 'List', select: 'Custom list' }[block.inputType]}
                diffChangeState={getDiffChangeState()}
                isTextSticky={false}
              >
                <Formik
                  initialValues={{}}
                  onSubmit={() => {
                    /* no-op */
                  }}
                >
                  <Field
                    value={selectOptions}
                    component={FieldSetMultiSelectCreatable}
                    options={selectOptionsList}
                    placeholder="Create options*"
                    isDisabled={true}
                  />
                </Formik>
              </DiffContainer>
            </div>
          )}

          {isSketchType && !hasRecordedSketch && (
            <Button type="primary" isDisabled={true}>
              <FontAwesomeIcon icon="paintbrush" />
              <span>Add Sketch</span>
            </Button>
          )}

          {hasRecordedSketch && (
            <div className="flex">
              {(recorded?.value as SketchValue)?.text && (
                <div
                  style={{ minWidth: '12rem' }}
                  className="h-fit text-sm p-2 border border-gray-400 rounded bg-gray-300 bg-opacity-50 whitespace-pre-wrap"
                >
                  {(recorded?.value as SketchValue)?.text as RecordedString}
                </div>
              )}

              <div className="ml-2">
                <AttachmentPreview size="sm" crop={false} canDownload={false} />
              </div>
            </div>
          )}

          {hasRecordedAttachment && (
            <div className="mt-0.5">
              <AttachmentPreview />
            </div>
          )}
          {isAttachmentType && !hasRecordedAttachment && (
            <div className="mt-1">
              <input type="file" disabled />
            </div>
          )}
        </div>
        {/* Multiple choice options are rendered in a vertical list */}
        {isMultipleChoiceType && (
          <div className="flex mb-2">
            <Formik
              initialValues={{}}
              onSubmit={() => {
                /* no-op */
              }}
            >
              <Field
                name={blockId}
                onChange={setRecordedValue}
                options={(block as FieldInputMultipleChoiceBlockDiffElement).options}
                value={recorded && recorded.value}
                component={RadioGroupFieldSetReview}
                isDisabled={!isEnabled}
              />
            </Formik>
          </div>
        )}

        {block.inputType === 'external_search' && (
          <FieldInputExternalSearchReview
            block={block as FieldInputExternalSearchBlock}
            recorded={recorded as { value: ExternalDataValue }}
            isEnabled={isEnabled}
            onRecordValuesChanged={onRecordValuesChanged as (recorded: RecordedValue<ExternalDataItem>) => void}
            onContentRefChanged={onContentRefChanged}
            scrollMarginTopValueRem={scrollMarginTopValueRem}
          />
        )}
      </div>
    );
  }
);

export default FieldInputReview;
