import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  AI_PROCEDURE_GENERATION_ENABLED,
  AUTOMATION_UI_ENABLED_KEY,
  BUILDS_PERMISSIONS_ENABLED_KEY,
  DEV_NEW_NOTIFICATIONS_DISABLED_KEY,
  DEV_TELEMETRY_BULK_FETCH,
  FULL_DIFF_REVIEW_DISABLED_KEY,
  INVENTORY_ENABLED_KEY,
  ISSUES_ENABLED_KEY,
  JAMA_INTEGRATION_ENABLED,
  JIRA_INTEGRATION_ENABLED_KEY,
  PLANNING_ENABLED_KEY,
  POSTGRES_READ_WRITE_RUNS_ENABLED,
  POSTGRES_READ_WRITE_SNIPPETS_ENABLED,
  POSTGRES_READ_WRITE_REDLINES_ENABLED,
  PRO_ENABLED_KEY,
  PROJECT_NAVIGATION_ENABLED_KEY,
  QUICKBOOKS_INTEGRATION_ENABLED_KEY,
  RISKS_ENABLED_KEY,
  SLACK_INTEGRATION_ENABLED_KEY,
  STORAGE_ENABLED_KEY,
  SUBSTEP_SYNC_DEBOUNCE,
  TEST_CONDITIONS_MATRIX_ENABLED_KEY,
  TEST_MANAGEMENT_VERSIONS_REVIEW_KEY,
  WORK_ORDERS_ENABLED_KEY,
} from 'shared/lib/types/api/settings/modules/models';
import { useMixpanel } from './MixpanelContext';
import {
  fetchConfig,
  fetchExternalData,
  fetchEnabledModules,
  fetchOperations,
  fetchProjects,
  fetchTags,
  fetchRunTags,
  updateUsers,
  fetchDefaultView,
  selectDocByTeamId,
  fetchIntegrations,
  type ReduxState,
  type ReduceAction,
  updateOperatorRoles,
  updateUnits,
  fetchUserPreferences,
} from './settingsSlice';
import MixpanelUtil from '../lib/mixpanel';
import { useDatabaseServices } from './DatabaseContext';
import { getSession, getDefaultTeamIdFromSession } from '../api/superlogin';
import { DOC_ID_EXTERNAL_DATA } from '../api/settings';
import { type Settings } from '../components/Settings/types';
import { ExternalDataDocument } from '../lib/models/postgres/externalData';
import projectUtil from '../lib/projectUtil';
import { CouchLikeOperations } from 'shared/lib/types/operations';
import { EnabledModulesView, OperatorRoles, Units } from '../lib/views/settings';
import { Operator } from 'shared/lib/types/settings';
import { Config, ConfigList, Projects, RunTags, Tags, Users } from 'shared/lib/types/couch/settings';
import { Integrations } from '../lib/models/postgres/integrations';
import { useRealtimeContext } from './RealtimeContext';
import { EventMessage } from 'shared/lib/types/realtimeUpdatesTypes';
import { Services } from '../api/types';
import OperatorRolesService from '../api/operatorRoles';
import { getOperatorRoleToUsersMap } from 'shared/lib/settingsUtil';
import { DefaultView, UserPreference, UserPreferences } from 'shared/lib/types/postgres/users';
import UnitsService from '../settings/api/units';
import { Unit } from 'shared/lib/types/api/settings/units/models';
import { CreateUnitReq } from 'shared/lib/types/api/settings/units/requests';
import { E3Session } from 'shared/lib/types/api/util';

interface Props {
  children: React.ReactNode;
}

export type OperationsPagination = {
  planning_page: number;
  running_page: number;
  ended_page: number;
  limit: number;
};

const SettingsContext = React.createContext<undefined | Settings>(undefined);

const SettingsProvider = (props: Props) => {
  const { services, currentTeamId } = useDatabaseServices();
  const { realtimeService } = useRealtimeContext();
  const users = useSelector((state: ReduxState) => selectDocByTeamId<Users>(state, currentTeamId, 'users'));
  const defaultView = useSelector((state: ReduxState) =>
    selectDocByTeamId<DefaultView>(state, currentTeamId, 'default_view')
  );
  const userPreferences = useSelector((state: ReduxState) =>
    selectDocByTeamId<UserPreferences>(state, currentTeamId, 'user_preferences')
  );
  const config = useSelector((state: ReduxState) => selectDocByTeamId<Config>(state, currentTeamId, 'config'));
  const operatorRoles = useSelector((state: ReduxState) =>
    selectDocByTeamId<OperatorRoles>(state, currentTeamId, 'operator_roles')
  );
  const units = useSelector((state: ReduxState) => selectDocByTeamId<Units>(state, currentTeamId, 'units'));
  const projects = useSelector((state: ReduxState) => selectDocByTeamId<Projects>(state, currentTeamId, 'projects'));
  const tags = useSelector((state: ReduxState) => selectDocByTeamId<Tags>(state, currentTeamId, 'tags'));
  const runTags = useSelector((state: ReduxState) => selectDocByTeamId<RunTags>(state, currentTeamId, 'run_tags'));
  const operations = useSelector((state: ReduxState) =>
    selectDocByTeamId<CouchLikeOperations>(state, currentTeamId, 'operations')
  );
  const [operationsPagination, setOperationsPagination] = useState<OperationsPagination | null>(null);
  const [operationsSearchTerm, setOperationsSearchTerm] = useState<string | null>(null);
  const externalData = useSelector((state: ReduxState) =>
    selectDocByTeamId<ExternalDataDocument>(state, currentTeamId, DOC_ID_EXTERNAL_DATA)
  );
  const enabledModules = useSelector((state: ReduxState) =>
    selectDocByTeamId<EnabledModulesView>(state, currentTeamId, 'enabled_modules')
  );
  const integrations = useSelector((state: ReduxState) =>
    selectDocByTeamId<Integrations>(state, currentTeamId, 'integrations')
  );
  const { mixpanel } = useMixpanel();
  const dispatch = useDispatch();

  const [currentView, setCurrentView] = useState<DefaultView | null>(defaultView);
  const [defaultViewIsLoaded, setDefaultViewIsLoaded] = useState(false);

  const fetchOperationsConfig = useMemo(() => {
    return {
      operations: {
        pagination: operationsPagination,
        searchTerm: operationsSearchTerm,
      },
    };
  }, [operationsPagination, operationsSearchTerm]);

  // This also handles initial operations load
  useEffect(() => {
    dispatch(
      fetchOperations({
        services,
        config: fetchOperationsConfig,
      })
    );
  }, [dispatch, fetchOperationsConfig, services]);

  useEffect(() => {
    if (config && mixpanel) {
      const session = getSession() as E3Session;
      const teamId = getDefaultTeamIdFromSession(session);
      MixpanelUtil.updateWithTeam(mixpanel, teamId, config.name);
    }
  }, [config, mixpanel]);

  // Load and observe team operations list
  useEffect(() => {
    if (!services.settings) {
      return;
    }
    // Setup watcher before initial load
    const observer = (services as Services).settings.onOperationsChanged(() => {
      dispatch(fetchOperations({ services, config: fetchOperationsConfig }));
    });
    // Cleanup
    return () => observer.cancel();
  }, [services, dispatch, fetchOperationsConfig]);

  // Load and observe team config
  useEffect(() => {
    if (!services.settings) {
      return;
    }
    // Setup watcher before initial load
    const observer = services.settings.onConfigChanged(() => {
      dispatch(fetchConfig({ services }));
    });
    // Initial config load
    dispatch(fetchConfig({ services }));
    // Cleanup
    return () => observer.cancel();
  }, [services, dispatch]);

  // Load and observe operator roles
  useEffect(() => {
    if (!realtimeService) {
      return;
    }

    // Initial load
    dispatch(updateOperatorRoles({ updatesDoc: { action: 'BACK_ONLINE', data: {} }, teamId: currentTeamId, services }));
    const realtimeObserver = realtimeService.onOperatorRoleEvent((data: EventMessage) => {
      dispatch(updateOperatorRoles({ updatesDoc: data, teamId: currentTeamId, services }));
    });
    return () => {
      realtimeObserver.cancel();
    };
  }, [realtimeService, dispatch, currentTeamId, services]);

  // Load and observe units
  useEffect(() => {
    if (!realtimeService) {
      return;
    }

    // Initial load
    dispatch(updateUnits({ updatesDoc: { action: 'BACK_ONLINE', data: {} }, teamId: currentTeamId, services }));
    const realtimeObserver = realtimeService.onUnitEvent((data: EventMessage) => {
      dispatch(updateUnits({ updatesDoc: data, teamId: currentTeamId, services }));
    });
    return () => {
      realtimeObserver.cancel();
    };
  }, [realtimeService, dispatch, currentTeamId, services]);

  // Load and observe team users
  useEffect(() => {
    if (!realtimeService) {
      return;
    }
    const realtimeObserver = realtimeService.onUsersEvent((data: EventMessage) => {
      dispatch(updateUsers({ updatesDoc: data, teamId: currentTeamId, services }));
    });

    return () => {
      realtimeObserver.cancel();
    };
  }, [services, dispatch, realtimeService, currentTeamId]);

  // When the current team changes, reset loaded state to allow the default view to re-fetch
  useEffect(() => {
    setDefaultViewIsLoaded(false);
  }, [currentTeamId]);

  // Load and observe default views
  useEffect(() => {
    if (!services.settings || defaultViewIsLoaded) {
      return;
    }
    // Setup watcher before initial load
    const observer = services.settings.onDefaultViewChanged(() => {
      dispatch(fetchDefaultView({ services }));
    });
    // Initial load - only set a default view once it is defined and not null
    if (defaultView) {
      setCurrentView(defaultView);
      setDefaultViewIsLoaded(true);
    }
    dispatch(fetchDefaultView({ services }));
    // Cleanup
    return () => observer.cancel();
  }, [services, dispatch, defaultView, defaultViewIsLoaded]);

  // Load user preferences, do not observe for now
  useEffect(() => {
    if (!services.settings) {
      return;
    }
    dispatch(fetchUserPreferences({ services }));
  }, [services, dispatch]);

  // Load and observe team projects
  useEffect(() => {
    if (!services.settings) {
      return;
    }
    // Setup watcher before initial load
    const observer = services.settings.onProjectsChanged(() => {
      dispatch(fetchProjects({ services }));
    });
    // Initial load
    dispatch(fetchProjects({ services }));
    // Cleanup
    return () => observer.cancel();
  }, [services, dispatch]);

  // Load and observe external data config.
  useEffect(() => {
    if (!services.settings) {
      return;
    }
    // Setup watcher before initial load
    const observer = services.settings.onDocChanged(DOC_ID_EXTERNAL_DATA, () => {
      dispatch(fetchExternalData({ services }));
    });
    // Initial load
    dispatch(fetchExternalData({ services }));
    // Cleanup
    return () => observer.cancel();
  }, [services, dispatch]);

  // Load enabled modules list.
  useEffect(() => {
    if (!services.settings) {
      return;
    }
    // Initial load
    dispatch(fetchEnabledModules({ services }));
  }, [services, dispatch]);

  // Load and observe team tags.
  useEffect(() => {
    if (!services.settings) {
      return;
    }
    // Setup watcher before initial load
    const observer = services.settings.onDocChanged('tags', () => {
      dispatch(fetchTags({ services }));
    });
    // Initial load
    dispatch(fetchTags({ services }));
    // Cleanup
    return () => observer.cancel();
  }, [services, dispatch]);

  // Load and observe team run tags.
  useEffect(() => {
    if (!services.settings) {
      return;
    }
    // Setup watcher before initial load
    const observer = services.settings.onDocChanged('run_tags', () => {
      dispatch(fetchRunTags({ services }));
    });
    // Initial load
    dispatch(fetchRunTags({ services }));
    // Cleanup
    return () => observer.cancel();
  }, [services, dispatch]);

  // Load integrations
  useEffect(() => {
    if (!services.settings) {
      return;
    }
    // Initial load
    dispatch(fetchIntegrations({ services }));
  }, [services, dispatch]);

  const updateConfig = useCallback(
    (config) => {
      if (!services.settings) {
        return Promise.reject('Database services unavailable.');
      }
      return services.settings
        .updateConfig(config)
        .then(() =>
          // Refresh config doc and return to callers
          dispatch(fetchConfig({ services }))
        )
        .then((res: ReduceAction<Config>) => {
          if (res.error || !(res.payload && res.payload.doc)) {
            return Promise.reject(new Error('Error updating config'));
          }
          return res.payload.doc;
        });
    },
    [services, dispatch]
  );

  const createOperatorRole = useCallback(
    (role: Operator): Promise<{ id: string }> => {
      if (!services.settings) {
        return Promise.reject('Database services unavailable.');
      }
      const service = new OperatorRolesService(currentTeamId);
      return service.createOperatorRole({
        name: role.name,
        code: role.code,
        canRelease: role.can_release,
        canSuggestEdits: role.can_suggest_edits,
      });
    },
    [services, currentTeamId]
  );

  const createUnit = useCallback(
    (unit: Unit | CreateUnitReq): Promise<number> => {
      if (!services.settings) {
        return Promise.reject('Database services unavailable.');
      }
      const service = new UnitsService(currentTeamId);
      return service.createUnit(unit);
    },
    [services, currentTeamId]
  );

  const getSetting = useCallback(
    (key, defaultValue) => {
      // If config isn't loaded or key is not present, return default value.
      if (!config || !(key in config)) {
        return defaultValue;
      }
      return config[key];
    },
    [config]
  );

  const setSetting = useCallback(
    (key, value) => {
      if (!services.settings) {
        return Promise.reject('Database services unavailable.');
      }

      return services.settings
        .setSetting(key, value)
        .then(() =>
          // Refresh config doc and return to callers
          dispatch(fetchConfig({ services }))
        )
        .then((res: ReduceAction<Config | Users | Projects>) => {
          if (res.error || !(res.payload && res.payload.doc)) {
            return Promise.reject(new Error('Error updating setting'));
          }
          return res.payload.doc;
        });
    },
    [services, dispatch]
  );

  const updateCurrentView = useCallback((view: DefaultView) => {
    setCurrentView(view);
  }, []);

  const updateUserPreference = useCallback(
    (preference: UserPreference) => {
      if (!services.settings) {
        return Promise.reject('Database services unavailable.');
      }
      // The then => dispatch is to update redux offline so a page refresh doesn't hit stale preferences
      return services.settings
        .updateUserPreference(preference)
        .then(() => dispatch(fetchUserPreferences({ services })));
    },
    [dispatch, services]
  );

  const updateDefaultView = useCallback(
    (defaultView: DefaultView) => {
      if (!services.settings) {
        return Promise.reject('Database services unavailable.');
      }

      return services.settings
        .updateDefaultView(defaultView)
        .then(() => dispatch(fetchDefaultView({ services })))
        .then((res: ReduceAction<DefaultView>) => {
          if (res.error || !(res.payload && res.payload.doc)) {
            return Promise.reject(new Error('Error updating default view'));
          }
          return res.payload.doc;
        });
    },
    [services, dispatch]
  );

  const addProject = useCallback(
    (project) => {
      if (!services.settings) {
        return Promise.reject('Database services unavailable.');
      }
      return services.settings
        .addProject(project)
        .then(() =>
          // Refresh projects doc and return to callers.
          dispatch(fetchProjects({ services }))
        )
        .then((res: ReduceAction<Projects>) => {
          if (res.error || !(res.payload && res.payload.doc)) {
            return Promise.reject(new Error('Error adding project'));
          }
          return res.payload.doc;
        });
    },
    [services, dispatch]
  );

  const updateProject = useCallback(
    (project) => {
      if (!services.settings) {
        return Promise.reject('Database services unavailable.');
      }

      if (!projectUtil.isProjectCodeUnique(project, projects?.projects)) {
        return Promise.reject(new Error('Please enter a unique project code.'));
      }

      return services.settings
        .updateProject(project, project.id)
        .then(() =>
          // Refresh projects doc and return to callers.
          dispatch(fetchProjects({ services }))
        )
        .then((res: ReduceAction<Projects>) => {
          if (res.error || !(res.payload && res.payload.doc)) {
            return Promise.reject(new Error('Error updating project'));
          }
          return res.payload.doc;
        });
    },
    [services, dispatch, projects]
  );

  const externalDataTypes = useMemo(() => {
    if (!externalData || !externalData.types) {
      return [];
    }
    return externalData.types;
  }, [externalData]);

  const getListMetadata = useCallback(
    (listId): ConfigList | undefined => {
      return config?.lists?.[listId];
    },
    [config]
  );

  const getListValues = useCallback(
    (listId): ConfigList['options'] | undefined => {
      return getListMetadata(listId)?.options;
    },
    [getListMetadata]
  );

  const isProductModuleEnabled = useCallback(
    (moduleKey) => {
      if (!enabledModules || !enabledModules.keys) {
        return false;
      }
      return enabledModules.keys.includes(moduleKey);
    },
    [enabledModules]
  );

  const isProEnabled = useCallback(() => {
    return isProductModuleEnabled(PRO_ENABLED_KEY);
  }, [isProductModuleEnabled]);

  const isScheduleModuleEnabled = useCallback(() => {
    return isProductModuleEnabled(PLANNING_ENABLED_KEY);
  }, [isProductModuleEnabled]);

  const isManufacturingEnabled = useCallback(() => {
    return isProductModuleEnabled(INVENTORY_ENABLED_KEY);
  }, [isProductModuleEnabled]);

  const isStorageEnabled = useCallback(() => {
    return isProductModuleEnabled(STORAGE_ENABLED_KEY);
  }, [isProductModuleEnabled]);

  const isJiraIntegrationEnabled = useCallback(() => {
    if (isProductModuleEnabled(ISSUES_ENABLED_KEY)) {
      return false;
    }
    if (!isProductModuleEnabled(JIRA_INTEGRATION_ENABLED_KEY)) {
      return false;
    }
    const jiraProperties = integrations?.integrations['jira']?.properties;
    if (jiraProperties) {
      for (const property of jiraProperties) {
        // The Jira app will set the 'webtrigger' property when connected to E3
        if (property.name === 'webtrigger') {
          return true;
        }
      }
    }
    return false;
  }, [isProductModuleEnabled, integrations]);

  const operatorRoleToUsersMap = useMemo(() => {
    return getOperatorRoleToUsersMap(users?.users);
  }, [users]);

  const isIssuesEnabled = useCallback(() => {
    if (isProductModuleEnabled(JIRA_INTEGRATION_ENABLED_KEY)) {
      return false;
    }
    return isProductModuleEnabled(ISSUES_ENABLED_KEY);
  }, [isProductModuleEnabled]);

  const isSlackIntegrationEnabled = useCallback(() => {
    return isProductModuleEnabled(SLACK_INTEGRATION_ENABLED_KEY);
  }, [isProductModuleEnabled]);

  const isBoxIntegrationInstallEnabled = useCallback(() => isProEnabled(), [isProEnabled]);

  const isDuroIntegrationInstallEnabled = useCallback(() => isManufacturingEnabled(), [isManufacturingEnabled]);

  const isQuickbooksIntegrationEnabled = useCallback(() => {
    return isProductModuleEnabled(QUICKBOOKS_INTEGRATION_ENABLED_KEY);
  }, [isProductModuleEnabled]);

  const isTestConditionsMatrixEnabled = useCallback(() => {
    return isProductModuleEnabled(TEST_CONDITIONS_MATRIX_ENABLED_KEY);
  }, [isProductModuleEnabled]);

  const isProcedureImportEnabled = useCallback(() => isProEnabled(), [isProEnabled]);

  const isFullDiffReviewDisabled = useCallback(() => {
    return isProductModuleEnabled(FULL_DIFF_REVIEW_DISABLED_KEY);
  }, [isProductModuleEnabled]);

  const areNewNotificationsDisabled = useCallback(() => {
    return isProductModuleEnabled(DEV_NEW_NOTIFICATIONS_DISABLED_KEY);
  }, [isProductModuleEnabled]);

  const isAutomationUIEnabled = useCallback(() => {
    return isProductModuleEnabled(AUTOMATION_UI_ENABLED_KEY);
  }, [isProductModuleEnabled]);

  const isTestManagementVersionsReview = useCallback(() => {
    return isProductModuleEnabled(TEST_MANAGEMENT_VERSIONS_REVIEW_KEY);
  }, [isProductModuleEnabled]);

  const isTelemetryBulkFetchEnabled = useCallback(() => {
    return isProductModuleEnabled(DEV_TELEMETRY_BULK_FETCH);
  }, [isProductModuleEnabled]);

  const isRisksEnabled = useCallback(() => {
    return isProductModuleEnabled(RISKS_ENABLED_KEY);
  }, [isProductModuleEnabled]);

  const isJamaIntegrationEnabled = useCallback(() => {
    return isProductModuleEnabled(JAMA_INTEGRATION_ENABLED);
  }, [isProductModuleEnabled]);

  const isBuildsPermissionsEnabled = useCallback(() => {
    return isProductModuleEnabled(BUILDS_PERMISSIONS_ENABLED_KEY);
  }, [isProductModuleEnabled]);

  const isProjectNavigationEnabled = useCallback(() => {
    return isProductModuleEnabled(PROJECT_NAVIGATION_ENABLED_KEY);
  }, [isProductModuleEnabled]);

  const isWorkOrdersEnabled = useCallback(() => {
    return isProductModuleEnabled(WORK_ORDERS_ENABLED_KEY);
  }, [isProductModuleEnabled]);

  const isSubstepDebounceEnabled = useCallback(() => {
    return isProductModuleEnabled(SUBSTEP_SYNC_DEBOUNCE);
  }, [isProductModuleEnabled]);

  const isAiProcedureGenerationEnabled = useCallback(() => {
    return isProductModuleEnabled(AI_PROCEDURE_GENERATION_ENABLED);
  }, [isProductModuleEnabled]);

  const isPostgresReadWriteRunsEnabled = useCallback(() => {
    return isProductModuleEnabled(POSTGRES_READ_WRITE_RUNS_ENABLED);
  }, [isProductModuleEnabled]);

  const isPostgresReadWriteSnippetsEnabled = useCallback(() => {
    return isProductModuleEnabled(POSTGRES_READ_WRITE_SNIPPETS_ENABLED);
  }, [isProductModuleEnabled]);

  const isPostgresReadWriteRedlinesEnabled = useCallback(() => {
    return isProductModuleEnabled(POSTGRES_READ_WRITE_REDLINES_ENABLED);
  }, [isProductModuleEnabled]);

  return (
    <SettingsContext.Provider
      value={{
        config,
        operatorRoles,
        units,
        externalDataTypes,
        createOperatorRole,
        createUnit,
        updateConfig,
        users,
        userPreferences,
        updateUserPreference,
        defaultView,
        updateDefaultView,
        currentView,
        updateCurrentView,
        projects,
        tags,
        runTags,
        addProject,
        getListMetadata,
        getListValues,
        updateProject,
        getSetting,
        setSetting,
        operations,
        operationsPagination,
        setOperationsPagination,
        operationsSearchTerm,
        setOperationsSearchTerm,
        operatorRoleToUsersMap,
        isProductModuleEnabled,
        isProEnabled,
        isScheduleModuleEnabled,
        isManufacturingEnabled,
        isStorageEnabled,
        isJiraIntegrationEnabled,
        isSlackIntegrationEnabled,
        isBoxIntegrationInstallEnabled,
        isDuroIntegrationInstallEnabled,
        isQuickbooksIntegrationEnabled,
        isIssuesEnabled,
        isTestConditionsMatrixEnabled,
        isProcedureImportEnabled,
        isFullDiffReviewDisabled,
        areNewNotificationsDisabled,
        isAutomationUIEnabled,
        isTestManagementVersionsReview,
        isTelemetryBulkFetchEnabled,
        isRisksEnabled,
        isJamaIntegrationEnabled,
        isBuildsPermissionsEnabled,
        isProjectNavigationEnabled,
        isWorkOrdersEnabled,
        isSubstepDebounceEnabled,
        isAiProcedureGenerationEnabled,
        isPostgresReadWriteRunsEnabled,
        isPostgresReadWriteSnippetsEnabled,
        isPostgresReadWriteRedlinesEnabled,
      }}
    >
      {props.children}
    </SettingsContext.Provider>
  );
};

const useSettings = () => {
  const context = useContext(SettingsContext);
  if (context === undefined) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }

  return (
    context || {
      config: {},
      users: {},
      projects: {},
      tags: {},
      runTags: {},
      operations: {},
      externalDataTypes: [],
    }
  );
};

/**
 * AUTO_COLLAPSE_UNACTIONABLE_STEPS_KEY used to be
 * AUTO_COLLAPSE_COMPLETED_STEPS_KEY. It was updated to encompase what evolved
 * into a more general settings toggle, but the underlying key stored in each
 * team settings doc was unchanged to avoid a migration.
 */
const AUTO_COLLAPSE_UNACTIONABLE_STEPS_KEY = 'auto_collapse_completed_steps';
const STREAM_TELELMETRY_FOR_COLLAPSED_STEPS = 'stream_telemetry_for_collapsed_steps';
const REQUIRE_SUGGESTED_EDIT_APPROVAL_KEY = 'require_suggested_edit_approval';
const REQUIRE_SEND_COMMAND_CONFIRMATION_KEY = 'require_send_command_confirmation';
const AUTO_COLLAPSE_STEP_TIMELINE_KEY = 'auto_collapse_step_timeline_key';
const API_WEBHOOK_URL_KEY = 'api_webhook_url';
const REQUIRED_REVIEWERS_KEY = 'reviewer_groups';
const REVIEW_TYPES_KEY = 'review_types';
const ORDERS_REVIEW_TYPES_KEY = 'orders_review_types';
const PARTS_REVIEW_TYPES_KEY = 'parts_review_types';
const PARTS_REVIEW_ENABLED_KEY = 'parts_review_enabled';
const BUILDS_AUTO_NUMBERING_KEY = 'builds_auto_numbering';
const PIN_SIGNOFF_ENABLED_KEY = 'pin_signoff';
const DISABLE_COMMENTS_ON_ENDED_RUNS_KEY = 'disable_comments_on_ended_runs';

export {
  SettingsProvider,
  useSettings,
  AUTO_COLLAPSE_UNACTIONABLE_STEPS_KEY,
  REQUIRE_SUGGESTED_EDIT_APPROVAL_KEY,
  API_WEBHOOK_URL_KEY,
  REQUIRED_REVIEWERS_KEY,
  REVIEW_TYPES_KEY,
  REQUIRE_SEND_COMMAND_CONFIRMATION_KEY,
  ORDERS_REVIEW_TYPES_KEY,
  PARTS_REVIEW_TYPES_KEY,
  PARTS_REVIEW_ENABLED_KEY,
  BUILDS_AUTO_NUMBERING_KEY,
  PIN_SIGNOFF_ENABLED_KEY,
  STREAM_TELELMETRY_FOR_COLLAPSED_STEPS,
  AUTO_COLLAPSE_STEP_TIMELINE_KEY,
  DISABLE_COMMENTS_ON_ENDED_RUNS_KEY,
};
