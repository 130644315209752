import procedureUtil from '../../lib/procedureUtil';
import {
  DraftTableInputBlock,
  RunStepComment,
  TableColumn,
} from 'shared/lib/types/views/procedures';
import { TestCase } from '../../testing/types';
import tableInputUtil from '../../lib/tableInputUtil';

export const SIGNOFF_COLUMN_TYPE = 'signoff';

export const INITIAL_COLUMN_TEXT = {
  id: '',
  name: '',
  column_type: 'text',
  input_type: 'text',
  units: '',
  width: 45,
} as TableColumn;

export const INITIAL_COLUMN_INPUT_TEXT = {
  name: '',
  column_type: 'input',
  input_type: 'text',
  units: '',
  width: 45,
} as TableColumn;

export const INITIAL_COLUMN_INPUT_CHECKBOX = {
  name: '',
  column_type: 'input',
  input_type: 'checkbox',
  units: '',
  width: 10,
} as TableColumn;

export const INITIAL_COLUMN_INPUT_LIST = {
  name: '',
  column_type: 'input',
  input_type: 'list',
  units: '',
  width: 45,
  list: '',
} as TableColumn;

export const INITIAL_COLUMN_SIGNOFF = {
  name: '',
  column_type: SIGNOFF_COLUMN_TYPE,
  input_type: undefined,
  units: '',
  width: 45,
} as TableColumn;

export const INITIAL_COLUMN_TEST_POINT = {
  name: 'Test Point',
  column_type: 'test_point',
  input_type: undefined,
  units: '',
  width: 60,
  disabled: true,
} as TableColumn;

export const INITIAL_COLUMN_COMMENT = {
  name: '',
  column_type: 'comment',
  input_type: undefined,
  units: '',
  width: 10,
} as TableColumn;

export const getInitialContentTableInput = (
  isReadOnly = false,
  numRows = 4
): DraftTableInputBlock => {
  const tableColumn = isReadOnly
    ? [{ ...INITIAL_COLUMN_TEXT }, { ...INITIAL_COLUMN_TEXT }]
    : [
        { ...INITIAL_COLUMN_TEXT },
        { ...INITIAL_COLUMN_INPUT_TEXT },
        { ...INITIAL_COLUMN_INPUT_CHECKBOX },
      ];
  return {
    id: procedureUtil.generateContentId(),
    type: 'table_input',
    columns: tableColumn.map((column) => {
      return {
        ...column,
        id: procedureUtil.generateTableInputColumnId(),
      };
    }),
    row_metadata: Array.from({ length: numRows }, () => ({
      id: procedureUtil.generateTableInputRowId(),
    })),
    rows: numRows,
    cells: [
      new Array(3).fill(''),
      new Array(3).fill(''),
      new Array(3).fill(''),
      new Array(3).fill(''),
    ],
    include_in_summary: true,
  };
};

export const getInitialTestPointTable = ({
  testPoints,
  listName,
  listId,
}: {
  testPoints: Array<TestCase>;
  listName?: string;
  listId?: string;
}): DraftTableInputBlock => {
  const tableColumn = [
    { ...INITIAL_COLUMN_TEST_POINT },
    { ...getInitialSignoffColumn(true, 'Start', 10) },
    { ...getInitialInputListColumn(listName, listId, 10) },
    { ...getInitialSignoffColumn(false, 'End', 10) },
    { ...INITIAL_COLUMN_COMMENT },
  ];

  return {
    id: procedureUtil.generateContentId(),
    type: 'table_input',
    sub_type: 'test_point',
    columns: tableColumn.map((column) => {
      return {
        ...column,
        id: procedureUtil.generateTableInputColumnId(),
      };
    }),
    row_metadata: Array.from({ length: testPoints.length }, () => ({
      id: procedureUtil.generateTableInputRowId(),
    })),
    rows: testPoints.length,
    cells: testPoints.map((testPoint) => [
      testPoint,
      [tableInputUtil.getInitialSignoffObject()],
      '', // Empty string for list value
      [tableInputUtil.getInitialSignoffObject()],
      [] as Array<RunStepComment>, // Empty array for comments
    ]),
    include_in_summary: true,
  };
};

export const getInitialTextColumn = (label: string): TableColumn => {
  const column = { ...INITIAL_COLUMN_TEXT };

  column.name = label;
  column.id = procedureUtil.generateTableInputColumnId();

  return column;
};

const getInitialSignoffColumn = (allowInput = false, name = '', width = 45) => {
  const column = { ...INITIAL_COLUMN_SIGNOFF };
  column.allow_input_after_signoff = allowInput;
  column.name = name;
  column.width = width;
  return column;
};

const getInitialInputListColumn = (listName = '', listId = '', width = 45) => {
  const column = { ...INITIAL_COLUMN_INPUT_LIST };
  column.name = listName;
  column.list = listId;
  column.width = width;
  return column;
};
