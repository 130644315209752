import { useCallback, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { Tool } from 'shared/lib/types/api/manufacturing/tools/models';
import {
  DraftToolCheckInBlock,
  DraftToolCheckOutBlock,
  InitialToolCheckInBlock,
  InitialToolCheckOutBlock,
} from 'shared/lib/types/views/procedures';
import FieldSetCheckbox from '../../../components/FieldSetCheckbox';
import { NumberSelectOption as SelectOption } from '../../../lib/formik';
import { reactSelectStyles } from '../../../lib/styles';
import useTools from '../../hooks/useTools';
import toDisplay from '../../lib/tools/toDisplay';
import ThumbnailImageDisplay from '../../../components/Attachments/ThumbnailImageDisplay';
import { toolToSelectOption } from './utils';
import { useRunContext } from '../../../contexts/RunContext';

export type ContentErrors = {
  tool: string;
};

interface DraftToolCheckOutInProps {
  content: InitialToolCheckOutBlock | DraftToolCheckOutBlock | InitialToolCheckInBlock | DraftToolCheckInBlock;
  type: 'out' | 'in';
  contentErrors?: ContentErrors | null;
  path: string;
  setFieldValue?: (path: string, value: DraftToolCheckOutBlock | DraftToolCheckInBlock) => void;
}

const DraftToolCheckOutIn = ({ content, type, contentErrors, path, setFieldValue }: DraftToolCheckOutInProps) => {
  const { allTools } = useTools();
  const { isRun } = useRunContext();

  const [selectedTool, setSelectedTool] = useState<SelectOption>();

  useEffect(() => {
    if (content.tool_id === null || !allTools) {
      return;
    }
    const tool = allTools.find((tool) => tool.id === content.tool_id);
    if (tool) {
      setSelectedTool(toolToSelectOption(tool));
    }
  }, [allTools, content.tool_id]);

  const toolOptions: Array<SelectOption> = useMemo(() => {
    if (!allTools) {
      return [];
    }
    return allTools.map(toolToSelectOption);
  }, [allTools]);

  const formatOptionLabel = (tool?: Tool) => {
    if (!tool) {
      return null;
    }
    return (
      <div className="flex">
        <div>
          <ThumbnailImageDisplay size="sm" attachment={toDisplay.fromToolImageProps(tool)} />
        </div>
        <span className="pl-2 truncate">
          {tool.tool_number} {tool.name}
        </span>
      </div>
    );
  };

  const onToolChange = useCallback(
    (toolOption: SelectOption) => {
      const value: DraftToolCheckOutBlock | DraftToolCheckInBlock = {
        ...content,
        tool_id: toolOption.value,
      };
      setSelectedTool(toolOption);
      if (setFieldValue) {
        setFieldValue(path, value);
      }
    },
    [content, path, setFieldValue]
  );

  return (
    <div className="flex">
      <div className="w-72">
        <div className="field-title">Tool for {`${type === 'out' ? 'Check-Out' : 'Check-In'}`}</div>
        <Select
          styles={reactSelectStyles}
          classNamePrefix="react-select"
          formatOptionLabel={(option: SelectOption) =>
            formatOptionLabel(allTools?.find((tool) => tool.id === option.value))
          }
          options={toolOptions}
          value={selectedTool}
          onChange={onToolChange}
          placeholder="Search tools"
          aria-label="Select tool"
        />
        {contentErrors?.tool && <div className="text-red-700 text-sm">{contentErrors.tool}</div>}
      </div>
      <div className="w-2" />
      {/* Include in summary checkbox */}
      <div className="self-end mb-2 flex flex-row items-center">
        <FieldSetCheckbox
          text="Include in Summary"
          fieldName={`${path}.include_in_summary`}
          setFieldValue={setFieldValue}
          isDisabled={isRun}
        />
      </div>
    </div>
  );
};

export default DraftToolCheckOutIn;
