import { useCallback } from 'react';
import { Snippet } from 'shared/lib/types/views/procedures';
import Button, { BUTTON_TYPES } from '../Button';
import SnippetDeleteButton from './SnippetDeleteButton';
import { isEqual } from 'lodash';

interface FieldSetSnippetHeaderProps {
  present: Snippet;
  snippet: Snippet;
  onClose?: () => void;
  onSave: () => void;
  onRemove?: (id: string) => Promise<void>;
  showSubmitError?: boolean;
}

const FieldSetSnippetHeader = ({
  present,
  snippet,
  onClose,
  onSave,
  onRemove,
  showSubmitError,
}: FieldSetSnippetHeaderProps) => {
  const handleOnClose = useCallback(() => {
    // Show close confirmation only if user has made edits
    if (!isEqual(present, snippet)) {
      // Confirm with user before closing edit snippet
      const message = 'Are you sure you want to leave? Any changes will be lost.';
      if (!window.confirm(message)) {
        return;
      }
    }
    onClose?.();
  }, [onClose, present, snippet]);

  return (
    <>
      {/* Edit Header */}
      <div className="flex justify-between py-2">
        <span className="text-2xl font-medium"> Edit {present.name} </span>

        {showSubmitError && (
          <div className="flex justify-center m-1">
            <span className="mx-1 text-red-700 self-center">Missing or Invalid Fields</span>
          </div>
        )}

        <div className="flex gap-x-2">
          <SnippetDeleteButton snippet={present} onRemove={onRemove} />

          <Button type={BUTTON_TYPES.SECONDARY} size="sm" onClick={handleOnClose}>
            Cancel
          </Button>

          <Button type={BUTTON_TYPES.PRIMARY} size="sm" onClick={onSave}>
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default FieldSetSnippetHeader;
