import procedureUtil from './procedureUtil';
import { ProcedureContentBlockTypes } from 'shared/lib/types/blockTypes';

const attachmentUtil = {
  /**
   * Upload attached file using upload service, then update content element's
   * attributes to reflect updated attachment attributes.
   *
   * @param {import('shared/lib/types/views/procedures').AttachmentBlock} content - Content element containing file attachment
   * @param {import('../attachments/types').Attachments} service
   * @param {boolean} [remote] - if true, sync to remote
   * @returns {Promise<void>} - Promise that resolves when content element is updated
   *
   */
  _uploadFileFromContent: (content, service, remote = true) => {
    return service.uploadFile(/** @type {File} */ (content.file), 'procedures:file', { remote }).then((results) => {
      if (!results) {
        return;
      }

      content.attachment_id = results.attachment_id;
      content.name = results.name;
      content.content_type = results.content_type;
      delete content.file; // No longer needed, remove from document
    });
  },

  /**
   * Helper method to convert all file objects in a step to uploaded
   * attachments.
   *
   * Modifies passed step object in-place.
   *
   * @param {import('shared/lib/types/views/procedures').Step | import('shared/lib/types/views/procedures').RedlinedStep} step - step with attached files.
   * @param {import('../attachments/types').Attachments} service - AttachmentService object.
   * @param {boolean} [remote] - if true, sync to remote
   * @returns {Promise<Array<void>>} - Promise that resolves when all content elements are
   *                     updated
   */
  uploadAllFilesFromStep: (step, service, remote = true) => {
    const uploads = [];

    step.content.forEach((content) => {
      if (content.type === ProcedureContentBlockTypes.Attachment && 'file' in content && content.file) {
        uploads.push(attachmentUtil._uploadFileFromContent(content, service, remote));
      }
    });

    return Promise.all(uploads);
  },

  /**
   * Helper method to convert all file objects in a section to uploaded
   * attachments.
   *
   * Modifies passed section object in-place.
   *
   * @param {import('shared/lib/types/views/procedures').Section} section - section with attached files.
   * @param {import('../attachments/types').Attachments} service - AttachmentService object.
   * @returns {Promise<Array<void>>} - Promise that resolves when all content elements are
   *                     updated
   */
  uploadAllFilesFromSection: (section, service) => {
    const uploads = [];

    // upload files for each step within section
    section.steps.forEach((step) => {
      uploads.push(attachmentUtil.uploadAllFilesFromStep(step, service));
    });

    // upload files in each header of given section
    section.headers?.forEach((sectionHeader) => {
      sectionHeader.content.forEach((content) => {
        if (content.type === ProcedureContentBlockTypes.Attachment && 'file' in content && content.file) {
          uploads.push(attachmentUtil._uploadFileFromContent(content, service));
        }
      });
    });

    return Promise.all(uploads);
  },

  /**
   * Helper method to convert all file objects in a procedure doc to uploaded
   * attachments.
   *
   * Modifies passed procedure doc in-place.
   *
   * @param {import('shared/lib/types/views/procedures').Procedure} procedure - Procedure document with attached files.
   * @param {import('../attachments/types').Attachments} service - AttachmentService object.
   * @returns {Promise<Array<void>>} - Promise that resolves when all content elements are
   *                     updated
   */
  uploadAllFilesFromProcedure: (procedure, service) => {
    const uploads = [];

    // Convert uploadable files to attachments
    procedure.sections.forEach((section) => {
      uploads.push(attachmentUtil.uploadAllFilesFromSection(section, service));
    });
    procedure.headers?.forEach((header) => {
      header.content.forEach((content) => {
        if (content.type === ProcedureContentBlockTypes.Attachment && 'file' in content && content.file) {
          uploads.push(attachmentUtil._uploadFileFromContent(content, service));
        }
      });
    });

    return Promise.all(uploads);
  },

  /**
   * Get all attachment blocks in a procedure.
   *
   * @param {import('shared/lib/types/views/procedures').Procedure} procedure - Procedure document.
   * @returns {Array<import('shared/lib/types/views/procedures').AttachmentBlock>} - List of
   * attachment blocks.
   */
  getAttachments: (procedure) => {
    const attachments = procedureUtil
      .getAllContentType(procedure, ProcedureContentBlockTypes.Attachment)
      .filter((attachment) => 'attachment_id' in attachment && attachment.attachment_id);
    return /** @type {Array<import('shared/lib/types/views/procedures').AttachmentBlock>} */ (attachments);
  },

  /**
   * Helper method to download all file attachments in a procedure.
   *
   * @param {import('shared/lib/types/views/procedures').Procedure} procedure
   * @param {import('../attachments/types').Attachments} service
   * @returns {Promise} - Promise that resolves on success or rejects on
   *   any failure.
   */
  downloadProcedureAttachments: (procedure, service) => {
    const attachments = attachmentUtil.getAttachments(procedure);
    return service.downloadAllAttachments(attachments);
  },

  /**
   * Wrapper around URL.createObjectURL which is not available in nodejs
   * and therefore cannot be mocked properly for testing useAttachment
   *
   * @param {object} obj - Object to derive the URL from
   * @returns {string}
   */
  createObjectURL: (obj) => {
    return URL.createObjectURL(obj);
  },

  /**
   * Wrapper around URL.revokeObjectURL which is not available in nodejs
   * and therefore cannot be mocked properly for testing useAttachment
   *
   * @param {string} objectUrl - Object URL to revoke
   * @returns {void}
   */
  revokeObjectURL: (objectUrl) => {
    URL.revokeObjectURL(objectUrl);
  },

  /**
   * @param {import('shared/lib/types/attachments').Attachment} attachment
   */
  _isImageAttachment: (attachment) => {
    return attachment.content_type?.startsWith('image');
  },

  /**
   * @param {Array<import('shared/lib/types/attachments').Attachment>} attachments
   */
  getImageAttachents: (attachments) => {
    return attachments.filter(attachmentUtil._isImageAttachment);
  },

  /**
   * @param {Array<import('shared/lib/types/attachments').Attachment>} attachments
   */
  getFileAttachents: (attachments) => {
    return attachments.filter((a) => !attachmentUtil._isImageAttachment(a));
  },

  /**
   * @param {File} attachment
   */
  _isStagedImageAttachment: (attachment) => {
    return attachment.type?.startsWith('image');
  },

  /**
   * @param {Array<File>} attachments
   */
  getStagedImageAttachents: (attachments) => {
    return attachments.filter(attachmentUtil._isStagedImageAttachment);
  },

  /**
   * @param {Array<File>} attachments
   */
  getStagedFileAttachents: (attachments) => {
    return attachments.filter((a) => !attachmentUtil._isStagedImageAttachment(a));
  },
};

export default attachmentUtil;
