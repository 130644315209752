import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import FieldSetColumnHeader from './FieldSetColumnHeader';
import { Field, getIn, setIn } from 'formik';
import RunFieldSetTableCell from './RunFieldSetTableCell';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { INITIAL_COLUMN_TEXT, SIGNOFF_COLUMN_TYPE } from './TableInputConstants';
import TableHeaderMenuContext from './TableHeaderMenuContext';
import tableInputUtil from '../../lib/tableInputUtil';
import cloneDeep from 'lodash.clonedeep';
import procedureUtil from '../../lib/procedureUtil';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import SignoffCell from './SignoffCell';
import UnitDisplay from '../Settings/Units/UnitDisplay';
import tableUtil from 'shared/lib/tableUtil';
import { isNil } from 'lodash';
import Selector from '../../elements/Selector';
import { useSettings } from '../../contexts/SettingsContext';
import TestPointSelectionModal from '../../testing/components/TestPointSelectionModal';
import TestPointCell from './TestPointCell';

const NEW_COLUMN_WIDTH = 20;
const MIN_COLUMN_WIDTH = 8;
const MAX_COLUMN_COUNT = 20;

const TableEdit = React.memo(({ path, content, setFieldValue: updateGlobalState, isReadOnly = false }) => {
  const tableContainerRef = useRef(document.createElement('div'));
  const columnHeaderRefs = useRef({});
  const [columnWidths, setColumnWidths] = useState(() => {
    const widths = {};

    content.columns.forEach((col) => {
      widths[col.id] = col.width;
    });
    return widths;
  });
  const [activeCell, setActiveCell] = useState();
  const [testPointSelectorContext, setTestPointSelectorContext] = useState({
    visible: false,
    sourceRowIndex: null,
  });
  const referenceState = useRef(content);

  const { getListMetadata } = useSettings();

  const updateState = useCallback(
    (path, content) => {
      referenceState.current = content;
      updateGlobalState(path, content);
    },
    [updateGlobalState]
  );

  /**
   * Keep referenceState up to date.
   */
  useEffect(() => {
    referenceState.current = content;
  }, [content]);

  useEffect(() => {
    const resetActiveCell = () => {
      setActiveCell(undefined);
    };

    if (activeCell) {
      document.addEventListener('click', resetActiveCell);
    }

    return () => {
      document.removeEventListener('click', resetActiveCell);
    };
  }, [activeCell]);

  const displayRows = useMemo(() => {
    const rows = new Array(Number(content.rows)).fill('');

    return rows.map((row, rowIndex) =>
      content.columns.map((column, columnIndex) => {
        const cellPath = `cells[${rowIndex}][${columnIndex}]`;
        const inputType = column.input_type;
        const columnType = column.column_type;
        const cellObject = {
          path: cellPath,
          label: column.units ? <UnitDisplay unit={column.units} /> : column.units,
          value: getIn(content, cellPath),
          inputType,
          columnType,
          ...(column.list && { list: column.list }),
        };

        return cellObject;
      })
    );
  }, [content]);

  /**
   *
   * @type {(contentPath: string, value: string | import('shared/lib/types/views/procedures').TableColumn, columnIndexToClear?: number, resetValue?: import('shared/lib/types/views/procedures').TableCell) => void}
   */
  const onContentChange = useCallback(
    (contentPath, value, columnIndexToClear = undefined, resetValue = '') => {
      let updatedContent = setIn(content, contentPath, value);

      if (!isNil(columnIndexToClear) && displayRows) {
        for (let rowIndex = 0; rowIndex < displayRows.length; rowIndex++) {
          updatedContent = setIn(updatedContent, `cells[${rowIndex}][${columnIndexToClear}]`, resetValue);
        }
      }

      referenceState.current = updatedContent;
      updateState(path, updatedContent);
    },
    [content, path, updateState, displayRows]
  );

  const recalculateColumnWidths = useCallback(({ action, columnId, content, columnWidths }) => {
    const newColumnWidths = {};
    const { width: tableContainerWidth } = tableContainerRef.current.getBoundingClientRect();

    if (action === 'add') {
      const problemColumns = [];
      let totalDifference = 0;
      const remainingPercentage = 100 - NEW_COLUMN_WIDTH;

      // Get all the columns that would become less than 5% and subtract the total difference from the new added column
      content.columns.forEach((col) => {
        if (col.id !== columnId) {
          const { width: columnContainerWidth } = columnHeaderRefs.current[col.id].getBoundingClientRect();
          const newWidth = (columnContainerWidth / tableContainerWidth) * remainingPercentage;
          if (newWidth < MIN_COLUMN_WIDTH) {
            problemColumns.push(col.id);
            totalDifference += MIN_COLUMN_WIDTH - newWidth;
          }
        }
      });

      content.columns.forEach((col) => {
        if (col.id === columnId) {
          newColumnWidths[col.id] =
            NEW_COLUMN_WIDTH - totalDifference / (content.columns.length - problemColumns.length - 1);
        } else if (problemColumns.includes(col.id)) {
          newColumnWidths[col.id] = MIN_COLUMN_WIDTH;
        } else {
          const { width: columnContainerWidth } = columnHeaderRefs.current[col.id].getBoundingClientRect();
          const columnContainerWidthPercentage = columnContainerWidth / tableContainerWidth;
          newColumnWidths[col.id] = remainingPercentage * columnContainerWidthPercentage;
        }
      });
      return newColumnWidths;
    } else if (action === 'remove') {
      const removedColumnWidth = columnWidths[columnId];

      const oldTotalPercentage = 100 - removedColumnWidth;

      content.columns.forEach((col) => {
        const { width: columnContainerWidth } = columnHeaderRefs.current[col.id].getBoundingClientRect();
        const columnContainerWidthPercentage = columnContainerWidth / tableContainerWidth;

        newColumnWidths[col.id] = ((100 * columnContainerWidthPercentage) / oldTotalPercentage) * 100;
      });
      return newColumnWidths;
    }
  }, []);

  /**
   * We use referenceState here instead of content directly because there is a race condition where the content
   * is not updated in time for the add Row button is pressed at the end of the table.
   */
  const onAddRow = useCallback(
    (sourceIndex) => {
      if (referenceState.current.sub_type === 'test_point') {
        setTestPointSelectorContext({
          visible: true,
          sourceRowIndex: sourceIndex,
        });
        return;
      }
      const newContent = tableInputUtil.cloneAndMigrateContent(referenceState.current);

      if (typeof newContent.rows === 'string') {
        newContent.rows = Number(newContent.rows);
      }

      newContent.rows = newContent.rows + 1;
      newContent.cells = tableInputUtil.getUpdatedCellsOnRowChange({
        content: newContent,
        action: 'add',
        sourceIndex,
      });
      newContent.row_metadata = tableInputUtil.getUpdatedRowMetadataOnRowChange({
        content: newContent,
        action: 'add',
        sourceIndex,
      });

      updateState(path, newContent);
    },
    [path, updateState]
  );

  const onAddTestPoints = useCallback(
    (testPoints) => {
      const newContent = tableInputUtil.cloneAndMigrateContent(content);
      if (typeof newContent.rows === 'string') {
        newContent.rows = Number(newContent.rows);
      }

      testPoints.forEach((testPoint) => {
        const testPointIndex = newContent.columns.findIndex((column) => column.column_type === 'test_point');
        if (testPointIndex === -1) {
          return;
        }
        const numRows = newContent.rows + 1;
        const lastRowIndex = numRows - 1;
        const newRowIndex = testPointSelectorContext.sourceRowIndex ?? lastRowIndex + 1;
        newContent.rows = numRows;
        newContent.cells = tableInputUtil.getUpdatedCellsOnRowChange({
          content: newContent,
          action: 'add',
          sourceIndex: newRowIndex,
        });
        newContent.row_metadata = tableInputUtil.getUpdatedRowMetadataOnRowChange({
          content: newContent,
          action: 'add',
          sourceIndex: newRowIndex,
        });

        newContent.cells[newRowIndex][testPointIndex] = testPoint;
      });
      updateState(path, newContent);

      setTestPointSelectorContext({
        visible: false,
        sourceRowIndex: null,
      });
    },
    [content, path, updateState, testPointSelectorContext.sourceRowIndex]
  );

  const onAddRowToEnd = useCallback(() => {
    onAddRow(content.rows);
  }, [content, onAddRow]);

  const onDuplicateRow = useCallback(
    (sourceIndex) => {
      const newContent = tableInputUtil.cloneAndMigrateContent(content);

      if (typeof newContent.rows === 'string') {
        newContent.rows = Number(newContent.rows);
      }

      newContent.rows = newContent.rows + 1;
      newContent.cells = tableInputUtil.getUpdatedCellsOnRowChange({
        content: newContent,
        action: 'duplicate',
        sourceIndex,
      });
      newContent.row_metadata = tableInputUtil.getUpdatedRowMetadataOnRowChange({
        content: newContent,
        action: 'duplicate',
        sourceIndex,
      });

      updateState(path, newContent);
    },
    [content, path, updateState]
  );

  const onRemoveRow = useCallback(
    (sourceIndex) => {
      const newContent = tableInputUtil.cloneAndMigrateContent(content);

      if (typeof newContent.rows === 'string') {
        newContent.rows = Number(newContent.rows);
      }

      newContent.rows = newContent.rows - 1;
      newContent.cells = tableInputUtil.getUpdatedCellsOnRowChange({
        content: newContent,
        action: 'remove',
        sourceIndex,
      });
      newContent.row_metadata = tableInputUtil.getUpdatedRowMetadataOnRowChange({
        content: newContent,
        action: 'remove',
        sourceIndex,
      });

      updateState(path, newContent);
    },
    [content, path, updateState]
  );

  const onMoveColumnLeft = useCallback(
    (sourceIndex) => {
      const newContent = tableInputUtil.cloneAndMigrateContent(content);

      // Swap columns
      [newContent.columns[sourceIndex - 1], newContent.columns[sourceIndex]] = [
        newContent.columns[sourceIndex],
        newContent.columns[sourceIndex - 1],
      ];

      newContent.cells = tableInputUtil.getUpdatedCellsOnColumnChange({
        content: newContent,
        action: 'swap',
        sourceIndex,
        destinationIndex: sourceIndex - 1,
      });

      updateState(path, newContent);
    },
    [content, path, updateState]
  );

  const onMoveColumnRight = useCallback(
    (sourceIndex) => {
      const newContent = tableInputUtil.cloneAndMigrateContent(content);

      // Swap columns
      [newContent.columns[sourceIndex + 1], newContent.columns[sourceIndex]] = [
        newContent.columns[sourceIndex],
        newContent.columns[sourceIndex + 1],
      ];

      newContent.cells = tableInputUtil.getUpdatedCellsOnColumnChange({
        content: newContent,
        action: 'swap',
        sourceIndex,
        destinationIndex: sourceIndex + 1,
      });

      updateState(path, newContent);
    },
    [content, path, updateState]
  );

  // Updates content.cells after adding a column.
  const onAddColumn = useCallback(
    (sourceIndex) => {
      if (content.columns.length >= MAX_COLUMN_COUNT) {
        return;
      }

      const newContent = tableInputUtil.cloneAndMigrateContent(referenceState.current);
      const columnIndex = sourceIndex + 1; // Add column to one place after the given sourceIndex.
      const newColumn = {
        ...INITIAL_COLUMN_TEXT,
        id: procedureUtil.generateTableInputColumnId(),
      };

      newContent.columns.splice(columnIndex, 0, newColumn);
      newContent.cells = tableInputUtil.getUpdatedCellsOnColumnChange({
        content: newContent,
        action: 'add',
        sourceIndex: columnIndex,
      });

      const columnWidths = recalculateColumnWidths({
        action: 'add',
        content: newContent,
        columnId: newColumn.id,
        columnWidths: undefined,
      });

      newContent.columns.forEach((col) => {
        col.width = columnWidths?.[col.id];
      });

      updateState(path, newContent);

      setColumnWidths(columnWidths ?? {});
    },
    [content, recalculateColumnWidths, updateState, path]
  );

  const onAddColumnToEnd = useCallback(() => {
    onAddColumn(content.columns.length - 1);
  }, [content, onAddColumn]);

  // Updates content.cells after removing a column.
  const onRemoveColumn = useCallback(
    (sourceIndex) => {
      const newContent = tableInputUtil.cloneAndMigrateContent(content);

      // Remove column from columns array.
      const [removedColumn] = newContent.columns.splice(sourceIndex, 1);

      // Update cells to reflect deleted column.
      newContent.cells = tableInputUtil.getUpdatedCellsOnColumnChange({
        content: newContent,
        sourceIndex,
        action: 'remove',
      });

      const newColumnWidths = recalculateColumnWidths({
        action: 'remove',
        columnId: removedColumn.id,
        columnWidths,
        content: newContent,
      });

      newContent.columns.forEach((col) => {
        col.width = newColumnWidths?.[col.id];
      });

      setColumnWidths(newColumnWidths ?? {});

      updateState(`${path}`, newContent);
    },
    [content, columnWidths, recalculateColumnWidths, updateState, path]
  );

  // Disable remove column button if only 1 column is left.
  const isRemoveColumnDisabled = useMemo(() => content.columns.length === 1, [content.columns]);

  const onColumnHeaderRefChanged = useCallback((ref, columnId) => {
    columnHeaderRefs.current[columnId] = ref;
  }, []);

  const onColumnResizeEnd = useCallback(() => {
    const newContent = cloneDeep(content);
    const { width: tableContainerWidth } = tableContainerRef.current.getBoundingClientRect();

    newContent.columns.forEach((column) => {
      const { width: columnContainerWidth } = columnHeaderRefs.current[column.id].getBoundingClientRect();
      const columnContainerWidthPercentage = (columnContainerWidth / tableContainerWidth) * 100;

      column.width = columnContainerWidthPercentage;
    });

    updateState(path, newContent);
  }, [content, updateState, path]);

  const onColumnResize = useCallback((delta, leftColumn, rightColumn) => {
    const { width: tableContainerWidth } = tableContainerRef.current.getBoundingClientRect();
    const percentageDelta = (delta * 100) / tableContainerWidth;

    setColumnWidths((columnWidths) => {
      const leftColumnWidth = columnWidths[leftColumn.id];
      const rightColumnWidth = columnWidths[rightColumn.id];

      let newLeftColumnWidth = leftColumnWidth - percentageDelta;
      let newRightColumnWidth = rightColumnWidth + percentageDelta;

      if (leftColumnWidth - percentageDelta <= MIN_COLUMN_WIDTH) {
        newLeftColumnWidth = leftColumnWidth;
        newRightColumnWidth = rightColumnWidth;
      } else if (rightColumnWidth + percentageDelta <= MIN_COLUMN_WIDTH) {
        newRightColumnWidth = rightColumnWidth;
        newLeftColumnWidth = leftColumnWidth;
      }

      return {
        ...columnWidths,
        [leftColumn.id]: newLeftColumnWidth,
        [rightColumn.id]: newRightColumnWidth,
      };
    });
  }, []);

  const onRowDragStart = useCallback(() => {
    // Blur any active inputs, otherwise they behave weirdly during dragging

    // @ts-expect-error Blur is defined for HTMLElement but not for Element
    document?.activeElement?.blur();
  }, []);

  const onRowDragEnd = useCallback(
    (results) => {
      const { source, destination } = results;

      if (!source || !destination) {
        return;
      }

      const updatedCellsCopy = tableInputUtil.getUpdatedCellsOnRowChange({
        content,
        action: 'rearrange',
        sourceIndex: source.index,
        destinationIndex: destination.index,
      });
      const updatedRowMetadata = tableInputUtil.getUpdatedRowMetadataOnRowChange({
        content,
        action: 'rearrange',
        sourceIndex: source.index,
        destinationIndex: destination.index,
      });

      updateState(path, {
        ...content,
        cells: updatedCellsCopy,
        row_metadata: updatedRowMetadata,
      });
    },
    [updateState, content, path]
  );

  const getCellColor = useCallback((cell) => {
    return cell.columnType === 'text' || cell.columnType === SIGNOFF_COLUMN_TYPE
      ? 'bg-white'
      : 'bg-gray-100 text-gray-500';
  }, []);

  return (
    <div className="flex flex-col group ml-8">
      <div className="flex flex-row">
        <table ref={tableContainerRef} className="table-fixed w-full">
          <DragDropContext onDragStart={onRowDragStart} onDragEnd={onRowDragEnd}>
            <thead>
              <tr>
                {content.columns.map((column, index, columnsArray) => {
                  const columnPath = `${path}.columns[${index}]`;
                  return (
                    <th
                      align="left"
                      key={columnPath}
                      ref={(ref) => onColumnHeaderRefChanged(ref, column.id)}
                      style={{
                        width: `${columnWidths[column.id]}%`,
                        maxWidth: `${columnWidths[column.id]}%`,
                        minWidth: `${columnWidths[column.id]}%`,
                      }}
                      className={`align-top h-fit border border-gray-400 ${
                        column.column_type === 'comment' || column.column_type === 'test_point'
                          ? 'bg-gray-200'
                          : 'bg-white'
                      }`}
                    >
                      <div className="flex flex-col h-full">
                        <div className="bg-gray-200 text-gray-500 text-center text-xs">
                          {procedureUtil.displaySectionKey(index, 'letters')}
                        </div>
                        <div className="border-t border-gray-400">
                          <FieldSetColumnHeader
                            column={column}
                            index={index}
                            isRemoveColumnDisabled={isRemoveColumnDisabled}
                            onAddColumn={onAddColumn}
                            onRemoveColumn={onRemoveColumn}
                            path={`columns[${index}]`}
                            onContentChange={onContentChange}
                            onColumnResize={
                              index < columnsArray.length - 1
                                ? (delta) => onColumnResize(delta, column, columnsArray[index + 1])
                                : undefined
                            }
                            onColumnResizeEnd={onColumnResizeEnd}
                            onMoveColumnLeft={index !== 0 ? () => onMoveColumnLeft(index) : undefined}
                            onMoveColumnRight={
                              index !== columnsArray.length - 1 ? () => onMoveColumnRight(index) : undefined
                            }
                            isReadOnly={isReadOnly}
                            isLast={index === columnsArray.length - 1}
                            isDisabled={column.disabled}
                          />
                        </div>
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <Droppable droppableId="table-input" type="row">
              {(droppableProvided) => (
                <tbody ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                  {displayRows.map((row, rowIndex) => {
                    const rowPath = `rows[${rowIndex}]`;

                    return (
                      <Draggable key={rowPath} draggableId={rowPath} index={rowIndex}>
                        {(provided) => (
                          <tr className="h-full w-full" ref={provided.innerRef} {...provided.draggableProps}>
                            {row.map((cell, cellIndex) => (
                              <td
                                key={`${rowPath}[${cellIndex}]`}
                                className={`relative h-fit p-0 border border-gray-400 ${getCellColor(cell)}`}
                                /* Hack to get the row to stay the same width as the table when being dragged */
                                style={{
                                  width: `${columnWidths[content.columns[cellIndex].id] * 0.1}% `,
                                  maxWidth: `${columnWidths[content.columns[cellIndex].id] * 0.1}%`,
                                }}
                              >
                                <div
                                  className={`flex flex-row h-full w-full
                                  ${
                                    cell.columnType === SIGNOFF_COLUMN_TYPE && activeCell === `${rowPath}[${cellIndex}]`
                                      ? 'ring-2 ring-blue-600'
                                      : ''
                                  }`}
                                >
                                  <div
                                    className="flex flex-row grow w-full h-full"
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      setActiveCell(`${rowPath}[${cellIndex}]`);
                                    }}
                                  >
                                    {cellIndex === 0 && (
                                      <div className="flex absolute -translate-x-full -left-px w-6 top-px bottom-px text-gray-500 items-center justify-center bg-gray-200 text-xs border-y border-l border-gray-400">
                                        {rowIndex + 1}
                                      </div>
                                    )}
                                    {cellIndex === 0 && (
                                      <div
                                        {...provided.dragHandleProps}
                                        className="flex absolute left-1 top-0 bottom-0 items-center text-gray-400"
                                      >
                                        <FontAwesomeIcon icon="grip-vertical" />
                                      </div>
                                    )}

                                    {cell.columnType === 'text' && (
                                      <div
                                        contentEditable={true}
                                        suppressContentEditableWarning={true}
                                        className={`w-full h-full text-sm py-3 px-2 ${
                                          cellIndex === row.length - 1 && 'pr-6'
                                        } ${
                                          cellIndex === 0 && 'pl-6'
                                        } self-top whitespace-pre-line break-words focus:outline-none focus:ring-2 focus:ring-blue-600`}
                                        onBlur={(event) => onContentChange(cell.path, event.target.innerText)}
                                      >
                                        {tableUtil.isSignoffCell(cell.value) ? '' : cell.value}
                                      </div>
                                    )}
                                    {cell.columnType === 'input' && cell.inputType !== 'list' && (
                                      <div className={`w-full h-full ${cellIndex === row.length - 1 && 'pr-6'}`}>
                                        <RunFieldSetTableCell
                                          type={cell.inputType}
                                          label={cell.label}
                                          isDisabled={true}
                                        />
                                      </div>
                                    )}
                                    {cell.columnType === 'input' && cell.inputType === 'list' && (
                                      <div
                                        className={`w-full h-full ${cellIndex === row.length - 1 && 'pr-6'} ${
                                          cellIndex === 0 && 'pl-6'
                                        }`}
                                      >
                                        <Field name={path}>
                                          {({ field }) => (
                                            <Selector
                                              name={field.name}
                                              type="table-cell"
                                              description="List Value"
                                              onChangeHandler={() => null}
                                              selectOptions={[]}
                                              selectedOption={{
                                                label: getListMetadata(cell.list)?.name ?? '',
                                                value: getListMetadata(cell.list)?.name ?? '',
                                              }}
                                              isDisabled={true}
                                            />
                                          )}
                                        </Field>
                                      </div>
                                    )}
                                    {cell.columnType === SIGNOFF_COLUMN_TYPE && (
                                      <div
                                        className={`w-full h-full text-sm py-1 overflow-hidden ${
                                          cellIndex === row.length - 1 && 'pr-6'
                                        } ${cellIndex === 0 && 'pl-6'}`}
                                      >
                                        <SignoffCell
                                          signoffs={cell.value}
                                          onUpdateSignoffs={(updatedCellValue) => {
                                            onContentChange(cell.path, updatedCellValue);
                                          }}
                                          isActive={activeCell === `${rowPath}[${cellIndex}]`}
                                        />
                                      </div>
                                    )}
                                    {cell.columnType === 'comment' && (
                                      <div
                                        className={`flex items-center justify-center w-full h-full text-sm py-1 overflow-hidden ${
                                          cellIndex === row.length - 1 && 'pr-6'
                                        } ${cellIndex === 0 && 'pl-6'}`}
                                        aria-label="comment cell"
                                      >
                                        <FontAwesomeIcon icon="comment" className="p-2" />
                                      </div>
                                    )}
                                    {cell.columnType === 'test_point' && (
                                      <div
                                        className={`h-full w-full ${cellIndex === row.length - 1 && 'pr-6'} ${
                                          cellIndex === 0 && 'pl-6'
                                        }`}
                                      >
                                        <TestPointCell testPoint={cell.value} />
                                      </div>
                                    )}
                                  </div>
                                  {cellIndex === row.length - 1 && (
                                    <div className="flex items-center absolute right-0 bottom-0 top-0">
                                      <TableHeaderMenuContext
                                        label="row"
                                        index={rowIndex}
                                        onAddHeader={onAddRow}
                                        onRemoveHeader={onRemoveRow}
                                        onDuplicateHeader={onDuplicateRow}
                                        isRemoveHeaderDisabled={displayRows.length === 1}
                                        isAddHeaderDisabled={false}
                                      />
                                    </div>
                                  )}
                                </div>
                              </td>
                            ))}
                          </tr>
                        )}
                      </Draggable>
                    );
                  })}
                  {droppableProvided.placeholder}
                  <tr>
                    <td colSpan={content.columns.length}>
                      <div
                        className="flex justify-center items-center mt-1 w-full h-6 opacity-0 bg-gray-200 border border-transparent text-white cursor-pointer hover:bg-blue-500 group-hover:opacity-100 rounded"
                        onClick={onAddRowToEnd}
                        aria-label="Add row to end"
                      >
                        <FontAwesomeIcon icon="plus-circle" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </Droppable>
          </DragDropContext>
        </table>

        <TestPointSelectionModal
          visible={testPointSelectorContext.visible}
          onAdd={onAddTestPoints}
          onCancel={() =>
            setTestPointSelectorContext({
              visible: false,
              sourceRowIndex: null,
            })
          }
        />

        {/* Add column and add row buttons */}
        <div className="flex flex-col">
          <div
            className="flex justify-center items-center ml-1 h-full w-6 opacity-0 bg-gray-200 border border-transparent text-white cursor-pointer hover:bg-blue-500 group-hover:opacity-100 rounded"
            onClick={onAddColumnToEnd}
          >
            <FontAwesomeIcon icon="plus-circle" />
          </div>
          {/* spacer for add row button */}
          <div className="h-8"></div>
        </div>
      </div>
    </div>
  );
});

export default TableEdit;
