import { useCallback, useMemo, useState, useEffect, KeyboardEvent, useRef } from 'react';
import { cloneDeep, isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import { Snippet, StepSnippet } from 'shared/lib/types/views/procedures';
import { ProcedureContextProvider } from '../../contexts/ProcedureContext';
import FieldSetProcedureStep from '../FieldSetProcedureStep';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { ProcedureContentBlockTypes } from 'shared/lib/types/blockTypes';
import attachmentUtil from '../../lib/attachmentUtil';
import PromptBeforeUnload from '../Prompt/PromptBeforeUnload';
import AddContentMenu from '../ContentMenu/AddContentMenu';
import getStepAddContentItems from '../ContentMenu/stepAddContentItems';
import { Actions } from '../ContentMenu/addContentTypes';
import procedureUtil from '../../lib/procedureUtil';
import { SelectionContextProvider } from '../../contexts/Selection';
import useExpandCollapse from '../../hooks/useExpandCollapse';
import FlashMessage from '../FlashMessage';
import Selectable, { Boundary } from '../Selection/Selectable';
import clipboardUtil from '../../lib/clipboardUtil';
import snippetUtil from '../../lib/snippetUtil';
import { DatabaseServices, copyItemToClipboard, selectClipboardItem } from '../../contexts/proceduresSlice';
import { useMixpanel } from '../../contexts/MixpanelContext';
import FieldSetSnippetHeader from './FieldSetSnippetHeader';
import { useSnippetValidation } from '../../hooks/useSnippetValidation';

interface FieldSetStepSnippetProps {
  snippet: StepSnippet;
  validationErrors?: Record<string, unknown>;
  onClose?: () => void;
  onRemove?: (id: string) => Promise<void>;
  onSaveSuccess?: () => void;
  isDuplicate?: boolean;
  testingModule?: boolean;
  onChange?: (updatedSnippet: Snippet) => void;
}

const FieldSetStepSnippet = ({
  snippet,
  validationErrors: parentValidationErrors,
  onClose,
  onSaveSuccess,
  testingModule,
  onChange,
}: FieldSetStepSnippetProps) => {
  const [present, setPresent] = useState(snippet);
  const { services, currentTeamId }: { services: DatabaseServices; currentTeamId: string } = useDatabaseServices();
  const { setIsCollapsed, isCollapsedMap } = useExpandCollapse();

  const { validateSnippet, validationErrors: hookValidationErrors } = useSnippetValidation(present, currentTeamId);
  const validationErrors = parentValidationErrors || hookValidationErrors;

  const formikRef = useRef<FormikProps<{
    name: string;
    description: string;
  }> | null>(null);

  const [formValues, setFormValues] = useState({
    name: present.name || '',
    description: present.description || '',
  });

  const dispatch = useDispatch();
  const { mixpanel } = useMixpanel();
  const [flashMessage, setFlashMessage] = useState<string | null>(null);
  const clipboardItem = useSelector((state) => selectClipboardItem(state, currentTeamId));

  const clipboardBlock = useMemo(() => {
    if (!clipboardItem) {
      return undefined;
    }
    const block = clipboardUtil.getBlockFromClipboardItem(clipboardItem);
    if (!block || snippetUtil.validateBlockForSnippet(block) !== undefined) {
      return undefined;
    }
    return block;
  }, [clipboardItem]);

  const snippetValidationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    description: Yup.string(),
  });

  const updatePresent = useCallback(
    (updated: StepSnippet) => {
      setPresent(updated);
      onChange?.(updated);
    },
    [onChange]
  );

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const updated = cloneDeep(present);
    updated[name] = value;
    if (!isEqual(updated, present)) {
      updatePresent(updated);
    }
    if (formikRef.current) {
      formikRef.current.handleChange(e);
    }
  };

  useEffect(() => {
    const updated = cloneDeep(present);
    updated.name = formValues.name;
    updated.description = formValues.description;
    if (!isEqual(updated, present)) {
      updatePresent(updated);
    }
  }, [formValues, present, updatePresent]);

  const mixpanelTrack = useCallback(
    (trackingKey: string) => {
      if (mixpanel && trackingKey) {
        mixpanel.track(trackingKey);
      }
    },
    [mixpanel]
  );

  const onCopyStep = useCallback(() => {
    mixpanelTrack('Copy Step');
    const copiedStep = procedureUtil.copyStep(present.step);
    const clipboardItemStep = clipboardUtil.createClipboardItemStep(copiedStep);
    dispatch(copyItemToClipboard(currentTeamId, clipboardItemStep));
    setFlashMessage('Step copied');
  }, [currentTeamId, dispatch, mixpanelTrack, present]);

  const onPasteBlock = useCallback(() => {
    if (!clipboardBlock) return;
    mixpanelTrack('Paste Block');
    const updatedSnippet = cloneDeep(present);
    const blockCopy = procedureUtil.copyBlock(clipboardBlock);
    updatedSnippet.step.content.push(blockCopy);
    updatePresent(updatedSnippet);
  }, [clipboardBlock, mixpanelTrack, present, updatePresent]);

  const procedureShell = useMemo(() => {
    return {
      _id: '',
      code: '',
      name: '',
      description: '',
      sections: [{ steps: [present] }],
    };
  }, [present]);

  const onAddStepHeader = () => {
    const stepHeader = procedureUtil.newStepHeader();
    const updated = cloneDeep(present);
    if (!updated.step.headers) {
      updated.step.headers = [];
    }
    updated.step.headers.push(stepHeader);
    updatePresent(updated);
  };

  const onRemoveStepHeader = () => {
    const updated = cloneDeep(present);
    updated.step.headers?.pop();
    updatePresent(updated);
  };

  const onSnippetStepChanged = useCallback(
    (values, sectionIndex: number, stepIndex: number) => {
      if (!present) return;
      const updated = cloneDeep(present);
      const step = present.step;
      updated.step = {
        id: step.id,
        ...values,
      };
      if (isEqual(present, updated)) return;
      updatePresent(updated);
    },
    [present, updatePresent]
  );

  const onSaveStepSnippet = useCallback(
    async (values: { name: string; description: string }) => {
      const updatedStep = cloneDeep(present.step);
      const snippetId = present.snippet_id;
      const { name, description } = values;

      return services.settings
        .saveStepSnippet({
          id: snippetId,
          name,
          description,
          step: updatedStep,
          isTestSnippet: testingModule,
        })
        .then(() => {
          const updated = cloneDeep(present);
          updated.name = name;
          updated.description = description;
          updatePresent(updated);
          onClose?.();
        })
        .then(() => onSaveSuccess && onSaveSuccess())
        .catch(() => undefined);
    },
    [onClose, onSaveSuccess, present, services.settings, testingModule, updatePresent]
  );

  const shouldPrompt = useMemo(() => {
    return !isEqual(present, snippet);
  }, [present, snippet]);

  const onContentMenuClick = (menuItem) => {
    switch (menuItem.action) {
      case Actions.AddStepHeader:
        return onAddStepHeader();
      case Actions.DeleteStepHeader:
        return onRemoveStepHeader();
      case Actions.CopyStep:
        return onCopyStep();
      case Actions.PasteBlock:
        return onPasteBlock();
      default:
        return;
    }
  };

  const onKeyboard = useCallback(
    (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.code === 'KeyC') {
        onCopyStep();
      } else if ((event.metaKey || event.ctrlKey) && event.code === 'KeyV') {
        onPasteBlock();
      }
    },
    [onCopyStep, onPasteBlock]
  );

  const onSave = useCallback(async () => {
    const form = formikRef.current;
    if (form) {
      // Upload all attachments before validating snippet
      try {
        await attachmentUtil.uploadAllFilesFromStep(present.step, services.attachments);
      } catch (error) {
        // Do nothing with attachment upload errors, as they will get validated below.
      }

      const snippetValid = await validateSnippet();
      const formErrors = await form.validateForm();

      if (snippetValid && Object.keys(formErrors).length === 0) {
        form.handleSubmit();
      }
    }
  }, [present, services.attachments, validateSnippet]);

  useEffect(() => {
    if (formikRef.current) {
      setFormValues(formikRef.current.values);
    }
  }, [formikRef.current?.values]);

  return (
    <>
      <SelectionContextProvider>
        <PromptBeforeUnload shouldPrompt={shouldPrompt} />
        {onChange === undefined && (
          <FieldSetSnippetHeader
            present={present}
            snippet={snippet}
            onSave={onSave}
            onClose={onClose}
            showSubmitError={Object.keys(validationErrors).length !== 0}
          />
        )}
        <Formik
          initialValues={{
            name: present.name || '',
            description: present.description || '',
          }}
          validationSchema={snippetValidationSchema}
          onSubmit={onSaveStepSnippet}
          innerRef={formikRef}
        >
          {({ values, handleBlur }) => {
            return (
              <Form className="mb-4">
                <div className="flex flex-col">
                  <label className="font-semibold text-sm" htmlFor="name">
                    Name
                  </label>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    className="text-sm border-1 border-gray-400 rounded"
                    onChange={handleFormChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                  {Boolean(validationErrors.name) && (
                    <div className="text-red-700">{validationErrors.name as string}</div>
                  )}
                  <label className="mt-2 font-semibold text-sm" htmlFor="description">
                    Description
                  </label>
                  <input
                    id="description"
                    name="description"
                    type="text"
                    className="text-sm border-1 border-gray-400 rounded"
                    onChange={handleFormChange}
                    onBlur={handleBlur}
                    value={values.description}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
        <Selectable boundary={Boundary.Step} block={present.step} onKeyboard={onKeyboard}>
          {({ styles }) => (
            <div aria-label="Step" role="region" className="flex flex-row mt-2 gap-x-3">
              <div className="flex flex-row gap-x-3">
                <AddContentMenu
                  menuItems={getStepAddContentItems({
                    hasStepHeader: (present.step?.headers?.length || 0) > 0,
                    canAddStep: false,
                    canInsertSnippet: false,
                    canDeleteStep: false,
                    canCopyStep: true,
                    canCutStep: false,
                    canPasteStep: false,
                    canPasteBlock: !!clipboardBlock,
                    canSaveAsSnippet: false,
                  })}
                  onClick={(menuItem) => onContentMenuClick(menuItem)}
                />
              </div>

              <div className={`w-full ${styles}`}>
                <ProcedureContextProvider procedure={procedureShell} scrollTo={undefined}>
                  <FieldSetProcedureStep
                    step={present.step}
                    isPending={true}
                    isStepHeadersEnabled={true}
                    errors={validationErrors}
                    onFieldRefChanged={() => undefined}
                    configurePartKitBlock={() => undefined}
                    configurePartBuildBlock={() => undefined}
                    onPartChanged={() => undefined}
                    onStepFormChanged={onSnippetStepChanged}
                    enabledContentTypes={[
                      ProcedureContentBlockTypes.Alert,
                      ProcedureContentBlockTypes.Text,
                      ProcedureContentBlockTypes.Attachment,
                      ProcedureContentBlockTypes.FieldInput,
                      ProcedureContentBlockTypes.TableInput,
                      ProcedureContentBlockTypes.ProcedureLink,
                      ProcedureContentBlockTypes.Commanding,
                      ProcedureContentBlockTypes.Telemetry,
                      ProcedureContentBlockTypes.Requirement,
                      ProcedureContentBlockTypes.ExternalItem,
                      ProcedureContentBlockTypes.FieldInputTable,
                      ...(testingModule ? [ProcedureContentBlockTypes.TestCases] : []),
                    ]}
                    stepIndex={undefined}
                    sectionIndex={undefined}
                    isCollapsed={isCollapsedMap[present.step.id]}
                    precedingStepId={undefined}
                    onStepCollapse={setIsCollapsed}
                    onAcceptRedlineField={undefined}
                    onRejectRedlineField={undefined}
                    onAcceptRedlineBlock={undefined}
                    onRejectRedlineBlock={undefined}
                    redlines={undefined}
                    changedStepIdSet={undefined}
                    setChangedStepIdSet={undefined}
                    onRemoveStepHeader={onRemoveStepHeader}
                    areDependenciesEnabled={false}
                  />
                </ProcedureContextProvider>
              </div>
            </div>
          )}
        </Selectable>
      </SelectionContextProvider>
      <FlashMessage message={flashMessage} messageUpdater={setFlashMessage} />
    </>
  );
};

export default FieldSetStepSnippet;
