import React, { useCallback, useEffect } from 'react';
import ReferenceSelectMenu from './Expression/ReferenceSelectMenu';
import { useProcedureContext } from '../contexts/ProcedureContext';
import expression from '../lib/expression';
import { FormikHelpers, FormikValues } from 'formik';
import useMenu from '../hooks/useMenu';
import { InputTextarea } from 'primereact/inputtextarea';
import { isEmpty } from 'lodash';
import { AddedStep, ExpressionToken } from 'shared/lib/types/views/procedures';
import SelectTableCellModal from './Expression/SelectTableCellModal';
import useReferenceTokens from '../hooks/useReferenceTokens';

interface ReferenceTextAreaProps {
  field;
  placeholder: string;
  path: string;
  setFieldValue?: FormikHelpers<FormikValues>['setFieldValue'];
  pendingStep?: AddedStep;
  precedingStepId?: string;
}

const ReferenceTextArea = ({
  field,
  placeholder,
  path,
  setFieldValue,
  pendingStep,
  precedingStepId,
}: ReferenceTextAreaProps) => {
  const { isMenuVisible, setIsMenuVisible, displayMenu } = useMenu();
  const { stepOptions } = useProcedureContext();

  const onReferencesChanged = (updatedTokens: Array<ExpressionToken>) => {
    if (inputRef && inputRef.current) {
      const newValue = expression.tokensToRawText(updatedTokens, referenceOptions);
      if (inputRef.current.value !== newValue) {
        inputRef.current.value = newValue;
      }
    }
  };

  const setField = useCallback(
    (updatedTokens: Array<ExpressionToken>, text?: string) => {
      const updatedFieldValue = {
        ...field.value,
        tokens: updatedTokens,
        ...(text && { text }),
      };
      setFieldValue && setFieldValue(`${path}`, updatedFieldValue);
    },
    [field.value, path, setFieldValue]
  );

  const validateField = useCallback(() => {
    // No-op
  }, []);

  const closeMenuHandler = useCallback(() => {
    setIsMenuVisible(false);
  }, [setIsMenuVisible]);

  const {
    inputRef,
    selectionStartRef,
    selectionEndRef,
    tokens,
    onSetField,
    onValidateField,
    referenceOptions,
    selectedTable,
    setSelectedTable,
    onSelectReference,
    onSelectTableCellReference,
  } = useReferenceTokens<HTMLTextAreaElement>({
    initialTokens: field.value?.tokens ?? [
      {
        type: 'text',
        value: field.value.text,
      },
    ],
    block: field.value,
    onReferencesChanged,
    setField,
    validateField,
    pendingStep,
    precedingStepId,
    selectReferenceCallback: closeMenuHandler,
  });

  const handleAddReferenceClick = (event) => {
    if (inputRef && inputRef.current) {
      selectionStartRef.current = inputRef.current.selectionStart;
      selectionEndRef.current = inputRef.current.selectionEnd;
    }
    event.stopPropagation();
    displayMenu();
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (isMenuVisible) {
        event.preventDefault();
      }
    },
    [isMenuVisible]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const handleBlur = () => {
    if (!isMenuVisible) {
      onSetField();
    }
  };

  return (
    <>
      <SelectTableCellModal
        selectedTable={selectedTable}
        isVisible={Boolean(selectedTable)}
        onClose={() => {
          setSelectedTable(null);
        }}
        onSelectCell={onSelectTableCellReference}
      />
      <div className="relative flex flex-row bg-white border border-gray-400 focus-within:ring-1 focus-within:ring-blue-600 focus-within:border-blue-600 rounded p-0.5 w-full items-center">
        <InputTextarea
          autoResize={true}
          aria-label="TextBlock"
          ref={inputRef}
          className="outline-none w-full text-sm h-6"
          placeholder={placeholder}
          onChange={onValidateField}
          onBlur={handleBlur}
          defaultValue={expression.tokensToRawText(tokens, referenceOptions)}
          style={{ boxShadow: 'none', border: 'none' }}
        />
        {((stepOptions && isEmpty(stepOptions)) || stepOptions?.displayAddReferences) && (
          <>
            <button
              type="button"
              className="bg-blue-500 text-white text-lg font-semibold tracking-wide uppercase whitespace-nowrap px-2 py-0.5 rounded"
              onClick={handleAddReferenceClick}
              title="Insert reference"
            >
              +
            </button>
            {isMenuVisible && (
              <ReferenceSelectMenu
                onSelect={onSelectReference}
                close={closeMenuHandler}
                menuOptions={referenceOptions}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ReferenceTextArea;
