import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ItemSelect from '../ItemSelect';
import { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import { Item } from '../../types';
import useItems from '../../hooks/useItems';
import { getItemTopLabel } from '../../lib/items';
import useLocations from '../../hooks/useLocations';
import { isEmptyValue } from 'shared/lib/text';
import { isPartRestricted } from '../../lib/parts';
import RestrictedInfo from '../RestrictedInfo';
import UnitDisplay from '../../../components/Settings/Units/UnitDisplay';
import PartBadge from '../PartBadge';

const ReviewInputInventoryItem = ({
  part,
  item,
  recorded,
  isEnabled,
  teamId,
  onRecordValuesChanged,
  isStepComplete,
}) => {
  const { getItem } = useItems();
  const { getLocationLabel } = useLocations();
  const partRestricted = isPartRestricted(part);

  const onChangeItem = (inventoryItem: Item) => {
    const values = {
      ...item,
      ...recorded,
      item_id: inventoryItem?.id || undefined,
    };
    onRecordValuesChanged?.(values);
  };

  const updateAmount = (amount) => {
    const values = {
      ...item,
      ...recorded,
      amount,
    };
    onRecordValuesChanged?.(values);
  };

  const onChangeAmount = (e) => {
    if (isEmptyValue(e.target.value)) {
      return;
    }
    const valueInt = parseInt(e.target.value);
    const amount = Math.max(valueInt, 0);
    updateAmount(amount);
  };

  // Serial items have the additional constraint that quantity can be max 1
  const onChangeAmountSerial = (e) => {
    if (isEmptyValue(e.target.value)) {
      return;
    }
    const valueInt = parseInt(e.target.value);
    const amount = Math.min(Math.max(valueInt, 0), 1); // amount is either 0 or 1
    updateAmount(amount);
  };

  let amount = item.amount;
  if (!isEmptyValue(recorded?.amount)) {
    amount = recorded?.amount;
  }

  const styleByDiffChangeState =
    item.diff_change_state === ARRAY_CHANGE_SYMBOLS.ADDED
      ? 'bg-emerald-100 text-emerald-800'
      : item.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED
      ? 'bg-red-100 text-red-600'
      : 'bg-white';

  const StepCompletedPartKit = () => {
    if (recorded && recorded.amount && recorded.item_id) {
      const hydratedItem = getItem(recorded.item_id);
      if (!hydratedItem) {
        return null;
      }
      return (
        <>
          <td>
            <div className="text-base p-1">{recorded.amount}</div>
          </td>
          <td>
            <div className="flex items-center justify-between">
              <div className="text-base p-1">{getItemTopLabel(hydratedItem as Item, getLocationLabel)}</div>
              <div>
                <FontAwesomeIcon icon="check-square" className=" text-green-500" />
              </div>
            </div>
          </td>
        </>
      );
    }
    return null;
  };

  return (
    <tr className={`h-10 text-sm ${styleByDiffChangeState} border-b items-center w-full`}>
      <td>
        <PartBadge part={part} teamId={teamId} showImage={false} />
      </td>
      {!isStepComplete && (
        <>
          <td>
            <div className="p-1">
              {!partRestricted && (
                <div className="flex items-center gap-x-2">
                  <input
                    type="number"
                    className="w-full max-w-[14rem] text-sm border-1 border-gray-300 rounded disabled:bg-gray-100"
                    disabled={!isEnabled}
                    value={amount}
                    onChange={part?.tracking === 'serial' ? onChangeAmountSerial : onChangeAmount}
                    data-testid="quantity-input"
                  />
                  {part?.units && <UnitDisplay unit={part.units} />}
                </div>
              )}
              {partRestricted && (
                <div className="ml-0.5">
                  <RestrictedInfo />
                </div>
              )}
            </div>
          </td>
          <td>
            {part?.tracking && !partRestricted && (
              <ItemSelect
                itemId={recorded?.item_id || item.item_id}
                partId={part.id}
                minAmount={amount}
                onChangeItem={onChangeItem}
                isDisabled={!isEnabled}
              />
            )}
            {partRestricted && (
              <div className="ml-1">
                <RestrictedInfo />
              </div>
            )}
          </td>
        </>
      )}
      {isStepComplete && <StepCompletedPartKit />}
    </tr>
  );
};

export default ReviewInputInventoryItem;
