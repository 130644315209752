import { useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import procedureUtil from '../../../lib/procedureUtil';
import { useProcedureContext } from '../../../contexts/ProcedureContext';
import { useRunContext } from '../../../contexts/RunContext';
import ExpressionTokenDisplay from '../../Expression/ExpressionTokenDisplay';
import useProcedureAdapter from '../../../hooks/useProcedureAdapter';
import InvalidMessage from '../../InvalidMessage';
import { useBlockState } from '../../../contexts/BlockContext';
import { ExpressionReferences, ExpressionToken, FieldInputBlock, RunStep } from 'shared/lib/types/views/procedures';
import { getContentBlock } from 'shared/lib/expressionUtil';
import revisions from '../../../lib/revisions';
import { getLatestApprovedBlock } from '../../../hooks/useBlockRedlines';
import tableInputUtil from '../../../lib/tableInputUtil';
import { faTable } from '@fortawesome/free-solid-svg-icons';
import isNumber from '../../../lib/number';
import { STEP_STATE } from 'shared/lib/runUtil';

interface ReferenceTokenProps {
  originalReferencedContentId: string;
  references?: ExpressionReferences;
  originalToken?: ExpressionToken;
}

const ReferenceToken = ({ originalReferencedContentId, references, originalToken }: ReferenceTokenProps) => {
  const { scrollTo, getContentItemPath } = useProcedureContext();
  const { isActiveRun } = useRunContext();
  const { getReferencedContentContext } = useProcedureAdapter();
  const { isValid } = useBlockState();

  const referencedContentContext = getReferencedContentContext(
    originalReferencedContentId,
    undefined,
    references?.[originalReferencedContentId]
  );
  const {
    referencedFromStepKey,
    referencedFromSectionKey,
    sectionRepeatKey,
    stepRepeatKey,
    referencedContent,
    referencedContentIndex,
    stepRecordedState,
    isVariable,
    isVariableRecorded,
    referencedFromStep,
    substepKeyMap,
  } = referencedContentContext ?? {};

  const goToContent = useCallback(
    (destinationContentId) => {
      const contentPath = getContentItemPath(destinationContentId);
      // If contentPath is null, it could mean a procedure variable is referenced.
      const { sectionId, stepId, stepHeaderId } = procedureUtil.parseContentPath(contentPath);
      scrollTo({
        sectionId,
        stepId,
        stepHeaderId,
        contentId: destinationContentId,
      });
    },
    [getContentItemPath, scrollTo]
  );

  const goToSourceContent = useCallback(
    () => referencedContent && goToContent(referencedContent.id),
    [goToContent, referencedContent]
  );

  const latestApprovedBlock = useMemo(() => {
    if (!referencedContent) {
      return;
    }

    const blockRedlines = revisions.getBlockChanges(
      referencedContent,
      referencedContentIndex,
      (referencedFromStep as RunStep)?.redlines ?? []
    );
    return getContentBlock(
      getLatestApprovedBlock({
        block: referencedContent,
        redlines: blockRedlines,
      }),
      originalToken?.field_index
    );
  }, [originalToken?.field_index, referencedContent, referencedContentIndex, referencedFromStep]);

  const referenceName = useMemo(() => {
    if (!latestApprovedBlock) {
      return '';
    }
    if (latestApprovedBlock?.type === 'table_input') {
      return tableInputUtil.getCellCoordinates({
        rowId: originalToken?.table_reference?.row_id,
        columnId: originalToken?.table_reference?.column_id,
        rowMetadata: latestApprovedBlock.row_metadata,
        columnMetadata: latestApprovedBlock.columns,
      });
    }

    if (
      referencedContent?.type === 'field_input_table' &&
      originalToken?.field_index !== undefined &&
      isNumber(originalToken?.field_index)
    ) {
      return referencedContent.fields[originalToken.field_index]?.name ?? '';
    }

    return (latestApprovedBlock as FieldInputBlock).name ?? '';
  }, [
    latestApprovedBlock,
    referencedContent,
    originalToken?.field_index,
    originalToken?.table_reference?.row_id,
    originalToken?.table_reference?.column_id,
  ]);

  const referenceIcon = useMemo(() => {
    if (!latestApprovedBlock) {
      return;
    }

    if (latestApprovedBlock.type === 'table_input') {
      return faTable;
    }
  }, [latestApprovedBlock]);

  const primaryKey = useMemo(() => {
    if (isVariable) {
      return 'Variable';
    }
    if (sectionRepeatKey) {
      return `Section ${referencedFromSectionKey}`;
    }

    return referencedFromSectionKey;
  }, [isVariable, referencedFromSectionKey, sectionRepeatKey]);

  return (
    <>
      {isValid && referencedContentContext && referencedContent && referenceName && (
        <ExpressionTokenDisplay
          referenceName={referenceName ?? ''}
          referenceIcon={referenceIcon}
          onClick={goToSourceContent}
          primaryKey={primaryKey}
          primaryRepeatKey={sectionRepeatKey}
          secondaryKey={substepKeyMap?.[referencedContent.id] ?? referencedFromStepKey}
          secondaryRepeatKey={stepRepeatKey}
          stepRecordedState={
            Boolean(isVariable) && Boolean(isVariableRecorded) ? STEP_STATE.COMPLETED : stepRecordedState
          }
          inRunningRun={isActiveRun}
        />
      )}
      {isValid && !(referencedContentContext && referencedContent && referenceName) && (
        <span className="mt-2 mr-8 py-2 w-full">
          <FontAwesomeIcon className="mr-2 text-red-500" icon="exclamation-circle" />
          Referenced Input Field Not Found
        </span>
      )}
      {!isValid && (
        <div className="px-2">
          <InvalidMessage>Reference no longer valid</InvalidMessage>
        </div>
      )}
    </>
  );
};

export default ReferenceToken;
