import { useCallback } from 'react';
import { Project } from 'shared/lib/types/couch/settings';
import { useSettings } from '../contexts/SettingsContext';
import { useAuth } from '../contexts/AuthContext';

interface useProjectsReturns {
  getProjectOptions: (permissionKey: string) => Array<projectOption>;
}

type projectOption = {
  id: string;
  name: string;
  value: Project;
};

const useProjects = (): useProjectsReturns => {
  const { projects } = useSettings();
  const { auth } = useAuth();
  const getProjectOptions = useCallback(
    (permissionKey: string): Array<projectOption> => {
      if (!projects) {
        return [];
      }

      const projectOptions = [
        ...Object.values(projects.projects)
          .filter((project) =>
            typeof auth.hasPermission === 'function'
              ? auth.hasPermission(permissionKey, project.id)
              : true
          )
          .map((project: Project) => ({
            id: project.id,
            name: project.name,
            value: project,
          })),
      ];
      return projectOptions;
    },
    [projects, auth]
  );

  return {
    getProjectOptions,
  };
};

export default useProjects;
