import React, { useCallback, useEffect, useMemo, useState, MouseEvent } from 'react';
import { matchSorter } from 'match-sorter';
import { ReferenceOption } from '../../lib/expression';

/*
 * Printable keys by definition have length of 1. Special keys like Escape
 * and Backspace use their names and have lengths of 6 and 9 respectively
 */
const isPrintable = (key: string) => {
  return key && key.length === 1;
};

const NO_MATCHING_REFERENCE_OPTIONS = 'No matching input fields';

interface ReferenceSelectMenuProps {
  onSelect: (item: ReferenceOption) => void;
  close: () => void;
  menuOptions: ReferenceOption[];
}

const ReferenceSelectMenu = ({ onSelect, close, menuOptions }: ReferenceSelectMenuProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItem, setSelectedItem] = useState<string>();
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(0);

  const menuItems: ReferenceOption[] = useMemo(() => {
    const emptyReferenceOption: ReferenceOption = {
      id: '--',
      name: NO_MATCHING_REFERENCE_OPTIONS,
      referenceLabel: '',
      textToSearch: '',
      type: 'empty',
    };

    if (menuOptions.length === 0) {
      return [emptyReferenceOption];
    }

    if (!searchTerm) {
      return menuOptions;
    }

    const matches = matchSorter(menuOptions, searchTerm, { keys: ['name', 'textToSearch'] });
    if (matches.length === 0) {
      matches.push(emptyReferenceOption);
    }
    return matches;
  }, [menuOptions, searchTerm]);

  const selectOption = useCallback(
    (item: ReferenceOption) => {
      if (item.name !== NO_MATCHING_REFERENCE_OPTIONS) {
        onSelect(item);
      }
    },
    [onSelect]
  );

  const keyDownHandler = useCallback(
    (e: KeyboardEvent) => {
      const selectedItemIndex = menuItems.findIndex((menuItem) => menuItem.id === selectedItem);

      switch (e.key) {
        case '}':
          close();
          break;
        case 'Tab':
        case 'Enter':
          e.preventDefault();
          if (selectedItemIndex !== -1) {
            selectOption(menuItems[selectedItemIndex]);
          } else {
            close();
          }
          break;
        case 'Escape':
          close();
          break;
        case 'Backspace':
          if (!searchTerm) {
            close();
          }
          setSearchTerm(searchTerm.substring(0, searchTerm.length - 1));
          break;
        case 'ArrowUp':
          e.preventDefault();
          setSelectedItemIndex(selectedItemIndex === 0 ? menuItems.length - 1 : selectedItemIndex - 1);
          break;
        case 'ArrowDown':
          e.preventDefault();
          setSelectedItemIndex(selectedItemIndex === menuItems.length - 1 ? 0 : selectedItemIndex + 1);
          break;
        default:
          if (isPrintable(e.key)) {
            setSearchTerm(searchTerm + e.key);
          }
          break;
      }
    },
    [close, menuItems, selectOption, searchTerm, selectedItem]
  );

  const clickHandler = useCallback(
    (event: MouseEvent<HTMLDivElement>, item: ReferenceOption) => {
      event.stopPropagation();
      selectOption(item);
    },
    [selectOption]
  );

  useEffect(() => {
    if (menuItems && menuItems[selectedItemIndex]) {
      setSelectedItem(menuItems[selectedItemIndex].id);
    }

    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [keyDownHandler, clickHandler, menuItems, selectedItemIndex, close]);

  return (
    <div
      data-testid="formula-select-menu"
      className="absolute w-full top-full left-0 flex flex-col border border-gray-300 border-rounded cursor-pointer rounded-md bg-white shadow-lg text-sm uppercase z-50 font-medium max-h-[200px] overflow-y-auto"
    >
      {menuItems.map((item) => (
        <div
          key={item.id}
          className={`px-2 py-2 rounded hover:bg-gray-100 ${item.id === selectedItem ? 'bg-gray-100' : ''}`}
          onClick={(e) => clickHandler(e, item)}
        >
          {item.referenceLabel} {item.name}
        </div>
      ))}
    </div>
  );
};

export default React.memo(ReferenceSelectMenu);
