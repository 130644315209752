import { useCallback, useMemo } from 'react';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import diffUtil from '../lib/diffUtil';
import ProcedureFieldDiff from './ProcedureFieldDiff';
import {
  ExpressionTokenDiffElement,
  RowMetadataDiffElement,
  TableColumn,
  TableColumnDiffElement,
  TableInputBlockDiffElement,
  TextBlockDiffElement,
  WithDiffChangeI,
} from 'shared/lib/types/views/procedures';
import useProcedureAdapter from '../hooks/useProcedureAdapter';
import tableInputUtil from '../lib/tableInputUtil';
import { NAMESPACE_DELIMITER } from '../lib/expression';

interface BlockTextProps {
  block: TextBlockDiffElement;
}

const ReferenceTextViewDiff = ({ block }: BlockTextProps) => {
  const { getReferencedContentContext } = useProcedureAdapter();

  const getVersionString = useCallback(
    (version: 'old' | 'new') => {
      if (
        !block.tokens ||
        (version === 'old' && block.diff_change_state === ARRAY_CHANGE_SYMBOLS.ADDED) ||
        (version === 'new' && block.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED)
      ) {
        return '';
      }

      return diffUtil
        .getForVersion(block.tokens as Array<ExpressionTokenDiffElement>, version)
        .map((token) => {
          const value = sharedDiffUtil.getDiffValue<string>(token, 'value', version);

          if (token.type === 'text') {
            return value;
          }

          const referencedContentContext = getReferencedContentContext(token.reference_id ?? '');
          if (!referencedContentContext) {
            return value;
          }

          const { referencedContent, referencedFromStepKey, substepKeyMap } = referencedContentContext;
          const key = substepKeyMap?.[referencedContent?.id ?? ''] ?? referencedFromStepKey;
          if (token.type === 'reference' && token.table_reference && referencedContent) {
            const rowMetadataRaw =
              version === 'new' && sharedDiffUtil.wasFieldAdded(referencedContent, 'row_metadata')
                ? referencedContent['row_metadata__added']
                : (referencedContent as TableInputBlockDiffElement).row_metadata;
            const rowMetadata = diffUtil.getForVersion(rowMetadataRaw, version) as Array<RowMetadataDiffElement>;
            rowMetadata.forEach((row) => {
              row.id = sharedDiffUtil.getDiffValue(row, 'id', version);
            });
            const columnMetadata = diffUtil.getForVersion(
              (referencedContent as TableInputBlockDiffElement).columns as Array<WithDiffChangeI>,
              version
            ) as Array<TableColumnDiffElement>;
            columnMetadata.forEach((column) => {
              column.id = sharedDiffUtil.getDiffValue(column, 'id', version);
            });
            const tableCoordinates = tableInputUtil.getCellCoordinates({
              rowId: sharedDiffUtil.getDiffValue(token.table_reference, 'row_id', version),
              columnId: sharedDiffUtil.getDiffValue(token.table_reference, 'column_id', version),
              rowMetadata,
              columnMetadata: columnMetadata as Array<TableColumn>,
            });
            return `{${key}${NAMESPACE_DELIMITER}TABLE${NAMESPACE_DELIMITER}${tableCoordinates}}`;
          }

          return `{${key}${NAMESPACE_DELIMITER}${value}}`;
        })
        .join('');
    },
    [block.diff_change_state, block.tokens, getReferencedContentContext]
  );

  const oldValue = useMemo(() => getVersionString('old'), [getVersionString]);
  const newValue = useMemo(() => getVersionString('new'), [getVersionString]);

  return (
    <ProcedureFieldDiff
      original={oldValue === newValue ? newValue : oldValue}
      redlined={oldValue === newValue ? undefined : newValue}
      useMarkdownWhenNoDiff={true}
    />
  );
};

export default ReferenceTextViewDiff;
