import React, { useCallback, useMemo } from 'react';
import { getIn } from 'formik';
import { isEmptyValue } from 'shared/lib/text';
import ReviewFieldSetTableCell from './ReviewFieldSetTableCell';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import ColumnTypesDiffDetails from './ColumnTypesDiffDetails';
import TooltipOverlay from '../TooltipOverlay';
import { SIGNOFF_COLUMN_TYPE } from './TableInputConstants';
import ReviewSignoffCell from './ReviewSignoffCell';
import tableUtil from 'shared/lib/tableUtil';
import { useSettings } from '../../contexts/SettingsContext';
import tableInputUtil from '../../lib/tableInputUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual } from 'lodash';
import procedureUtil from '../../lib/procedureUtil';
import TestPointCell from './TestPointCell';
import { canReferenceColumn } from 'shared/lib/expressionUtil';

const getCellColor = (cell, defaultColor = 'bg-white', isDark = false) => {
  if (cell.isRemoved) {
    return isDark ? 'bg-red-200 text-red-700 line-through' : 'bg-red-100 text-red-600 line-through';
  }
  if (cell.isAdded) {
    return isDark ? 'bg-emerald-200 text-emerald-900' : 'bg-emerald-100 text-emerald-800';
  }

  if (cell.isSelected) {
    return 'bg-blue-100';
  }

  if (cell.isDisabled) {
    return 'bg-gray-100';
  }

  return defaultColor;
};

/*
 * Renders a Table with Column Headers that have a three dot menu context and/or cells that
 * are enabled and disabled according to props passed in. The props represent the following:
 *
 *  path: initialPath to the table_input object in a procedure
 *  columns: An array of columnHeader objects that include input_type, units and name
 *  rowsCount: Number representing rows
 *  cells: The values for all rows.
 *  areRowsDisabled: Boolean that sets the cells to a disabled field or enabled field
 *  values: the recorded formik values that will be passed on to recordValuesChanged and to non formik cells when rows are disabled
 *  errors: Object with error values for the cells
 *  recordValuesChanged: function that will be called onBlur of field inputs
 *  setFieldValue: formik function to set value in form
 */
const ReviewTable = React.memo(
  ({
    path,
    uniqueId,
    columns,
    rowMetadata,
    rowsCount,
    cells,
    values,
    errors,
    setFieldValue,
    addedRows,
    removedRows,
    addedColumns,
    removedColumns,
    onSelectCell,
    selectedCell,
  }) => {
    const { getListMetadata } = useSettings();

    /**
     * TODO: Remove onRecordValueChanged. This is just a stub now.
     */
    const onRecordValueChanged = useCallback(() => null, []);

    const getColumnWidth = (column, index) =>
      removedColumns && removedColumns.has(index)
        ? sharedDiffUtil.getDiffValue(column, 'width', 'old')
        : sharedDiffUtil.getDiffValue(column, 'width', 'new');

    const getCellValue = useCallback(
      ({ value, column, columnType, inputType, isCellAdded, isCellRemoved }) => {
        if (columnType === 'input' && inputType === 'list') {
          const oldList = /** @type {string} */ (sharedDiffUtil.getDiffValue(column, 'list', 'old'));
          const newList = /** @type {string} */ (sharedDiffUtil.getDiffValue(column, 'list', 'new'));
          const oldListObject = getListMetadata(oldList);
          const newListObject = getListMetadata(newList);

          return {
            __old: isCellAdded ? '' : oldListObject?.name ?? '',
            __new: isCellRemoved ? '' : newListObject?.name ?? '',
          };
        }

        if (isEmptyValue(value)) {
          return columnType === SIGNOFF_COLUMN_TYPE ? undefined : '';
        }

        return tableUtil.isSignoffCell(value) && columnType !== SIGNOFF_COLUMN_TYPE ? '' : value;
      },
      [getListMetadata]
    );

    const { rowIndex: selectedRowIndex, columnIndex: selectedColumnIndex } = useMemo(() => {
      return tableInputUtil.cellIdsToIndices({
        rowId: selectedCell?.rowId,
        columnId: selectedCell?.columnId,
        rowMetadata,
        columnMetadata: columns,
      });
    }, [columns, rowMetadata, selectedCell]);

    const displayRows = useMemo(
      () =>
        new Array(Number(rowsCount)).fill('').map((row, rowIndex) =>
          columns.map((column, columnIndex) => {
            const cellPath = `${path}[${rowIndex}][${columnIndex}]`;
            const columnType = sharedDiffUtil.getDiffValue(column, 'column_type', 'new') || 'input'; // For backwards compatibility when we did not have column_type.
            const inputType = columnType === 'text' ? 'text' : sharedDiffUtil.getDiffValue(column, 'input_type', 'new'); // Regardless of input_type, if column_type is text use text input.
            const value = getIn(values, cellPath);
            const isRemoved =
              column.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED ||
              (removedRows && removedRows.has(rowIndex)) ||
              (removedColumns && removedColumns.has(columnIndex));
            const isAdded =
              !isRemoved &&
              (column.diff_change_state === ARRAY_CHANGE_SYMBOLS.ADDED ||
                (addedRows && addedRows.has(rowIndex)) ||
                (addedColumns && addedColumns.has(columnIndex)));

            const cellValue = getCellValue({
              value,
              column,
              columnType,
              inputType,
              isCellAdded: isAdded,
              isCellRemoved: isRemoved,
            });
            const newCellValue = cellValue?.__new ?? cellValue;
            const oldCellValue = cellValue?.__old ?? cellValue;
            return {
              name: cellPath,
              columnType,
              inputType,
              label:
                // Format added/removed units into an object, so they can be appropriately styled by `ProcedureDiffText`.
                isRemoved
                  ? { __old: sharedDiffUtil.getDiffValue(column, 'units', 'old'), __new: '' }
                  : isAdded
                  ? { __old: '', __new: sharedDiffUtil.getDiffValue(column, 'units', 'new') }
                  : columnType === 'text' && !sharedDiffUtil.isChanged(column, 'units')
                  ? ''
                  : column.units,
              isDisabled: true,
              isAdded,
              isRemoved,
              value: cellValue,
              newValue: newCellValue,
              oldValue: oldCellValue,
              isChanged: !isEqual(oldCellValue, newCellValue),
              error: getIn(errors, cellPath),
              list: column.list,
              isSelected: selectedRowIndex === rowIndex && selectedColumnIndex === columnIndex,
            };
          })
        ),
      [
        rowsCount,
        columns,
        path,
        values,
        removedRows,
        removedColumns,
        addedRows,
        addedColumns,
        getCellValue,
        errors,
        selectedRowIndex,
        selectedColumnIndex,
      ]
    );

    const showColumnTypeChanges = (column) =>
      sharedDiffUtil.isChanged(column, 'input_type') ||
      sharedDiffUtil.isChanged(column, 'column_type') ||
      sharedDiffUtil.isChanged(column, 'allow_input_after_signoff');

    const getColumnIcon = useCallback((column) => {
      if (sharedDiffUtil.getDiffValue(column, 'column_type', 'new') === SIGNOFF_COLUMN_TYPE) {
        return sharedDiffUtil.getDiffValue(column, 'allow_input_after_signoff', 'new') ? 'lock-open' : 'lock';
      }
      return '';
    }, []);

    const getColumnIconDescription = useCallback((column) => {
      if (sharedDiffUtil.getDiffValue(column, 'column_type', 'new') === SIGNOFF_COLUMN_TYPE) {
        return tableInputUtil
          .getSignoffSettingsDescriptionSegments(
            sharedDiffUtil.getDiffValue(column, 'allow_input_after_signoff', 'new')
          )
          .join(' ');
      }
      return '';
    }, []);

    const diffRowIndexLabelMap = useMemo(() => {
      let labelCounter = 1;
      return displayRows.reduce((rowMap, row, rowIndex) => {
        if (removedRows && removedRows.has(rowIndex)) {
          rowMap[rowIndex] = '\u00A0\u00A0'; // non-breaking space
        } else {
          rowMap[rowIndex] = labelCounter++;
        }
        return rowMap;
      }, {});
    }, [displayRows, removedRows]);

    const diffColumnIndexLabelMap = useMemo(() => {
      let labelCounter = 0;
      return columns.reduce((columnMap, _, columnIndex) => {
        if (removedColumns && removedColumns.has(columnIndex)) {
          columnMap[columnIndex] = '\u00A0\u00A0'; // non-breaking space
        } else {
          columnMap[columnIndex] = procedureUtil.displaySectionKey(labelCounter++, 'letters');
        }
        return columnMap;
      }, {});
    }, [columns, removedColumns]);

    const handleSelectCell = useCallback(
      ({ rowIndex, columnIndex }) => {
        const singleRowMetadata = rowMetadata?.[rowIndex];
        const singleColumnMetadata = columns?.[columnIndex];
        if (!canReferenceColumn(singleColumnMetadata)) {
          return;
        }
        onSelectCell({
          rowId: singleRowMetadata?.id,
          columnId: singleColumnMetadata?.id,
        });
      },
      [columns, onSelectCell, rowMetadata]
    );

    return (
      <table className="w-full h-full table-fixed">
        <thead>
          <tr>
            {columns.map((column, index) => {
              const uniqueColumnId = `${uniqueId}-${column.id}-${index}`;
              return (
                <th
                  key={uniqueColumnId}
                  align="left"
                  className={`border border-gray-400 break-words ${getCellColor(
                    {
                      isRemoved: removedColumns && removedColumns.has(index),
                      isAdded: addedColumns && addedColumns.has(index),
                    },
                    'bg-gray-200',
                    true
                  )}`}
                  style={{
                    width: `${getColumnWidth(column, index)}%`,
                    maxWidth: `${getColumnWidth(column, index)}%`,
                    minWidth: `${getColumnWidth(column, index)}%`,
                    backgroundImage: `${
                      showColumnTypeChanges(column)
                        ? 'linear-gradient(225deg, red, red 10px, transparent 0px, transparent)'
                        : ''
                    }`,
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  <div className="h-full">
                    <TooltipOverlay
                      content={<ColumnTypesDiffDetails column={column} columnIndex={index} cells={cells} />}
                      visible={showColumnTypeChanges(column)}
                    >
                      <div className="flex flex-col h-full">
                        <div className="text-gray-500 text-center text-xs">{diffColumnIndexLabelMap[index]}</div>
                        <div className="border-t border-gray-400">
                          {column.column_type === 'comment' && (
                            <div className="flex items-center justify-center h-full w-full">
                              <FontAwesomeIcon icon="comment" className="p-2 text-gray-500" />
                            </div>
                          )}

                          {column.column_type !== 'comment' && (
                            <ReviewFieldSetTableCell
                              type="text"
                              value={column.name}
                              isDisabled={true}
                              icon={getColumnIcon(column)}
                              iconDescription={getColumnIconDescription(column)}
                            />
                          )}
                        </div>
                      </div>
                    </TooltipOverlay>
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {displayRows.map((row, rowIndex) => {
            const rowKey = `${uniqueId}-rows[${rowIndex}]`;

            return (
              <tr key={rowKey} className="h-full group/row" aria-label="Field Input Table Row">
                {row.map((cell, cellIndex) => (
                  <td
                    aria-label={`${diffColumnIndexLabelMap[cellIndex]}${rowIndex + 1}`}
                    key={`${rowKey}[${cellIndex}]`}
                    // Require checkbox cells to be at least 44 x 44px to allow for easy clickability
                    className={`relative h-full w-fit border border-gray-400  ${getCellColor(cell)}`}
                    style={{
                      minWidth: '3.5rem',
                      maxWidth: '100%',
                    }}
                    onClick={() =>
                      handleSelectCell({
                        rowIndex,
                        columnIndex: cellIndex,
                      })
                    }
                  >
                    {cellIndex === 0 && (
                      <div
                        className={`${getCellColor(
                          {
                            isRemoved: removedRows && removedRows.has(rowIndex),
                            isAdded: addedRows && addedRows.has(rowIndex),
                          },
                          'bg-gray-200'
                        )} flex absolute -translate-x-full -left-px w-6 top-0 bottom-0 text-gray-500 items-center justify-center text-xs border-y border-l border-gray-400 my-0.5`}
                      >
                        {diffRowIndexLabelMap[rowIndex]}
                      </div>
                    )}
                    {cell.columnType === SIGNOFF_COLUMN_TYPE && (
                      <div className="py-1">
                        <ReviewSignoffCell signoffs={cell.value} isAdded={cell.isAdded} isRemoved={cell.isRemoved} />
                      </div>
                    )}
                    {cell.columnType === 'comment' && isEqual(cell.value, []) && (
                      <div className="flex items-center justify-center h-full w-full">
                        <FontAwesomeIcon icon="comment" className="p-2 text-gray-500" />
                      </div>
                    )}
                    {cell.columnType === 'test_point' && (
                      <div className="flex flex-col h-full-w-full divide-y-2 divide-dashed divide-gray-400">
                        {cell.newValue && (
                          <TestPointCell
                            testPoint={cell.newValue}
                            backgroundColor={getCellColor({
                              isAdded: (cell.isChanged && cell.newValue) || cell.isAdded,
                              isRemoved: cell.isRemoved,
                              isDisabled: true,
                            })}
                          />
                        )}

                        {cell.isChanged && cell.oldValue && (
                          <TestPointCell
                            testPoint={cell.oldValue}
                            backgroundColor={getCellColor({ isRemoved: true })}
                          />
                        )}
                      </div>
                    )}
                    {![SIGNOFF_COLUMN_TYPE, 'comment', 'test_point'].includes(cell.columnType) && (
                      <div
                        className={`h-full border-2 border-transparent ${
                          onSelectCell && canReferenceColumn(columns?.[cellIndex])
                            ? 'cursor-pointer hover:border-2 hover:border-blue-500 hover:bg-blue-100'
                            : ''
                        }`}
                      >
                        <ReviewFieldSetTableCell
                          name={cell.name}
                          type={cell.inputType}
                          label={cell.label}
                          value={cell.value}
                          isDisabled={cell.isDisabled}
                          error={cell.error}
                          setFieldValue={setFieldValue}
                          onBlur={onRecordValueChanged}
                        />
                      </div>
                    )}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
);

export default ReviewTable;
