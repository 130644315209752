import { useCallback, useEffect, useMemo, useState } from 'react';
import { SortColumn } from 'react-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Draft, ProcedureMetadata, RedlinesMetadata } from 'shared/lib/types/views/procedures';
import { getPendingProcedureIndex, isPendingId, isReleased, stripProcedureId } from 'shared/lib/procedureUtil';
import { CancelFunc } from '../api/procedures';
import { selectOfflineInfo } from '../app/offline';
import RunBatchProcedureModal from '../components/BatchSteps/RunBatchProcedureModal';
import Button, { BUTTON_TYPES } from '../components/Button';
import { RowWithProjectName } from '../components/Home/GridExpandCollapseButton';
import HomeScreenTableRDG from '../components/Home/HomeScreenTableRDG';
import ListHeader, { Filter } from '../components/Home/ListHeader';
import { getColumns, getRows } from '../components/Home/procedureGridUtils';
import usePersistedView from '../components/Home/usePersistedView';
import ImportUploadModal from '../components/ImportUploadModal';
import NewProcedureModal from '../components/NewProcedureModal';
import { TabProps } from '../components/TabBar/TabBar';
import { useAuth } from '../contexts/AuthContext';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { useMixpanel } from '../contexts/MixpanelContext';
import { useSettings } from '../contexts/SettingsContext';
import { useUserInfo } from '../contexts/UserContext';
import {
  DocSynced,
  selectProcedures,
  selectProceduresLoading,
  selectProceduresMetadata,
  selectProceduresSynced,
} from '../contexts/proceduresSlice';
import useLocationParams from '../hooks/useLocationParams';
import useMasterProcedureListHelpers from '../hooks/useMasterProcedureListHelpers';
import useProcedureListActions from '../hooks/useProcedureListActions';
import useProfile from '../hooks/useProfile';
import { PERM } from '../lib/auth';
import { CouchDBChanges } from '../lib/couchdbUtil';
import homeUtil from '../lib/homeUtil';
import REFRESH_TRY_AGAIN_MESSAGE from '../lib/messages';
import { procedureEditPath, proceduresPath } from '../lib/pathUtil';
import procedureUtil from '../lib/procedureUtil';
import projectUtil from '../lib/projectUtil';
import { getTagNames, getTagsAsOptions } from '../lib/tagsUtil';
import FlashMessage from '../components/FlashMessage';
import { ReactComponent as SparkleIcon } from '../images/sparkle_FAv6-icon.svg';
import apm from '../lib/apm';
import ChangeProjectModal from '../components/Home/ChangeProjectModal';
import PopupListWithSearch from '../elements/PopupListWithSearch';
import Label from '../components/Label';
import { Project } from 'shared/lib/types/couch/settings';
import { ReactComponent as FolderArrowSvg } from '../images/folder-arrow-side_custom-icon.svg';
import pluralize from 'pluralize';
import useProjects from '../hooks/useProjects';
import NewProcedureAIModal from '../components/NewProcedureAIModal';
import { faCaretDown, faPlus } from '@fortawesome/free-solid-svg-icons';
import MenuContext, { MenuContextAction } from '../components/MenuContext';
import useMenu from '../hooks/useMenu';

const emptyListText = 'No Procedures';
const PROCEDURES_KEY = 'master-procedure-list';
const ARCHIVED_PROCEDURES_KEY = 'archived-procedures';

const FILE_UPLOAD_SUCCESS = 'Upload successful. You will receive a notification when the procedure is imported.';

const DEFAULT_SORT: Record<string, Array<SortColumn>> = {
  [PROCEDURES_KEY]: [
    {
      columnKey: 'procedureTitle',
      direction: 'ASC',
    },
  ],
  [ARCHIVED_PROCEDURES_KEY]: [
    {
      columnKey: 'archivedDate',
      direction: 'DESC',
    },
  ],
};

const PROJECT_LABEL_LENGTH = 25;

const ChangeProjectButton = () => {
  return (
    <span className="ml-2 py-1">
      <Button removePadding={true} type={BUTTON_TYPES.TERTIARY} leadingIcon={FolderArrowSvg} title="Change Project">
        Change Project
      </Button>
    </span>
  );
};

const ProcedureList = () => {
  const { auth } = useAuth();
  const hasEditPermission = useMemo(
    () => auth.hasPermission(PERM.PROCEDURES_EDIT) || auth.hasProjectsWithEditPermission(),
    [auth]
  );
  const dispatch = useDispatch();
  const { userInfo } = useUserInfo();
  const history = useHistory();
  const { mixpanel } = useMixpanel();
  const userId = userInfo.session.user_id;
  const { isOfflineProcedureDataEnabled } = useProfile();
  const isOnline = useSelector((state) => selectOfflineInfo(state).online);
  const { services, currentTeamId } = useDatabaseServices();
  const { syncMasterProcedureList } = useMasterProcedureListHelpers();
  const { showBatchRunModal, onStartRun, onStartBatchRun, onCancelBatchRun } = useProcedureListActions();
  const { getProjectOptions } = useProjects();

  const [redlinesMetadata, setRedlinesMetadata] = useState<Array<RedlinesMetadata>>([]);
  const [loading, setLoading] = useState(false);
  const procedures = useSelector((state) => selectProcedures(state, currentTeamId));
  const proceduresSynced = useSelector((state) => selectProceduresSynced(state, currentTeamId));
  const proceduresMetadata = useSelector((state) => selectProceduresMetadata(state, currentTeamId));
  const isProceduresLoading = useSelector((state) => selectProceduresLoading(state, currentTeamId));
  const { projects, tags, isAutomationUIEnabled, isAiProcedureGenerationEnabled } = useSettings();
  const [showBuildYourOwnModal, setShowBuildYourOwnModal] = useState(false);
  const [showGenerateWithAiModal, setShowGenerateWithAiModal] = useState(false);
  const [showImportUploadModal, setShowImportUploadModal] = useState(false);
  const [uploadSuccessMessage, setUploadSuccessMessage] = useState<string | null>(null);
  const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set());
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [submitSuccessMessage, setSubmitSuccessMessage] = useState<null | string>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const persistedView = usePersistedView();

  const [allProcedures, setAllProcedures] = useState<Array<ProcedureMetadata>>([]);

  const location = useLocation();
  const { hash } = useLocationParams(location);
  const navigatedSection = useMemo(() => {
    return hash === PROCEDURES_KEY || hash === ARCHIVED_PROCEDURES_KEY ? hash : PROCEDURES_KEY;
  }, [hash]);
  const [sortPreferenceByTab, setSortPreferenceByTab] = useState(DEFAULT_SORT);
  const sortPreference = useMemo<Array<SortColumn>>(() => {
    return sortPreferenceByTab[navigatedSection];
  }, [navigatedSection, sortPreferenceByTab]);
  const { isMenuVisible, setIsMenuVisible } = useMenu();

  const setSortPreference = useCallback(
    (newSortPreference: Array<SortColumn>) => {
      setSortPreferenceByTab((oldSortPreferenceByTab) => {
        return {
          ...oldSortPreferenceByTab,
          [navigatedSection]: newSortPreference,
        };
      });
    },
    [navigatedSection]
  );

  useEffect(() => {
    if (!services.procedures) {
      return;
    }

    setLoading(true);
    const refreshProcedures = async (changes: CouchDBChanges | null) => {
      await syncMasterProcedureList(changes);
      setLoading(false);
    };
    const refreshArchivedProcedures = () =>
      services.procedures.getAllProceduresMetadata().then((procedures: Array<ProcedureMetadata>) => {
        setAllProcedures(procedures);
        setLoading(false);
      });

    const getRedlinesMetadata = () =>
      services.procedures.getRedlinesMetadata().then((redlines) => {
        setRedlinesMetadata(redlines.rows);
      });

    let observer: CancelFunc;
    if (navigatedSection === PROCEDURES_KEY) {
      observer = services.procedures.onProceduresChanged(refreshProcedures);
      // Manually refresh the list on first load.
      refreshProcedures(null)
        .then(() => getRedlinesMetadata())
        .catch((err) => apm.captureError(err));
    } else if (navigatedSection === ARCHIVED_PROCEDURES_KEY) {
      observer = services.procedures.onProceduresChanged(refreshArchivedProcedures);
      refreshArchivedProcedures();
    }

    return () => {
      if (observer) {
        observer.cancel();
      }
    };
  }, [services, dispatch, currentTeamId, syncMasterProcedureList, navigatedSection]);

  const mixpanelTrack = useCallback(
    (trackingKey: string, properties?: object | undefined) => {
      if (mixpanel) {
        mixpanel.track(trackingKey, properties);
      }
    },
    [mixpanel]
  );

  const onUnarchive = useCallback(
    (procedure: ProcedureMetadata, procedureLink: string) => {
      services.procedures
        .unarchiveProcedure(procedure)
        .then(() => {
          history.push(procedureLink);
        })
        .catch(() => {
          /* Ignore */
        });
    },
    [history, services.procedures]
  );

  const onNewProcedure = useCallback(
    async (initalProcedure?: Draft) => {
      let persistedProcedure;
      try {
        // Generate procedure doc locally
        const procedureDoc = procedureUtil.newProcedure(userId, initalProcedure);
        // If the user is a project-only admin, set a project id to allow permissions to work
        if (auth.hasProjectOnlyEditPermissions() && !procedureDoc.project_id) {
          procedureDoc.project_id = auth.projectsWithEditPermission()[0];
        }
        // Try to persist to the backend
        persistedProcedure = await services.procedures.saveDraft(procedureDoc);
      } catch (error) {
        persistedView.setSaveError(REFRESH_TRY_AGAIN_MESSAGE);
        return;
      }
      mixpanelTrack('New Procedure');
      history.push(procedureEditPath(currentTeamId, persistedProcedure.procedure_id));
    },
    [mixpanelTrack, history, currentTeamId, userId, auth, services.procedures, persistedView]
  );

  const onClickNewProcedure = useCallback(() => {
    if (!isOnline) {
      persistedView.setSaveError('Working offline, new procedures only available online');
      return;
    }
    setIsMenuVisible((current) => !current);
  }, [isOnline, persistedView, setIsMenuVisible]);

  const onImportProcedureClicked = useCallback(() => {
    setShowImportUploadModal(true);
    mixpanelTrack('[Import Procedure] Upload modal opened');
  }, [mixpanelTrack]);

  const getFilteredRows = useCallback(
    (dataset: Array<ProcedureMetadata>, offlineSyncMap?: Record<string, DocSynced>) => {
      return getRows({
        procedures: dataset,
        searchTerm: persistedView.searchTerm,
        context: {
          projects,
          currentTeamId,
          proceduresSynced: offlineSyncMap,
          auth,
          isOfflineProcedureDataEnabled,
          redlinesMetadataMap: homeUtil.getRedlinesMetadataMap(redlinesMetadata),
          allProceduresMetadata: Object.entries(proceduresMetadata).map(([, procedure]) => procedure),
          onStartRun,
          onUnarchive,
        },
      });
    },
    [
      auth,
      currentTeamId,
      isOfflineProcedureDataEnabled,
      onStartRun,
      onUnarchive,
      proceduresMetadata,
      projects,
      redlinesMetadata,
      persistedView.searchTerm,
    ]
  );

  const activeRows = useMemo(
    () => getFilteredRows(procedureUtil.getMasterProcedureList(Object.values(proceduresMetadata)), proceduresSynced),
    [getFilteredRows, proceduresMetadata, proceduresSynced]
  );
  const archivedRows = useMemo(
    () => getFilteredRows(allProcedures.filter((p) => isReleased(p) && p.archived)),
    [allProcedures, getFilteredRows]
  );
  const rows = navigatedSection === PROCEDURES_KEY ? activeRows : archivedRows;

  const tagOptions = useMemo(() => getTagsAsOptions(tags), [tags]);
  const selectedTagNames = useMemo(
    () => getTagNames(persistedView.selectedTagKeys, tags),
    [persistedView.selectedTagKeys, tags]
  );

  const selectedProjectNames = useMemo(
    () => projectUtil.getProjectNames(persistedView.selectedProjectIds, projects),
    [projects, persistedView.selectedProjectIds]
  );

  const TABS: ReadonlyArray<TabProps<string>> = [
    { id: PROCEDURES_KEY, label: 'Active', count: homeUtil.tabCount(activeRows.length) },
    { id: ARCHIVED_PROCEDURES_KEY, label: 'Archived', count: homeUtil.tabCount(archivedRows.length) },
  ];

  const updateTab = useCallback(
    (tab: string) => {
      switch (tab) {
        case PROCEDURES_KEY:
          history.push(proceduresPath(currentTeamId));
          break;
        case ARCHIVED_PROCEDURES_KEY:
          history.push(proceduresPath(currentTeamId, ARCHIVED_PROCEDURES_KEY));
          break;
      }
    },
    [currentTeamId, history]
  );

  const headers = useMemo(() => {
    if (navigatedSection === PROCEDURES_KEY) {
      return getColumns({ state: 'Active' });
    } else if (navigatedSection === ARCHIVED_PROCEDURES_KEY) {
      return getColumns({ state: 'Archived' });
    }
    return [];
  }, [navigatedSection]);

  const onCloseUploadModal = (showUploadSuccessMessage: boolean) => {
    setShowImportUploadModal(false);
    if (showUploadSuccessMessage) {
      setUploadSuccessMessage(FILE_UPLOAD_SUCCESS);
    }
  };

  const isLoading = useMemo(() => {
    // Master procedures tab loading state is handled by redux proceduresSlice.
    if (navigatedSection === PROCEDURES_KEY) {
      return isProceduresLoading && loading;
    }

    return loading;
  }, [navigatedSection, loading, isProceduresLoading]);

  const onCreateProcedure = useCallback(
    (procedureGenerationType: 'generate_procedure_with_ai' | 'build_your_own_procedure') => {
      if (procedureGenerationType === 'build_your_own_procedure') {
        setShowBuildYourOwnModal(true);
      }
      if (procedureGenerationType === 'generate_procedure_with_ai') {
        setShowGenerateWithAiModal(true);
      }
    },
    []
  );

  const menuActions: Array<MenuContextAction> = useMemo(() => {
    const baseActions: Array<MenuContextAction> = [
      {
        type: 'label',
        label: 'Create',
        data: {
          icon: 'pencil-alt',
          onClick: () => onCreateProcedure('build_your_own_procedure'),
        },
      },
      {
        type: 'label',
        label: 'Import',
        data: {
          icon: 'file-import',
          onClick: () => onImportProcedureClicked(),
        },
      },
    ];

    // Conditionally include the "Generate" action
    if (isAiProcedureGenerationEnabled && isAiProcedureGenerationEnabled()) {
      return [
        baseActions[0], // Create
        {
          type: 'label',
          label: 'Generate',
          data: {
            icon: SparkleIcon,
            onClick: () => onCreateProcedure('generate_procedure_with_ai'),
          },
        },
        ...baseActions.slice(1), // Import
      ];
    }

    return baseActions;
  }, [onCreateProcedure, onImportProcedureClicked, isAiProcedureGenerationEnabled]);

  const actions = useMemo(() => {
    return navigatedSection === PROCEDURES_KEY && hasEditPermission ? (
      <div className="flex flex-row gap-x-1">
        {showImportUploadModal && <ImportUploadModal onClose={onCloseUploadModal} />}
        <FlashMessage message={uploadSuccessMessage} messageUpdater={setUploadSuccessMessage} />
        {showGenerateWithAiModal && (
          <NewProcedureAIModal
            cancelActionCloseModal={() => {
              setShowGenerateWithAiModal(false);
            }}
            primaryActionCloseModal={() => {
              setShowGenerateWithAiModal(false);
              setUploadSuccessMessage(
                'Procedure generation submitted. You will receive a notification when the procedure is available.'
              );
            }}
            setErrorMessage={(message: string) => setErrorMessage(message)}
          />
        )}
        {showBuildYourOwnModal && (
          <NewProcedureModal
            isProjectClearable={!auth.hasProjectOnlyEditPermissions()}
            procedures={procedures}
            onNewProcedure={onNewProcedure}
            closeModal={() => setShowBuildYourOwnModal(false)}
            isAutomationUIEnabled={isAutomationUIEnabled()}
          />
        )}
        <div className="justify-end flex flex-row gap-2">
          <Button
            title="New Procedure"
            onClick={() => onClickNewProcedure()}
            type={BUTTON_TYPES.PRIMARY}
            leadingIcon={faPlus}
            trailingIcon={faCaretDown}
          >
            New Procedure
          </Button>
          {isMenuVisible && (
            <div className="absolute top-16 w-40 z-40">
              <MenuContext menuContextActions={[menuActions]} className="font-medium text-xs" hasDividers={true} />
            </div>
          )}
        </div>
      </div>
    ) : (
      <></>
    );
  }, [
    navigatedSection,
    hasEditPermission,
    showImportUploadModal,
    uploadSuccessMessage,
    showGenerateWithAiModal,
    auth,
    procedures,
    onNewProcedure,
    isAutomationUIEnabled,
    showBuildYourOwnModal,
    isMenuVisible,
    menuActions,
    onClickNewProcedure,
  ]);

  /*
   * The selected row IDs may either be a draft ID or released ID.  To support changing the project
   * of both documents, we need to get the corresponding draft or released IDs as well.  Not all drafts
   * have a released procedure, and not all procedures have an open draft.
   */
  const getDraftAndReleasedIds = useCallback(
    (ids: Set<string>) => {
      const metadataIds = new Set();
      for (const metadata of Object.values(proceduresMetadata)) {
        if (metadata.archived) {
          continue;
        }
        metadataIds.add(metadata._id);
      }
      const allSelectedIds = new Set<string>();
      ids.forEach((id) => {
        allSelectedIds.add(id);
        const associatedId = isPendingId(id) ? stripProcedureId(id) : getPendingProcedureIndex(id);
        if (metadataIds.has(associatedId)) {
          allSelectedIds.add(associatedId);
        }
      });
      return allSelectedIds;
    },
    [proceduresMetadata]
  );

  const onChangeProject = useCallback(async () => {
    if (!selectedProject) {
      return;
    }
    const allSelectedIds = getDraftAndReleasedIds(selectedRows);
    try {
      await services.procedures.bulkChangeProject([...allSelectedIds], selectedProject.id);
      setSubmitSuccessMessage(`Changed project for ${pluralize('procedure', selectedRows.size, true)}`);
      setSelectedRows(new Set());
    } catch {
      setErrorMessage(`Failed to change project for ${pluralize('procedure', selectedRows.size, true)}`);
    }
    setSelectedProject(null);
  }, [selectedProject, selectedRows, getDraftAndReleasedIds, services.procedures]);

  return (
    <>
      <FlashMessage message={submitSuccessMessage} messageUpdater={setSubmitSuccessMessage} />
      <FlashMessage message={errorMessage} messageUpdater={setErrorMessage} type="warning" />
      <div className="flex flex-col flex-grow px-5">
        <ListHeader
          isLoading={isLoading}
          persistedView={persistedView}
          filters={new Set([Filter.Projects, Filter.Tags])}
          tagOptions={tagOptions}
          name="Procedures"
          tabs={TABS}
          rows={rows as ReadonlyArray<RowWithProjectName>}
          navigatedSection={navigatedSection}
          updateTab={updateTab}
          actions={actions}
        />
        {hasEditPermission && (
          <div className="flex w-full divide-x">
            <span className="text-sm text-gray-400 py-1 mr-2">{`${pluralize(
              'procedure',
              selectedRows.size,
              true
            )} selected`}</span>
            {selectedRows.size > 0 && isOnline && (
              <span>
                <PopupListWithSearch
                  Components={{
                    Trigger: () => <ChangeProjectButton />,
                    ListItem: (option) => {
                      return (
                        <Label
                          text={projectUtil.getProjectName(projects, option.id as string) || ''}
                          color="bg-gray-200"
                          maxLength={PROJECT_LABEL_LENGTH}
                        />
                      );
                    },
                  }}
                  options={getProjectOptions(PERM.PROCEDURES_EDIT)}
                  onSelect={async (option) => {
                    setSelectedProject(option.value ? option.value : null);
                  }}
                />
              </span>
            )}
          </div>
        )}

        <ChangeProjectModal
          selectedProject={selectedProject}
          onCancel={() => setSelectedProject(null)}
          onChangeProject={onChangeProject}
          changeCount={selectedRows.size}
        ></ChangeProjectModal>

        {showBatchRunModal && <RunBatchProcedureModal onRun={onStartBatchRun} onCancel={onCancelBatchRun} />}
        <HomeScreenTableRDG
          key={navigatedSection}
          headers={headers}
          rows={rows}
          emptyListText={emptyListText}
          searchTerm={persistedView.searchTerm}
          setSearchTerm={persistedView.setSearchTerm}
          projectNamesFilter={selectedProjectNames}
          tagNamesFilter={selectedTagNames}
          sortPreference={sortPreference}
          setSortPreference={setSortPreference}
          showParentChildRelation={false}
          viewTab={persistedView.viewTab}
          expandedProjectNames={persistedView.expandedProjectNames}
          setExpandedProjectNames={persistedView.setExpandedProjectNames}
          showCheckboxes={hasEditPermission}
          setSelectedRows={(selected) => setSelectedRows(selected)}
          selectedRows={selectedRows}
        />
      </div>
    </>
  );
};

export default ProcedureList;
