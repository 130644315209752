import React, { useRef, useState, useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalBase from './ModalBase';
import { useHistory } from 'react-router-dom';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { useMixpanel } from '../contexts/MixpanelContext';
import Button, { BUTTON_TYPES } from './Button';
import { procedureImportPath } from '../lib/pathUtil';
import { useSettings } from '../contexts/SettingsContext';

const TUTORIAL_URL = 'https://support.epsilon3.io/en/articles/7892897-import-procedure';
const ERROR_TRY_AGAIN = 'Error, please try again';
const ML_SUPPORTED_FILE_TYPES = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

/**
 * ImportUploadModal properties.
 *
 * @param onClose - Callback for closing modal in parent.
 */
interface ImportUploadModalProps {
  onClose: (showUploadSuccessMessage: boolean) => void;
}

/**
 * Displays a fullscreen, blocking modal prompting for import upload.
 */
const ImportUploadModal = ({ onClose }: ImportUploadModalProps) => {
  const importProcedureInputRef = useRef<HTMLInputElement>(null);
  const history = useHistory();
  const { services, currentTeamId } = useDatabaseServices();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const { mixpanel } = useMixpanel();
  const { isProcedureImportEnabled } = useSettings();

  const supportedFileTypes = useMemo(() => {
    const types = ['.csv', '.prl'];
    if (isProcedureImportEnabled && isProcedureImportEnabled()) {
      types.push('.pdf', '.docx');
    }
    return types;
  }, [isProcedureImportEnabled]);

  const supportedFileTypesMessage = useMemo(() => {
    if (isProcedureImportEnabled && isProcedureImportEnabled()) {
      return 'Supports .csv, .docx, .pdf, and .prl file formats.';
    }
    return 'Supports .csv and .prl file formats.';
  }, [isProcedureImportEnabled]);

  const importCsvOrPrl = useCallback(
    (file) => {
      return services.procedures.importProcedure(file).then((res) => {
        setUploadError('');
        const protoBlockList = res.data.protoblocks;
        const importUUID = res.data.importUUID;

        if (mixpanel) {
          mixpanel.track('[Import Procedure] Initial Protoblock List Generated', {
            n_protoblocks: protoBlockList.length,
            importUUID,
          });
        }

        if (importProcedureInputRef && importProcedureInputRef.current) {
          importProcedureInputRef.current.value = '';
        }
        history.push({
          pathname: procedureImportPath(currentTeamId, importUUID),
          state: {
            importFileName: file.name,
            protoBlockList,
          },
        });
      });
    },
    [mixpanel, history, services.procedures, currentTeamId]
  );

  const onImportProcedureInputChange = useCallback(
    async (e) => {
      setIsUploading(true);
      const file = e.currentTarget.files[0];

      if (mixpanel) {
        mixpanel.track('[Import Procedure] File Uploaded', {
          file_type: file.type !== '' ? file.type : file.name.slice(-4),
          file_size: file.size,
        });
      }

      if (isProcedureImportEnabled() && ML_SUPPORTED_FILE_TYPES.includes(file.type)) {
        return services.ml
          .processProcedureAttachment(file)
          .catch(() => setUploadError(ERROR_TRY_AGAIN))
          .finally(() => {
            setIsUploading(false);
            onClose(true);
          });
      }

      return importCsvOrPrl(file)
        .catch((error) => {
          setUploadError(error.response?.data?.error || ERROR_TRY_AGAIN);
        })
        .finally(() => setIsUploading(false));
    },
    [mixpanel, services.ml, importCsvOrPrl, onClose, isProcedureImportEnabled]
  );

  const onUploadFile = useCallback(() => {
    if (!importProcedureInputRef.current) {
      return;
    }
    importProcedureInputRef.current.click();
  }, []);

  return (
    <ModalBase>
      <FontAwesomeIcon
        onClick={() => onClose(false)}
        icon="times"
        size="lg"
        className="absolute right-5 top-4 text-gray-200 hover:text-gray-400 cursor-pointer"
      />
      <div className="flex flex-col">
        <h1 className="text-center mt-3">Import Procedure</h1>
        <div className="flex justify-center my-2 mx-2">
          <input
            ref={importProcedureInputRef}
            type="file"
            value=""
            accept={supportedFileTypes.join(',')}
            id="import-procedure"
            onChange={(e) => onImportProcedureInputChange(e)}
            className="hidden"
          />
          <Button type={BUTTON_TYPES.PRIMARY} leadingIcon="upload" onClick={onUploadFile}>
            {isUploading ? 'Uploading...' : 'Upload File'}
          </Button>
        </div>
        {uploadError !== '' && (
          <div className="mx-5 py-2 text-red-900 px-3 bg-red-200 text-center rounded space-x-2">
            <FontAwesomeIcon icon="times-circle" className="mr-2" />
            {uploadError}
          </div>
        )}
        <div className="my-3 mx-4">
          {`${supportedFileTypesMessage} For examples and more, check out our`}
          <a
            href={TUTORIAL_URL}
            className="ml-1 underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
            target="_blank"
            rel="noopener noreferrer"
          >
            tutorial
          </a>
          .
        </div>
      </div>
    </ModalBase>
  );
};

export default ImportUploadModal;
