import React, { useRef, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AttachmentPreview from './Attachments/AttachmentPreview';
import FormStepComment from './FormStepComment';
import useAttachmentCommentActions from '../hooks/useAttachmentCommentActions';
import { Mention } from 'shared/lib/types/postgres/util';
import { RunStepComment } from 'shared/lib/types/views/procedures';

interface CommentFormProps {
  parentId: string;
  sectionId: string;
  stepId: string;
  saveNewComment?: (comment: RunStepComment) => void;
  formVisible: boolean;
  setFormVisible: (isVisible: boolean) => void;
  commentButton?: React.ReactElement;
  isRun?: boolean;
  isCommentingDisabled?: boolean;
}

const CommentForm = ({
  parentId,
  sectionId,
  stepId,
  saveNewComment,
  formVisible,
  setFormVisible,
  commentButton,
  isRun,
  isCommentingDisabled,
}: CommentFormProps) => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const isMounted = useRef(true);

  const {
    uploadFile,
    setUploadFile,
    isSubmittingAttachment,
    isAttachmentRenderingImage,
    isAddingText,
    attachButton,
    setIsAddingText,
    fileInputRef,
    onFileInputChange,
    onSaveTextComment,
    onSubmitAttachment,
    onShouldRenderImageChange,
  } = useAttachmentCommentActions({
    sectionId,
    stepId,
    parentId,
    onSaveNewComment: saveNewComment,
  });

  const onCancelText = (e) => {
    setFormVisible(false);
    setIsAddingText(false);
  };

  useEffect(() => {
    if (formVisible && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [formVisible, textAreaRef]);

  const onFilePasted = useCallback(
    (file) => {
      setUploadFile(file);
    },
    [setUploadFile]
  );

  const onSubmitComment = async (values: { comment: string; mentions: Array<Mention> }, { setSubmitting }) => {
    if (isAddingText && uploadFile) {
      onSubmitAttachment();
    }
    return onSaveTextComment(values)
      .then(() => {
        // Form is removed on success so no need to call setSubmitting(false).
        setFormVisible(false);
        setIsAddingText(false);
      })
      .catch(() => {
        // TODO: Show error message on error?
        if (!isMounted.current) {
          return;
        }
        setSubmitting(false);
        setIsAddingText(false);
      });
  };

  const onCancelFile = () => {
    setUploadFile(null);
  };

  const onTextChanged = useCallback(
    (containsText: boolean) => {
      setIsAddingText(containsText);
    },
    [setIsAddingText]
  );

  return (
    <>
      {!isRun && <div className="h-3" />}
      {isRun && (
        <div className={`flex ${parentId ? 'px-2 pb-1' : ''} ${isAddingText ? 'flex-col' : ''}`}>
          {(isAddingText || (!uploadFile && formVisible)) && (
            <FormStepComment
              onSubmit={onSubmitComment}
              onCancel={onCancelText}
              placeholder="Add comment"
              onFilePasted={onFilePasted}
              onTextChanged={onTextChanged}
              parentId={parentId}
            />
          )}
          {uploadFile && (
            <div className="w-full flex items-center relative">
              <div className="flex relative">
                <div className="w-full p-2">
                  <AttachmentPreview
                    //@ts-ignore
                    file={uploadFile}
                    onShouldRenderImageChange={onShouldRenderImageChange}
                    attachment={undefined}
                  />
                </div>
              </div>
              <div className={`${isAttachmentRenderingImage ? '' : 'flex flex-row items-center'}`}>
                <button
                  className={`${
                    isAttachmentRenderingImage ? 'absolute' : ''
                  } ml-2 app-bg-gray-5 top-2.5 align-top rounded-full w-5 h-5 flex justify-center items-center disabled:bg-gray-100`}
                  onClick={onCancelFile}
                  disabled={isSubmittingAttachment}
                >
                  <FontAwesomeIcon icon="times" size="xs" className="text-white" />
                </button>
                {!isAddingText && (
                  <button
                    className={`${isAttachmentRenderingImage ? 'absolute' : 'ml-2'} ${
                      parentId ? 'hover:bg-slate-300' : 'hover:bg-blue-100'
                    } px-3 py-2.5 text-sm rounded-md btn-link bottom-0 disabled:bg-gray-100 disabled:text-gray-600 disabled:cursor-default`}
                    onClick={() => onSubmitAttachment()}
                    disabled={isSubmittingAttachment}
                  >
                    <div className="flex flex-row items-center">
                      {isSubmittingAttachment ? 'Saving' : 'Post'}
                      {!isSubmittingAttachment && (
                        <FontAwesomeIcon icon="chevron-right" size="xs" className="ml-1 align-middle" />
                      )}
                    </div>
                  </button>
                )}
              </div>
            </div>
          )}
          {!uploadFile && !formVisible && !isCommentingDisabled && (
            <div className="flex">
              {/* Text commenting Button */}
              {commentButton}
              {/* File attachments */}
              <div>
                {/* Hidden input for triggering file picker */}
                <input
                  ref={fileInputRef}
                  type="file"
                  id={`sections[${sectionId}].steps[${stepId}]._file_input`}
                  onChange={(e) => onFileInputChange(e)}
                  className="hidden"
                  data-testid="file_attachment_input"
                />
                {attachButton}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default React.memo(CommentForm);
