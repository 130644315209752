import React, { useCallback, useMemo, useRef } from 'react';
import { Field, Formik } from 'formik';
import TextAreaAutoHeight from './TextAreaAutoHeight';
import UnitDisplay from './Settings/Units/UnitDisplay';
import {
  FieldInputBlock,
  FieldInputNumberBlock,
  FieldInputTextBlock,
  ReleaseFieldInputBlock,
  RunFieldInputRecordedValue,
  RunFieldInputTimestampBlock,
} from 'shared/lib/types/views/procedures';
import Select from 'react-select';
import { useSettings } from './../contexts/SettingsContext';
import isEqual from 'lodash.isequal';
import { cloneDeep, isNil } from 'lodash';
import { gridReactSelectStyles, disabledGridReactSelectStyles } from '../lib/styles';
import TimestampFieldInputDisplay from './Blocks/FieldInput/TimestampFieldInputDisplay';
import TextLinkify from './TextLinkify';
import ProcedureFieldDiff from './ProcedureFieldDiff';
import MarkdownView from './Markdown/MarkdownView';
import { isEmptyValue } from 'shared/lib/text';
import Checkbox from './Checkbox';
import GridTimestampFieldInput from './Blocks/FieldInput/GridTImestampFieldInput';
import { DateTime } from 'luxon';
import RuleStatusIcon from './Blocks/RuleStatusIcon';
import TooltipOverlay from './TooltipOverlay';
import Rule from './Blocks/Rule';
import fieldInputUtil from '../lib/fieldInputUtil';

export interface GridFieldInputProps {
  block: FieldInputBlock;
  redlinedBlock?: ReleaseFieldInputBlock;
  recorded;
  isEnabled: boolean;
  onRecordErrorsChanged?: (errors: { text?: string }) => void;
  onRecordValuesChanged?: (recorded: { value?: RunFieldInputRecordedValue }) => void;
}

const GridFieldInput = ({
  block,
  redlinedBlock,
  recorded,
  isEnabled,
  onRecordValuesChanged,
  onRecordErrorsChanged,
}: GridFieldInputProps) => {
  const inputRef = useRef(null);
  const { getListValues, getSetting } = useSettings();
  const isPassing = useMemo(() => {
    return fieldInputUtil.isNumberFieldInputPassing(block as FieldInputBlock, recorded);
  }, [block, recorded]);

  const selectOptionsList = useMemo(() => {
    if (block.inputType === 'select') {
      return block.options ? block.options.filter((option) => option !== '') : [];
    } else if (block.inputType === 'list') {
      return getListValues(block.list);
    }
  }, [block, getListValues]);

  const selectOptions = useMemo(() => {
    if (!Array.isArray(selectOptionsList)) {
      return [];
    }

    return selectOptionsList.map((value) => ({
      value,
      label: value,
    }));
  }, [selectOptionsList]);

  const updateInputValue = (e, value, formikField, setFieldValue) => {
    let validated = value;
    switch (block.inputType) {
      case 'number':
        if (!isNaN(parseFloat(value))) {
          validated = parseFloat(value);
        }
        break;
      default:
        break;
    }
    setFieldValue('text', validated);

    // Notify observers that value changed
    if (onRecordValuesChanged) {
      const _recorded = recorded ? cloneDeep(recorded) : {};
      _recorded.value = validated;
      if (!isEqual(recorded, _recorded)) {
        onRecordValuesChanged(_recorded);
      }
    }

    formikField?.onBlur(e);
  };

  const valueSelected = useMemo(() => {
    if (!recorded || !recorded.value) {
      return null;
    }
    return selectOptions.find((option) => option.value === recorded.value);
  }, [recorded, selectOptions]);

  const recordedValue = useMemo(() => {
    return recorded && !isNil(recorded.value) ? recorded.value : '';
  }, [recorded]);

  const initialValues = useMemo(() => {
    return { text: recordedValue };
  }, [recordedValue]);

  const numberBorderColor = useMemo(() => {
    if (isPassing !== null && block.inputType === 'number') {
      return isPassing ? 'ring-1 ring-green-500 bg-green-50 border-none' : 'ring-1 ring-red-500 bg-red-50 border-none';
    }
  }, [isPassing, block.inputType]);

  const onValidate = useCallback(
    (values) => {
      const errors = {};
      const text = values.text;

      switch (block.inputType) {
        case 'number':
          if (text && text !== '' && isNaN(parseFloat(text))) {
            errors['text'] = 'Enter a valid number';
          }
          break;
        default:
          break;
      }
      if (typeof onRecordErrorsChanged === 'function') {
        onRecordErrorsChanged(errors);
      }
      return errors;
    },
    [block.inputType, onRecordErrorsChanged]
  );

  const getTimeZoneCode = () => {
    const now = DateTime.now().setZone(getSetting('timezone', 'UTC'));
    return now.toFormat('ZZZZ');
  };

  const hasRule = Boolean((block as FieldInputNumberBlock).rule?.op);

  return (
    <>
      {isEnabled && (
        <Formik
          initialValues={initialValues}
          onSubmit={() => {
            /* no-op */
          }}
          validate={onValidate}
          validateOnBlur
          enableReinitialize
        >
          {({ setFieldValue, errors }) => (
            <div className="p-1 flex flex-col w-full">
              <div className="relative flex items-center w-full">
                <label
                  className="absolute top-0 px-1 text-xs text-gray-500 font-semibold truncate w-full"
                  style={{ zIndex: 1 }}
                >
                  {block.name}
                  {(block.inputType === 'number' || block.inputType === 'text') && block.units && (
                    <span className="inline-flex ml-1">
                      (<UnitDisplay unit={block.units} />)
                    </span>
                  )}
                  {block.inputType === 'timestamp' && <span className="inline-flex ml-1">({getTimeZoneCode()})</span>}
                </label>
                {block.inputType === 'text' && (
                  <>
                    <Field name="text">
                      {({ field: formikField }) => (
                        <TextAreaAutoHeight
                          {...formikField}
                          aria-label="Enter Value"
                          ref={inputRef}
                          onBlur={(e) => updateInputValue(e, e.target.value, formikField, setFieldValue)}
                          disabled={!isEnabled}
                          className="border rounded w-full"
                          style={{
                            paddingTop: '1rem',
                          }}
                        />
                      )}
                    </Field>
                  </>
                )}
                {block.inputType === 'number' && (
                  <div className="w-full">
                    <Field name="text">
                      {({ field: formikField }) => (
                        <TooltipOverlay
                          content={
                            <div>
                              <Rule block={block} isPassing={isPassing} />
                            </div>
                          }
                          visible={hasRule}
                        >
                          <div className="relative">
                            <input
                              type="text"
                              {...formikField}
                              aria-label="Enter Value"
                              ref={inputRef}
                              className={`border rounded w-full ${numberBorderColor} border-gray-400 text-sm ${
                                hasRule ? 'pr-5' : ''
                              }`}
                              onBlur={(e) => updateInputValue(e, e.target.value, formikField, setFieldValue)}
                              disabled={!isEnabled}
                              style={{ paddingTop: '1rem' }}
                            />
                            {hasRule && (
                              <div className="absolute top-1/2 -translate-y-1/2 right-1.5">
                                <RuleStatusIcon isPassing={isPassing} />
                              </div>
                            )}
                          </div>
                        </TooltipOverlay>
                      )}
                    </Field>
                    {errors.text && (
                      <span className="whitespace-nowrap text-sm ml-2 self-center text-red-700">
                        {errors.text as string}
                      </span>
                    )}
                  </div>
                )}

                {block.inputType === 'checkbox' && (
                  <Field name="text">
                    {({ field: formikField }) => (
                      <div
                        className="relative w-full h-[2.9rem] text-gray-500 border border-gray-400 rounded-md bg-white disabled:bg-gray-300 disabled:bg-opacity-50 flex items-center cursor-pointer"
                        onClick={(e) => updateInputValue(e, !formikField.value, formikField, setFieldValue)}
                      >
                        <div className="ml-2 mt-2">
                          <Checkbox
                            disabled={!isEnabled}
                            ariaLabel="checkbox"
                            size="text-lg"
                            checked={formikField.value}
                          />
                        </div>
                      </div>
                    )}
                  </Field>
                )}
                {block.inputType === 'timestamp' && (
                  <div className="border rounded-md border-gray-400 w-full flex flex-wrap items-center bg-white">
                    <GridTimestampFieldInput
                      block={block}
                      recorded={recorded as RunFieldInputTimestampBlock['recorded']}
                      onRecordValuesChanged={onRecordValuesChanged}
                    />
                  </div>
                )}
                {block.inputType === 'select' && (
                  <Select
                    classNamePrefix="react-select"
                    className="w-full border-gray-400"
                    onChange={(option) => updateInputValue(null, option.value, null, setFieldValue)}
                    options={selectOptions}
                    styles={gridReactSelectStyles}
                    value={valueSelected}
                    aria-label="Field Input Select"
                    isDisabled={!isEnabled}
                  />
                )}
                {block.inputType === 'list' && (
                  <Select
                    classNamePrefix="react-select"
                    className="w-full border-gray-400"
                    onChange={(option) => updateInputValue(null, option.value, null, setFieldValue)}
                    options={selectOptions}
                    styles={gridReactSelectStyles}
                    value={valueSelected}
                    aria-label="Field Input List"
                    isDisabled={!isEnabled}
                  />
                )}
              </div>
            </div>
          )}
        </Formik>
      )}
      {!isEnabled && (
        <div className="flex flex-col w-full p-1 relative">
          {/* Display for each block type */}
          <TextLinkify>
            <div className="flex justify-start w-full absolute top-1 left-2 text-xs text-gray-500 font-semibold z-20 truncate">
              {redlinedBlock ? (
                <div className="whitespace-nowrap truncate overflow-hidden w-full">
                  <ProcedureFieldDiff original={block?.name ?? ''} redlined={redlinedBlock?.name ?? ''} />
                  {(block.inputType === 'number' || block.inputType === 'text') && (
                    <span className="inline-flex ml-1">
                      {' ('}
                      <ProcedureFieldDiff
                        original={(block as FieldInputTextBlock | FieldInputNumberBlock).units ?? ''}
                        redlined={(redlinedBlock as FieldInputTextBlock | FieldInputNumberBlock).units ?? ''}
                      />
                      {') '}
                    </span>
                  )}
                </div>
              ) : (
                <label className="text-xs text-gray-500 font-semibold z-20 truncate w-full pr-3">
                  {block.name}
                  {(block.inputType === 'number' || block.inputType === 'text') && block.units && (
                    <span className="inline-flex ml-1">
                      (<UnitDisplay unit={block.units} />)
                    </span>
                  )}
                </label>
              )}
            </div>
          </TextLinkify>

          {/* Specific input type handling */}
          {block.inputType === 'timestamp' && (
            <div className="border rounded-md border-gray-400 bg-gray-300 bg-opacity-50 overflow-hidden">
              <TimestampFieldInputDisplay
                type={block.dateTimeType}
                recorded={recorded as RunFieldInputTimestampBlock['recorded']}
                isGridFormat={true}
              >
                <></>
              </TimestampFieldInputDisplay>
            </div>
          )}

          {block.inputType === 'checkbox' && (
            <div className="w-full h-[2.9rem] text-gray-500 border border-gray-400 rounded bg-gray-300 bg-opacity-50 flex justify-start">
              <div className="ml-2 mt-3">
                <Checkbox disabled={true} ariaLabel="checkbox" size="text-md" checked={recorded?.value} />
              </div>
            </div>
          )}
          {block.inputType === 'text' && (
            <>
              {(!recorded || isEmptyValue(recorded.value)) && (
                <div className="text-sm px-2 py-1 border border-gray-400 rounded bg-gray-300 bg-opacity-50 h-[2.9rem] w-full" />
              )}
              {recorded && !isEmptyValue(recorded.value) && (
                <div className="text-sm px-2 pb-2 pt-4 border rounded border-gray-400 bg-gray-300 bg-opacity-50 w-full">
                  <MarkdownView text={String(recorded?.value)} />
                </div>
              )}
            </>
          )}

          {block.inputType === 'number' && (
            <TooltipOverlay
              content={
                <div>
                  <Rule block={block} isPassing={isPassing} />
                </div>
              }
              visible={hasRule}
            >
              <div className="flex flex-nowrap justify-between relative">
                {(!recorded || isEmptyValue(recorded.value)) && (
                  <div className="text-sm px-2 py-1 border border-gray-400 rounded bg-gray-300 bg-opacity-50 h-[2.9rem] w-full"></div>
                )}
                {recorded && !isEmptyValue(recorded.value) && (
                  <div
                    className={`text-sm p-2 border rounded border-gray-400 bg-gray-300 bg-opacity-50 truncate w-full pt-3 h-[2.9rem] ${numberBorderColor}`}
                    style={{
                      paddingTop: '1rem',
                    }}
                  >
                    {recorded?.value}
                  </div>
                )}
                {hasRule && (
                  <div className="absolute top-1/2 -translate-y-1/2 right-1.5">
                    <RuleStatusIcon isPassing={isPassing} />
                  </div>
                )}
              </div>
            </TooltipOverlay>
          )}
          {block.inputType === 'select' && (
            <Select
              classNamePrefix="react-select"
              className="w-full"
              styles={disabledGridReactSelectStyles}
              value={valueSelected}
              aria-label="Field Input Select"
              isDisabled={true}
            />
          )}
          {block.inputType === 'list' && (
            <Select
              classNamePrefix="react-select"
              className="w-full"
              styles={disabledGridReactSelectStyles}
              value={valueSelected}
              aria-label="Field Input List"
              isDisabled={true}
            />
          )}
        </div>
      )}
    </>
  );
};

export default GridFieldInput;
