import React, { useMemo, useCallback, useContext } from 'react';
import { useRunContext } from './RunContext';

type RunFilterContextType = {
  isSectionFiltered: (sectionId: string) => boolean;
  isStepFiltered: (stepId: string) => boolean;
};

const RunFilterContext = React.createContext<RunFilterContextType>({
  isSectionFiltered: () => true,
  isStepFiltered: () => true,
});

export const RunFilterProvider = ({ displaySections, children }) => {
  const { filteredByOperators } = useRunContext();
  const filterMap = useMemo(() => {
    const map = {};
    if (!displaySections) {
      return map;
    }
    displaySections.forEach(([section]) => {
      section.steps.forEach((step) => {
        const stepHasSignoffsWithFilteredOperator =
          step.signoffs &&
          step.signoffs.some((signoff) => signoff.operators.some((operator) => filteredByOperators.includes(operator)));
        const isDisplayed = !filteredByOperators.length || stepHasSignoffsWithFilteredOperator;
        map[step.id] = isDisplayed;
        if (isDisplayed) {
          map[section.id] = true;
        }
      });
    });
    return map;
  }, [filteredByOperators, displaySections]);

  const isSectionFiltered = useCallback(
    (sectionId) => {
      return filterMap[sectionId];
    },
    [filterMap]
  );

  const isStepFiltered = useCallback(
    (stepId) => {
      return filterMap[stepId];
    },
    [filterMap]
  );

  const value = {
    isSectionFiltered,
    isStepFiltered,
  };

  return <RunFilterContext.Provider value={value}>{children}</RunFilterContext.Provider>;
};

export const useRunFilter = () => {
  const context = useContext(RunFilterContext);
  if (context === undefined) {
    return {
      isSectionFiltered: () => true,
      isStepFiltered: () => true,
    };
  }
  return context;
};
