import React, { useMemo } from 'react';
import { Field, FormikHelpers, FormikValues } from 'formik';
import validateUtil from '../lib/validateUtil';
import {
  AddedStep,
  DraftTextBlock,
  ReleaseStepBlock,
  ReleaseTextBlock,
  RunHeaderBlockRedline,
  RunStepBlockRedline,
} from 'shared/lib/types/views/procedures';
import { HeaderBlockRedline, StepBlockRedline } from 'shared/lib/types/views/redlines';
import { getRedlineDocsSortedLatestToEarliest } from '../lib/redlineUtil';
import TextBlockRedlineBlock from './Blocks/redline/TextBlockRedlineBlock';
import { TextBlockContentErrors } from '../lib/types';
import { getRedlineFromDoc } from 'shared/lib/redlineUtil';
import ReferenceTextArea from './ReferenceTextArea';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { getRedlineSourceStepPath } from '../lib/pathUtil';

/**
 * Component for rendering form elements for a text block.
 *
 * @param content Form values object of type { text: }
 *          May also contain a (hidden) field 'redlines': Array of RedlineBlock objects
 * @param path Formik values path for this content block.
 *       Eg, 'sections[0].steps[0].content[0]'. May be blank.
 * @param contentErrors Formik errors object for these form values
 * @param showsLabels Whether or not to show label above the field
 * @param acceptRedline - Function to call when a redline block is accepted
 * @param rejectRedline - Function to call when a redline block is rejected
 */
interface FieldSetTextProps {
  block: DraftTextBlock;
  redlines?: Array<HeaderBlockRedline> | Array<StepBlockRedline>;
  path: string;
  contentErrors: TextBlockContentErrors;
  showsLabels?: boolean;
  acceptRedline?: (path: string, block: ReleaseTextBlock, redline: RunHeaderBlockRedline | RunStepBlockRedline) => void;
  rejectRedline?: (
    path: string,
    block: ReleaseTextBlock | ReleaseStepBlock,
    redline: RunHeaderBlockRedline | RunStepBlockRedline
  ) => void;
  setFieldValue?: FormikHelpers<FormikValues>['setFieldValue'];
  pendingStep?: AddedStep;
  precedingStepId?: string;
}

const FieldSetText = ({
  block,
  redlines,
  path,
  contentErrors,
  showsLabels = true,
  acceptRedline,
  rejectRedline,
  setFieldValue,
  pendingStep,
  precedingStepId,
}: FieldSetTextProps) => {
  const { currentTeamId } = useDatabaseServices();
  const redlinesSorted = useMemo(
    () => getRedlineDocsSortedLatestToEarliest<HeaderBlockRedline | StepBlockRedline>(redlines),
    [redlines]
  );

  const hasRedlines = useMemo(() => redlinesSorted && redlinesSorted.length > 0, [redlinesSorted]);

  return (
    <div className="min-w-0 w-full break-words">
      {showsLabels && <label className="field-title">Text</label>}
      <Field name={path} validate={validateUtil.validateFieldText}>
        {({ field }) => (
          <div className="flex flex-col gap-y-2">
            {hasRedlines &&
              acceptRedline &&
              rejectRedline &&
              redlinesSorted.map((redline) => (
                <TextBlockRedlineBlock
                  key={redline._id}
                  path={path}
                  block={block}
                  runRedline={getRedlineFromDoc(redline) as RunHeaderBlockRedline | RunStepBlockRedline}
                  contentErrors={contentErrors}
                  acceptRedline={acceptRedline}
                  rejectRedline={rejectRedline}
                  contextUrl={getRedlineSourceStepPath({
                    teamId: currentTeamId,
                    redline,
                  })}
                />
              ))}
            <ReferenceTextArea
              field={field}
              path={path}
              setFieldValue={setFieldValue}
              placeholder="Text, content, notes, etc...*"
              pendingStep={pendingStep}
              precedingStepId={precedingStepId}
            />
            {contentErrors && contentErrors.text && <div className="text-red-700 text-sm">{contentErrors.text}</div>}
          </div>
        )}
      </Field>
    </div>
  );
};

export default FieldSetText;
