import { useCallback, useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import Button, { BUTTON_TYPES } from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import { CsvExport } from '../lib/csv';
import { CSVLink } from 'react-csv';
import { CSVLinkRef, CsvDownload } from './ExportCsvContentButton';

const CSV_COMPONENT_TREE_FILENAME = 'parts_inventory.csv';

interface ExportInventoryModalProps {
  onCancel: () => void;
  onExport: () => CsvExport;
  onExportComponentTrees: () => void;
}

const ExportInventoryModal = ({ onCancel, onExport, onExportComponentTrees }: ExportInventoryModalProps) => {
  const [includeComponentTree, toggleIncludeComponentTree] = useState(false);
  const [csvDownload, setCsvDownload] = useState<CsvDownload>({ ready: false, content: [] });
  const csvLink = useRef<CSVLinkRef>();

  useEffect(() => {
    if (!csvDownload.ready) {
      return;
    }
    csvLink.current.link.click();
    setCsvDownload({ ready: false, content: [] });
    onCancel();
  }, [csvDownload.ready, onCancel]);

  const handleFlatExport = useCallback(() => {
    const content = onExport();
    if (!content) {
      return;
    }

    setCsvDownload({ ready: true, content });
  }, [onExport]);

  const getFooter = useCallback(() => {
    return (
      <div className="sm:flex sm:flex-row-reverse">
        <Button type={BUTTON_TYPES.PRIMARY} onClick={includeComponentTree ? onExportComponentTrees : handleFlatExport}>
          Export
        </Button>
        <Button type={BUTTON_TYPES.SECONDARY} onClick={onCancel}>
          Cancel
        </Button>
      </div>
    );
  }, [includeComponentTree, onCancel, handleFlatExport, onExportComponentTrees]);

  return (
    <Dialog
      header="Export Selected Items"
      onHide={onCancel}
      visible={true}
      closable={false}
      draggable={false}
      className="w-1/4 transition-all"
      footer={getFooter}
    >
      <div className="pt-4 pl-2">
        <Checkbox
          ariaLabel="Export component tree checkbox"
          checked={includeComponentTree}
          disabled={false}
          onClick={() => toggleIncludeComponentTree(!includeComponentTree)}
          label="Include item component tree"
        />
      </div>
      {/* Hidden CSV downloader */}
      <CSVLink
        data={csvDownload.content}
        enclosingCharacter='"'
        filename={CSV_COMPONENT_TREE_FILENAME}
        className="hidden"
        ref={csvLink}
        target="_blank"
        rel="noopener noreferrer"
      />
    </Dialog>
  );
};

export default ExportInventoryModal;
