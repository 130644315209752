import { useCallback, useEffect, useMemo, useState } from 'react';
import runUtilTs, {
  AttachmentListMetadata,
  Location,
  StepBlockLocation,
  StepCommentLocation,
  VariableLocation,
} from '../../lib/runUtilTs';
import { Run, StepDoc, V2RunVariable } from 'shared/lib/types/views/procedures';
import Grid, { GridColumn } from '../../elements/Grid';
import AvatarStack from '../AvatarStack';
import { useSelector } from 'react-redux';
import { selectRunStep } from '../../contexts/runsSlice';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { RunStateType } from '../Blocks/ReferenceBlock';
import { getStepState, isRunStateActive } from 'shared/lib/runUtil';
import { isEmpty, isEqual } from 'lodash';
import runUtil from '../../lib/runUtil';
import { useSettings } from '../../contexts/SettingsContext';
import ExpressionTokenDisplay from '../Expression/ExpressionTokenDisplay';
import AttachmentListPreview from '../Attachments/AttachmentListPreview';
import { StepState } from 'shared/lib/types/couch/procedures';
import { useRunContext } from '../../contexts/RunContext';
import { getSourcePath } from '../../lib/pathUtil';
import renderDateTime from '../Home/Renderers/DateTime';
import procedureUtil from '../../lib/procedureUtil';

interface RunAttachmentSummaryListProps {
  run: Run;
  verticalSpacePx: number;
}

const RunAttachmentSummaryList = ({ run, verticalSpacePx }: RunAttachmentSummaryListProps) => {
  const { currentTeamId, services } = useDatabaseServices();
  const { getSetting } = useSettings();
  const [runStepMap, setRunStepMap] = useState({});
  const { isActiveRun } = useRunContext();

  const stepLocationsWithAttachmentInput: Array<{
    sectionId: string;
    stepId: string;
  }> = useMemo(() => {
    if (!run) {
      return [];
    }
    return run.sections.flatMap((section) =>
      section.steps.flatMap((step) => {
        if (step.content.some((block) => block.type === 'input' && block.inputType === 'attachment')) {
          return [{ sectionId: section.id, stepId: step.id }];
        }
        return [];
      })
    );
  }, [run]);

  const liveRunStepDocMap = useSelector((state: RunStateType) => {
    if (!run) {
      return {};
    }

    return stepLocationsWithAttachmentInput
      .map(({ sectionId, stepId }) => selectRunStep(state, currentTeamId, run._id, sectionId, stepId))
      .filter((liveRunStepResult): liveRunStepResult is StepDoc => Boolean(liveRunStepResult))
      .reduce((stepMap, step) => {
        stepMap[step.step_id] = step;
        return stepMap;
      }, {});
  });

  useEffect(() => {
    if (!run || isEmpty(stepLocationsWithAttachmentInput)) {
      return;
    }
    // Get the run step docs from redux if the run is active.
    if (isRunStateActive(run.state) && !isEqual(runStepMap, liveRunStepDocMap)) {
      setRunStepMap(liveRunStepDocMap);
    }

    // Request the run step docs if the run is ended and the run step docs have not already been pulled.
    if (!services.runs || (!isEmpty(stepLocationsWithAttachmentInput) && !isEmpty(runStepMap))) {
      return;
    }

    const runStepIdsWithAttachmentInput = stepLocationsWithAttachmentInput.map(({ stepId }) => stepId);
    services.runs.getRunSteps(run._id, runStepIdsWithAttachmentInput).then((runSteps) => {
      const updatedRunStepMap = runSteps.reduce((stepMap, step) => {
        stepMap[step.step_id] = step;
        return stepMap;
      }, {});
      setRunStepMap(updatedRunStepMap);
    });
  }, [liveRunStepDocMap, run, runStepMap, services.runs, stepLocationsWithAttachmentInput]);

  const locationDataMap = useMemo(() => {
    if (!run) {
      return {};
    }

    const map: { [contentId: string]: object } = {};
    run.sections.forEach((section, sectionIndex) => {
      const style = getSetting('display_sections_as', 'letters');
      const sectionKey = runUtil.displaySectionKey(run.sections, sectionIndex, style);
      const sectionRepeatKey = runUtil.displayRepeatKey(run.sections, sectionIndex);
      section.steps.forEach((step, stepIndex) => {
        const stepKey = runUtil.displayStepKey(section.steps, stepIndex);
        const stepRepeatKey = runUtil.displayRepeatKey(section.steps, stepIndex);
        const stepRecordedState = getStepState(step);
        const substepKeyMap = procedureUtil.getSubstepKeyMap({
          step,
          formattedStepKey: procedureUtil.formatStepKey({
            sectionKey,
            stepKey,
            style,
          }),
        });
        step.content.forEach((block, blockIndex) => {
          if (block.type !== 'attachment' && !(block.type === 'input' && block.inputType === 'attachment')) {
            return;
          }
          map[block.id] = {
            primaryKey: sectionRepeatKey ? `Section ${sectionKey}` : undefined,
            primaryRepeatKey: sectionRepeatKey,
            secondaryKey: `Step ${substepKeyMap[block.id]}`,
            secondaryRepeatKey: stepRepeatKey,
            ordinalNumber: blockIndex + 1,
            stepRecordedState,
          };
        });
        (step.comments ?? []).forEach((comment, commentIndex) => {
          if (!comment.attachment) {
            return;
          }
          map[comment.id] = {
            primaryKey: `Section ${sectionKey}`,
            primaryRepeatKey: sectionRepeatKey,
            secondaryKey: `Step ${stepKey}`,
            secondaryRepeatKey: stepRepeatKey,
            tertiaryKey: `Comment ${commentIndex + 1}`,
            ordinalNumber: commentIndex + 1,
            stepRecordedState,
          };
        });
      });
    });

    return map;
  }, [getSetting, run]);

  const getLocationDisplay = useCallback(
    (
      location: Location
    ): Partial<{
      primaryKey: string | number;
      primaryRepeatKey: string | number;
      secondaryKey: string | number;
      secondaryRepeatKey: string | number;
      tertiaryKey: string | number;
      stepRecordedState: StepState;
      ordinalNumber?: number;
    }> => {
      if (!location || !run) {
        return {};
      }

      if ((location as VariableLocation).variable_id && run.variables) {
        const variableIndex = run.variables.findIndex(
          (variable) => (variable as V2RunVariable).id === (location as VariableLocation).variable_id
        );
        return {
          primaryKey: `Variable ${variableIndex + 1}`,
          ordinalNumber: variableIndex + 1,
        };
      }

      if (
        (location as StepBlockLocation).section_id &&
        (location as StepBlockLocation).step_id &&
        (location as StepBlockLocation).content_id
      ) {
        return locationDataMap[(location as StepBlockLocation).content_id] ?? {};
      }

      if (
        (location as StepCommentLocation).section_id &&
        (location as StepCommentLocation).step_id &&
        (location as StepCommentLocation).comment_id
      ) {
        return locationDataMap[(location as StepCommentLocation).comment_id] ?? {};
      }

      return {};
    },
    [run, locationDataMap]
  );

  const columns: ReadonlyArray<GridColumn<AttachmentListMetadata>> = useMemo(() => {
    return [
      {
        key: 'attachment',
        name: 'Attachment',
        sortable: true,
        comparator: (a: AttachmentListMetadata, b: AttachmentListMetadata) => {
          return (a.attachment.name ?? '').localeCompare(b.attachment.name ?? '');
        },
        width: '40%',
        renderCell({ row }: { row: AttachmentListMetadata }) {
          return <AttachmentListPreview attachment={row.attachment} size="lg" />;
        },
      },
      {
        key: 'location',
        name: 'Location',
        sortable: true,
        comparator: (a: AttachmentListMetadata, b: AttachmentListMetadata) => {
          return a.location.order - b.location.order;
        },
        width: '30%',
        renderCell({ row }: { row: AttachmentListMetadata }) {
          const locationData = getLocationDisplay(row.location);
          return (
            <div className="leading-normal w-full">
              <ExpressionTokenDisplay
                referenceName=""
                onClick={() => {
                  const url = getSourcePath({
                    teamId: currentTeamId,
                    runId: run._id,
                    sectionId: (row.location as StepBlockLocation).section_id,
                    stepId: (row.location as StepBlockLocation).step_id,
                  });
                  window.open(url, '_blank', 'noreferrer');
                }}
                primaryKey={locationData.primaryKey}
                primaryRepeatKey={locationData.primaryRepeatKey}
                secondaryKey={locationData.secondaryKey}
                secondaryRepeatKey={locationData.secondaryRepeatKey}
                tertiaryKey={locationData.tertiaryKey}
                stepRecordedState={locationData.stepRecordedState}
                inRunningRun={isActiveRun}
              />
            </div>
          );
        },
      },
      {
        key: 'uploadedBy',
        name: 'Uploaded By',
        sortable: true,
        comparator: (a: AttachmentListMetadata, b: AttachmentListMetadata) => {
          return (a.uploaded_by ?? '').localeCompare(b.uploaded_by ?? '');
        },
        width: '10%',
        renderCell({ row }: { row: AttachmentListMetadata }) {
          return (
            <div className="leading-normal flex w-full pl-2">
              {row.uploaded_by && <AvatarStack userIds={[row.uploaded_by]} />}
            </div>
          );
        },
      },
      {
        key: 'uploadedAt',
        name: 'Uploaded At',
        sortable: true,
        comparator: (a: AttachmentListMetadata, b: AttachmentListMetadata) => {
          return (a.timestamp ?? '').localeCompare(b.timestamp ?? '');
        },
        width: '20%',
        renderCell({ row }: { row: AttachmentListMetadata }) {
          return renderDateTime(row.timestamp);
        },
      },
    ];
  }, [currentTeamId, getLocationDisplay, isActiveRun, run._id]);

  const allAttachments = useMemo(() => {
    return runUtilTs.getAllAttachmentMetadata({ run, runStepMap });
  }, [runStepMap, run]);

  return (
    <Grid
      columns={columns}
      rows={allAttachments}
      rowHeight={85}
      usedVerticalSpace={verticalSpacePx}
      defaultSort={[{ columnKey: 'location', direction: 'ASC' }]}
      emptyRowMessage="No attachments found"
    />
  );
};

export default RunAttachmentSummaryList;
