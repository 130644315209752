import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setIn } from 'formik';
import { clone, cloneDeep, isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { ProcedureContentBlockTypes, PropertyTypes } from 'shared/lib/types/blockTypes';
import { getRedlineFromDoc, getRedlineId, isStepRedline, REDLINE_TYPE } from 'shared/lib/redlineUtil';
import { ProcedureType } from 'shared/lib/types/couch/procedures';
import {
  Draft,
  PartList,
  ProcedureRisk,
  RunAddedStep,
  RunHeaderBlock,
  RunHeaderBlockRedline,
  RunHeaderFieldRedline,
  RunStepBlock,
  RunStepBlockRedline,
  RunStepFieldRedline,
  Section,
  TestCaseList,
} from 'shared/lib/types/views/procedures';
import { useAuth } from '../contexts/AuthContext';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { useMixpanel } from '../contexts/MixpanelContext';
import { ProcedureContextProvider } from '../contexts/ProcedureContext';
import { useProcedureEdit } from '../contexts/ProcedureEditContext';
import {
  copyItemToClipboard,
  DatabaseServices,
  selectClipboardItem,
  selectProcedures,
  selectProceduresNoDraftsForReleased,
} from '../contexts/proceduresSlice';
import { useSelectionContext } from '../contexts/Selection';
import { useSettings } from '../contexts/SettingsContext';
import { useUserInfo } from '../contexts/UserContext';
import useFormHelpers from '../hooks/useFormHelpers';
import apm from '../lib/apm';
import attachmentUtil from '../lib/attachmentUtil';
import { PERM } from '../lib/auth';
import clipboardUtil from '../lib/clipboardUtil';
import externalDataUtil from '../lib/externalDataUtil';
import procedureUtil from '../lib/procedureUtil';
import procedureVariableUtil from '../lib/procedureVariableUtil';
import { getAddedStepRedlineMap, getHeaderRedlineMap, getStepRedlineMap, REDLINE_STATE } from '../lib/redlineUtil';
import revisionsUtil from '../lib/revisions';
import snippetUtil from '../lib/snippetUtil';
import validateUtil from '../lib/validateUtil';
import versionUtil, { DEFAULT_VERSION_SETTING } from '../lib/version';
import PartListFieldSet from '../manufacturing/components/PartListFieldSet';
import usePartBlockHelpers, { PartStub } from '../manufacturing/hooks/usePartBlockHelpers';
import FormProcedureRisks from '../risks/components/FormProcedureRisks';
import SnippetSelector from '../testing/components/SnippetSelector';
import Button, { BUTTON_TYPES } from './Button';
import AddContentMenu from './ContentMenu/AddContentMenu';
import { Actions } from './ContentMenu/addContentTypes';
import getProcedureAddContentItems from './ContentMenu/procedureAddContentItems';
import getSectionAddContentItems from './ContentMenu/sectionAddContentItems';
import { useRedlineModalContext } from '../contexts/RedlineModalContext';
import DetachedRedlinesModal from './DetachedRedlineModal';
import { EDIT_STICKY_HEADER_HEIGHT_REM } from './EditToolbar';
import FieldSetProcedureDetails from './FieldSetProcedureDetails';
import FieldSetProcedureHeader from './FieldSetProcedureHeader';
import FieldSetProcedureSection from './FieldSetProcedureSection';
import FieldSetProcedureSettings from './FieldSetProcedureSettings';
import FlashMessage from './FlashMessage';
import FormProcedureVariables from './FormProcedureVariables';
import ModalDeleteSectionStepUnresolvedComments from './ModalDeleteSectionStepUnresolvedComments';
import PageSidebar from './PageSidebar';
import ProcedureSection from './ProcedureSection';
import PromptBeforeUnload from './Prompt/PromptBeforeUnload';
import Selectable, { Boundary } from './Selection/Selectable';
import ModalDetachSnippet from './Snippet/ModalDetachSnippet';
import ModalSaveSnippet from './Snippet/ModalSaveSnippet';
import ModalSaveSnippetUnresolvedActions from './Snippet/ModalSaveSnippetUnresolvedActions';
import ModalSnippetSelect from './Snippet/ModalSnippetSelect';
import PromptSnippet from './Snippet/PromptSnippet';
import ProcedureFlowChart from './StepConditionals/ProcedureFlowChart';
import LastSavedDisplay from './Time/LastSavedDisplay';

// Drop zone types for react-beautiful-dnd
const DroppableType = Object.freeze({
  Section: 'section',
  Step: 'step',
  Content: 'content', // For future use
});

const getNonEmptyRisks = (procedureRisks) => {
  return procedureRisks.filter((risk) => risk.id);
};

const getIsSectionLinked = (sectionId, procedureLinks) => {
  return procedureLinks.some((link) => link.content.section === sectionId);
};

const getUniqueProcedureLinks = (procedureLinks) => {
  const map = {};
  if (!procedureLinks) {
    return map;
  }
  procedureLinks.forEach((link) => (map[link.procedure] = link));

  return Object.values(map);
};

const getLinkedSectionErrorMessage = (uniqueProcedureLinks) => {
  return `${
    'Some sections in this procedure are linked and cannot ' +
    'be removed. Please unlink these sections and try again.\n\nLinked from '
  }${uniqueProcedureLinks.map((link) => `${link.code} - ${link.name}`).join(', ')}`;
};

// TODO fill in types
interface FormProcedureProps {
  procedure: Draft;
  snippets;
  risks;
  externalItems;
  procedureErrors;
  onSave;
  submission;
  expandCollapse;
  setIsCollapsed;
  onResolveComment;
  onSaveReviewComment;
  onUnresolveComment;
  lastSavedTime;
  isDirty;
  currentTab;
  stateHistory: {
    present: Draft;
    updatePresent: (draft: Draft) => void;
  };
  onProcedureChanged;
  promptBeforeUnload;
  shouldScrollToError;
  setShouldScrollToError;
  onScrollToRefChanged;
  onScrollToId;
  unactionedRedlines;
  commentRedlines;
}

/*
 * Form for editing a procedure.
 *
 * procedure: A procedure doc to edit, or undefined for a new procedure.
 */
export const FormProcedure = ({
  procedure,
  snippets,
  risks,
  externalItems,
  procedureErrors,
  onSave,
  submission,
  expandCollapse,
  setIsCollapsed,
  onResolveComment,
  onSaveReviewComment,
  onUnresolveComment,
  lastSavedTime,
  isDirty,
  currentTab,
  stateHistory,
  onProcedureChanged,
  promptBeforeUnload,
  shouldScrollToError,
  setShouldScrollToError,
  onScrollToRefChanged,
  onScrollToId,
  unactionedRedlines,
  commentRedlines,
}: FormProcedureProps) => {
  const { userInfo } = useUserInfo();
  const { auth } = useAuth();
  const store = useStore();
  const userId = userInfo.session.user_id;
  const { getSetting, isManufacturingEnabled, isRisksEnabled } = useSettings();
  const { mergeAllSectionErrors } = useProcedureEdit();
  const { showRedlineModal, setShowRedlineModal, detachedRedlines, detachedRedlineComments } = useRedlineModalContext();

  const [showProcedureSettings, setShowProcedureSettings] = useState(false);
  const [localRisks, setLocalRisks] = useState<Array<ProcedureRisk>>();
  const [loading, setLoading] = useState(true);
  const { present, updatePresent } = stateHistory;
  let part: PartStub | null = null;
  if (present?.part_list?.part_id && present?.part_list?.part) {
    part = {
      id: present.part_list.part_id,
      rev: present.part_list.part.rev,
    };
  }
  const { configurePartKitBlock, configurePartBuildBlock } = usePartBlockHelpers({ part });

  const versionSetting = useMemo(() => {
    return getSetting('version', DEFAULT_VERSION_SETTING);
  }, [getSetting]);

  /*
   * Returns procedure with a new initial section that contains one initial step
   * Both the initial step and section are generated with unique ids
   */
  const newProcedure = React.useCallback(() => {
    return procedureUtil.newProcedure(userId);
  }, [userId]);

  const isMounted = useRef(true);
  const { mixpanel } = useMixpanel();
  const { services, currentTeamId }: { services: DatabaseServices; currentTeamId: string } = useDatabaseServices();

  const [snippetSaveModalState, setSnippetSaveModalState] = useState<{
    section: Section | undefined;
    sectionIndex: number;
    hidden: boolean;
  }>({
    section: undefined,
    sectionIndex: 0,
    hidden: true,
  });
  const [snippetSelectModalState, setSnippetSelectModalState] = useState({
    sectionIndex: 0,
    hidden: true,
  });
  const [modalDetachSnippetState, setModalDetachSnippetState] = useState<{
    section: Section | null;
    sectionIndex: number;
    hidden: boolean;
  }>({
    section: null,
    sectionIndex: 0,
    hidden: true,
  });
  const [modalSaveSnippetUnresolvedActionsState, setModalSaveSnippetUnresolvedActionsState] = useState({
    hidden: true,
  });
  const [modalDeleteSectionStepUnresolvedCommentsState, setModalDeleteSectionStepUnresolvedCommentsState] = useState({
    hidden: true,
  });

  const snippetsMap = useMemo(() => {
    /**
     * Mapping from snippet.snippet_id -> snippet object
     */
    if (!snippets) {
      return {};
    }
    const mapping = {};
    for (const snippet of snippets) {
      mapping[snippet.snippet_id] = snippet;
    }
    return mapping;
  }, [snippets]);

  const [changedStepIdSet, setChangedStepIdSet] = useState(new Set());

  const { isCollapsedMap, areAllStepsInSectionExpanded, setAllStepsInSectionExpanded } = expandCollapse;

  // Array of booleans mapping whether all the steps in section *i* are collapsed
  const allStepsInSectionExpandedMap = React.useMemo(
    () =>
      procedure &&
      procedure.sections &&
      areAllStepsInSectionExpanded &&
      procedure.sections.map(areAllStepsInSectionExpanded),
    [areAllStepsInSectionExpanded, procedure]
  );

  // TODO: DRY this up, possibly by creating a MixpanelContext.js
  const mixpanelTrack = useCallback(
    (name, options?) => {
      if (mixpanel && name) {
        mixpanel.track(name, options);
      }
    },
    [mixpanel]
  );

  const dispatch = useDispatch();
  const [flashMessage, setFlashMessage] = useState('');
  const clipboardItem = useSelector((state) => selectClipboardItem(state, currentTeamId));
  const clipboardSection = useMemo(() => {
    if (!clipboardItem) {
      return undefined;
    }
    return clipboardUtil.getSectionFromClipboardItem(clipboardItem);
  }, [clipboardItem]);
  const clipboardStep = useMemo(() => {
    if (!clipboardItem) {
      return undefined;
    }
    return clipboardUtil.getStepFromClipboardItem(clipboardItem);
  }, [clipboardItem]);
  const clipboardBlock = useMemo(() => {
    if (!clipboardItem) {
      return undefined;
    }
    return clipboardUtil.getBlockFromClipboardItem(clipboardItem);
  }, [clipboardItem]);

  const hasUnresolvedSectionComments = useCallback(
    (section) => {
      if (!present) {
        return false;
      }
      const sectionComments = procedureUtil.getCommentsBySection(section, present.comments);
      return (
        revisionsUtil.getUnresolvedRedlineComments(sectionComments).length > 0 ||
        revisionsUtil.getUnresolvedParentReviewComments(sectionComments).length > 0
      );
    },
    [present]
  );

  const addRedlineAction = useCallback(
    /**
     * Adds a redline action to the passed-in procedure object.
     *
     * @param {object} updatedProcedure - the procedure to which to add the redline action
     * @param {object} redline - the redline that is being acted upon. If passing a redline
     *                           for an ignored added step, this redline will be from the redlines
     *                           db and not have redline_id; in this case `providedRedlineId` should
     *                           also be provided
     * @param state - the updated state of the redline
     * @param providedRedlineId - provided redline id
     */
    (updatedProcedure, redline, state: string, providedRedlineId?: string) => {
      const redlineId = getRedlineId(redline) ?? providedRedlineId;

      // For backwards compatibility, do not add a redline action if there is not a redline id
      if (!redlineId) {
        return;
      }

      const action = {
        redline_id: redlineId,
        state,
        resolved_by: userId,
        resolved_at: new Date(),
        resolution_procedure_rev_num: procedure.procedure_rev_num, // procedure_rev_num is never updated in `present`, so use `procedure` instead
      };

      /*
       * In order for undo/redo to work as expected, the redline actions array needs to be cloned instead of just pushing a new action to an existing redline actions array.
       * But the clone only needs to be a shallow clone, since existing redline actions are not being mutated, and only a new action is being pushed to the redline actions array.
       * TODO: Once all callers of addRedlineAction are updated to pass in a deep clone for the procedure, simplify the code below, since redline_actions will already be cloned.
       */
      const updatedRedlineActions = updatedProcedure.redline_actions ? clone(updatedProcedure.redline_actions) : [];
      updatedRedlineActions.push(action);
      updatedProcedure.redline_actions = updatedRedlineActions;
    },
    [procedure.procedure_rev_num, userId]
  );

  const stepRedlineMap = useMemo(() => getStepRedlineMap(unactionedRedlines), [unactionedRedlines]);

  /**
   * Adds all unresolved redlines to redline actions in a given deleted step
   */
  const resolveActiveRedlinesInDeletedStep = useCallback(
    (updatedProcedure, stepId) => {
      const stepRedlines = stepRedlineMap?.get(stepId) ?? [];
      for (const redline of stepRedlines) {
        if (!revisionsUtil.hasRedlineActionNew(updatedProcedure, redline)) {
          const stepRedline = getRedlineFromDoc(redline);
          addRedlineAction(updatedProcedure, stepRedline, REDLINE_STATE.REJECTED);
        }
      }
    },
    [addRedlineAction, stepRedlineMap]
  );

  /**
   * Adds all unresolved redlines to redline actions in a given deleted step
   */
  const resolveDisconnectedStepRedline = useCallback(
    (redlineId) => {
      const updated = cloneDeep(present);
      const stepRedlineDoc = detachedRedlines.find((redline) => redline._id === redlineId);
      if (!stepRedlineDoc) {
        return;
      }
      const runStepRedline = getRedlineFromDoc(stepRedlineDoc);
      if (!revisionsUtil.hasRedlineActionNew(updated, stepRedlineDoc)) {
        addRedlineAction(updated, runStepRedline, REDLINE_STATE.REJECTED);
      }

      onProcedureChanged(updated, present);
    },
    [addRedlineAction, present, detachedRedlines, onProcedureChanged]
  );

  const resolveDisconnectedHeaderRedline = useCallback(
    (redlineId) => {
      const updated = cloneDeep(present);
      const stepRedline = detachedRedlines.find((redline) => redline._id === redlineId) ?? [];
      // @ts-ignore
      const { header_redline: redlineHeader } = stepRedline;
      if (!revisionsUtil.hasRedlineActionNew(updated, stepRedline)) {
        addRedlineAction(updated, redlineHeader, REDLINE_STATE.REJECTED);
      }

      onProcedureChanged(updated, present);
    },
    [addRedlineAction, present, detachedRedlines, onProcedureChanged]
  );

  /**
   * Checks if there are any linked procedures linking to this section,
   * if not, the current state will be added to history and then the section is deleted.
   * If there is a linked procedure, we will show the user a blocking message and disallow deletion.
   */
  const onRemoveSection = useCallback(
    (sectionIndex) => {
      const procedures = Object.values(selectProcedures(store.getState(), currentTeamId));
      const procedureId = procedureUtil.getProcedureId(present);
      const procedureLinks = procedureUtil.getProcedureLinks(procedureId, procedures);
      const isSectionLinked = getIsSectionLinked(present.sections[sectionIndex].id, procedureLinks);

      if (isSectionLinked) {
        const uniqueProcedureLinks = getUniqueProcedureLinks(procedureLinks);

        window.alert(getLinkedSectionErrorMessage(uniqueProcedureLinks));
        return;
      }

      const updated = cloneDeep(present);
      const removedStepIds = updated.sections[sectionIndex].steps.map((step) => step.id);

      if (hasUnresolvedSectionComments(updated.sections[sectionIndex])) {
        setModalDeleteSectionStepUnresolvedCommentsState({
          hidden: false,
        });
        return;
      }
      for (const removedStepId of removedStepIds) {
        resolveActiveRedlinesInDeletedStep(updated, removedStepId);
      }

      updated.sections.splice(sectionIndex, 1);
      if (updated.sections.length === 0) {
        updated.sections.push(procedureUtil.newSection());
      }
      procedureUtil.removeStepReferences(updated, removedStepIds);

      onProcedureChanged(updated, present);
    },
    [
      currentTeamId,
      present,
      hasUnresolvedSectionComments,
      onProcedureChanged,
      resolveActiveRedlinesInDeletedStep,
      store,
    ]
  );

  const onCopySection = useCallback(
    ({ sectionIndex }) => {
      mixpanelTrack('Copy Section');
      const copiedSection = procedureUtil.copySection(present.sections[sectionIndex]);
      const clipboardItemSection = clipboardUtil.createClipboardItemSection(copiedSection);
      dispatch(copyItemToClipboard(currentTeamId, clipboardItemSection));
      setFlashMessage('Section copied');
    },
    [currentTeamId, dispatch, mixpanelTrack, present]
  );

  const onCutSection = useCallback(
    ({ sectionIndex }) => {
      mixpanelTrack('Cut Section');
      const copiedSection = procedureUtil.copySection(present.sections[sectionIndex]);
      const clipboardItemSection = clipboardUtil.createClipboardItemSection(copiedSection);
      dispatch(copyItemToClipboard(currentTeamId, clipboardItemSection));
      onRemoveSection(sectionIndex);
      setFlashMessage('Section cut');
    },
    [currentTeamId, dispatch, mixpanelTrack, present, onRemoveSection]
  );

  const onPasteStep = useCallback(
    ({ sectionIndex }) => {
      if (!clipboardStep) {
        return;
      }
      mixpanelTrack('Paste Step');
      const updated = cloneDeep(present);
      const stepCopy = procedureUtil.copyStep(clipboardStep);
      updated.sections[sectionIndex].steps.push(stepCopy);
      onProcedureChanged(updated, present);
    },
    [clipboardStep, mixpanelTrack, onProcedureChanged, present]
  );

  const onPasteBlockIntoProcedureHeader = useCallback(
    ({ headerIndex }) => {
      if (
        !clipboardBlock ||
        (clipboardBlock.type !== ProcedureContentBlockTypes.Alert &&
          clipboardBlock.type !== ProcedureContentBlockTypes.Text &&
          clipboardBlock.type !== ProcedureContentBlockTypes.Attachment)
      ) {
        return;
      }
      mixpanelTrack('Paste Block');
      const updated = cloneDeep(present);
      const blockCopy = procedureUtil.copyBlock(clipboardBlock);
      updated.headers?.[headerIndex].content.push(blockCopy);
      onProcedureChanged(updated, present);
    },
    [clipboardBlock, mixpanelTrack, onProcedureChanged, present]
  );

  const {
    onPartListChanged,
    onProcedureDetailsFormChanged,
    onProcedureSettingsFormChanged,
    onHeaderFormChanged,
    onSectionHeaderFormChanged,
    onSectionFormChanged,
    onStepFormChanged,
    onAddProcedureVariable,
    onRemoveProcedureVariable,
    onVariablesChanged,
  } = useFormHelpers({
    present,
    onProcedureChanged,
  });

  const onAddProcedureRisk = useCallback(() => {
    // @ts-expect-error
    setLocalRisks((current) => {
      const updated: object[] = current ? cloneDeep(current) : [];
      updated.push({});
      return updated;
    });
  }, []);

  const onRemoveProcedureRisk = useCallback(
    (index) => {
      setLocalRisks((current) => {
        const updated = current ? cloneDeep(current) : [];
        if (index >= 0 && index < updated.length) {
          updated.splice(index, 1);
        }
        const updatedProcedure = {
          ...cloneDeep(present),
          risks: getNonEmptyRisks(updated),
        };
        onProcedureChanged(updatedProcedure, present);
        return updated;
      });
    },
    [onProcedureChanged, present]
  );

  const onRisksChanged = useCallback(
    (procedureRisks) => {
      if (isEqual(localRisks, procedureRisks)) {
        return;
      }
      setLocalRisks(procedureRisks);
      const updated = {
        ...cloneDeep(present),
        risks: getNonEmptyRisks(procedureRisks),
      };
      onProcedureChanged(updated, present);
    },
    [localRisks, onProcedureChanged, present]
  );

  useEffect(() => {
    setLocalRisks((current) => {
      // present can't be undefined, but it is in many tests
      if (present?.risks) {
        const existingEmpties = current ? current.filter((risk) => !risk.id) : [];
        return [...present.risks, ...existingEmpties];
      }
    });
  }, [present]);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  const scrollToFirstError = useCallback(() => {
    const { errorFieldRef, errorHeaderId, errorSectionId, errorStepId } = procedureErrors.firstErrorField;
    onScrollToId({
      stepId: errorStepId,
      headerId: errorHeaderId,
      sectionId: errorSectionId,
      scrollToId: errorFieldRef,
    });
  }, [onScrollToId, procedureErrors.firstErrorField]);

  useEffect(() => {
    if (shouldScrollToError) {
      scrollToFirstError();
      setShouldScrollToError(false);
    }
  }, [scrollToFirstError, shouldScrollToError, setShouldScrollToError]);

  const getHeaderErrors = useCallback(
    (headerId) => {
      if (!procedureErrors.errors || !procedureErrors.errors.headers) {
        return null;
      }
      return procedureErrors.errors.headers[headerId];
    },
    [procedureErrors.errors]
  );

  const initialProcedure = useMemo(() => {
    if (procedure) {
      if (procedure.headers) {
        return procedure;
      }

      const procedureWithHeaders = cloneDeep(procedure);
      procedureWithHeaders.headers = [];

      return procedureWithHeaders;
    }

    return newProcedure();
  }, [procedure, newProcedure]);

  const headerRedlineMap = useMemo(() => getHeaderRedlineMap(unactionedRedlines), [unactionedRedlines]);
  const getHeaderRedlines = useCallback((headerId) => headerRedlineMap.get(headerId) ?? [], [headerRedlineMap]);

  const addedStepRedlineMap = useMemo(() => getAddedStepRedlineMap(unactionedRedlines), [unactionedRedlines]);

  /**
   * Sets up the form and its dependencies on initial load.
   * TODO (Deep): Break this up into smaller more readable functions.
   */
  useEffect(() => {
    if (present || !unactionedRedlines || !commentRedlines) {
      setLoading(false);
      return;
    }
    let updatedProcedure = cloneDeep(initialProcedure);

    // Merge redline changes and migrate content id's for legacy documents.
    updatedProcedure = revisionsUtil.mergeRedlineCommentsFromDocs(updatedProcedure, commentRedlines);
    updatedProcedure = revisionsUtil.mergeRedlineCommentsFromDocs(updatedProcedure, detachedRedlineComments);
    updatedProcedure = revisionsUtil.mergeAddedStepsToProcedure(updatedProcedure as Draft, addedStepRedlineMap);
    updatedProcedure = procedureUtil.migrateRequiresPreviousStepToDependencies(updatedProcedure);
    updatedProcedure = procedureUtil.migrateContent(updatedProcedure);

    // Updates older procedure variable structures for backwards compatability
    updatedProcedure = procedureVariableUtil.migrateProcedureVariables(updatedProcedure);

    // Auto-detach deleted snippets
    if (snippets) {
      updatedProcedure = snippetUtil.detachDeletedSnippets(updatedProcedure, snippets);
    }

    // Auto-update external data items.
    if (externalItems) {
      updatedProcedure = externalDataUtil.updateProcedureWithItems(updatedProcedure, externalItems);
    }

    // Sets version if version does not exist yet.
    if (!updatedProcedure.version) {
      updatedProcedure.version = versionUtil.generateVersion(versionSetting);
    }

    if (initialProcedure.state === 'released' && initialProcedure.release_note && 'release_note' in updatedProcedure) {
      delete updatedProcedure.release_note;
    }
    if (initialProcedure.state !== 'draft') {
      (updatedProcedure as Draft).initial_rev_num = initialProcedure.procedure_rev_num;
    }

    /**
     * Auto save the draft when it is first created as a draft (not already a draft and not in review)
     * Note: This is done to persist pending redlines,
     * and to provide the user with a more predictable user experience.
     */
    if (procedure) {
      onSave(updatedProcedure);
    }
    procedureUtil.updateDocAsDraft(updatedProcedure);
    // Update the present document (present is used to render sections/steps and content).
    updatePresent(updatedProcedure as Draft);
    setLoading(false);
  }, [
    initialProcedure,
    procedure,
    present,
    updatePresent,
    onSave,
    snippets,
    externalItems,
    unactionedRedlines,
    addedStepRedlineMap,
    commentRedlines,
    versionSetting,
    detachedRedlineComments,
  ]);

  const fieldRef = useCallback((field) => (element) => onScrollToRefChanged(field, element), [onScrollToRefChanged]);

  const onAddHeader = useCallback(() => {
    const header = procedureUtil.newHeader();
    const updated = cloneDeep(present);

    updated.headers?.push(header);
    onProcedureChanged(updated, present);
  }, [onProcedureChanged, present]);

  const onAddSectionHeader = useCallback(
    (sectionIndex) => {
      const sectionHeader = procedureUtil.newSectionHeader();
      const updated = cloneDeep(present);

      // Allow for backwards-compatibility if a section was created without a `headers` empty array
      if (!updated.sections[sectionIndex].headers) {
        updated.sections[sectionIndex].headers = [];
      }

      updated.sections[sectionIndex].headers?.push(sectionHeader);
      onProcedureChanged(updated, present);
    },
    [onProcedureChanged, present]
  );

  const onAddSectionDependencies = useCallback(
    (sectionIndex) => {
      if (present.sections[sectionIndex].dependencies) {
        return;
      }

      const updated = cloneDeep(present);
      updated.sections[sectionIndex].dependencies = [procedureUtil.newDependency()];
      onProcedureChanged(updated, present);
    },
    [onProcedureChanged, present]
  );

  const onAddStepHeader = (sectionIndex, stepIndex) => {
    const stepHeader = procedureUtil.newStepHeader();
    const updated = cloneDeep(present);

    // Allow for backwards-compatibility if a step was created without a `headers` empty array
    if (!updated.sections[sectionIndex].steps[stepIndex].headers) {
      updated.sections[sectionIndex].steps[stepIndex].headers = [];
    }

    updated.sections[sectionIndex].steps[stepIndex].headers?.push(stepHeader);
    onProcedureChanged(updated, present);
  };

  const onAddSection = useCallback(
    (index) => {
      const section = procedureUtil.newSection();
      const updated = cloneDeep(present);

      // Add section after specified index.
      updated.sections.splice(index + 1, 0, section);
      onProcedureChanged(updated, present);
    },
    [onProcedureChanged, present]
  );

  const onAddStep = (sectionIndex, stepIndex) => {
    const step = procedureUtil.newStep();

    const updated = cloneDeep(present);
    updated.sections[sectionIndex].steps.splice(stepIndex + 1, 0, step);

    onProcedureChanged(updated, present);
  };

  const onInsertStepSnippet = useCallback(
    (sectionIndex, stepIndex, snippet) => {
      const idsMap = procedureUtil.generateNewIdsMapForStep(snippet.step);
      const step = procedureUtil.copyStep(snippet.step, idsMap);
      step.snippet_id = snippet.snippet_id;
      step.snippet_name = snippet.name;
      step.snippet_rev = snippet.revision;
      step.snippet_ids_map = idsMap;
      const updated = cloneDeep(present);
      updated.sections[sectionIndex].steps.splice(stepIndex + 1, 0, step);

      onProcedureChanged(updated, present);
    },
    [onProcedureChanged, present]
  );

  /**
   * Adds all unresolved redlines to redline actions in a given deleted header
   */
  const resolveActiveRedlinesInHeader = useCallback(
    (updatedProcedure, headerId) => {
      const headerRedlines = headerRedlineMap?.get(headerId) ?? [];
      for (const redline of headerRedlines) {
        if (!revisionsUtil.hasRedlineActionNew(updatedProcedure, redline)) {
          const headerRedline = getRedlineFromDoc(redline);
          addRedlineAction(updatedProcedure, headerRedline, REDLINE_STATE.REJECTED);
        }
      }
    },
    [addRedlineAction, headerRedlineMap]
  );

  /**
   * Accepts the field redline by updating the field with the redline value, and then adding a redline action.
   *
   * @param path - The path to the step (ex. sections[0].steps[1]) or the header (ex. headers[1])
   * @param type - The type of redline
   * @param fieldName - only needed for full step redlines
   */
  const onAcceptRedlineField = useCallback(
    (
      path: string,
      type: 'header' | 'step',
      redline: RunHeaderFieldRedline | RunStepFieldRedline,
      fieldName?: string
    ) => {
      if (type !== 'header' && type !== 'step') {
        throw new Error(`type must be either \`step\` or \`header\`, but was \`${type}\``);
      }
      const field = fieldName ?? redline.field;
      // Do nothing if the redline already has an action stored.
      if (revisionsUtil.hasRedlineActionNew(present, redline)) {
        return;
      }

      if (mixpanel) {
        mixpanel.track('Redline Accepted', { 'Field Name': field });
      }

      let field_redlines_path;
      if (type === 'step') {
        field_redlines_path = `${path}.step_field_redlines`;
      } else if (type === 'header') {
        field_redlines_path = `${path}.header_field_redlines`;
      } else {
        throw new Error(`type must be either \`step\` or \`header\`, but was \`${type}\``);
      }

      // Get the redline value.
      const redlineContent = 'header' in redline ? redline.header : redline.step;
      const redlineValue = redlineContent[field];

      /*
       * Use cloneDeep to ensure there are no references to objects on the undo/redo stack.
       * This will also ensure that redlines that represent no change still behave as expected with undo/redo, as well.
       */
      const procedureCopy = cloneDeep(present);

      /*
       * Update the field in the procedure with the accepted value.
       * setIn will return a shallow copy of present, except for deeply setting the value at the specified path
       */
      let updatedProcedure = setIn(procedureCopy, `${path}.${field}`, redlineValue);

      // Remove the redline (for backwards compatibility).
      updatedProcedure = setIn(updatedProcedure, `${field_redlines_path}.${field}`, []);

      // Store the redline action in the procedure.
      addRedlineAction(updatedProcedure, redline, REDLINE_STATE.ACCEPTED);

      onProcedureChanged(updatedProcedure, present);
    },
    [addRedlineAction, mixpanel, onProcedureChanged, present]
  );

  /**
   * Rejects the field redline by adding a redline action.
   *
   * @param redline - the header or step run redline
   * @param fieldName - only needed for full step redlines
   */
  const onRejectRedlineField = useCallback(
    (
      path: string,
      type: 'header' | 'step',
      redline: RunHeaderFieldRedline | RunStepFieldRedline,
      fieldName?: string
    ) => {
      const field = fieldName ?? redline.field;
      // Do nothing if the redline already has an action stored.
      if (revisionsUtil.hasRedlineActionNew(present, redline)) {
        return;
      }

      if (mixpanel) {
        mixpanel.track('Redline Rejected', { 'Field Name': field });
      }
      let field_redlines_path;
      if (type === 'step') {
        field_redlines_path = `${path}.step_field_redlines`;
      } else if (type === 'header') {
        field_redlines_path = `${path}.header_field_redlines`;
      } else {
        throw new Error(`type must be either \`step\` or \`header\`, but was \`${type}\``);
      }

      // Use cloneDeep to ensure there are no references to objects on the undo/redo stack.
      const procedureCopy = cloneDeep(present);

      // Remove the redline (for backwards compatibility).
      const updatedProcedure = setIn(procedureCopy, `${field_redlines_path}.${field}`, []);

      // Store the redline action in the procedure.
      addRedlineAction(updatedProcedure, redline, REDLINE_STATE.REJECTED);

      onProcedureChanged(updatedProcedure, present);
    },
    [addRedlineAction, mixpanel, onProcedureChanged, present]
  );

  /**
   * Accepts the block redline by updating the block with the redline value,
   * then adding a redline action that includes redline_id, state, resolved_by, and resolved_at fields.
   * The redline is removed from the draft.
   *
   * @param path - the full path from the procedure top level to the content block location, (ex. sections[0].steps[1].content[0]) or the header (ex. headers[1].content[0])
   * @param block - the existing block
   * @param redline - the redline to be accepted
   * @param sourceContentId - only needed for full step redlines
   */
  const onAcceptRedlineBlock = useCallback(
    (
      path: string,
      block: RunHeaderBlock | RunStepBlock,
      redline: RunHeaderBlockRedline | RunStepBlockRedline,
      sourceContentId?: string
    ) => {
      // Do nothing if the redline already has an action stored.
      if (revisionsUtil.hasRedlineAction(present, redline)) {
        return;
      }

      const accepted =
        'header' in redline
          ? cloneDeep(revisionsUtil.getHeaderRedlineBlock(redline))
          : cloneDeep(revisionsUtil.getStepRedlineBlock(redline, sourceContentId));

      // Keep the original content id to account for the case of a redline occurring in a repeated step
      accepted.id = block.id;
      accepted.redlines = [];

      if (mixpanel) {
        mixpanel.track('Redline Accepted', { 'Block Type': accepted.type });
      }

      const procedureCopy = cloneDeep(present);
      const updatedProcedure = setIn(procedureCopy, path, accepted);

      // Store the redline action in the procedure.
      addRedlineAction(updatedProcedure, redline, REDLINE_STATE.ACCEPTED);
      onProcedureChanged(updatedProcedure, present);
    },
    [addRedlineAction, mixpanel, onProcedureChanged, present]
  );

  /**
   * Rejects the block redline by adding a redline action that includes redline_id, state, resolved_by, and resolved_at fields.
   * The redline is removed from the draft.
   *
   * @param path - the full path from the procedure top level to the content block location, (ex. sections[0].steps[1].content[0]) or the header (ex. headers[1].content[0])
   * @param block - the existing block
   * @param redline - the redline to be rejected
   */
  const onRejectRedlineBlock = useCallback(
    (path: string, block: RunHeaderBlock | RunStepBlock, redline: RunHeaderBlockRedline | RunStepBlockRedline) => {
      // Do nothing if the redline already has an action stored.
      if (revisionsUtil.hasRedlineAction(present, redline)) {
        return;
      }

      if (mixpanel) {
        mixpanel.track('Redline Rejected', { 'Block Type': block.type });
      }

      const blockCopy = cloneDeep(block);
      const rejected = setIn(blockCopy, 'redlines', []);

      const procedureCopy = cloneDeep(present);
      const updatedProcedure = setIn(procedureCopy, path, rejected);

      // Store the redline action in the procedure.
      addRedlineAction(updatedProcedure, redline, REDLINE_STATE.REJECTED);

      onProcedureChanged(updatedProcedure, present);
    },
    [addRedlineAction, mixpanel, onProcedureChanged, present]
  );

  /**
   * Accepts the added step by selecting fields from the added step that only belong in live steps.
   * A redline action that includes redline_id, state, resolved_by, and resolved_at fields is then added.
   *
   * @param sectionIndex
   * @param stepIndex
   * @param step - the added step
   */
  const onAcceptRedlineStep = useCallback(
    (sectionIndex: number, stepIndex: number, step: RunAddedStep) => {
      // Do nothing if the redline already has an action stored.
      if (revisionsUtil.hasRedlineAction(present, step)) {
        return;
      }

      mixpanelTrack('Redline Added Step Accepted');

      /*
       * It is possible that some added steps that had recorded data were merged into a draft,
       * so scrub added steps again to ensure recorded data and other active content are removed
       * when the added step is accepted into the draft.
       */
      const draftAddedStep = revisionsUtil.convertToDraftAddedStep(step);
      const procedureCopy = cloneDeep(present);
      const updatedProcedure = setIn(procedureCopy, `sections[${sectionIndex}].steps[${stepIndex}]`, draftAddedStep);
      addRedlineAction(updatedProcedure, step, REDLINE_STATE.ACCEPTED);

      onProcedureChanged(updatedProcedure, present);
      setChangedStepIdSet((oldSet) => {
        oldSet.add(step.id);
        return oldSet;
      });
    },
    [mixpanelTrack, present, addRedlineAction, onProcedureChanged]
  );

  /**
   * Rejects the added step by removing it from a shallow copy of the steps array.
   * A redline action that includes redline_id, state, resolved_by, and resolved_at fields is then added.
   *
   * @param sectionIndex
   * @param stepIndex
   * @param step - the added step
   */
  const onRejectRedlineStep = useCallback(
    (sectionIndex: number, stepIndex: number, step: RunAddedStep) => {
      // Do nothing if the redline already has an action stored.
      if (revisionsUtil.hasRedlineAction(present, step)) {
        return;
      }

      mixpanelTrack('Redline Added Step Rejected');

      const updatedProcedure = cloneDeep(present);
      updatedProcedure.sections[sectionIndex].steps.splice(stepIndex, 1);

      addRedlineAction(updatedProcedure, step, REDLINE_STATE.REJECTED);
      // reject all pending redlines/comments for this rejected step
      if (step.created_during_run) {
        services.procedures
          .getUnresolvedRedlineDocs(procedureUtil.getProcedureId(procedure))
          .then((redlines) => {
            redlines.forEach((redline) => {
              if (
                (isStepRedline(redline) || redline.type === REDLINE_TYPE.REDLINE_COMMENT) &&
                step.id === redline.step_id
              ) {
                addRedlineAction(updatedProcedure, redline, REDLINE_STATE.REJECTED, redline._id);
              }
            });
          })
          .catch((err) => apm.captureError(err));
      }

      onProcedureChanged(updatedProcedure, present);
    },
    [mixpanelTrack, present, addRedlineAction, services.procedures, procedure, onProcedureChanged]
  );

  const onSaveStepSnippet = useCallback(
    async (sectionIndex, stepIndex, values, step) => {
      const updatedStep = cloneDeep(step);
      const snippetId = procedureUtil.generateSnippetId();
      const name = values.name;
      const description = values.description;
      const reversedIdsMap = procedureUtil.generateNewIdsMapForStep(updatedStep);
      const snippetStep = procedureUtil.copyStep(updatedStep, reversedIdsMap);
      const idsMap = {};
      for (const idInProcedure in reversedIdsMap) {
        const idInSnippet = reversedIdsMap[idInProcedure];
        idsMap[idInSnippet] = idInProcedure;
      }
      return attachmentUtil
        .uploadAllFilesFromStep(step, services.attachments)
        .then(() =>
          services.settings.saveStepSnippet({
            id: snippetId,
            name,
            description,
            step: snippetStep,
            isTestSnippet: !!procedure.procedure_type,
          })
        )
        .then(() => {
          updatedStep.snippet_id = snippetId;
          updatedStep.snippet_name = name;
          updatedStep.snippet_rev = 1;
          updatedStep.snippet_ids_map = idsMap;

          const updated = cloneDeep(present);
          updated.sections[sectionIndex].steps.splice(stepIndex, 1, updatedStep);

          onProcedureChanged(updated, present);
        })
        .catch((err) => apm.captureError(err));
    },
    [services.attachments, services.settings, present, onProcedureChanged, procedure.procedure_type]
  );

  const onSaveSectionSnippet = useCallback(
    async (sectionIndex, values, section) => {
      const updatedSection = cloneDeep(section);

      // Detach snippets in section before saving.
      updatedSection.steps.forEach((step) => {
        snippetUtil.detachSnippet(step);
      });

      const snippetId = await procedureUtil.generateSnippetId();
      const name = values.name;
      const description = values.description;
      const reversedIdsMap = procedureUtil.generateNewIdsMapForSection(updatedSection);
      const snippetSection = procedureUtil.copySection(updatedSection, reversedIdsMap);
      const idsMap = {};
      for (const idInProcedure in reversedIdsMap) {
        const idInSnippet = reversedIdsMap[idInProcedure];
        idsMap[idInSnippet] = idInProcedure;
      }
      return attachmentUtil
        .uploadAllFilesFromSection(section, services.attachments)
        .then(() =>
          services.settings.saveSectionSnippet({
            id: snippetId,
            name,
            description,
            section: snippetSection,
          })
        )
        .then(() => {
          // Replace section with new snippet.
          updatedSection.snippet_id = snippetId;
          updatedSection.snippet_name = name;
          updatedSection.snippet_rev = 1;
          updatedSection.snippet_ids_map = idsMap;

          const updated = cloneDeep(present);
          updated.sections.splice(sectionIndex, 1, updatedSection);

          onProcedureChanged(updated, present);
        })
        .catch((err) => apm.captureError(err));
    },
    [services.attachments, services.settings, present, onProcedureChanged]
  );

  const onSaveSnippet = useCallback(
    async (values) => {
      if (!snippetSaveModalState || snippetSaveModalState.hidden) {
        return;
      }
      onSaveSectionSnippet(snippetSaveModalState.sectionIndex, values, snippetSaveModalState.section)
        .then(() => {
          setSnippetSaveModalState({
            section: undefined,
            sectionIndex: 0,
            hidden: true,
          });
        })
        .catch((err) => apm.captureError(err));
    },
    [onSaveSectionSnippet, snippetSaveModalState]
  );

  const onAddSectionSnippet = useCallback(
    (snippet, sectionIndex) => {
      const idsMap = procedureUtil.generateNewIdsMapForSection(snippet.section);
      const section = procedureUtil.copySection(snippet.section, idsMap);
      section.snippet_id = snippet.snippet_id;
      section.snippet_name = snippet.name;
      section.snippet_rev = snippet.revision;
      section.snippet_ids_map = idsMap;

      const updated = cloneDeep(present);
      updated.sections.splice(sectionIndex + 1, 0, section);
      onProcedureChanged(updated, present);
    },
    [onProcedureChanged, present]
  );

  const onPasteSection = useCallback(
    ({ sectionIndex }) => {
      if (!clipboardSection) {
        return;
      }
      mixpanelTrack('Paste Section');
      if (clipboardSection.snippet_id) {
        const snippet = snippetsMap[clipboardSection.snippet_id];
        if (!snippet) {
          return;
        }
        onAddSectionSnippet(snippet, sectionIndex);
      } else {
        const updated = cloneDeep(present);
        const sectionCopy = procedureUtil.copySection(clipboardSection);
        updated.sections.splice(sectionIndex + 1, 0, sectionCopy);
        onProcedureChanged(updated, present);
      }
    },
    [clipboardSection, mixpanelTrack, onAddSectionSnippet, onProcedureChanged, present, snippetsMap]
  );

  const hasUnresolvedStepComments = useCallback(
    (stepId) => {
      if (!present) {
        return false;
      }
      const stepComments = procedureUtil.getCommentsByReferenceId(stepId, present.comments);
      return (
        revisionsUtil.getUnresolvedRedlineComments(stepComments).length > 0 ||
        revisionsUtil.getUnresolvedParentReviewComments(stepComments).length > 0
      );
    },
    [present]
  );

  const hasUnresolvedRedlineItems = useCallback(
    (section) => {
      if (!procedure) {
        return false;
      }

      const sectionComments = procedureUtil.getCommentsBySection(section, procedure.comments);
      return (
        revisionsUtil.getUnresolvedRedlineComments(sectionComments).length > 0 ||
        revisionsUtil.getUnresolvedParentReviewComments(sectionComments).length > 0 ||
        procedureUtil.getStepRedlinesBySection(stepRedlineMap, section)?.size > 0
      );
    },
    [procedure, stepRedlineMap]
  );

  const SECTION_HAS_STEP_SNIPPETS_MESSAGE =
    'This section has step snippets, these step snippets will be detached if you continue. Would you like to continue?';

  const onValidateSectionSnippet = useCallback(
    async (sectionIndex, section) => {
      const procedures = selectProceduresNoDraftsForReleased(store.getState(), currentTeamId);
      const hasSnippets = snippetUtil.sectionHasSnippets(section);

      // If section has step snippets, show message before continuing.
      if (hasSnippets && !window.confirm(SECTION_HAS_STEP_SNIPPETS_MESSAGE)) {
        return;
      }

      const contentTypeErrors = snippetUtil.validateSectionForSnippet(section);

      if (contentTypeErrors && contentTypeErrors.type === 'unsupported_content') {
        setFlashMessage(contentTypeErrors['unsupported_content'] || 'Error validating section snippet');
        return;
      }

      // Validate section.
      const { errors: updatedSectionErrors } = await validateUtil.validateSection({
        section,
        teamId: currentTeamId,
        procedures,
        showRedlineValidation: true,
      });

      if (Object.keys(updatedSectionErrors).length !== 0) {
        mergeAllSectionErrors({
          sections: {
            [section.id]: updatedSectionErrors,
          },
        });
        return;
      }

      if (hasUnresolvedRedlineItems(section)) {
        setModalSaveSnippetUnresolvedActionsState({
          hidden: false,
        });
        return;
      }

      setSnippetSaveModalState({
        section,
        sectionIndex,
        hidden: false,
      });
    },
    [currentTeamId, hasUnresolvedRedlineItems, mergeAllSectionErrors, store]
  );

  // Removes the given header.
  const onRemoveHeader = useCallback(
    (headerIndex) => {
      const headerId = present.headers?.[headerIndex].id;
      const updated = cloneDeep(present);
      resolveActiveRedlinesInHeader(updated, headerId);
      updated.headers?.splice(headerIndex, 1);

      onProcedureChanged(updated, present);
    },
    [present, resolveActiveRedlinesInHeader, onProcedureChanged]
  );

  // Removes the section given header.
  const onRemoveSectionHeader = useCallback(
    (sectionIndex, headerIndex?) => {
      const updated = cloneDeep(present);
      updated.sections[sectionIndex].headers?.splice(headerIndex, 1);

      onProcedureChanged(updated, present);
    },
    [present, onProcedureChanged]
  );

  // Removes the given step header.
  const onRemoveStepHeader = useCallback(
    (sectionIndex, stepIndex) => {
      const updated = cloneDeep(present);
      updated.sections[sectionIndex].steps[stepIndex].headers?.pop();

      onProcedureChanged(updated, present);
    },
    [present, onProcedureChanged]
  );

  const trackedCallback = useCallback(
    (trackingKey, callback) => (event?) => {
      mixpanelTrack(trackingKey);
      callback(event);
    },
    [mixpanelTrack]
  );

  /*
   * Removes the given step.
   * If this is the last step, removes the section, unless there is only one section, in which case the step is replaced with an empty step
   */
  const onRemoveStep = useCallback(
    (sectionIndex, stepIndex) => {
      const updated = cloneDeep(present);
      const removedStepId = updated.sections[sectionIndex].steps[stepIndex].id;
      // Resolve active Redlines before removing step
      resolveActiveRedlinesInDeletedStep(updated, removedStepId);

      if (hasUnresolvedStepComments(removedStepId)) {
        setModalDeleteSectionStepUnresolvedCommentsState({
          hidden: false,
        });
        return;
      }

      const numSteps = procedureUtil.numNonAddedStepsInSection(present.sections[sectionIndex]);
      if (numSteps > 1) {
        // There are multiple steps, disregard how many sections
        updated.sections[sectionIndex].steps.splice(stepIndex, 1);
        procedureUtil.removeStepReferences(updated, [removedStepId]);

        onProcedureChanged(updated, present);
      } else {
        const procedures = Object.values(selectProcedures(store.getState(), currentTeamId));
        const procedureId = procedureUtil.getProcedureId(present);
        const procedureLinks = procedureUtil.getProcedureLinks(procedureId, procedures);
        const isSectionLinked = getIsSectionLinked(present.sections[sectionIndex].id, procedureLinks);

        if (isSectionLinked) {
          const uniqueProcedureLinks = getUniqueProcedureLinks(procedureLinks);

          window.alert(getLinkedSectionErrorMessage(uniqueProcedureLinks));
          return;
        }
        if (present.sections.length > 1) {
          // The step is the only step, but there are multiple sections
          trackedCallback('Remove Section', () => {
            updated.sections.splice(sectionIndex, 1);
            procedureUtil.removeStepReferences(updated, [removedStepId]);

            onProcedureChanged(updated, present);
          })();
        } else {
          // The step is the only step, and there is only one section
          updated.sections[sectionIndex].steps.splice(stepIndex, 1);
          procedureUtil.removeStepReferences(updated, [removedStepId]);

          // insert new step at beginning of section
          updated.sections[sectionIndex].steps.unshift(procedureUtil.newStep());
          onProcedureChanged(updated, present);
        }
      }
    },
    [
      currentTeamId,
      hasUnresolvedStepComments,
      present,
      resolveActiveRedlinesInDeletedStep,
      onProcedureChanged,
      trackedCallback,
      store,
    ]
  );

  const onDragSectionEnd = useCallback(
    (results) => {
      const { source, destination } = results;

      if (!source || !destination || source.index === destination.index) {
        return;
      }

      // Reorder sections and compute updated procedure doc.
      const updated = cloneDeep(present);
      const [removed] = updated.sections.splice(source.index, 1);
      updated.sections.splice(destination.index, 0, removed);

      onProcedureChanged(updated, present);
    },
    [present, onProcedureChanged]
  );

  const onDragStepEnd = useCallback(
    (results) => {
      const { source, destination } = results;

      if (!source || !destination) {
        return;
      }

      const sourceSectionIndex = present.sections.findIndex((section) => section.id === source.droppableId);
      const sourceStepIndex = source.index;

      const destinationSectionIndex = present.sections.findIndex((section) => section.id === destination.droppableId);
      const destinationStepIndex = destination.index;

      if (sourceSectionIndex === destinationSectionIndex && sourceStepIndex === destinationStepIndex) {
        return;
      }

      // Remove step from the original list.
      const updated = cloneDeep(present);
      const [removed] = updated.sections[sourceSectionIndex].steps.splice(sourceStepIndex, 1);

      // Insert step at destination.
      updated.sections[destinationSectionIndex].steps.splice(destinationStepIndex, 0, removed);

      // If there are no steps left in the source list, add a default step.
      const numSteps = procedureUtil.numNonAddedStepsInSection(updated.sections[sourceSectionIndex]);
      if (numSteps === 0) {
        // insert new step at beginning of section
        const step = procedureUtil.newStep();
        updated.sections[sourceSectionIndex].steps.unshift(step);
      }

      onProcedureChanged(updated, present);
    },
    [present, onProcedureChanged]
  );

  /*
   *If the drop location is the same as the source location (inferred by same droppableId and same index),
   *we will not have to do anything. If it is not the same, we check if the source list and destination list
   *is the same, which would result in a move in the same list. If the drop list and the source list are different
   *we will need to remove the item from the source list and append it to the destination list.
   */
  const onDragEnd = useCallback(
    (results) => {
      if (results.type === DroppableType.Section) {
        mixpanelTrack('Sections Reordered', { Using: 'Drag and Drop' });
        onDragSectionEnd(results);
      } else if (results.type === DroppableType.Step) {
        mixpanelTrack('Steps Reordered', { Using: 'Drag and Drop' });
        onDragStepEnd(results);
      }
    },
    [onDragSectionEnd, onDragStepEnd, mixpanelTrack]
  );

  const onDragStart = useCallback(() => {
    // Blur any active inputs, otherwise they behave weirdly during dragging

    // @ts-expect-error Blur is defined for HTMLElement but not for Element
    document?.activeElement?.blur();
  }, []);

  const getSectionErrors = useCallback(
    (sectionId) => {
      if (!procedureErrors.errors.sections) {
        return null;
      }
      return procedureErrors.errors.sections[sectionId];
    },
    [procedureErrors.errors]
  );

  const onDetachSectionSnippet = useCallback(() => {
    const section = modalDetachSnippetState.section;
    if (!section) {
      return;
    }
    const sectionIndex = modalDetachSnippetState.sectionIndex;
    mixpanelTrack('Section Snippet Detached');
    const updated = cloneDeep(section);
    snippetUtil.detachSnippet(updated);
    onSectionFormChanged(updated, sectionIndex);
    setModalDetachSnippetState({
      section: null,
      sectionIndex: 0,
      hidden: true,
    });
  }, [onSectionFormChanged, mixpanelTrack, modalDetachSnippetState]);

  const getOnRemoveSectionSnippet = useCallback(
    (sectionIndex) => {
      return () => {
        mixpanelTrack('Section Snippet Removed');
        onRemoveSection(sectionIndex);
      };
    },
    [onRemoveSection, mixpanelTrack]
  );
  const getOnAcceptLatestSectionSnippet = useCallback(
    ({ snippet, sectionIndex, sectionSnippetIdsMap }) => {
      return () => {
        if (!snippet) {
          return;
        }
        mixpanelTrack('Section Snippet Accepted Latest Version');
        const section = procedureUtil.copySection(snippet.section, sectionSnippetIdsMap);
        section.snippet_id = snippet.snippet_id;
        section.snippet_name = snippet.name;
        section.snippet_rev = snippet.revision;
        section.snippet_ids_map = sectionSnippetIdsMap;
        onSectionFormChanged(section, sectionIndex);
      };
    },
    [onSectionFormChanged, mixpanelTrack]
  );

  const onSelectSnippet = useCallback(
    (snippet) => {
      onAddSectionSnippet(snippet, snippetSelectModalState.sectionIndex);

      setSnippetSelectModalState({
        sectionIndex: 0,
        hidden: true,
      });
    },
    [onAddSectionSnippet, snippetSelectModalState.sectionIndex]
  );

  const onAddPartBuild = useCallback(() => {
    const updated = cloneDeep(present);

    // Add Part List (BOM)
    updated.part_list = procedureUtil.newInitialBlock(PropertyTypes.PartList) as PartList;

    onProcedureChanged(updated, present);
  }, [present, onProcedureChanged]);

  const onRemovePartBuild = useCallback(() => {
    const updated = cloneDeep(present);

    delete updated.part_list;

    onProcedureChanged(updated, present);
  }, [present, onProcedureChanged]);

  const onAddTestCases = useCallback(() => {
    if (present.test_case_list) {
      return;
    }

    const updated = cloneDeep(present);

    // Add test point list block to procedure
    updated.test_case_list = procedureUtil.newInitialBlock(ProcedureContentBlockTypes.TestCases) as TestCaseList;

    onProcedureChanged(updated, present);
  }, [present, onProcedureChanged]);

  const setPartListValueAndSync = useCallback(
    (path, value) => {
      let updated = cloneDeep(present);
      updated = setIn(updated, 'part_list', value);
      onProcedureChanged(updated, present);
    },
    [present, onProcedureChanged]
  );

  const latestActionRef = useRef(null);
  const latestIdRef = useRef(null);

  const addTestSnippet = useCallback(
    async (id) => {
      services.settings
        .listSnippets({ snippetIds: [id], isTestSnippet: true })
        .then((snippet) => {
          const stepIndex = present.sections[0].steps.length;
          onInsertStepSnippet(0, stepIndex, snippet[0]);
        })
        .catch((err) => apm.captureError(err));
    },
    [services.settings, onInsertStepSnippet, present]
  );

  const removeTestSnippet = useCallback(
    async (id) => {
      let foundMatch;

      present.sections.some((section, sectionIndex) => {
        return section.steps.some((step, stepIndex) => {
          if (step.snippet_id === id) {
            foundMatch = { sectionIndex, stepIndex };
            return true;
          }
          return false;
        });
      });

      if (foundMatch) {
        const { sectionIndex, stepIndex } = foundMatch;
        onRemoveStep(sectionIndex, stepIndex);
      }
    },
    [onRemoveStep, present]
  );

  const reorderTestSnippet = useCallback(
    async (dest) => {
      const { id, delta } = dest;
      let foundMatch;

      present.sections.some((section) => {
        return section.steps.some((step, stepIndex) => {
          if (step.snippet_id === id) {
            foundMatch = { id: section.id, stepIndex };
            return true;
          }
          return false;
        });
      });

      if (foundMatch) {
        const { id, stepIndex } = foundMatch;
        const changeObject = {
          destination: {
            droppableId: id,
            index: stepIndex + delta,
          },
          source: {
            droppableId: id,
            index: stepIndex,
          },
        };
        onDragStepEnd(changeObject);
      }
    },
    [onDragStepEnd, present]
  );

  useEffect(() => {
    if (latestActionRef.current) {
      if (latestActionRef.current === 'added') {
        addTestSnippet(latestIdRef.current).catch((err) => apm.captureError(err));
      } else if (latestActionRef.current === 'removed') {
        removeTestSnippet(latestIdRef.current).catch((err) => apm.captureError(err));
      } else if (latestActionRef.current === 'reorder') {
        reorderTestSnippet(latestIdRef.current).catch((err) => apm.captureError(err));
      }

      latestActionRef.current = null;
      latestIdRef.current = null;
    }
  }, [addTestSnippet, removeTestSnippet, reorderTestSnippet]);

  const setTestCasesListField = useCallback(
    (value, action, id) => {
      let updated = cloneDeep(present);
      updated = setIn(updated, 'test_case_list', value);
      onProcedureChanged(updated, present);

      latestActionRef.current = action;
      latestIdRef.current = id;
    },
    [present, onProcedureChanged]
  );

  const getSectionAddContentMenuItems = useCallback(
    (sectionIndex, section) =>
      getSectionAddContentItems({
        canSaveAsSnippet: auth.hasPermission(PERM.PROCEDURES_EDIT),
        hasSectionHeader: section?.headers?.length > 0,
        canPasteSection: !!clipboardSection,
        canPasteStep: !!clipboardStep,
        allStepsAreExpanded: allStepsInSectionExpandedMap && allStepsInSectionExpandedMap[sectionIndex],
        isSectionCollapsed: isCollapsedMap[section.id],
        isSnippet: snippetUtil.isSnippet(section),
        hasDependencies: !!section?.dependencies,
      }),
    [allStepsInSectionExpandedMap, auth, clipboardSection, clipboardStep, isCollapsedMap]
  );

  const onContentMenuClick = useCallback(
    (menuItem, index?, section?) => {
      mixpanelTrack(menuItem.label);
      switch (menuItem.action) {
        case Actions.AddSectionHeader:
          return onAddSectionHeader(index);
        case Actions.AddSectionDependencies:
          return onAddSectionDependencies(index);
        case Actions.AddSection:
          return onAddSection(index);
        case Actions.InsertSnippet:
          return setSnippetSelectModalState({
            sectionIndex: index,
            hidden: false,
          });
        case Actions.AddProcedureVariable:
          return onAddProcedureVariable();
        case Actions.AddRisk:
          return onAddProcedureRisk();
        case Actions.AddProcedureHeader:
          return onAddHeader();
        case Actions.AddPartBuild:
          return onAddPartBuild();
        case Actions.AddTestCases:
          return onAddTestCases();
        case Actions.DeleteSection:
          return onRemoveSection(index);
        case Actions.CopySection:
          return onCopySection({ sectionIndex: index });
        case Actions.CutSection:
          return onCutSection({ sectionIndex: index });
        case Actions.PasteSection:
          return onPasteSection({ sectionIndex: index });
        case Actions.PasteStep:
          return onPasteStep({ sectionIndex: index });
        case Actions.SaveAsSnippet:
          return onValidateSectionSnippet(index, section);
        case Actions.DeleteSectionHeader:
          return onRemoveSectionHeader(index);
        case Actions.CollapseAllStepsInSection:
          return setAllStepsInSectionExpanded(false, section);
        case Actions.ExpandAllStepsInSection:
          setIsCollapsed(section.id, false);
          setAllStepsInSectionExpanded(true, section);
          return;
        default:
          return;
      }
    },
    [
      mixpanelTrack,
      onAddHeader,
      onAddPartBuild,
      onAddProcedureRisk,
      onAddProcedureVariable,
      onAddSection,
      onAddSectionHeader,
      onAddSectionDependencies,
      onAddTestCases,
      onCopySection,
      onCutSection,
      onPasteSection,
      onPasteStep,
      onRemoveSection,
      onRemoveSectionHeader,
      onValidateSectionSnippet,
      setAllStepsInSectionExpanded,
      setIsCollapsed,
    ]
  );

  const onKeyboard = useCallback(
    (event, boundary, index) => {
      if (boundary === Boundary.Header) {
        if (event.code === 'Backspace' || event.code === 'Delete') {
          onRemoveHeader(index);
        } else if ((event.metaKey || event.ctrlKey) && event.code === 'KeyV') {
          if (clipboardBlock) {
            onPasteBlockIntoProcedureHeader({ headerIndex: index });
          }
        }
      } else if (boundary === Boundary.Section) {
        if (event.code === 'Backspace' || event.code === 'Delete') {
          onRemoveSection(index);
        } else if ((event.metaKey || event.ctrlKey) && event.code === 'KeyC') {
          onCopySection({ sectionIndex: index });
        } else if ((event.metaKey || event.ctrlKey) && event.code === 'KeyX') {
          onCutSection({ sectionIndex: index });
        } else if ((event.metaKey || event.ctrlKey) && event.code === 'KeyV') {
          if (clipboardSection) {
            onPasteSection({ sectionIndex: index });
          } else if (clipboardStep) {
            onPasteStep({ sectionIndex: index });
          }
        }
      }
    },
    [
      clipboardBlock,
      clipboardSection,
      clipboardStep,
      onCopySection,
      onCutSection,
      onPasteBlockIntoProcedureHeader,
      onPasteSection,
      onPasteStep,
      onRemoveHeader,
      onRemoveSection,
    ]
  );

  const { clearSelections } = useSelectionContext();

  const handleComponentClick = (e) => {
    if (!e.target.closest('.selectable-block')) {
      clearSelections();
    }
  };

  const hasWorkspaceOrProjectEditPermissions = auth.hasPermission(PERM.PROCEDURES_EDIT, procedure.project_id);

  const onRemoveTestCaseList = useCallback(() => {
    const updated = cloneDeep(present);

    delete updated.test_case_list;

    onProcedureChanged(updated, present);
  }, [present, onProcedureChanged]);

  const sourceName = useMemo(() => {
    if (procedure.code && procedure.name) {
      return `${procedure.code} - ${procedure.name}`;
    }

    return 'Untitled procedure';
  }, [procedure]);

  if (loading || !present) {
    return null;
  }

  return (
    <div
      aria-label="Procedure"
      role="region"
      onClick={handleComponentClick}
      className={`flex flex-col flex-grow ${showProcedureSettings && 'mr-72'}`}
    >
      <ProcedureContextProvider procedure={present} scrollTo={undefined}>
        <div className="flex flex-row transition-all items-center justify-between px-4 lg:px-8">
          <span className="text-sm ml-5 text-gray-500">* Indicates required field</span>
          <div className="lg:hidden">
            <LastSavedDisplay lastSavedTime={lastSavedTime} isSubmitting={submission.isSubmitting} isDirty={isDirty} />
          </div>
          {!showProcedureSettings && (
            <Button
              type={BUTTON_TYPES.TERTIARY}
              ariaLabel="Procedure Settings"
              leadingIcon={['fas', 'cog']}
              onClick={() => setShowProcedureSettings(true)}
            >
              Procedure Settings
            </Button>
          )}
        </div>
        <PromptBeforeUnload shouldPrompt={promptBeforeUnload} />
        <div className="flex-1 transition-all px-4 lg:px-8 pb-10 py-4 print:m-0 print:p-0">
          {currentTab === 'chart' && <ProcedureFlowChart procedure={present} />}
          {currentTab === 'list' && (
            <>
              {/* Cannot save snippet with unresolved actions modal */}
              {!modalSaveSnippetUnresolvedActionsState.hidden && (
                <ModalSaveSnippetUnresolvedActions
                  onSecondaryAction={() => {
                    setModalSaveSnippetUnresolvedActionsState({
                      hidden: true,
                    });
                  }}
                />
              )}

              {/* Cannot delete section with unresolved comments */}
              {!modalDeleteSectionStepUnresolvedCommentsState.hidden && (
                <ModalDeleteSectionStepUnresolvedComments
                  onSecondaryAction={() => {
                    setModalDeleteSectionStepUnresolvedCommentsState({
                      hidden: true,
                    });
                  }}
                />
              )}

              {!snippetSaveModalState.hidden && (
                <ModalSaveSnippet
                  onPrimaryAction={onSaveSnippet}
                  onSecondaryAction={() =>
                    setSnippetSaveModalState({
                      section: undefined,
                      sectionIndex: 0,
                      hidden: true,
                    })
                  }
                />
              )}

              {/* Insert section snippet modal */}
              {!snippetSelectModalState.hidden && (
                <ModalSnippetSelect
                  onSelectSnippet={onSelectSnippet}
                  onSecondaryAction={() =>
                    setSnippetSelectModalState({
                      sectionIndex: 0,
                      hidden: true,
                    })
                  }
                  snippetType="section"
                  isTestSnippet={!!procedure.procedure_type}
                />
              )}

              {/* Detach Snippet modal */}
              {!modalDetachSnippetState.hidden && (
                <ModalDetachSnippet
                  onClickProceed={onDetachSectionSnippet}
                  onClickCancel={() =>
                    setModalDetachSnippetState({
                      section: null,
                      sectionIndex: 0,
                      hidden: true,
                    })
                  }
                />
              )}

              <div className="flex flex-row gap-x-3 ml-8 mr-6">
                <div className="mt-1">
                  <AddContentMenu
                    menuItems={getProcedureAddContentItems({
                      canAddPartBuild: isManufacturingEnabled && isManufacturingEnabled(),
                      hasPartBuild: Boolean(present.part_list),
                      canHaveTestCases: procedure.procedure_type === ProcedureType.TestPlan,
                      hasTestCases: Boolean(present.test_case_list),
                      canHaveRisks: isRisksEnabled?.(),
                      // @ts-expect-error
                      hasRisks: Boolean(localRisks?.length > 0),
                    })}
                    onClick={(menuItem) => onContentMenuClick(menuItem)}
                  />
                </div>
                <div className="w-full">
                  <FieldSetProcedureDetails
                    procedure={present}
                    errors={procedureErrors.errors}
                    onFieldRefChanged={onScrollToRefChanged}
                    onProcedureDetailsFormChanged={onProcedureDetailsFormChanged}
                  />
                </div>
              </div>

              {/* Procedure Variables */}
              <div className="ml-12 pl-3">
                <FormProcedureVariables
                  values={{ variables: present.variables }}
                  errors={{ variables: procedureErrors.errors.variables }}
                  onRemoveVariable={onRemoveProcedureVariable}
                  onFieldRefChanged={onScrollToRefChanged}
                  onChanged={onVariablesChanged}
                />
              </div>

              {
                // @ts-expect-error
                isRisksEnabled?.() && localRisks?.length > 0 && (
                  <div className="ml-12 pl-3 mt-6 mr-6">
                    <FormProcedureRisks
                      procedureRisks={localRisks || []}
                      risks={risks}
                      errors={procedureErrors.errors.risks}
                      onRemoveRisk={onRemoveProcedureRisk}
                      onFieldRefChanged={onScrollToRefChanged}
                      onChanged={onRisksChanged}
                      projectId={present.project_id}
                    />
                    <Button type={BUTTON_TYPES.TERTIARY} leadingIcon={faCirclePlus} onClick={onAddProcedureRisk}>
                      Add Risk
                    </Button>
                  </div>
                )
              }

              {/* Part Build (BOM) */}
              {present.part_list && (
                <div className="ml-12 pl-3 mt-6">
                  <PartListFieldSet
                    content={present.part_list}
                    projectId={present.project_id}
                    contentErrors={procedureErrors.errors.part_list}
                    path=""
                    setFieldValue={setPartListValueAndSync}
                    onPartListChanged={onPartListChanged}
                    onPartListRemoved={onRemovePartBuild}
                  />
                </div>
              )}

              {/* Test Points List */}
              {present.test_case_list && (
                <div className="flex flex-row gap-x-3 ml-12 pl-3 mt-2 group">
                  <SnippetSelector
                    content={present.test_case_list}
                    contentErrors={procedureErrors.errors.test_case_list}
                    setFieldValue={setTestCasesListField}
                    isDraft={true}
                  />

                  <div className="justify-end opacity-0 group-hover:opacity-100">
                    <button type="button" title="Remove Section" tabIndex={-1} onClick={onRemoveTestCaseList}>
                      <FontAwesomeIcon
                        icon="times-circle"
                        className="self-center text-gray-400 hover:text-gray-500"
                      ></FontAwesomeIcon>
                    </button>
                  </div>
                </div>
              )}

              {/* Procedure Headers */}
              {present.headers && (
                <div className="flex items-start mt-2">
                  <div className="flex flex-col justify-end w-full">
                    <div className="border-2 border-transparent">
                      <div className="flex flex-nowrap items-start w-full">
                        <div className="w-full">
                          {present.headers.map((header, headerIndex) => (
                            <Selectable
                              key={header.id}
                              boundary={Boundary.Header}
                              block={header}
                              onKeyboard={(event) => onKeyboard(event, Boundary.Header, headerIndex)}
                            >
                              {({ styles, isSelected }) => (
                                <div className="flex items-start mt-4 ml-12 group/header">
                                  <div className={`flex w-full grow py-1 pr-2 ${styles}`}>
                                    <FieldSetProcedureHeader
                                      key={header.id}
                                      header={header}
                                      errors={getHeaderErrors(header.id)}
                                      index={headerIndex}
                                      isHeaderCollapsed={isCollapsedMap[header.id]}
                                      onHeaderCollapse={setIsCollapsed}
                                      onFieldRefChanged={onScrollToRefChanged}
                                      onHeaderFormChanged={onHeaderFormChanged}
                                      onSaveReviewComment={onSaveReviewComment}
                                      onResolveComment={onResolveComment}
                                      onUnresolveComment={onUnresolveComment}
                                      comments={procedureUtil.getCommentsByReferenceId(header.id, present.comments)}
                                      onAcceptRedlineField={onAcceptRedlineField}
                                      onRejectRedlineField={onRejectRedlineField}
                                      onAcceptRedlineBlock={onAcceptRedlineBlock}
                                      onRejectRedlineBlock={onRejectRedlineBlock}
                                      headerRedlines={getHeaderRedlines(header.id)}
                                    />
                                  </div>
                                  <div
                                    className={`pl-1 justify-end ${
                                      isSelected ? 'opacity-100' : 'opacity-0 group-hover/header:opacity-100'
                                    }`}
                                  >
                                    <button
                                      type="button"
                                      title="Remove Procedure Header"
                                      tabIndex={-1}
                                      onClick={() => onRemoveHeader(headerIndex)}
                                    >
                                      <FontAwesomeIcon
                                        icon="times-circle"
                                        className="self-center text-gray-400 hover:text-gray-500"
                                      ></FontAwesomeIcon>
                                    </button>
                                  </div>
                                </div>
                              )}
                            </Selectable>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="flex items-start mt-2">
                <div className="flex flex-col justify-end w-full">
                  <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                    <Droppable droppableId="procedure" type={DroppableType.Section}>
                      {(droppableProvided, droppableSnapshot) => (
                        <div
                          ref={droppableProvided.innerRef}
                          className={`flex flex-col gap-y-2 border-2 border-dashed mt-2 ${
                            droppableSnapshot.isDraggingOver ? 'border-gray-400' : 'border-transparent'
                          }`}
                          {...droppableProvided.droppableProps}
                        >
                          {present.sections &&
                            present.sections.map((section, sectionIndex) => (
                              <div aria-label="Section" role="region" key={section.id} className="group/section">
                                <Selectable
                                  block={section}
                                  boundary={Boundary.Section}
                                  onKeyboard={(event) => onKeyboard(event, Boundary.Section, sectionIndex)}
                                >
                                  {({ isSelected, styles }) => (
                                    <Draggable key={section.id} draggableId={section.id} index={sectionIndex}>
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          className="flex items-start w-full"
                                        >
                                          {/* Hover section items */}
                                          <div
                                            className={`h-9 flex flex-row gap-x-2 items-center ${
                                              isSelected ? 'opacity-100' : 'opacity-0 group-hover/section:opacity-50'
                                            }`}
                                          >
                                            <AddContentMenu
                                              menuItems={getSectionAddContentMenuItems(sectionIndex, section)}
                                              onClick={(menuItem) =>
                                                onContentMenuClick(menuItem, sectionIndex, section)
                                              }
                                            />
                                            {/* Drag and drop grip */}
                                            <div
                                              {...provided.dragHandleProps}
                                              tabIndex={undefined}
                                              className="text-gray-400 opacity-0 group-hover/section:opacity-100"
                                            >
                                              <FontAwesomeIcon icon="grip-vertical" />
                                            </div>
                                          </div>

                                          <div className={`w-full ml-2 p-1 ${styles}`}>
                                            {!section.snippet_id && (
                                              <FieldSetProcedureSection
                                                section={section}
                                                sectionIndex={sectionIndex}
                                                snippetsMap={snippetsMap}
                                                errors={getSectionErrors(section.id)}
                                                onSectionHeaderFormChanged={onSectionHeaderFormChanged}
                                                onAddStep={(stepIndex) => onAddStep(sectionIndex, stepIndex)}
                                                onAddStepHeader={(stepIndex) =>
                                                  onAddStepHeader(sectionIndex, stepIndex)
                                                }
                                                onAddSection={() => onAddSection(sectionIndex)}
                                                onInsertStepSnippet={(stepIndex, snippet) =>
                                                  onInsertStepSnippet(sectionIndex, stepIndex, snippet)
                                                }
                                                onSaveStepSnippet={(stepIndex, values, step) =>
                                                  onSaveStepSnippet(sectionIndex, stepIndex, values, step)
                                                }
                                                onRemoveStep={(stepIndex) => onRemoveStep(sectionIndex, stepIndex)}
                                                onRemoveStepHeader={(stepIndex) =>
                                                  onRemoveStepHeader(sectionIndex, stepIndex)
                                                }
                                                configurePartKitBlock={configurePartKitBlock}
                                                configurePartBuildBlock={configurePartBuildBlock}
                                                onRemoveSectionHeader={() => onRemoveSectionHeader(sectionIndex)}
                                                onFieldRefChanged={onScrollToRefChanged}
                                                isCollapsedMap={isCollapsedMap}
                                                onCollapse={setIsCollapsed}
                                                comments={present.comments}
                                                onSectionFormChanged={onSectionFormChanged}
                                                onStepFormChanged={onStepFormChanged}
                                                onSaveReviewComment={onSaveReviewComment}
                                                onResolveComment={onResolveComment}
                                                onUnresolveComment={onUnresolveComment}
                                                onAcceptRedlineField={onAcceptRedlineField}
                                                onRejectRedlineField={onRejectRedlineField}
                                                onAcceptRedlineBlock={onAcceptRedlineBlock}
                                                onRejectRedlineBlock={onRejectRedlineBlock}
                                                onAcceptRedlineStep={onAcceptRedlineStep}
                                                onRejectRedlineStep={onRejectRedlineStep}
                                                stepRedlineMap={stepRedlineMap}
                                                changedStepIdSet={changedStepIdSet}
                                                setChangedStepIdSet={setChangedStepIdSet}
                                                isSaveStepSnippetDisabled={!auth.hasPermission(PERM.PROCEDURES_EDIT)}
                                                enabledContentTypes={undefined}
                                                isRestrictedToSnippetContents={false}
                                                areDependenciesEnabled={hasWorkspaceOrProjectEditPermissions}
                                                isTestProcedure={Boolean(procedure.procedure_type)}
                                              />
                                            )}
                                            {section.snippet_id && (
                                              <>
                                                <div
                                                  className="w-full flex flex-col"
                                                  ref={fieldRef(`${section.id}.unresolvedSnippet`)}
                                                  style={{ scrollMarginTop: `${EDIT_STICKY_HEADER_HEIGHT_REM}rem` }}
                                                ></div>
                                                <PromptSnippet
                                                  onAcceptLatest={getOnAcceptLatestSectionSnippet({
                                                    snippet: snippetsMap[section.snippet_id],
                                                    sectionIndex,
                                                    sectionSnippetIdsMap: section.snippet_ids_map || {},
                                                  })}
                                                  onDetach={() =>
                                                    setModalDetachSnippetState({
                                                      section,
                                                      sectionIndex,
                                                      hidden: false,
                                                    })
                                                  }
                                                  onRemove={getOnRemoveSectionSnippet(sectionIndex)}
                                                  isCurrentLatest={
                                                    !snippetsMap ||
                                                    !snippetsMap[section.snippet_id] ||
                                                    section.snippet_rev === snippetsMap[section.snippet_id].revision
                                                  }
                                                  currentName={section.snippet_name}
                                                  latestName={
                                                    snippetsMap &&
                                                    snippetsMap[section.snippet_id] &&
                                                    snippetsMap[section.snippet_id].name
                                                  }
                                                  currentVersion={
                                                    <ProcedureSection
                                                      section={section}
                                                      sectionIndex={sectionIndex}
                                                      sectionKey={procedureUtil.displaySectionKey(
                                                        sectionIndex,
                                                        getSetting('display_sections_as', 'letters')
                                                      )}
                                                      isCollapsedMap={isCollapsedMap}
                                                      onCollapse={setIsCollapsed}
                                                      onExpandCollapseAllSteps={() =>
                                                        setAllStepsInSectionExpanded(
                                                          !allStepsInSectionExpandedMap[sectionIndex],
                                                          section
                                                        )
                                                      }
                                                      allStepsAreExpanded={
                                                        allStepsInSectionExpandedMap &&
                                                        allStepsInSectionExpandedMap[sectionIndex]
                                                      }
                                                      sourceName={sourceName}
                                                    />
                                                  }
                                                  latestVersion={
                                                    snippetsMap && snippetsMap[section.snippet_id] ? (
                                                      <ProcedureSection
                                                        section={{
                                                          ...snippetsMap[section.snippet_id].section,
                                                          id: section.id,
                                                          snippet_id: section.snippet_id,
                                                          snippet_name: section.snippet_name,
                                                        }}
                                                        sectionIndex={sectionIndex}
                                                        sectionKey={procedureUtil.displaySectionKey(
                                                          sectionIndex,
                                                          getSetting('display_sections_as', 'letters')
                                                        )}
                                                        isCollapsedMap={isCollapsedMap}
                                                        onCollapse={setIsCollapsed}
                                                        onExpandCollapseAllSteps={() => {
                                                          if (section.snippet_id) {
                                                            setAllStepsInSectionExpanded(
                                                              !allStepsInSectionExpandedMap[sectionIndex],
                                                              {
                                                                ...snippetsMap[section.snippet_id].section,
                                                                id: section.id,
                                                              }
                                                            );
                                                          }
                                                        }}
                                                        allStepsAreExpanded={
                                                          allStepsInSectionExpandedMap &&
                                                          allStepsInSectionExpandedMap[sectionIndex]
                                                        }
                                                        sourceName={sourceName}
                                                      />
                                                    ) : (
                                                      <></>
                                                    )
                                                  }
                                                />
                                                {getSectionErrors(section.id) &&
                                                  getSectionErrors(section.id).unresolvedSnippet && (
                                                    <div className="flex justify-end text-red-700">
                                                      {getSectionErrors(section.id).unresolvedSnippet}
                                                    </div>
                                                  )}
                                              </>
                                            )}
                                          </div>
                                          <div
                                            className={`pl-1 justify-end ${
                                              isSelected ? 'opacity-100' : 'opacity-0 group-hover/section:opacity-100'
                                            }`}
                                          >
                                            <button
                                              type="button"
                                              title="Remove Section"
                                              tabIndex={-1}
                                              onClick={() => onRemoveSection(sectionIndex)}
                                            >
                                              <FontAwesomeIcon
                                                icon="times-circle"
                                                className="self-center text-gray-400 hover:text-gray-500"
                                              ></FontAwesomeIcon>
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  )}
                                </Selectable>
                              </div>
                            ))}
                          {droppableProvided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>
            </>
          )}
        </div>
        {showProcedureSettings && (
          <div className="fixed top-10">
            <PageSidebar title="Procedure Settings" onClose={() => setShowProcedureSettings(false)} hasTopBuffer={true}>
              <FieldSetProcedureSettings
                procedure={present}
                onProcedureSettingsFormChanged={onProcedureSettingsFormChanged}
                isProjectClearable={!auth.hasProjectOnlyEditPermissions()}
              />
            </PageSidebar>
          </div>
        )}
        <FlashMessage
          message={flashMessage}
          // @ts-ignore
          messageUpdater={setFlashMessage}
        />
        <DetachedRedlinesModal
          show={showRedlineModal}
          onClose={() => setShowRedlineModal(false)}
          detachedRedlines={detachedRedlines}
          currentTeamId={currentTeamId}
          resolveDisconnectedStepRedline={resolveDisconnectedStepRedline}
          resolveDisconnectedHeaderRedline={resolveDisconnectedHeaderRedline}
          onResolveComment={onResolveComment}
        />
      </ProcedureContextProvider>
    </div>
  );
};

export { DroppableType };
