import React, { useMemo } from 'react';
import { Field } from 'formik';
import { AlertSubtypes } from './AlertRun';
import AlertIcon from './AlertIcon';
import validateUtil from '../../lib/validateUtil';
import { TypedFieldSetProps } from './BlockTypes';
import ReferenceTextArea from '../ReferenceTextArea';

/*
 * Component for rendering form fields for a Block of type AlertRun.
 * Conforms to TypedFieldSet, see comments in useBlockComponents.js
 */
const AlertFieldSet = React.memo<TypedFieldSetProps<'alert'>>(
  ({ path, content, contentErrors, setFieldValue, pendingStep, precedingStepId }) => {
    const alert = content;
    const fieldName = path;

    const title = useMemo(() => {
      switch (alert.subtype) {
        case AlertSubtypes.NOTE:
          return 'Note';
        case AlertSubtypes.CAUTION:
          return 'Caution';
        case AlertSubtypes.WARNING:
          return 'Warning';
        default:
          return '';
      }
    }, [alert.subtype]);

    if (
      alert.subtype !== AlertSubtypes.NOTE &&
      alert.subtype !== AlertSubtypes.CAUTION &&
      alert.subtype !== AlertSubtypes.WARNING
    ) {
      return null;
    }

    return (
      <div className="flex flex-col grow">
        <span className="field-title">{title}</span>
        <Field name={fieldName} validate={validateUtil.validateFieldAlert}>
          {({ field }) => (
            <div className="min-w-0 w-full">
              <div className="flex flex-col min-w-0 w-full relative">
                <div className="absolute top-2 left-3 text-gray-400">
                  <AlertIcon alert={alert} />
                </div>
                {/* pl-10 is padding-left: 2.5rem */}
                <ReferenceTextArea
                  field={field}
                  path={path ?? ''}
                  setFieldValue={setFieldValue}
                  placeholder="Text, content, notes, etc...*"
                  pendingStep={pendingStep}
                  precedingStepId={precedingStepId}
                />
              </div>
              {contentErrors && contentErrors.text && <div className="text-red-700 text-sm">{contentErrors.text}</div>}
            </div>
          )}
        </Field>
      </div>
    );
  }
);

export default AlertFieldSet;
