import { cloneDeep, isNil } from 'lodash';
import { getIn } from 'formik';
import { evaluate } from 'mathjs';

import procedureUtil from './procedureUtil';
import {
  getInitialContentTableInput,
  getInitialTextColumn,
} from '../components/TableInput/TableInputConstants';

import { ACTION_TYPE } from 'shared/lib/runUtil';
import {
  TableCells,
  TableColumn,
  TableSignoff,
  TableSignoffAction,
  DraftTableInputBlock,
  TableRevokeSignoffAction,
  TableAction,
  TableInputBlock,
} from 'shared/lib/types/views/procedures';
import signoffUtil from 'shared/lib/signoffUtil';
import { isEmptyValue } from 'shared/lib/text';
import sharedTableUtil from 'shared/lib/tableUtil';

type ImportObjectType = {
  data: Array<{ [key: string]: string }>;
  meta: {
    fields: Array<string>;
  };
};

const INITIAL_SIGNOFF_OBJECT = {
  id: '',
  operators: [],
  actions: [],
} as TableSignoff;

const EXPRESSION_DELIMITER_PATTERN = /[^{}]+(?=})/g;
const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

const ADDITIONAL_MATH_METHODS = {
  concat: (...args) => {
    return args.join('');
  },
};

const tableInputUtil = {
  getInitialTableInputContent: (isReadOnly = false): DraftTableInputBlock => {
    return getInitialContentTableInput(isReadOnly);
  },

  transformImportedDataToTableInput: (
    importObject: ImportObjectType
  ): DraftTableInputBlock => {
    const { data, meta } = importObject;
    const columns: Array<TableColumn> = [];

    if (meta.fields?.length) {
      meta.fields.forEach((columnLabel) => {
        const column = getInitialTextColumn(columnLabel);
        column.width = 100 / meta.fields.length;

        columns.push(column);
      });
    }

    const cells: TableCells = [];

    data.forEach((datum) => {
      const row: Array<string | null> = [];

      columns.forEach((column) => {
        row.push(datum[column.name]);
      });

      cells.push(row);
    });

    const numRows = data.length;
    const initialTableInputContent = getInitialContentTableInput(
      undefined,
      numRows
    );

    initialTableInputContent.cells = cells;
    initialTableInputContent.columns = columns;

    return initialTableInputContent;
  },

  cloneAndMigrateContent: (
    content: DraftTableInputBlock
  ): DraftTableInputBlock => {
    const contentClone = cloneDeep(content);

    /**
     * In legacy table input content, the row number was stored as a string,
     * causing string concatenation instead of addition/subtraction.
     */
    if (typeof contentClone.rows === 'string') {
      contentClone.rows = Number(contentClone.rows);
    }

    if (content.cells === undefined) {
      contentClone.cells = sharedTableUtil.generateEmptyCellsArray(
        contentClone.columns.length,
        contentClone.rows
      );
    }

    contentClone.columns.forEach((column) => {
      const numColumns = content.columns.length;

      if (!column.id) {
        column.id = procedureUtil.generateTableInputColumnId();
      }

      if (!column.width) {
        column.width = 100 / numColumns;
      }
    });

    return contentClone;
  },

  getUpdatedCellsOnColumnChange: ({
    content,
    action,
    sourceIndex,
    destinationIndex,
  }: {
    content: DraftTableInputBlock;
    action: 'remove' | 'add' | 'swap';
    sourceIndex: number;
    destinationIndex?: number;
  }): TableCells => {
    // If cells property does not exist (in legacy tables) return a rows 2d array.
    if (content.cells === undefined) {
      return sharedTableUtil.generateEmptyCellsArray(
        content.columns.length,
        content.rows
      );
    }

    const cellsCopy = cloneDeep(content.cells);

    if (action === 'remove') {
      cellsCopy.forEach((row) => row.splice(sourceIndex, 1));
    } else if (action === 'add') {
      cellsCopy.forEach((row) => row.splice(sourceIndex, 0, ''));
    } else if (action === 'swap') {
      if (destinationIndex === undefined) {
        throw new Error('destinationIndex missing');
      }

      cellsCopy.forEach((row) => {
        [row[sourceIndex], row[destinationIndex]] = [
          row[destinationIndex],
          row[sourceIndex],
        ];
      });
    }

    return cellsCopy;
  },

  getUpdatedRowMetadataOnRowChange: ({
    content,
    action,
    sourceIndex,
    destinationIndex,
  }: {
    content: DraftTableInputBlock;
    action: 'remove' | 'add' | 'duplicate' | 'rearrange';
    sourceIndex: number;
    destinationIndex?: number;
  }): DraftTableInputBlock['row_metadata'] => {
    // If row_metadata property does not exist (in legacy tables) return a new row_metadata array (content.rows should be updated already).
    if (!content.row_metadata) {
      return Array.from({ length: content.rows }, () => ({
        id: procedureUtil.generateTableInputRowId(),
      }));
    }
    const rowMetadataCopy = cloneDeep(content.row_metadata);

    if (action === 'remove') {
      rowMetadataCopy.splice(sourceIndex, 1);
    } else if (action === 'add') {
      rowMetadataCopy.splice(sourceIndex, 0, {
        id: procedureUtil.generateTableInputRowId(),
      });
    } else if (action === 'duplicate') {
      rowMetadataCopy.splice(sourceIndex, 0, {
        id: procedureUtil.generateTableInputRowId(),
      });
    } else if (action === 'rearrange') {
      if (destinationIndex === undefined) {
        throw new Error('destinationIndex missing');
      }

      // Remove source row from row_metadata array.
      const [sourceRow] = rowMetadataCopy.splice(sourceIndex, 1);
      // Add source row after the destination row in the cells array.
      rowMetadataCopy.splice(destinationIndex, 0, sourceRow);
    }

    return rowMetadataCopy;
  },

  /**
   * @returns - a deep copy of a cells array with modified row.
   */
  getUpdatedCellsOnRowChange: ({
    content,
    action,
    sourceIndex,
    destinationIndex,
  }: {
    content: DraftTableInputBlock;
    action: 'remove' | 'add' | 'duplicate' | 'rearrange';
    sourceIndex: number;
    destinationIndex?: number;
  }): TableCells => {
    // If cells property does not exist (in legacy tables) return a rows 2d array.
    if (content.cells === undefined) {
      return sharedTableUtil.generateEmptyCellsArray(
        content.columns.length,
        content.rows
      );
    }

    const cellsCopy = cloneDeep(content.cells);

    if (action === 'remove') {
      cellsCopy.splice(sourceIndex, 1);
    } else if (action === 'add') {
      const newRowValues = new Array(content.columns.length).fill('');
      const signoffIndices = sharedTableUtil.getAllSignoffColumnIndices(
        content.columns
      );
      signoffIndices.forEach((signoffIndex) => {
        newRowValues[signoffIndex] = [tableInputUtil.getInitialSignoffObject()];
      });

      const commentIndices = sharedTableUtil.getAllCommentColumnIndices(
        content.columns
      );
      commentIndices.forEach((commentIndex) => {
        newRowValues[commentIndex] = [];
      });

      cellsCopy.splice(sourceIndex, 0, newRowValues);
    } else if (action === 'duplicate') {
      const rowCopy = [...cellsCopy[sourceIndex]];
      cellsCopy.splice(sourceIndex, 0, rowCopy);
    } else if (action === 'rearrange') {
      if (destinationIndex === undefined) {
        throw new Error('destinationIndex missing');
      }

      // Remove source row from cells array.
      const [sourceRow] = cellsCopy.splice(sourceIndex, 1);
      // Add source row after the destination row in the cells array.
      cellsCopy.splice(destinationIndex, 0, sourceRow);
    }

    return cellsCopy;
  },

  getInitialSignoffObject: (): TableSignoff => {
    const signoffObject = cloneDeep(INITIAL_SIGNOFF_OBJECT);
    signoffObject.id = procedureUtil.generateTableSignoffId();

    return signoffObject;
  },

  getSignoffAction: ({
    userId,
    operator,
  }: {
    userId: string;
    operator?: string;
  }): TableSignoffAction => {
    return {
      timestamp: new Date().toISOString(),
      user_id: userId,
      type: ACTION_TYPE.SIGNOFF,
      operator,
    };
  },

  getRevokeSignoffAction: ({
    userId,
    actions,
    signoffId,
  }: {
    userId: string;
    actions: Array<TableAction>;
    signoffId: string;
  }): TableRevokeSignoffAction => {
    return signoffUtil.getRevokeSignoffAction({
      userId,
      signoffId,
      timestamp: new Date().toISOString(),
      actions: actions.map((a) => ({
        ...a,
        signoff_id: signoffId,
      })),
    });
  },

  /**
   * expression: A string representation of a Math.js parse-able expression, starting with an "=" with references bound by { & }
   * returns an evalauted expression or original expression value (in the case the expression parsing fails)
   */
  evaluateExpression: ({
    expression,
    values,
  }: {
    expression: string;
    values: TableCells;
  }): string | number | undefined => {
    const extractedValues = expression.match(EXPRESSION_DELIMITER_PATTERN);
    const parsedExpressionObject = {};
    let evaluatedValue;

    if (!extractedValues) {
      return undefined;
    }

    extractedValues.forEach((referenceString) => {
      const referenceStringUppercase = referenceString.toUpperCase();
      const splitAplhaNumeric = [
        referenceStringUppercase[0],
        referenceStringUppercase.slice(1),
      ];

      if (splitAplhaNumeric.length !== 2) {
        return undefined;
      }

      const columnIndex = ALPHABET.indexOf(splitAplhaNumeric[0]);

      if (columnIndex === -1) {
        return undefined;
      }

      const rowIndex = Number(splitAplhaNumeric[1]) - 1;
      const referencePath = `[${rowIndex}][${columnIndex}]`;
      const referenceValue = getIn(values, referencePath);

      if (typeof referenceValue === 'object') {
        parsedExpressionObject[referenceString] = undefined;
      } else {
        parsedExpressionObject[referenceString] = referenceValue;
      }
    });

    if (
      Object.values(parsedExpressionObject).some((value) => isEmptyValue(value))
    ) {
      return undefined;
    }

    // Strips empty whitespace, the "=" at the beginning of the expression and curly braces anywhere in the string.
    const strippedExpressionString = expression
      .trim()
      .substring(1)
      .replace(/[{}]/g, '');

    // Add custom methods for Math.js to use.
    const scope = {
      ...parsedExpressionObject,
      ...ADDITIONAL_MATH_METHODS,
    };

    try {
      evaluatedValue = evaluate(strippedExpressionString, scope);
    } catch (e) {
      // Don't do anything for now, we need to catch errors to prevent system crashes.
    }

    return evaluatedValue;
  },

  getSignoffSettingsDescriptionSegments: (
    allowInputAfterSignoff = false
  ): Array<string> => {
    return [
      'Input',
      allowInputAfterSignoff ? 'can' : 'cannot',
      'be made in the row after signoff.',
    ];
  },

  cellIdsToIndices: ({
    rowId,
    columnId,
    rowMetadata,
    columnMetadata,
  }: {
    rowId?: string;
    columnId?: string;
    rowMetadata?: TableInputBlock['row_metadata'];
    columnMetadata?: Array<TableColumn>;
  }): { rowIndex: number; columnIndex: number } => {
    if (!rowId || !columnId || !rowMetadata || !columnMetadata) {
      return {
        rowIndex: -1,
        columnIndex: -1,
      };
    }
    return {
      rowIndex: rowMetadata.findIndex(({ id }) => id === rowId),
      columnIndex: columnMetadata.findIndex(({ id }) => id === columnId),
    };
  },

  getCellCoordinates: ({
    rowId,
    columnId,
    rowMetadata,
    columnMetadata,
  }: {
    rowId?: string;
    columnId?: string;
    rowMetadata?: TableInputBlock['row_metadata'];
    columnMetadata?: Array<TableColumn>;
  }): string | undefined => {
    const { rowIndex, columnIndex } = tableInputUtil.cellIdsToIndices({
      rowId,
      columnId,
      rowMetadata,
      columnMetadata,
    });
    if (rowIndex === -1 || columnIndex === -1) {
      return;
    }
    return `${procedureUtil.displaySectionKey(columnIndex, 'letters')}${
      rowIndex + 1
    }`;
  },

  cellCoordinatesToIds: ({
    coordinates,
    rowMetadata,
    columnMetadata,
  }: {
    coordinates: string;
    rowMetadata?: TableInputBlock['row_metadata'];
    columnMetadata?: Array<TableColumn>;
  }): { rowId?: string; columnId?: string } => {
    if (!rowMetadata || !columnMetadata) {
      return {};
    }
    const coordinateMatches = coordinates?.match(/^([A-Za-z])(\d+)$/);
    const coordinateMatchArray = Array.from(coordinateMatches ?? []);

    if (coordinateMatchArray.length !== 3) {
      throw new Error('invalid cell coordinates');
    }
    const [, columnLetter, rowNumberOneBased] = coordinateMatchArray;

    if (isNil(columnLetter) || isNil(rowNumberOneBased)) {
      throw new Error('invalid cell coordinates');
    }

    const columnNumberZeroBased =
      columnLetter.toLowerCase().charCodeAt(0) - 'a'.charCodeAt(0);
    const rowNumberZeroBased = Number(rowNumberOneBased) - 1;
    return {
      rowId: rowMetadata[rowNumberZeroBased]?.id,
      columnId: columnMetadata[columnNumberZeroBased]?.id,
    };
  },
};

export default tableInputUtil;
