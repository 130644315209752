import { useEffect, useMemo, useState, useCallback } from 'react';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { Snippet } from 'shared/lib/types/views/procedures';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import SnippetToolbar from './SnippetToolBar';
import { MenuContextAction } from '../MenuContext';
import snippetUtil from '../../lib/snippetUtil';
import apm from '../../lib/apm';
import FieldSetSnippet from './FieldSetSnippet';
import PreviewSnippet from './ModalPreviewSnippet';
import attachmentUtil from '../../lib/attachmentUtil';
import SnippetSidebarWithContent from './SnippetSidebarWithContent';
import LoadingScreen from '../LoadingScreen';
import { PERM } from '../../lib/auth';
import { useAuth } from '../../contexts/AuthContext';
import { snippetsPath, snippetViewPath, testingSnippetListPath } from '../../lib/pathUtil';
import { useSnippetValidation } from '../../hooks/useSnippetValidation';
import PromptBeforeUnload from '../Prompt/PromptBeforeUnload';
import NotFound from '../NotFound';

const SnippetDetail = () => {
  const { services, currentTeamId } = useDatabaseServices();
  const { auth } = useAuth();
  const [snippet, setSnippet] = useState<Snippet | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [hasErrors, setHasErrors] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const { validateSnippet, validationErrors } = useSnippetValidation(snippet, currentTeamId);

  const { id: snippetId } = useParams<{ teamId: string; id: string }>();
  const location = useLocation();
  const history = useHistory();
  const getQueryParams = (search: string) => {
    const params = new URLSearchParams(search);
    return {
      isTestingModule: params.get('isTestingModule') === 'true', // Convert string to boolean
    };
  };

  const { isTestingModule } = getQueryParams(location.search);

  const canEdit = useMemo(() => {
    return auth.hasPermission(PERM.PROCEDURES_EDIT);
  }, [auth]);

  const refreshSnippet = useCallback(() => {
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get('type');

    if (snippetId === 'new') {
      if (type === 'section') {
        setSnippet(snippetUtil.getNewSectionSnippet() as Snippet);
      } else {
        setSnippet(snippetUtil.getNewStepSnippet() as Snippet);
      }
      setIsEditing(true);
      return Promise.resolve();
    }

    return services.settings
      .getSnippet({ snippetId, isTestSnippet: isTestingModule })
      .then((res) => {
        setSnippet(res);
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          setIsNotFound(true);
          setSnippet(null);
        }
        apm.captureError(err);
      });
  }, [services.settings, snippetId, isTestingModule, location.search]);

  useEffect(() => {
    refreshSnippet();
  }, [refreshSnippet]);

  const saveSnippet = useCallback(
    async (snippetToSave: Snippet) => {
      if (snippetToSave.snippet_type === 'section') {
        const savedSnippet = await services.settings.saveSectionSnippet({
          id: snippetToSave.snippet_id,
          name: snippetToSave.name,
          description: snippetToSave.description,
          section: snippetToSave.section,
          isTestSnippet: isTestingModule,
        });
        return savedSnippet;
      } else {
        const savedSnippet = await services.settings.saveStepSnippet({
          id: snippetToSave.snippet_id,
          name: snippetToSave.name,
          description: snippetToSave.description,
          step: snippetToSave.step,
          isTestSnippet: isTestingModule,
        });
        return savedSnippet;
      }
    },
    [isTestingModule, services.settings]
  );

  const onSave = useCallback(async () => {
    if (!snippet) return;

    // Upload all attachments first before validating.
    try {
      if (snippet.snippet_type === 'step') {
        await attachmentUtil.uploadAllFilesFromStep(snippet.step, services.attachments);
      } else if (snippet.snippet_type === 'section') {
        await attachmentUtil.uploadAllFilesFromSection(snippet.section, services.attachments);
      }
    } catch (error) {
      // Do nothing with attachment upload errors, they will get validated during snippet validation.
    }

    const isValid = await validateSnippet();
    if (!isValid) {
      setHasErrors(true);
      return;
    }
    setHasErrors(false);

    try {
      await saveSnippet(snippet);
      setIsEditing(false);
      setHasChanges(false);
      refreshSnippet();
    } catch (error) {
      apm.captureError(error);
    }
  }, [snippet, validateSnippet, refreshSnippet, saveSnippet, services.attachments]);

  const onCancelEdit = useCallback(() => {
    if (snippetId === 'new') {
      // If canceling a new snippet, just go back to list
      const path = isTestingModule ? testingSnippetListPath(currentTeamId) : snippetsPath(currentTeamId);
      history.push(path);
      return;
    }

    // Reload snippet from DB to discard changes
    refreshSnippet().then(() => {
      setIsEditing(false);
      setHasChanges(false);
    });
  }, [snippetId, history, refreshSnippet, isTestingModule, currentTeamId]);

  const onRemoveSnippet = useCallback(async () => {
    if (!snippet || !snippet.snippet_id) return;
    const prompt = 'This snippet will be permanently removed. Are you sure you want to proceed?';
    if (!window.confirm(prompt)) {
      return;
    }
    services.settings
      .deleteSnippet(snippet.snippet_id)
      .then(() => {
        setSuccessMessage('Snippet deleted');
        const path = isTestingModule ? testingSnippetListPath(currentTeamId) : snippetsPath(currentTeamId);
        history.push(path);
      })
      .catch((error) => {
        apm.captureError(error);
      });
  }, [services.settings, snippet, history, currentTeamId, isTestingModule]);

  const onDuplicateSnippet = useCallback(async () => {
    if (!snippet) return;

    const duplicated = snippetUtil.duplicateSnippet(snippet);
    try {
      const savedSnippet = await saveSnippet(duplicated);
      const newSnippetId = savedSnippet?.data?.id?.split(':')[1];
      history.push(snippetViewPath(currentTeamId, newSnippetId, isTestingModule));
    } catch (error) {
      apm.captureError(error);
    }
  }, [snippet, history, currentTeamId, isTestingModule, saveSnippet]);

  const menuActions: Array<MenuContextAction> = useMemo(() => {
    const actions: Array<MenuContextAction> = [];
    actions.push({
      type: 'label',
      label: 'Duplicate Snippet',
      data: {
        icon: 'copy',
        title: 'Duplicate Snippet',
        onClick: onDuplicateSnippet,
      },
    });

    if (snippet && snippet.snippet_id) {
      actions.push({
        type: 'label',
        label: 'Delete Snippet',
        data: {
          icon: 'trash',
          title: 'Delete Snippet',
          onClick: onRemoveSnippet,
        },
      });
    }

    return actions;
  }, [onRemoveSnippet, onDuplicateSnippet, snippet]);

  if (isNotFound) {
    return <NotFound />;
  }

  if (!snippet) {
    return <LoadingScreen />;
  }

  return (
    <>
      <PromptBeforeUnload shouldPrompt={hasChanges} />
      <SnippetToolbar
        teamId={currentTeamId}
        snippet={snippet}
        onSave={onSave}
        hasChanges={hasChanges}
        isEditing={isEditing}
        canEdit={canEdit}
        toggleEdit={() => setIsEditing((prev) => !prev)}
        onCancelEdit={onCancelEdit}
        menuActions={menuActions}
        showSubmitError={hasErrors}
        isTestingModule={isTestingModule}
      />
      <SnippetSidebarWithContent snippet={snippet} currentTeamId={currentTeamId} showPadding={true}>
        <div className="p-4">
          {isEditing && (
            <FieldSetSnippet
              snippet={snippet}
              validationErrors={validationErrors}
              onChange={(updatedSnippet) => {
                setSnippet(updatedSnippet);
                setHasChanges(true);
              }}
              onClose={onCancelEdit}
              onRemove={onRemoveSnippet}
              onSaveSuccess={() => {
                setSuccessMessage('Snippet saved');
                setHasChanges(false);
                setIsEditing(false);
              }}
              testingModule={false}
            />
          )}

          {!isEditing && <PreviewSnippet snippet={snippet} />}

          {successMessage && (
            <div className="mt-4">
              <div className="text-green-600">{successMessage}</div>
            </div>
          )}
        </div>
      </SnippetSidebarWithContent>
    </>
  );
};

export default SnippetDetail;
