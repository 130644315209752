import { Part } from 'shared/lib/types/postgres/manufacturing/types';
import AttachmentImage from '../../components/Attachments/AttachmentImage';
import PartLabel from './PartLabel';
import RestrictedInfo, { RESTRICTED_TEXT } from './RestrictedInfo';

interface PartBadgeProps {
  part?: Part;
  teamId: string;
  showImage?: boolean;
  partRestricted?: boolean;
}

const PartBadge = ({ part, teamId, showImage = true, partRestricted = false }: PartBadgeProps) => {
  if (partRestricted) {
    return (
      <div className="flex gap-x-2 text-base">
        {showImage && (
          <div className="relative w-12 h-12 rounded overflow-hidden">
            <AttachmentImage attachment={undefined} />
          </div>
        )}
        <div className="mt-3.5 ml-1">
          <RestrictedInfo text={RESTRICTED_TEXT} />
        </div>
      </div>
    );
  }
  if (!part) {
    return <div className="mt-3 ml-1 text-sm">[Unknown Part]</div>;
  }
  return (
    <div className="flex gap-x-2 text-base">
      {showImage && (
        <div className="relative w-12 h-12 rounded overflow-hidden">
          <div className="relative w-12 h-12 rounded overflow-hidden">
            <AttachmentImage attachment={part?.image} />
          </div>
        </div>
      )}
      <PartLabel part={part} teamId={teamId} />
    </div>
  );
};

export default PartBadge;
