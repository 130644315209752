import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReviewBlockAttachment from './Blocks/ReviewBlockAttachment';
import StepCommenting from '../StepCommenting';
import ReviewCommenting from '../ReviewCommenting';
import ProcedureFieldNoRedlines from '../ProcedureFieldNoRedlines';
import ReviewProcedureStepHeader from './ReviewProcedureStepHeader';
import ReviewStepDetail from './ReviewStepDetail';
import ExpandCollapseCaret from '../ExpandCollapse/ExpandCollapseCaret';
import { generateHiddenClassString } from '../../lib/styles';
import signoffUtil from 'shared/lib/signoffUtil';
import { AttachmentImageDisplayStyle } from '../Blocks/BlockTypes';
import reviewUtil from '../../lib/reviewUtil';
import ReviewProcedureStepSignoffButton from './ReviewProcedureStepSignoffButton';
import { useReviewContext } from '../../contexts/ReviewContext';
import { useSettings } from '../../contexts/SettingsContext';
import { DefaultStepDetailDefinitions } from '../StepDetailTypes';
import { isEmptyValue } from 'shared/lib/text';
import '../../App.css';
import procedureUtil from '../../lib/procedureUtil';
import ReviewStepConditionals from '../StepConditionals/ReviewStepConditionals';
import DiffContainer from '../Diff/DiffContainer';
import diffUtil from '../../lib/diffUtil';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import useDiff from '../../hooks/useDiff';
import useProcedureAdapter from '../../hooks/useProcedureAdapter';
import ReviewProcedureStepBanner from './ReviewProcedureStepBanner';
import { useProcedureContext } from '../../contexts/ProcedureContext';
import ReviewSettingBadge from './ReviewSettingBadge';
import { isStepSettingEnabled } from 'shared/lib/procedureUtil';
import { faLayerGroup, faRedo, faStepForward, faStrikethrough } from '@fortawesome/free-solid-svg-icons';
import { generatePlaceholderId } from 'shared/lib/idUtil';
import ReviewBlock from './ReviewBlock';

const PROCEDURE_LEVEL_STEP_SETTINGS = [
  {
    setting: 'skip_step_enabled',
    icon: faStepForward,
    tooltipText: 'Skip Step',
  },
  {
    setting: 'repeat_step_enabled',
    icon: faRedo,
    tooltipText: 'Repeat Step',
  },
  {
    setting: 'step_suggest_edits_enabled',
    icon: faStrikethrough,
    tooltipText: 'Suggest Edits',
  },
];

const MOBILE_WIDTH = 550;
const ReviewProcedureStep = ({
  step,
  stepKey,
  defaultFormattedStepKey,
  sectionKey,
  sourceName,
  docState,
  repeatKey,
  onRefChanged,
  onResolveReviewComment,
  onUnresolveReviewComment,
  saveReviewComment,
  comments,
  isHidden,
  isCollapsed,
  onStepCollapse,
  scrollToBufferRem = 0,
  showReviewComments = false,
  showModifiedDiffContainer = true,
  isFullWidth = false,
  hideUnchangedBlocks = false,
  onClickPlaceholder,
}) => {
  const { procedure } = useProcedureContext();

  const { config } = useSettings();

  const { sourceStepConditionalsMap, removedSourceStepConditionalsMap } = useProcedureAdapter();
  const isMounted = useRef(true);
  const stepGotoRef = useRef();
  const { onScrollToDiffRefChanged, isStepRemoved } = useReviewContext();
  const { handleOnScrollToDiffRefChanged } = useDiff({ onScrollToDiffRefChanged });
  const [isMobile, setIsMobile] = useState(window.innerWidth < MOBILE_WIDTH);

  const displayedBlocks = useMemo(() => {
    if (!hideUnchangedBlocks) {
      return step.content;
    }

    return diffUtil.getChangesWithContext({
      items: step.content,
      placeholderGenerator: () => ({
        id: generatePlaceholderId(),
        type: 'placeholder',
      }),
      getIsPlaceholder: (item) => item.type === 'placeholder',
    });
  }, [hideUnchangedBlocks, step.content]);

  useEffect(() => {
    const handleWindowResize = function () {
      setIsMobile(window.innerWidth < MOBILE_WIDTH);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  const isStepCollapsed = isCollapsed;

  // Returns an array of step detail objects.
  const allStepDetails = useMemo(() => {
    const standardStepDetails = Object.values(DefaultStepDetailDefinitions);

    // If config doc or step details do not exist, return standard step details.
    if (!config || !config.step_details) {
      return standardStepDetails;
    }

    const optionalStepDetails = Object.values(config.step_details);

    return [...standardStepDetails, ...optionalStepDetails];
  }, [config]);

  // Returns step details that have a non empty value.
  const visibleStepDetails = useMemo(() => {
    return allStepDetails.filter((stepDetail) => {
      const stepDetailValue = diffUtil.getFieldValue(step, stepDetail.id);

      if (Array.isArray(stepDetailValue)) {
        return stepDetailValue.length !== 0;
      }

      return !isEmptyValue(stepDetailValue);
    });
  }, [step, allStepDetails]);

  const toggleIsStepCollapsed = useCallback(() => {
    if (typeof onStepCollapse === 'function') {
      onStepCollapse(step.id, !isStepCollapsed);
    }
  }, [isStepCollapsed, onStepCollapse, step.id]);

  const onStepRefChanged = useCallback(
    (element) => {
      if (typeof onRefChanged === 'function') {
        onRefChanged(step.id, element);
      }
    },
    [step, onRefChanged]
  );

  const stepRemoved = useMemo(() => isStepRemoved && isStepRemoved(step), [isStepRemoved, step]);

  const formatStepKey = useCallback(
    (displayStyle) => {
      if (!stepKey || !sectionKey) {
        return;
      }

      if (step.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED) {
        return '--';
      }

      return procedureUtil.formatStepKey({
        sectionKey,
        stepKey,
        style: displayStyle,
      });
    },
    [sectionKey, step.diff_change_state, stepKey]
  );

  const stepHeaderHasValues = useMemo(() => procedureUtil.stepHeaderHasValues(step), [step]);

  const hasDependencies = useMemo(() => {
    const hasStepDependencies = Boolean(
      step.dependencies &&
        step.dependencies.length &&
        step.dependencies[0] &&
        step.dependencies[0].dependent_ids &&
        step.dependencies[0].dependent_ids.length
    );
    const stepId = step.id?.endsWith('__removed') ? step.id.replace('__removed', '') : step.id;
    const hasStepConditionals =
      Object.values(sourceStepConditionalsMap?.[stepId] || {}).length > 0 ||
      Object.values(removedSourceStepConditionalsMap?.[stepId] || {}).length > 0;
    return hasStepDependencies || hasStepConditionals;
  }, [step, sourceStepConditionalsMap, removedSourceStepConditionalsMap]);

  const showSignOffButton = useMemo(() => {
    return signoffUtil.isSignoffRequired(step.signoffs);
  }, [step]);

  const formattedStepKey = useMemo(() => {
    if (defaultFormattedStepKey) {
      return defaultFormattedStepKey;
    }
    return formatStepKey(config && config.display_sections_as);
  }, [defaultFormattedStepKey, config, formatStepKey]);

  const substepKeys = useMemo(() => {
    let currentSubStepKey = 1;
    /** @type {{ [blockId: string]: string }} */
    const numbers = {};
    step.content.forEach((content) => {
      const { substepKey: key, nextNumber } = procedureUtil.displaySubStepKey(
        formattedStepKey,
        currentSubStepKey,
        sharedDiffUtil.getDiffValue(content, 'type', 'new'),
        content.diff_change_state
      );
      numbers[content.id] = key;
      currentSubStepKey = nextNumber;
    });
    return numbers;
  }, [step.content, formattedStepKey]);

  const toTheSideImages = useMemo(() => {
    const toTheSideImages = [];
    if (step.content) {
      step.content.forEach((content) => {
        const type = /** @type {string} */ (sharedDiffUtil.getDiffValue(content, 'type', 'new') ?? '');
        if (
          type.toLowerCase() === 'attachment' &&
          sharedDiffUtil.getDiffValue(content, 'display_style', 'new') === AttachmentImageDisplayStyle.ToTheSide
        ) {
          toTheSideImages.push(content);
        }
      });
    }
    return toTheSideImages;
  }, [step.content]);

  const hasToTheSideImages = useMemo(() => {
    return toTheSideImages.length > 0;
  }, [toTheSideImages]);

  // Makes the images in line if they are the only step contents
  const onlyHasToTheSideImages = useMemo(() => {
    return step.content && toTheSideImages.length === step.content.length;
  }, [toTheSideImages, step.content]);

  const shouldDisplayToTheSideImages = useMemo(() => {
    return hasToTheSideImages && !onlyHasToTheSideImages && !isMobile;
  }, [hasToTheSideImages, onlyHasToTheSideImages, isMobile]);

  const colSpanValue = useMemo(() => {
    return shouldDisplayToTheSideImages ? '2' : '3';
  }, [shouldDisplayToTheSideImages]);

  const stepDataTestId = useMemo(() => {
    const changeInfo = [
      ARRAY_CHANGE_SYMBOLS.ADDED,
      ARRAY_CHANGE_SYMBOLS.MODIFIED,
      ARRAY_CHANGE_SYMBOLS.REMOVED,
    ].includes(step.diff_change_state)
      ? `_${step.diff_change_state}`
      : '';
    return `step-${formatStepKey(config && config.display_sections_as)}-${repeatKey || 0}${changeInfo}`;
  }, [config, repeatKey, formatStepKey, step.diff_change_state]);

  const hasSignoffLabels = useMemo(() => {
    return Boolean(
      step.signoffs && step.signoffs.length && signoffUtil.getAllOperatorsInSignoffs(step.signoffs).length
    );
  }, [step]);

  const stepBannerEnabled = useMemo(() => {
    return hasDependencies || step.timer || step.duration || hasSignoffLabels;
  }, [step.timer, step.duration, hasDependencies, hasSignoffLabels]);

  const diffChangeState = useMemo(() => {
    if (!showModifiedDiffContainer && step.diff_change_state === ARRAY_CHANGE_SYMBOLS.MODIFIED) {
      return ARRAY_CHANGE_SYMBOLS.UNCHANGED;
    }

    return step.diff_change_state;
  }, [showModifiedDiffContainer, step.diff_change_state]);

  const signoffRatio = useMemo(() => {
    if (!step.signoffs) {
      return;
    }

    const completeSignoffs = 0;
    const totalSignoffs = step.signoffs.length;

    return `${completeSignoffs}/${totalSignoffs}`;
  }, [step]);

  const renderStepDetails = (stepDetail) => {
    if (stepDetail.id === 'duration' && typeof step[stepDetail.id] === 'object') {
      // No-op
    } else if (stepDetail.id === 'timer' && typeof step[stepDetail.id] === 'object') {
      // No-op
    } else {
      return (
        <ReviewStepDetail
          key={stepDetail.id}
          icon={stepDetail.icon}
          label={stepDetail.title}
          value={diffUtil.getFieldValue(step, stepDetail.id)}
          diffChangeState={diffUtil.getDiffChangeStateForFieldValue(step, stepDetail.id)}
        />
      );
    }
  };

  return (
    <tbody data-testid={stepDataTestId}>
      <tr>
        <td colSpan={isFullWidth ? 1 : 3}>
          <DiffContainer
            label="Step"
            diffChangeState={diffChangeState}
            onScrollToDiffRefChanged={(element) => handleOnScrollToDiffRefChanged(step.id, element)}
          >
            <table className="table-fixed w-full border-collapse" cellSpacing="0" cellPadding="0" border={0}>
              <thead>
                <tr>
                  {!isFullWidth && <th className="w-8" />}
                  <th className="w-full" />
                  {!isFullWidth && <th className="w-8" />}
                </tr>
              </thead>
              <tbody aria-label="Step" role="region">
                <tr>
                  {!isFullWidth && <td />}
                  <td>
                    <table className="table-fixed w-full border-collapse" cellSpacing="0" cellPadding="0" border={0}>
                      <thead>
                        <tr>
                          <th className="w-4"></th>
                          <th className="w-full"></th>
                          <th className="w-0"></th>
                        </tr>
                      </thead>
                      {stepHeaderHasValues && (
                        <>
                          <tbody>
                            <tr>
                              <td>
                                <div
                                  className="w-0 h-0"
                                  ref={stepGotoRef}
                                  style={{ scrollMarginTop: `${scrollToBufferRem}rem` }}
                                ></div>
                              </td>
                            </tr>
                          </tbody>
                          <ReviewProcedureStepHeader
                            header={step.headers[0]}
                            onRefChanged={onRefChanged}
                            scrollMarginTopValueRem={scrollToBufferRem}
                          />
                        </>
                      )}
                      {stepBannerEnabled && (
                        <ReviewProcedureStepBanner
                          step={step}
                          hasDependencies={hasDependencies}
                          onRefChanged={onRefChanged}
                          scrollMarginTopValueRem={scrollToBufferRem}
                          updateStepDetail={undefined}
                          baseRunningCondition={false}
                          isStepActive={false}
                        />
                      )}
                      <tbody
                        aria-label="Step Body"
                        className={`shadow-lg shadow rounded border-b-2 border-x-2 ${
                          !stepBannerEnabled && 'border-t-2'
                        } border-app-gray-400 bg-white`}
                        ref={stepHeaderHasValues ? null : stepGotoRef}
                        style={{ scrollMarginTop: `${scrollToBufferRem}rem` }}
                        role="region"
                      >
                        {/* Title and completion checkbox row */}
                        <tr>
                          <td className="align-top">
                            <div className={generateHiddenClassString('flex py-2', isHidden)}>
                              <div className="h-9">
                                <ExpandCollapseCaret
                                  isExpanded={!isStepCollapsed}
                                  onClick={toggleIsStepCollapsed}
                                  ariaLabel="Expand Collapse Step Toggle"
                                  isHidden={!onStepCollapse}
                                />
                              </div>
                            </div>
                          </td>
                          <td colSpan={2} className="break-words">
                            <div
                              ref={onStepRefChanged}
                              className={generateHiddenClassString(
                                'ml-4 py-2 flex items-start app-border-gray-4 page-break',
                                isHidden
                              )}
                              style={{ scrollMarginTop: `${scrollToBufferRem}rem` }}
                            >
                              {/* Step key */}
                              <button
                                className="h-8 w-8 flex justify-center items-center rounded-full bg-black"
                                onClick={toggleIsStepCollapsed}
                              >
                                <span className="font-bold text-xs text-white">
                                  {formattedStepKey ? formattedStepKey : '--'}
                                </span>
                              </button>
                              {/* Step title bar */}
                              <div className="ml-4 w-8 flex flex-row grow justify-between">
                                {/* Step title bar left side content */}
                                <div className="flex grow font-semibold items-center">
                                  <ProcedureFieldNoRedlines
                                    fieldName="name"
                                    fieldValue={step.name}
                                    onLabelClick={toggleIsStepCollapsed}
                                    isBold={true}
                                    redlines={[]}
                                  />
                                  {repeatKey && (
                                    <div className="whitespace-nowrap flex-none self-start mt-1.5">
                                      <span className="ml-4 text-sm text-gray-600">
                                        <FontAwesomeIcon icon="redo" />
                                      </span>
                                      <span className="ml-1 text-sm font-bold text-gray-600 italic">
                                        Repeat {repeatKey}
                                      </span>
                                    </div>
                                  )}
                                </div>

                                {/* TODO (jon): refactor this gnarly jsx */}
                                {/* Step title bar right side content */}
                                <div className="flex items-start gap-x-2">
                                  {PROCEDURE_LEVEL_STEP_SETTINGS.map(({ setting, tooltipText, icon }) => {
                                    return (
                                      <ReviewSettingBadge
                                        key={setting}
                                        tooltipText={tooltipText}
                                        icon={icon}
                                        oldSettingValue={isStepSettingEnabled(
                                          sharedDiffUtil.getDiffValue(step, setting, 'old'),
                                          sharedDiffUtil.getDiffValue(procedure, setting, 'old')
                                        )}
                                        newSettingValue={isStepSettingEnabled(
                                          sharedDiffUtil.getDiffValue(step, setting, 'new'),
                                          sharedDiffUtil.getDiffValue(procedure, setting, 'new')
                                        )}
                                        oldVisibleSettingValue={
                                          !isStepSettingEnabled(
                                            undefined,
                                            sharedDiffUtil.getDiffValue(procedure, setting, 'old')
                                          )
                                        }
                                        newVisibleSettingValue={
                                          !isStepSettingEnabled(
                                            undefined,
                                            sharedDiffUtil.getDiffValue(procedure, setting, 'new')
                                          )
                                        }
                                      />
                                    );
                                  })}
                                  <ReviewSettingBadge
                                    tooltipText="Batch Step"
                                    icon={faLayerGroup}
                                    oldSettingValue={sharedDiffUtil.getDiffValue(step, 'runAsBatch', 'old') === true}
                                    newSettingValue={sharedDiffUtil.getDiffValue(step, 'runAsBatch', 'new') === true}
                                    oldVisibleSettingValue={true}
                                    newVisibleSettingValue={true}
                                  />

                                  {/* Content with checkbox for signoffs */}
                                  <div className="flex flex-col items-end px-2">
                                    {step.requires_previous && (
                                      <span className="ml-4 mr-3 font-semibold text-xs text-gray-600 whitespace-nowrap">
                                        Requires Previous Step
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        {!isStepCollapsed && (visibleStepDetails.length > 0 || hasDependencies) && (
                          <tr>
                            <td colSpan={3}>
                              <div className={generateHiddenClassString('', isStepCollapsed)}></div>
                              <div className={generateHiddenClassString(`flex flex-row flex-nowrap`, isStepCollapsed)}>
                                <div className="w-11 mr-1"></div>
                                <div className="flex flex-row flex-wrap gap-x-3 items-start text-sm font-medium">
                                  {stepRemoved && hasDependencies && (
                                    <div className="flex">
                                      <FontAwesomeIcon className="text-gray-500 self-center mr-1" icon="info-circle" />
                                      <div className="flex flex-row gap-x-1 py-1">
                                        Dependencies are no longer valid.
                                      </div>
                                    </div>
                                  )}
                                  {visibleStepDetails.map((stepDetail) => (
                                    <div key={stepDetail.id}>{renderStepDetails(stepDetail)}</div>
                                  ))}
                                </div>
                              </div>
                            </td>
                          </tr>
                        )}
                        {!isStepCollapsed && (
                          <tr>
                            <td colSpan={3} className="align-top">
                              <table
                                className="table-fixed w-full border-collapse"
                                cellSpacing="0"
                                cellPadding="0"
                                border={0}
                              >
                                <thead>
                                  {shouldDisplayToTheSideImages && (
                                    <tr>
                                      <th className="w-4"></th>
                                      <th className="w-2/4"></th>
                                      <th className="w-2/4"></th>
                                    </tr>
                                  )}
                                  {!shouldDisplayToTheSideImages && (
                                    <tr>
                                      <th className="w-4"></th>
                                      <th className="w-auto"></th>
                                    </tr>
                                  )}
                                </thead>
                                <tbody>
                                  <tr>
                                    <td colSpan={colSpanValue} className="align-top">
                                      <table
                                        className="table-fixed w-full border-collapse"
                                        cellSpacing="0"
                                        cellPadding="0"
                                        border={0}
                                      >
                                        <thead>
                                          <tr>
                                            <th className="w-4"></th>
                                            <th className="w-auto"></th>
                                            <th className="w-64"></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {/* Step content blocks */}
                                          {displayedBlocks.map((block) => (
                                            <ReviewBlock
                                              block={block}
                                              onClickPlaceholder={onClickPlaceholder}
                                              substepKeys={substepKeys}
                                              isHidden={isStepCollapsed}
                                              onRefChanged={onRefChanged}
                                              scrollToBufferRem={scrollToBufferRem}
                                              docState={docState}
                                              sourceName={sourceName}
                                              isValid={!stepRemoved}
                                              onlyHasToTheSideImages={onlyHasToTheSideImages}
                                              isMobile={isMobile}
                                              toTheSideImages={toTheSideImages}
                                              isStepComplete={false}
                                            />
                                          ))}
                                        </tbody>
                                      </table>
                                    </td>
                                    {shouldDisplayToTheSideImages && (
                                      <td className="align-top">
                                        <table
                                          className="table-fixed w-full border-collapse"
                                          cellSpacing="0"
                                          cellPadding="0"
                                          border={0}
                                        >
                                          <tbody>
                                            {/* Render side by side images */}
                                            {toTheSideImages.map((content) => (
                                              <ReviewBlockAttachment
                                                key={content.id}
                                                attachment={content}
                                                isHidden={isStepCollapsed}
                                                isSpacerHidden={true}
                                                blockLabel={substepKeys[content.id]}
                                              />
                                            ))}
                                          </tbody>
                                        </table>
                                      </td>
                                    )}
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        )}

                        {/* Divider between content and actions */}
                        {!isStepCollapsed && (
                          <tr>
                            <td colSpan={3}>
                              <div className="h-px bg-gray-300 my-2 mx-4"></div>
                            </td>
                          </tr>
                        )}

                        {/* Step signoff and conditional information */}
                        {!isStepCollapsed && (
                          <tr>
                            <td colSpan={3}>
                              <div className="flex flex-row flex-nowrap justify-between items-center mx-4">
                                {showSignOffButton && (
                                  <div className="flex flex-col gap-y-1">
                                    <div className="font-semibold text-xs text-gray-600">Signoff ({signoffRatio})</div>
                                    <div className="flex flex-row flex-wrap gap-x-2 gap-y-2">
                                      {step.signoffs.map((signoff) => (
                                        <ReviewProcedureStepSignoffButton
                                          key={signoff.id}
                                          signoff={signoff}
                                          isStepCollapsed={isStepCollapsed}
                                        />
                                      ))}
                                    </div>
                                  </div>
                                )}
                                {step.conditionals && step.conditionals.length > 0 && (
                                  <div className="flex flex-row flex-wrap gap-x-3 text-sm font-medium">
                                    <ReviewStepConditionals step={step} conditionals={step.conditionals} />
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        )}

                        {/* Review commenting row */}
                        {!isStepCollapsed && showReviewComments && (
                          <>
                            <tr>
                              <td></td>
                              <td colSpan={2}>
                                <div className={generateHiddenClassString('mb-2', isStepCollapsed)}></div>
                                <div className={generateHiddenClassString('mb-2 page-break', isStepCollapsed)}>
                                  <ReviewCommenting
                                    stepId={step.id}
                                    onResolveReviewComment={onResolveReviewComment}
                                    onUnresolveReviewComment={onUnresolveReviewComment}
                                    saveReviewComment={saveReviewComment}
                                    reviewComments={reviewUtil.getStepReviewComments(comments, step.id)}
                                  />
                                </div>
                              </td>
                            </tr>
                          </>
                        )}

                        {/* Extra padding if there is no run comment (isPreviewMode) and no review comment */}
                        {!isStepCollapsed && !showReviewComments && (
                          <tr className="h-3">
                            <td colSpan={3}></td>
                          </tr>
                        )}

                        {/* Step commenting row */}
                        {!isStepCollapsed && (
                          <tr>
                            <td></td>
                            <td colSpan={2}>
                              <div className={generateHiddenClassString('mb-2', isStepCollapsed)}></div>
                              <div className={generateHiddenClassString('mb-2 page-break', isStepCollapsed)}>
                                <StepCommenting
                                  redlineComments={step.redline_comments}
                                  onRefChanged={onRefChanged}
                                  isRedlineActive={false}
                                  isEditing={false}
                                  onEditRedlineStepComment={() => null}
                                  onDirtyRedlineCommentChanged={() => null}
                                  reasonRedlineIsDisabled={null}
                                />
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                      <tbody>
                        <tr className="h-4"></tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </DiffContainer>
        </td>
      </tr>
    </tbody>
  );
};

export default ReviewProcedureStep;
