import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import runUtil from 'shared/lib/runUtil';
import { ToolInstance } from 'shared/lib/types/api/manufacturing/tools/models';
import { StepState } from 'shared/lib/types/couch/procedures';
import { RunToolCheckInBlock, RunToolCheckOutBlock } from 'shared/lib/types/views/procedures';
import { useDatabaseServices } from '../../../contexts/DatabaseContext';
import { DatabaseServices } from '../../../contexts/proceduresSlice';
import apm from '../../../lib/apm';
import { NumberSelectOption as SelectOption } from '../../../lib/formik';
import { toolDetailPath } from '../../../lib/pathUtil';
import useTool from '../../hooks/useTool';
import fromClient from '../../lib/tools/fromClient';
import toDisplay from '../../lib/tools/toDisplay';
import ThumbnailImageDisplay from '../../../components/Attachments/ThumbnailImageDisplay';
import CompletedToolInstance from './CompletedToolInstance';
import SelectToolInstance from './SelectToolInstance';
import { isToolInstanceAvailable } from './utils';

interface RunToolCheckOutInProps {
  content: RunToolCheckOutBlock | RunToolCheckInBlock;
  type: 'out' | 'in';
  onRecordValuesChanged?: (
    contentId: string,
    recorded: RunToolCheckOutBlock['recorded'] | RunToolCheckInBlock['recorded']
  ) => void;
  recorded?: RunToolCheckOutBlock['recorded'] | RunToolCheckInBlock['recorded'];
  isEnabled: boolean;
  stepState: StepState | undefined;
  teamId: string;
}

const RunToolCheckOutIn = ({
  content,
  type,
  onRecordValuesChanged,
  recorded,
  isEnabled,
  stepState,
  teamId,
}: RunToolCheckOutInProps) => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const { tool } = useTool({ toolId: recorded?.tool_id ?? content.tool_id });
  const [toolInstancesForTool, setToolInstancesForTool] = useState<Array<ToolInstance>>();

  const value = useMemo(() => recorded ?? content, [content, recorded]);

  const isStepStateEnded = useMemo(() => runUtil.isStepStateEnded(stepState), [stepState]);

  useEffect(() => {
    (async () => {
      const clientToolInstances = await services.tools.getToolInstances();
      setToolInstancesForTool(
        clientToolInstances
          .filter((clientToolInstance) => clientToolInstance.tool_id === content.tool_id)
          .map(fromClient.toToolInstance)
      );
    })().catch((err) => apm.captureError(err));
    // content is needed so that other step check-outs update the available check-ins, and vice-versa
  }, [content, services.tools]);

  const toolInstance = useMemo(() => {
    if (!toolInstancesForTool || !recorded) {
      return undefined;
    }
    return toolInstancesForTool.find((toolInstance) => toolInstance.id === recorded.tool_instance_id);
  }, [recorded, toolInstancesForTool]);

  const onToolInstanceChange = useCallback(
    (toolInstanceOption: SelectOption) => {
      const newValue: RunToolCheckOutBlock['recorded'] | RunToolCheckInBlock['recorded'] = {
        ...value,
        tool_instance_id: toolInstanceOption?.value,
      };
      if (onRecordValuesChanged) {
        onRecordValuesChanged(value.id, newValue);
      }
    },
    [onRecordValuesChanged, value]
  );

  if (!tool) {
    return null;
  }

  return (
    <tr>
      <td></td>
      <td colSpan={2}>
        <div className="mt-3 ml-4">
          <div className="flex flex-row flex-wrap">
            <div className="w-72">
              <div className="field-title">Tool for {`${type === 'out' ? 'Check-Out' : 'Check-In'}`}</div>
              <div className="flex pt-2">
                <div>
                  <ThumbnailImageDisplay size="sm" attachment={toDisplay.fromToolImageProps(tool)} />
                </div>
                <div className="pl-1 pr-1 pt-[2px] truncate">
                  <Link className="text-blue-600" to={toolDetailPath(teamId, tool.id)}>
                    {tool.tool_number}
                  </Link>{' '}
                  {tool.name}
                </div>
              </div>
            </div>
            <div className="w-2" />
            <div>
              <div className="field-title">Tool Instance</div>
              <div className="flex">
                {!isStepStateEnded && (
                  <div className="w-72">
                    <SelectToolInstance
                      availableToolInstances={toolInstancesForTool?.filter((toolInstance) =>
                        isToolInstanceAvailable(toolInstance, type)
                      )}
                      tool={tool}
                      toolInstance={toolInstance}
                      onToolInstanceChange={onToolInstanceChange}
                      isEnabled={isEnabled}
                    />
                  </div>
                )}
                {isStepStateEnded && (
                  <CompletedToolInstance teamId={teamId} tool={tool} toolInstance={toolInstance} type={type} />
                )}
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default RunToolCheckOutIn;
