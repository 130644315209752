import React from 'react';
import { generateHiddenClassString } from '../../lib/styles';
import ButtonActionIcon from '../ButtonActionIcon';
import Spacer from '../Spacer';
import SubstepNumber from '../SubstepNumber';
import { TypedProcedureBlockProps } from './BlockTypes';

/*
 * Component for rendering a Block of type FieldInput within a procedure context.
 * Conforms to TypedProcedureBlock, see comments in useBlockComponents.js
 */
const FieldInputProcedureBlock = React.memo<TypedProcedureBlockProps>(
  ({ isHidden, actions, children, isSpacerHidden, hasExtraVerticalSpacing, blockLabel }) => (
    // Layout is intended for CSS grid template defined in Run.js and Procedure.js
    <tr>
      <td colSpan={4}>
        <>
          <div className={generateHiddenClassString('', isHidden)}></div>
          <div className={generateHiddenClassString('mt-2 mb-2 mr-8 flex items-start page-break', isHidden)}>
            <Spacer isHidden={isSpacerHidden} hasExtraVerticalSpacing={hasExtraVerticalSpacing} />

            <SubstepNumber blockLabel={blockLabel} isHidden={isSpacerHidden} hasExtraVerticalSpacing={true} />
            {/* Render child content */}
            {children}

            {/* Render action buttons */}
            {actions &&
              actions.map((action) => (
                <div key={action.ariaLabel} className="ml-1 self-start py-2">
                  <ButtonActionIcon
                    icon={action.icon}
                    onAction={action.onAction}
                    ariaLabel={action.ariaLabel}
                    pendingAction={action.pendingAction}
                    disabled={false}
                    iconType={action.iconType ?? undefined}
                    pendingDotMatchesIcon={action.pendingDotMatchesIcon}
                  />
                </div>
              ))}
          </div>
        </>
      </td>
    </tr>
  )
);

export default FieldInputProcedureBlock;
