import { copyStepWithoutActiveContent } from './runUtil';
import { IRedlineId } from './types/couch/procedures';
import {
  RedlinedStep,
  RunAddedStep,
  RunHeaderBlockRedline,
  RunHeaderFieldRedline,
  RunRedlineComment,
  RunStep,
  RunStepBlockRedline,
  RunStepFieldRedline,
  RunStepFullRedline,
  RunStepRedline,
} from './types/views/procedures';
import { generateRedlineDocId } from './idUtil';
import lodash from 'lodash';
import {
  AddedStepRedline,
  FullStepRedline,
  HeaderBlockRedline,
  HeaderFieldRedline,
  Redline,
  RedlineComment,
  StepBlockRedline,
  StepFieldRedline,
  StepRedline,
} from './types/views/redlines';

export type RunRedlineFromRedlineT<RedlineT> =
  RedlineT extends HeaderFieldRedline
    ? RunHeaderFieldRedline
    : RedlineT extends HeaderBlockRedline
    ? RunHeaderBlockRedline
    : RedlineT extends StepFieldRedline
    ? RunStepFieldRedline
    : RedlineT extends StepBlockRedline
    ? RunStepBlockRedline
    : RedlineT extends RedlineComment
    ? RunRedlineComment
    : RedlineT extends AddedStepRedline
    ? RunAddedStep
    : RedlineT extends FullStepRedline
    ? RunStepFullRedline
    : never;

export const REDLINE_STATE = {
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  RESOLVED: 'resolved',
  UNRESOLVED: 'unresolved',
};

export const REDLINE_TYPE = {
  HEADER_REDLINE: 'header_redline',
  REDLINE_COMMENT: 'redline_comment',
  ADDED_STEP: 'added_step',
  FULL_STEP_REDLINE: 'full_step_redline',
  /** @deprecated Only full step redlines should be created from now on. Use isStepRedline to determine if a redline is a step redline. */
  STEP_REDLINE: 'step_redline',
} as const;

export const getRedlineFromDoc = <T extends Redline>(
  redlineDoc: T | Partial<T>
): RunRedlineFromRedlineT<T> => {
  return (redlineDoc['redline'] ??
    redlineDoc[redlineDoc.type ?? 'redline']) as RunRedlineFromRedlineT<T>;
};

export const getRedlineId = <T extends IRedlineId>(
  redline: T
): string | undefined => redline.redline_id ?? redline.redlineId;

export const isRedlineAddedStep = (step: RunStep): boolean => {
  return (
    (step as RunAddedStep).created_during_run &&
    !(step as RunAddedStep).run_only
  );
};

export const convertToRedlineAddedStep = (
  runAddedStep: RunAddedStep
): RunAddedStep => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const redlineAddedStep = copyStepWithoutActiveContent(runAddedStep);
  if (!redlineAddedStep.redline_id) {
    redlineAddedStep.redline_id = getRedlineId(redlineAddedStep);
  }
  delete redlineAddedStep.repeat_step_enabled;
  return redlineAddedStep;
};

export const getCleanedRedlineStep = (
  step: RunStep | RedlinedStep
): RedlinedStep => {
  return lodash.omit(copyStepWithoutActiveContent(step), [
    'id',
    'redlines',
    'redline_comments',
    'redline_id',
  ]) as RedlinedStep;
};

/*
 * Helper method to create a new RedlineStep object.
 *
 * step: The Step object for the redline.
 * userId: The id of the user making the change.
 * pending: Indicates whether the redline is pending approval.
 * returns: A valid Step object with a timestamp of now.
 */
export const newStepRedline = ({
  step,
  userId,
  pending,
  fieldOrBlockMetadata,
  isRedline,
  createdAt = new Date().toISOString(),
  comments,
  type,
}: {
  step: RunStep;
  userId: string;
  pending?: boolean;
  fieldOrBlockMetadata?: object;
  isRedline?: boolean;
  createdAt?: string;
  comments?: Array<RunRedlineComment>;
  type?: (typeof REDLINE_TYPE)[keyof typeof REDLINE_TYPE];
}): RunStepRedline => {
  // Remove any active content before returning new redline step.
  const updated = getCleanedRedlineStep(step);

  return {
    redline_id: generateRedlineDocId(),
    createdAt,
    userId,
    step: updated,
    ...(pending && { pending }), // Include pending if pending is true
    ...fieldOrBlockMetadata,
    // Include run_only if isRedline is false
    ...(isRedline === false && { run_only: !isRedline }),
    ...(comments && { comments }),
    ...(type && { type }),
  };
};

export const isStepRedline = (redline: Redline): redline is StepRedline => {
  return (
    redline.type === REDLINE_TYPE.STEP_REDLINE ||
    redline.type === REDLINE_TYPE.FULL_STEP_REDLINE
  );
};

export const isFieldRedline = (
  redline: Redline
): redline is StepFieldRedline | FullStepRedline => {
  const redlineFromDoc = getRedlineFromDoc(redline);

  return 'field' in redline || 'field' in redlineFromDoc;
};
