import { useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FieldStepTimer from '../FieldStepTimer';
import FieldStepDuration from '../FieldStepDuration';
import { Duration } from 'luxon';
import ReviewDependencies from './ReviewDependencies';
import ProcedureFieldDiff from '../ProcedureFieldDiff';
import sharedDiffUtil from 'shared/lib/diffUtil';
import signoffUtil from 'shared/lib/signoffUtil';

const ReviewProcedureStepBanner = ({
  step,
  hasDependencies,
  onRefChanged,
  scrollMarginTopValueRem,
  updateStepDetail,
  baseRunningCondition,
  isStepActive,
}) => {
  const bgAndBorderColor = useMemo(() => {
    return 'bg-white border-x-2 border-t-2 border-app-gray-400';
  }, []);

  const timerMessage = useCallback(
    (getNew = true) => {
      if (!step.timer) {
        return '';
      }

      let output = 'Start Timer';
      if (step.timer.completed) {
        output = 'Timer Elapsed';
      } else if (step.timer.started_at) {
        output = 'Timer Running';
      }

      let timeLeft = step.timer.time_left;
      if (!timeLeft) {
        return output;
      }

      if (getNew) {
        timeLeft = sharedDiffUtil.getDiffValue(step.timer, 'time_left', 'new');
      } else {
        timeLeft = sharedDiffUtil.getDiffValue(step.timer, 'time_left', 'old');
      }

      const formattedTimeLeft = Duration.fromISO(timeLeft).toFormat('hh:mm:ss');
      return `${output} (${formattedTimeLeft})`;
    },
    [step.timer]
  );

  const signoffsNeeded = useMemo(() => {
    const incompleteSignoffOperators = signoffUtil
      .getIncompleteSignoffs(step)
      .map((signoff) => signoff.operators.join(', '))
      .join(', ');

    return (
      <div className="flex flex-row flex-nowrap gap-x-2 items-center">
        {incompleteSignoffOperators && (
          <div className="flex flex-row flex-nowrap gap-x-1 text-xs items-center">
            <FontAwesomeIcon icon="headset" />
            <div className="text-gray-600">Needs</div>
            <div className="font-semibold">{incompleteSignoffOperators}</div>
          </div>
        )}
      </div>
    );
  }, [step]);

  return (
    <tbody
      data-testid="header"
      className={`${bgAndBorderColor} shadow z-0`}
      ref={onRefChanged}
      style={{ scrollMarginTop: `${scrollMarginTopValueRem}rem` }}
      aria-label="Step Header"
      role="region"
    >
      <tr>
        <td colSpan={3}>
          <div className="flex flex-col">
            <div className="py-2 px-2 w-full flex flex-row justify-between text-xs items-center gap-x-1">
              <div className="flex flex-row flex-grow items-center">
                {step.requires_previous && (
                  <span className="ml-4 mr-3 font-semibold text-xs text-gray-600 whitespace-nowrap">
                    Requires Previous Step
                  </span>
                )}
                {step['duration'] && typeof step['duration'] === 'object' && (
                  <div className="flex flex-row">
                    <FontAwesomeIcon icon="stopwatch" />
                    <div className="ml-1 text-gray-600">{step.duration.started_at ? 'Step Started' : 'Start Step'}</div>
                  </div>
                )}
                {step['timer'] && typeof step['timer'] === 'object' && (
                  <div className="flex flex-row">
                    <FontAwesomeIcon icon="hourglass-start" />
                    <div className="ml-1 mr-3 text-gray-600">
                      <ProcedureFieldDiff original={timerMessage()} redlined={timerMessage(false)} />
                    </div>
                  </div>
                )}
                {hasDependencies && (
                  <div className="ml-2">
                    <ReviewDependencies dependencies={step.dependencies} blockId={step.id} />
                  </div>
                )}
              </div>
              <div className="flex flex-row items-center">
                {step['duration'] && typeof step['duration'] === 'object' && (
                  <FieldStepDuration
                    value={step['duration']}
                    disabled={!baseRunningCondition}
                    hideStartButton={typeof step['timer'] === 'object'}
                    updateStepDetail={updateStepDetail}
                    isPaused={!isStepActive}
                  />
                )}
                {step['timer'] && typeof step['timer'] === 'object' && (
                  <FieldStepTimer
                    value={step['timer']}
                    disabled={!baseRunningCondition}
                    updateStepDetail={updateStepDetail}
                  />
                )}
              </div>

              {signoffsNeeded}
            </div>

            <div className="h-px bg-gray-200"></div>
          </div>
        </td>
      </tr>
    </tbody>
  );
};

export default ReviewProcedureStepBanner;
