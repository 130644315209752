import { Attachment } from '../../hooks/useAttachment';
import AttachmentImage from './AttachmentImage';
import { SIZE } from './AttachmentListPreview';

interface ThumbnailImageDisplayProps {
  attachment: Attachment | undefined;
  size: keyof typeof SIZE;
}

const ThumbnailImageDisplay = ({ attachment, size }: ThumbnailImageDisplayProps) => {
  return (
    <div className={`${SIZE[size]} mx-1 rounded overflow-hidden`}>
      <AttachmentImage attachment={attachment} />
    </div>
  );
};

export default ThumbnailImageDisplay;
