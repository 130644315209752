import React, { useCallback, useMemo } from 'react';
import { useRunContext } from '../contexts/RunContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SearchFilter from './lib/SearchFilter';
import Tooltip from '../elements/Tooltip';
import stickyHeaderUtil from '../lib/stickyHeaderUtil';

type RunFilterProps = {
  showLabel?: boolean;
};

export default function RunFilter({ showLabel }: RunFilterProps) {
  const { filteredByOperators, updateFilteredByOperators, allOperatorRolesInRun, isSingleCardEnabled } =
    useRunContext();

  const isFiltered = useMemo(() => {
    return Boolean(filteredByOperators.length);
  }, [filteredByOperators]);

  const isFilterDisabled = useMemo(() => {
    if (isSingleCardEnabled) {
      return true;
    } else {
      return !allOperatorRolesInRun.length;
    }
  }, [allOperatorRolesInRun, isSingleCardEnabled]);

  const filterOptions = useMemo(() => {
    return allOperatorRolesInRun.map((operatorRole) => ({
      label: operatorRole,
      id: operatorRole,
    }));
  }, [allOperatorRolesInRun]);

  const _updateFilteredByOperators = useCallback(
    (selectedOperatorRoles) => {
      updateFilteredByOperators(Array.from(selectedOperatorRoles));
    },
    [updateFilteredByOperators]
  );

  const _selectedFilterIds = useMemo(() => {
    return new Set(filteredByOperators);
  }, [filteredByOperators]);

  const tooltipContent = useMemo(() => {
    if (filteredByOperators.length) {
      return <div>Filtered by {filteredByOperators.join(', ')}</div>;
    } else {
      return <div>Filter by operator roles</div>;
    }
  }, [filteredByOperators]);

  return (
    <SearchFilter
      filterOptions={filterOptions}
      selectedFilterIds={_selectedFilterIds}
      ariaLabel="Filter By Operator Role Menu"
      onFilterIdsChange={_updateFilteredByOperators}
      isDisabled={isFilterDisabled}
      filterButtonComponent={
        <Tooltip content={tooltipContent} position="bottom">
          <button className={stickyHeaderUtil.getButtonClassNames(isFilterDisabled)} disabled={isFilterDisabled}>
            <div className="flex items-center relative">
              <FontAwesomeIcon icon="filter" />
              {isFiltered && (
                <div className="absolute -top-2.5 -right-2.5 text-red-500">
                  <FontAwesomeIcon icon="plus-circle" size="sm" />
                </div>
              )}
            </div>
            {showLabel && <div className="ml-2">Filter by operator roles</div>}
          </button>
        </Tooltip>
      }
    />
  );
}
