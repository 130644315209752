import { useMemo } from 'react';
import { Snippet } from 'shared/lib/types/views/procedures';
import ProcedureSection from '../ProcedureSection';
import ProcedureStep from '../ProcedureStep';
import useExpandCollapse from '../../hooks/useExpandCollapse';
import { ProcedureContextProvider } from '../../contexts/ProcedureContext';
import { RunContextProvider } from '../../contexts/RunContext';
import useTelemetryParameters from '../../hooks/useTelemetryParameters';
import procedureUtil from '../../lib/procedureUtil';
import { newRunDoc } from 'shared/lib/runUtil';

const IRRELEVANT_USER_ID = 'irrelevant_user_id';

const generateProcedureShellForSectionSnippet = (sectionSnippet) => {
  const proc = procedureUtil.newProcedure(IRRELEVANT_USER_ID);
  proc.sections = [sectionSnippet];
  return proc;
};

const generateProcedureShellForStepSnippet = (stepSnippet) => {
  const proc = procedureUtil.newProcedure(IRRELEVANT_USER_ID);
  proc.sections = [
    {
      id: '',
      name: '',
      steps: [stepSnippet],
    },
  ];
  return proc;
};

interface PreviewSnippetProps {
  snippet: Snippet;
}

const PreviewSnippet = ({ snippet }: PreviewSnippetProps) => {
  const { isCollapsedMap } = useExpandCollapse();

  const sourceName = useMemo(() => {
    if (snippet && snippet.name) return snippet.name;
    return 'Untitled snippet';
  }, [snippet]);

  const procedure = useMemo(() => {
    return snippet.snippet_type === 'section'
      ? generateProcedureShellForSectionSnippet(snippet.section)
      : generateProcedureShellForStepSnippet(snippet.step);
  }, [snippet]);

  const run = useMemo(() => {
    const runDoc = newRunDoc({ procedure, userId: IRRELEVANT_USER_ID, userParticipantType: 'viewer' });
    // Remove actual run state for a clean preview
    delete runDoc.state;
    return runDoc;
  }, [procedure]);

  const { fetchedTelemetryParameters } = useTelemetryParameters({ procedure });

  return (
    <div className="relative bg-white rounded-lg p-5 border border-gray-300">
      <h2 className="text-xl font-semibold mb-4">{snippet.name || 'Untitled Snippet'}</h2>
      <h4 className="mb-4">{snippet.description || ''}</h4>
      <div>
        <ProcedureContextProvider procedure={procedure} scrollTo={undefined}>
          {/* @ts-ignore - props are not actually required for snippets */}
          <RunContextProvider run={run} fetchedTelemetryParameters={fetchedTelemetryParameters} isPreviewMode={true}>
            <table className="w-full">
              {snippet.snippet_type === 'section' ? (
                <>
                  {/* @ts-ignore */}
                  <ProcedureSection section={snippet.section} isCollapsedMap={isCollapsedMap} sourceName={sourceName} />
                </>
              ) : (
                <ProcedureStep step={snippet.step} sourceName={sourceName} />
              )}
            </table>
          </RunContextProvider>
        </ProcedureContextProvider>
      </div>
    </div>
  );
};

export default PreviewSnippet;
