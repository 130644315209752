import { forwardRef } from 'react';
import FieldSetStepSnippet from './FieldSetStepSnippet';
import FieldSetSectionSnippet from './FieldSetSectionSnippet';
import { Snippet } from 'shared/lib/types/views/procedures';

interface FieldSetSnippetProps {
  snippet: Snippet | null;
  validationErrors?: Record<string, unknown>;
  onClose?: () => void;
  onRemove?: (id: string) => Promise<void>;
  onSaveSuccess: () => void;
  testingModule?: boolean;
  onChange?: (updatedSnippet: Snippet) => void;
}

const FieldSetSnippet = forwardRef(
  ({ snippet, validationErrors, onClose, onRemove, onSaveSuccess, testingModule, onChange }: FieldSetSnippetProps) => {
    if (!snippet) return null;

    if (snippet.snippet_type === 'section') {
      return (
        <FieldSetSectionSnippet
          snippet={snippet}
          validationErrors={validationErrors}
          onClose={onClose}
          onRemove={onRemove}
          onSaveSuccess={onSaveSuccess}
          testingModule={testingModule}
          onChange={onChange}
        />
      );
    }

    if (snippet.snippet_type === 'step') {
      return (
        <FieldSetStepSnippet
          snippet={snippet}
          validationErrors={validationErrors}
          onClose={onClose}
          onRemove={onRemove}
          onSaveSuccess={onSaveSuccess}
          testingModule={testingModule}
          onChange={onChange}
        />
      );
    }

    return null;
  }
);

export default FieldSetSnippet;
