import useParts from './useParts';

type UsePartUnitsProps = {
  partIds: Array<string>;
};

type UsePartUnitsReturn = {
  getUnits: (revisionId: string) => string | null;
};

const usePartUnits = ({ partIds }: UsePartUnitsProps): UsePartUnitsReturn => {
  const { getPartByRevisionId } = useParts({
    partIds,
    includeAllReleasedRevisions: true,
  });

  const getUnits = (revisionId: string): string | null => {
    const part = getPartByRevisionId(revisionId);
    if (!part) {
      return null;
    }
    return part.units;
  };

  return { getUnits };
};

export default usePartUnits;
