import { MutableRefObject, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AddedStep, DraftTableInputBlock, ExpressionToken } from 'shared/lib/types/views/procedures';
import expression, { ReferenceOption } from '../lib/expression';
import tableInputUtil from '../lib/tableInputUtil';
import { DEFAULT_VALID_REFERENCE_SUBTYPES, useProcedureContext } from '../contexts/ProcedureContext';
import { useSettings } from '../contexts/SettingsContext';
import { isEqual } from 'lodash';

export type Cell = {
  rowId?: string;
  columnId?: string;
};

interface UseReferenceTokensProps {
  initialTokens?: Array<ExpressionToken>;
  block;
  onReferencesChanged: (updatedTokens: Array<ExpressionToken>) => void;
  setField: (updatedTokens: Array<ExpressionToken>, text?: string) => void;
  validateField: (updatedTokens: Array<ExpressionToken>, text?: string) => void;
  pendingStep?: AddedStep;
  precedingStepId?: string;
  allowedReferenceSubTypes?: ReadonlyArray<(typeof DEFAULT_VALID_REFERENCE_SUBTYPES)[number]>;
  selectReferenceCallback: () => void;
}

type UseReferenceTokensReturns<T extends HTMLInputElement | HTMLTextAreaElement> = {
  tokens: Array<ExpressionToken>;
  setTokens: (tokens: Array<ExpressionToken>) => void;
  setIsAutocomplete: (isAutocomplete: boolean) => void;
  inputRef: RefObject<T>;
  selectionStartRef: MutableRefObject<number | null>;
  selectionEndRef: MutableRefObject<number | null>;
  onSetField: () => void;
  onValidateField: () => void;
  submitReferenceSelection: ({
    id,
    options,
    fieldIndex,
    rowId,
    columnId,
  }: {
    id: string;
    options: ReferenceOption[];
    fieldIndex?: number;
    rowId?: string;
    columnId?: string;
  }) => void;
  referenceOptions: Array<ReferenceOption>;
  selectedTable: DraftTableInputBlock | null;
  setSelectedTable: (selectedTable: DraftTableInputBlock | null) => void;
  onSelectReference: (reference: ReferenceOption) => void;
  onSelectTableCellReference: (cell: Cell) => void;
};

const useReferenceTokens = <T extends HTMLInputElement | HTMLTextAreaElement>({
  initialTokens = [],
  block,
  onReferencesChanged,
  setField,
  validateField,
  pendingStep,
  precedingStepId,
  allowedReferenceSubTypes,
  selectReferenceCallback,
}: UseReferenceTokensProps): UseReferenceTokensReturns<T> => {
  const { validReferenceBlocks } = useProcedureContext();
  const { config } = useSettings();

  const inputRef = useRef<T>(null);
  const selectionStartRef = useRef<number | null>(null);
  const selectionEndRef = useRef<number | null>(null);

  const [tokens, setTokens] = useState(initialTokens);
  const [storedInitialTokens, setStoredInitialTokens] = useState(initialTokens);
  const [isAutocomplete, setIsAutocomplete] = useState(false);
  const [selectedTable, setSelectedTable] = useState<null | DraftTableInputBlock>(null);

  const displaySectionAs = (config && config.display_sections_as) || 'letters';
  const referenceOptionsHaveChanged = useRef(false);

  const referenceOptions = useMemo<ReferenceOption[]>(() => {
    referenceOptionsHaveChanged.current = true;
    return validReferenceBlocks({
      block,
      displaySectionAs,
      allowedReferenceSubTypes,
      pendingStep,
      precedingStepId,
    });
  }, [validReferenceBlocks, block, displaySectionAs, allowedReferenceSubTypes, pendingStep, precedingStepId]);

  /*
   * If the referenced fields change their names, update the token values to match
   * and update the input text as well.  Don't try to update unaltered tokens
   */
  useEffect(() => {
    let referencesHaveChanged = false;
    const updatedTokens = [...tokens];
    updatedTokens.forEach((token) => {
      if (token.type === 'reference' && token.reference_id) {
        const match = expression.findMatchFromToken({
          token,
          options: referenceOptions,
        });

        if (!match) {
          token.value = `{${token.value}}`;
          token.type = 'text';
          token.reference_id = undefined;
          referencesHaveChanged = true;
        } else if (match.type === 'table_input') {
          const cellCoordinates = tableInputUtil.getCellCoordinates({
            rowId: token.table_reference?.row_id,
            columnId: token.table_reference?.column_id,
            rowMetadata: match.row_metadata,
            columnMetadata: match.columns,
          });
          referencesHaveChanged = cellCoordinates !== token.value;
          token.value = cellCoordinates || '';
        } else if (token.value !== match.name) {
          token.value = match.name;
          referencesHaveChanged = true;
        }
      }
    });

    if (referencesHaveChanged) {
      setTokens(updatedTokens);
      if (inputRef && inputRef.current) {
        inputRef.current.value = expression.tokensToRawText(updatedTokens, referenceOptions);
      }
      onReferencesChanged(updatedTokens);
    }
  }, [onReferencesChanged, referenceOptions, tokens]);

  /*
   * If the steps are reordered, the step labels change and need to be updated
   * in the formula text, but we don't want to do this whenever the tokens change
   * or it ends up taking over the input box and disallowing free-typing.
   * So here I use a dirty flag only on reference options to limit the updates
   */
  useEffect(() => {
    if (referenceOptionsHaveChanged.current && inputRef && inputRef.current) {
      inputRef.current.value = expression.tokensToRawText(tokens, referenceOptions);
      referenceOptionsHaveChanged.current = false;
    }
  }, [inputRef, referenceOptions, referenceOptionsHaveChanged, tokens]);

  /*
   * Initial tokens are stored in order to compare against incoming tokens
   * in props from the parent.  This happens in undo, redo.  Only update tokens
   * when this happens to prevent fighting with the user input in the input field
   */
  useEffect(() => {
    if (!isEqual(storedInitialTokens, initialTokens)) {
      setStoredInitialTokens(initialTokens);
      setTokens(initialTokens);
      if (inputRef && inputRef.current) {
        inputRef.current.value = expression.tokensToRawText(initialTokens, referenceOptions);
      }
    }
  }, [initialTokens, inputRef, referenceOptions, setTokens, storedInitialTokens]);

  const _updateField = useCallback(
    (updateFieldCallback: (updatedTokens: Array<ExpressionToken>, text?: string) => void) => {
      if (inputRef && inputRef.current) {
        const updatedTokens = expression.textToTokens(inputRef.current.value, referenceOptions);
        setTokens(updatedTokens);
        updateFieldCallback(updatedTokens, inputRef.current?.value ?? '');
      }
    },
    [inputRef, referenceOptions, setTokens]
  );

  const onSetField = useCallback(() => {
    return _updateField(setField);
  }, [_updateField, setField]);

  const onValidateField = useCallback(() => {
    return _updateField(validateField);
  }, [_updateField, validateField]);

  const submitReferenceSelection = useCallback(
    ({
      id,
      options,
      fieldIndex,
      rowId,
      columnId,
    }: {
      id: string;
      options: ReferenceOption[];
      fieldIndex?: number;
      rowId?: string;
      columnId?: string;
    }) => {
      if (inputRef && inputRef.current) {
        const textValue =
          isAutocomplete && inputRef.current.value.includes('{')
            ? inputRef.current.value.substring(0, inputRef.current.value.lastIndexOf('{'))
            : inputRef.current.value;
        const referenceName = expression.getNamespacedReference({
          id,
          options,
          fieldIndex,
          rowId,
          columnId,
        });

        if (selectionStartRef.current !== null && selectionEndRef.current !== null) {
          const start = selectionStartRef.current;
          const end = selectionEndRef.current;
          inputRef.current.value = `${textValue.slice(0, start)}${referenceName}${textValue.slice(end)}`;
          inputRef.current.setSelectionRange(start + referenceName.length, start + referenceName.length);
        }

        inputRef.current.focus();
        onValidateField();
      }
    },
    [isAutocomplete, onValidateField]
  );

  const onSelectReference = useCallback(
    (reference: ReferenceOption) => {
      if (reference.type === 'table_input') {
        setSelectedTable(reference);
        return;
      }
      submitReferenceSelection({
        id: reference.id,
        fieldIndex: reference.origin?.fieldIndex,
        options: referenceOptions,
      });

      selectReferenceCallback();
    },
    [selectReferenceCallback, referenceOptions, submitReferenceSelection]
  );

  const onSelectTableCellReference = useCallback(
    (cell: Cell) => {
      if (!selectedTable) {
        return;
      }
      return submitReferenceSelection({
        id: selectedTable.id,
        options: referenceOptions,
        rowId: cell.rowId,
        columnId: cell.columnId,
      });
    },
    [selectedTable, submitReferenceSelection, referenceOptions]
  );

  return {
    tokens,
    setTokens,
    setIsAutocomplete,
    inputRef,
    selectionStartRef,
    selectionEndRef,
    onSetField,
    onValidateField,
    submitReferenceSelection,
    referenceOptions,
    selectedTable,
    setSelectedTable,
    onSelectReference,
    onSelectTableCellReference,
  };
};

export default useReferenceTokens;
