import useAttachment, { Attachment } from '../../hooks/useAttachment';
import ThumbnailImageDisplay from './ThumbnailImageDisplay';
import useContentType from '../../hooks/useContentType';
import React from 'react';
import ThumbnailFileDisplay from './ThumbnailFileDisplay';

export const SIZE = {
  sm: 'w-6 h-6',
  md: 'w-10 h-10',
  lg: 'w-12 h-12',
  xl: 'w-16 h-16',
};

interface AttachmentListPreviewProps {
  attachment: Attachment;
  size: keyof typeof SIZE;
}

const AttachmentListPreview = ({ attachment, size }: AttachmentListPreviewProps) => {
  const { shouldRenderImage } = useContentType({ attachment });
  const { url } = useAttachment({ attachment });

  return (
    <div className="leading-normal flex flex-row gap-x-2 items-center w-full">
      <>
        <div className="w-fit min-w-12">
          {shouldRenderImage && <ThumbnailImageDisplay attachment={attachment} size={size} />}
          {!shouldRenderImage && <ThumbnailFileDisplay attachment={attachment} size={size} />}
        </div>
        <a className="text-sm link truncate" target="_blank" rel="noreferrer" href={url}>
          {attachment.name}
        </a>
      </>
    </div>
  );
};

export default AttachmentListPreview;
