import React, { useMemo, useRef, useState } from 'react';
import { Formik, Form, Field, FormikProps } from 'formik';
import Modal from './Modal';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { useAuth } from '../contexts/AuthContext';
import TextAreaAutoHeight from './TextAreaAutoHeight';
import REFRESH_TRY_AGAIN_MESSAGE from '../lib/messages';
import { DatabaseServices } from '../contexts/proceduresSlice';

interface NewProcedureModalProps {
  cancelActionCloseModal: () => void;
  primaryActionCloseModal: () => void;
  setErrorMessage: (message: string) => void;
}

type initialValuesType = { prompt: string; projectId?: string };

const PLACEHOLDERS = [
  'build re-entry thermal protection system',
  'test the thrust output of a liquid rocket engine',
  'analyze the performance of a solid rocket motor at varying temperatures',
  'conduct a vacuum test for a bipropellant propulsion system',
  'validate the ignition sequence of a hybrid rocket engine',
  'perform a long-duration hot fire test for a spacecraft propulsion system',
  'conduct vibration testing on satellite structural components',
  'verify the thermal cycling endurance of solar panels',
  'perform leak testing on propellant tanks under high pressure',
  'test the reliability of avionics under simulated launch conditions',
  'validate the performance of an actuator in microgravity simulations',
  'prepare and test the ground support equipment for launch readiness',
  'simulate countdown and liftoff procedures for a rocket launch',
  'conduct a dry run for fueling operations of the launch vehicle',
  'test emergency abort systems for launch pad safety',
  'monitor telemetry systems during a simulated launch sequence',
  'perform in-orbit testing of satellite communication subsystems',
  'validate attitude control systems during a simulated orbital maneuver',
  'test satellite power systems under extended eclipse conditions',
  'conduct functional checks of payload instruments after deployment',
  'simulate data transmission between satellite and ground station',
  'analyze credit risk exposure in a diverse portfolio of corporate bonds',
  'perform a compliance audit for MiFID II trade reporting standards',
  'validate the data governance framework for GDPR compliance in financial operations',
  'test an algorithmic trading strategy for currency pairs under high market volatility',
  'test the scalability of a core banking platform under peak transaction loads',
  'test blockchain-based smart contracts for automated trade settlements',
];

const NewProcedureAIModal = ({
  cancelActionCloseModal,
  primaryActionCloseModal,
  setErrorMessage,
}: NewProcedureModalProps) => {
  const formRef = useRef<FormikProps<{ prompt: string }>>(null);
  const { auth } = useAuth();
  const [charCount, setCharCount] = useState(0);
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const maxLength = 200;
  const randomIndex = Math.floor(Math.random() * PLACEHOLDERS.length);

  const initialValues = useMemo(() => {
    const values: initialValuesType = { prompt: '' };
    if (auth.hasProjectOnlyEditPermissions() && !values.projectId) {
      values.projectId = auth.projectsWithEditPermission()[0];
    }

    return values;
  }, [auth]);

  const sanitizeInput = (value: string) => value.trim();

  const handlePrimaryAction = () => {
    if (formRef.current && !formRef.current.isSubmitting) {
      const sanitizedValues = {
        ...formRef.current.values,
        name: sanitizeInput(formRef.current.values.prompt || ''),
      };
      formRef.current.setValues(sanitizedValues);
      formRef.current.handleSubmit();
    }
  };

  return (
    <Modal
      title="Generate New Procedure"
      primaryActionTitle="Generate Procedure"
      secondaryActionTitle="Cancel"
      onPrimaryAction={handlePrimaryAction}
      onSecondaryAction={cancelActionCloseModal}
      size="md"
    >
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={async (values: initialValuesType) => {
          try {
            await services.ml.generateProcedure({
              prompt: values.prompt,
              projectId: values.projectId,
            });
          } catch {
            setErrorMessage(REFRESH_TRY_AGAIN_MESSAGE);
          }
          primaryActionCloseModal();
        }}
        enableReinitialize
      >
        {({ values, setFieldValue, setValues }) => (
          <Form className="w-full">
            <div className="mx-2 my-2">
              <div className="w-full flex-row items-start">
                <div className="flex flex-col">
                  {/* Prompt */}
                  <div className="relative flex flex-col">
                    <label className="font-semibold text-sm">Generate a procedure to </label>
                    <Field name="prompt">
                      {({ field }) => (
                        <div className="flex flex-col grow">
                          <TextAreaAutoHeight
                            placeholder={`e.g. ${PLACEHOLDERS[randomIndex]}`}
                            {...field}
                            maxLength={maxLength}
                            onChange={(e) => {
                              setCharCount(e.target.value.length); // Update character count
                              field.onChange(e);
                            }}
                          />
                        </div>
                      )}
                    </Field>
                    <div className="text-sm text-gray-500 mt-1">{maxLength - charCount} characters remaining</div>
                  </div>
                </div>
                <div className="flex flex-row mt-2">
                  {/* Examples and tips */}
                  <div className="flex flex-col">
                    <label className="font-semibold text-sm">Prompt Tips & Examples</label>
                    <ul className="ml-4 list-disc list-outside">
                      <li>Be clear and specific. E.g. "test a pressure reducing valve"</li>
                      <li>
                        Narrow down the focus, if possible. E.g. "test a pressure reducing valve at altitudes of over
                        10,000 feet"
                      </li>
                      <li>
                        Always check the results as AI is experimental and may generate incorrect information. You
                        should always use domain knowledge to evaluate the content before releasing a procedure.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default React.memo(NewProcedureAIModal);
