import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import QRModal from './QRModal';
import stickyHeaderUtil from '../../lib/stickyHeaderUtil';

type TrackingWithQRCodeParams = {
  name: string;
  url: string;
  showLabel?: boolean;
};

const TrackingWithQRCode = ({ name, url, showLabel = false }: TrackingWithQRCodeParams) => {
  const [showQrCode, setShowQrCode] = useState<boolean>(false);

  return (
    <>
      {showQrCode && <QRModal name={name} url={url} onClose={() => setShowQrCode(false)} />}
      <button onClick={() => setShowQrCode(true)} title="Show QR Code" aria-label="Show QR Code">
        <div className={stickyHeaderUtil.getButtonClassNames(false, false)}>
          <FontAwesomeIcon icon="qrcode" />
          {showLabel && <div className="ml-2">QR Code</div>}
        </div>
      </button>
    </>
  );
};

export default TrackingWithQRCode;
