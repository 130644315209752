import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import superlogin from '../api/superlogin';
import useAuth from '../hooks/useAuth';
import useLocationParams from '../hooks/useLocationParams';
import { LocationState } from './types';
import { API_URL } from '../config';
import util from 'util';

const LoginSchema = Yup.object().shape({
  email: Yup.string().email().required('Required'),
  password: Yup.string().required('Required'),
});

const LoginOauth = () => {
  const location = useLocation<LocationState>();
  const { searchParams } = useLocationParams(location);
  const [globalErrors, setGlobalErrors] = useState(null);
  const { onAuthenticationSuccess } = useAuth();

  const onAuthenticationFailure = useCallback((error) => {
    setGlobalErrors(error.message);
  }, []);

  const login = useCallback(
    async (email, password) => {
      setGlobalErrors(null);
      try {
        const session = await superlogin.login({
          username: email,
          password,
        });
        await onAuthenticationSuccess(session);
        const url = `${API_URL}/oauth/authorize`;
        const redirectUri = searchParams.get('redirect_uri');
        const params = {
          redirect_uri: redirectUri,
          response_type: searchParams.get('response_type'),
          client_id: searchParams.get('client_id'),
          state: searchParams.get('state'),
        };

        // oauth authorize endpoint
        const response = await superlogin.getHttp().get(url, { params });

        const redirectUrl = util.format(`${redirectUri}?code=%s&state=%s`, response.data?.code, response.data?.state);
        // Redirect to client callback URL
        window.location.href = redirectUrl;
      } catch (error) {
        onAuthenticationFailure(error);
      }
    },
    [onAuthenticationSuccess, onAuthenticationFailure, searchParams]
  );

  return (
    <div className="px-12 py-4">
      {/* Sets the document title */}
      <Helmet>
        <title>Epsilon3</title>
      </Helmet>
      <div className="items-start max-w-md mx-auto">
        <h1 className="mb-4">Authorize access to your Epsilon3 account</h1>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={LoginSchema}
          onSubmit={async (values) => login(values.email, values.password)}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <div className="flex flex-col">
                <label htmlFor="email">Email address</label>
                <Field
                  id="email"
                  name="email"
                  placeholder="Email address"
                  type="email"
                  className="w-full border-1 border-gray-400 rounded "
                />
                {errors.email && touched.email ? <div className="text-red-700">{errors.email}</div> : null}

                <label htmlFor="email" className="mt-2">
                  Password
                </label>
                <Field
                  id="password"
                  name="password"
                  placeholder="Password"
                  type="password"
                  className="w-full border-1 border-gray-400 rounded"
                />
                {errors.password && touched.password ? <div className="text-red-700">{errors.password}</div> : null}
                {globalErrors && <p className="self-center mt-2 text-red-700">{globalErrors}</p>}
                <div className="flex mt-2 gap-x-2 justify-center">
                  <button className="btn" type="submit" disabled={isSubmitting ? true : undefined}>
                    Authorize
                  </button>
                  <button className="btn" type="button" onClick={() => window.close()}>
                    Deny
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <div className="flex flex-col"></div>
        <div className="flex justify-between items-center mt-4">
          <div />
          <div>
            <a target="_blank" rel="noreferrer" href="https://epsilon3.io/privacy" className="link mr-2">
              Privacy
            </a>
            <a target="_blank" rel="noreferrer" href="https://epsilon3.io/terms" className="link">
              Terms
            </a>
          </div>
        </div>
        <div className="text-right">© 2025 Epsilon3®</div>
      </div>
    </div>
  );
};

export default LoginOauth;
