import { capitalize } from 'lodash';
import {
  Part,
  ComponentPart,
  UsageType,
  ProcedureOccurrenceType,
  PartProcedureOccurrence,
  RestrictedPartProcedureOccurrence,
} from 'shared/lib/types/postgres/manufacturing/types';
import sharedDiffUtil from 'shared/lib/diffUtil';
import { RunProcedureLinkBlock } from 'shared/lib/types/views/procedures';
import { sortBy } from 'shared/lib/collections';
import { InternalComponentPart } from 'shared/lib/types/api/manufacturing/internal/models';

const cleanPartNumber = (partNo: string): string => {
  return partNo.trim().toLowerCase();
};

export const partNumberUnique = (
  parts: Array<Part>,
  partNo: string
): boolean => {
  const partNosCleaned = parts
    .filter((part) => !isPartRestricted(part))
    .map((part) => {
      return cleanPartNumber(part.part_no);
    });
  const partNoCleaned = cleanPartNumber(partNo);
  return !partNosCleaned.includes(partNoCleaned);
};

export const getPartRevisionId = (part: Part): string | undefined =>
  part.revisions?.find(
    (r) => r.revision.toLowerCase() === part.rev.toLowerCase()
  )?.id;

const getPartAndRevisionLabel = (
  partNumber: string,
  revision: string
): string => {
  const revisionLabel = revision ? ` Rev ${revision}` : '';
  return `${partNumber}${revisionLabel}`;
};

export const getPartLabel = (part: Part | undefined): string => {
  if (part) {
    const partNumber = `${sharedDiffUtil.getDiffValue(part, 'part_no', 'new')}`;
    const revision = `${sharedDiffUtil.getDiffValue(part, 'rev', 'new')}`;
    return getPartAndRevisionLabel(partNumber, revision);
  }
  return '[Unknown Part]';
};

export const getComponentPartLabel = (
  component: Pick<ComponentPart, 'part_no' | 'revision'>,
  part: Part | undefined
): string => {
  if (component.part_no && component.revision !== undefined) {
    const partNumber = `${sharedDiffUtil.getDiffValue(
      component,
      'part_no',
      'new'
    )}`;
    const revision = `${sharedDiffUtil.getDiffValue(
      component,
      'revision',
      'new'
    )}`;
    return getPartAndRevisionLabel(partNumber, revision);
  }
  return getPartLabel(part);
};

export const asComponentPart = (part: Part, amount: number): ComponentPart => {
  const {
    id: part_id,
    part_no,
    name,
    image,
    tracking,
    low_inventory_threshold,
    units,
    assembly,
    project_id,
  } = part;

  let revision, revision_id;
  for (const r of part.revisions || []) {
    if (r.revision === part.rev) {
      revision = r.revision;
      revision_id = r.id;
    }
  }

  return {
    part_id,
    amount,
    part_no,
    name,
    image,
    revision,
    revision_id,
    tracking,
    low_inventory_threshold,
    units,
    assembly,
    project_id,
    components: [...part.components],
  };
};

export const getPartUsageTypes = (parts: Array<Part>): Array<string> => {
  const usageTypes = new Set<string>();
  parts.forEach((part) => {
    part.usage_types?.forEach((usageType) => {
      usageTypes.add(usageType.name);
    });
  });
  return Array.from(usageTypes);
};

export const getUsageTypeLabel = (usageType: UsageType | undefined): string => {
  if (!usageType || !usageType.name) {
    return '[Unknown Usage Type]';
  }
  return capitalize(sharedDiffUtil.getDiffValue(usageType, 'name', 'new'));
};

export const sortPartComponents = (part: Part, allParts: Array<Part>): void => {
  if (Array.isArray(part.components)) {
    const mappedComponents = part.components.map((component) => ({
      ...component,
      _sort_part_part_no: allParts.find((part) => part.id === component.part_id)
        ?.part_no, // default to undefined so that components without parts are last
    }));
    part.components = sortBy(mappedComponents, ['_sort_part_part_no']);
  }
};

export type PartProcedureLinkBlock = Omit<
  RunProcedureLinkBlock,
  'id' | 'type'
> & {
  procedureCode: string;
  procedureName: string;
  occurenceTypes: Set<ProcedureOccurrenceType>;
  type: 'procedure_link' | 'restricted';
};

export const getAutoLinkedProceduresForPart = (
  procedureOccurrences: (
    | PartProcedureOccurrence
    | RestrictedPartProcedureOccurrence
  )[]
): Array<PartProcedureLinkBlock> => {
  const proceduresMap = {};
  for (const occurrence of procedureOccurrences) {
    if (occurrence.procedure_id in proceduresMap) {
      const procedure: PartProcedureLinkBlock =
        proceduresMap[occurrence.procedure_id];
      procedure.occurenceTypes.add(occurrence.occurrence_type);
      if (
        'section_id' in occurrence &&
        occurrence.section_id !== procedure.section
      ) {
        procedure.section = '';
      }
    } else {
      const procedure: PartProcedureLinkBlock =
        'procedure_code' in occurrence
          ? {
              type: 'procedure_link',
              procedure: occurrence.procedure_id,
              section: occurrence.section_id ?? '',
              procedureCode: occurrence.procedure_code ?? '',
              procedureName: occurrence.procedure_name ?? '',
              occurenceTypes: new Set([occurrence.occurrence_type]),
            }
          : {
              type: 'restricted',
              procedure: occurrence.procedure_id,
              section: '',
              procedureCode: '',
              procedureName: '',
              occurenceTypes: new Set([occurrence.occurrence_type]),
            };
      proceduresMap[occurrence.procedure_id] = procedure;
    }
  }

  const procedures: Array<PartProcedureLinkBlock> =
    Object.values(proceduresMap);
  procedures.sort((p1, p2) => p1.procedureCode.localeCompare(p2.procedureCode));
  return procedures;
};

export const getProceduresLinkedToPart = (
  part: Part
): Array<PartProcedureLinkBlock> => {
  // first process the automatically detected procedure occurrences of part
  const procedures = getAutoLinkedProceduresForPart(
    part.procedure_occurrences ?? []
  );
  const autoLinkedProcedureIds = procedures.map(
    (procedure) => procedure.procedure
  );

  // next add any manually linked procedures not already picked up above
  part.procedures
    .filter(
      (manuallyLinked) =>
        !autoLinkedProcedureIds.includes(manuallyLinked.procedure)
    )
    .forEach((manuallyLinked) =>
      procedures.push({
        ...manuallyLinked,
        procedureCode: '',
        procedureName: '',
        occurenceTypes: new Set(),
      })
    );
  return procedures;
};

// check if a user has project permissions to view a part
export const isPartRestricted = (
  part: Part | ComponentPart | undefined | InternalComponentPart
): boolean => {
  if (!part) {
    return true;
  }
  return !part.part_no;
};

export const isPartProcedureRestricted = (
  procedureLinkBlock: PartProcedureLinkBlock
): boolean => {
  return procedureLinkBlock.type === 'restricted';
};
