import { Run, RunMetadata } from 'shared/lib/types/views/procedures';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import runUtil from '../../lib/runUtil';
import RunProgressBar, { StepCounts } from '../RunProgressBar';
import RunStatusLabel from '../RunStatusLabel';
import RunLabel from '../RunLabel';
import { runViewPath } from '../../lib/pathUtil';
import NoDataPlaceholder from './NoDataPlaceholder';

interface StatusColumnProps {
  run: Run | RunMetadata;
  stepCounts: StepCounts;
}

const StatusColumn = ({ run, stepCounts }: StatusColumnProps) => {
  const { currentTeamId }: { currentTeamId: string } = useDatabaseServices();
  const status = runUtil.getStatus(run.state, run.status);
  if (!run || !status) {
    return <NoDataPlaceholder />;
  }

  if (status === 'running') {
    return (
      <div className="flex flex-col">
        <div className="flex flex-row items-center gap-x-2">
          <RunStatusLabel statusText={status} />
          <RunLabel
            code={run.code}
            runNumber={run.run_number}
            link={runViewPath(currentTeamId, run._id)}
            truncateAtCodeChars={10}
          />
        </div>
        <div className="flex flex-row self-start w-44">
          <RunProgressBar runStatus={{ id: run._id, status, state: 'running' }} stepCounts={stepCounts} />
        </div>
      </div>
    );
  }
  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center gap-x-2">
        <RunStatusLabel statusText={status} />
        <RunLabel code={run.code} runNumber={run.run_number} link={runViewPath(currentTeamId, run._id)} />
      </div>
    </div>
  );
};

export default StatusColumn;
