import { useMemo } from 'react';
import DisplayDependencies from './StepDependency/DisplayDependencies';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FieldStepTimer from './FieldStepTimer';
import FieldStepDuration from './FieldStepDuration';
import { STEP_STATE, isStepEnded } from 'shared/lib/runUtil';
import { Duration } from 'luxon';
import Tooltip from '../elements/Tooltip';
import signoffUtil from 'shared/lib/signoffUtil';

const ProcedureStepBanner = ({
  step,
  isRun,
  hasDependencies,
  isSingleCardEnabled,
  areConditionalAndStepDependenciesFulfilled,
  onRefChanged,
  scrollMarginTopValueRem,
  updateStepDetail,
  baseRunningCondition,
  isStepActive,
  isStepEnabled,
  stepState,
  isAddedStep = false,
  addedStepTooltip,
  repeatKey,
}) => {
  const timerDurationStarted = useMemo(() => {
    if (step.duration && typeof step.duration === 'object') {
      if (step.duration.started_at) {
        return true;
      } else {
        return false;
      }
    }

    if (step.timer && typeof step.timer === 'object') {
      if (step.timer.started_at) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }, [step.timer, step.duration]);

  const bgColor = useMemo(() => {
    if (!areConditionalAndStepDependenciesFulfilled || (hasDependencies && !isRun)) {
      return 'bg-gray-100 border-x-2 border-t-2 border-app-gray-400';
    } else if (stepState === STEP_STATE.FAILED) {
      return 'bg-red-200 border-x-2 border-t-2 border-red-400';
    } else if (stepState === STEP_STATE.SKIPPED) {
      return 'bg-app-gray-400 border-x-2 border-t-2 border-app-gray-400';
    } else if (stepState === STEP_STATE.COMPLETED) {
      return 'bg-app-green-200 border-x-2 border-t-2 border-green-400';
    } else if (timerDurationStarted) {
      return 'bg-blue-100 border-x-2 border-t-2 border-app-gray-400';
    } else if (!isStepEnabled) {
      return 'bg-white border-x-2 border-t-2 border-app-gray-400';
    } else {
      return 'bg-white border-x-2 border-t-2 border-blue-200';
    }
  }, [
    areConditionalAndStepDependenciesFulfilled,
    hasDependencies,
    isRun,
    stepState,
    timerDurationStarted,
    isStepEnabled,
  ]);

  const stepStatus = useMemo(() => {
    if (stepState === STEP_STATE.FAILED) {
      return (
        <div className="flex flex-row flex-nowrap gap-x-1 items-center">
          <FontAwesomeIcon className="text-red-500" icon="circle-exclamation" />
          <div className="text-gray-600">Failed</div>
        </div>
      );
    } else if (stepState === STEP_STATE.SKIPPED) {
      return (
        <div className="flex flex-row flex-nowrap gap-x-1 items-center">
          <FontAwesomeIcon className="text-gray-600" icon="forward-step" />
          <div className="text-gray-600">Skipped</div>
        </div>
      );
    } else if (stepState === STEP_STATE.COMPLETED) {
      return (
        <div className="flex flex-row flex-nowrap gap-x-1 items-center">
          <FontAwesomeIcon className="text-emerald-500" icon="circle-check" />
          <div className="text-gray-600">Complete</div>
          <div className="font-semibold">
            {step.actions &&
              step.actions
                .filter((action) => action.type === 'signoff')
                .map((action) => action.operator)
                .join(', ')}
          </div>
        </div>
      );
    } else if (stepState === STEP_STATE.INCOMPLETE) {
      const completedSignoffOperators = signoffUtil
        .getCompleteSignoffs(step)
        .map((signoff) => signoff.operators.join(', '))
        .join(', ');
      const incompleteSignoffOperators = signoffUtil
        .getIncompleteSignoffs(step)
        .map((signoff) => signoff.operators.join(', '))
        .join(', ');

      return (
        <div className="flex flex-row flex-nowrap gap-x-2 items-center">
          {incompleteSignoffOperators && (
            <div className="flex flex-row flex-nowrap gap-x-1 text-xs items-center">
              <FontAwesomeIcon icon="headset" />
              <div className="text-gray-600">Needs</div>
              <div className="font-semibold">{incompleteSignoffOperators}</div>
            </div>
          )}
          {completedSignoffOperators && (
            <div className="flex flex-row flex-nowrap gap-x-1 text-xs items-center">
              <FontAwesomeIcon className="text-gray-500" icon="circle-check" />
              <div>{completedSignoffOperators}</div>
            </div>
          )}
        </div>
      );
    }
  }, [stepState, step]);

  const timerMessage = useMemo(() => {
    if (!step.timer) {
      return;
    }

    let output = 'Start Timer';
    if (step.timer.completed) {
      output = 'Timer Elapsed';
    } else if (isStepEnded(step)) {
      output = 'Timer Not Completed';
    } else if (step.timer.started_at) {
      output = 'Timer Running';
    }

    const timeLeft = step.timer.time_left;
    if (!timeLeft) {
      return output;
    }

    const formattedTimeLeft = Duration.fromISO(timeLeft).toFormat('hh:mm:ss');
    return `${output} (${formattedTimeLeft})`;
  }, [step]);

  return (
    <tbody
      data-testid="header"
      className={`${bgColor} shadow z-0`}
      ref={onRefChanged}
      style={{ scrollMarginTop: `${scrollMarginTopValueRem}rem` }}
      aria-label="Step Header"
      role="region"
    >
      <tr>
        <td colSpan={3}>
          <div className="flex flex-col">
            <div className="py-2 px-2 w-full flex flex-row justify-between text-xs items-center">
              <div className="flex flex-row flex-grow gap-x-2 items-center">
                {isAddedStep && (
                  <Tooltip content={addedStepTooltip}>
                    <div className="flex flex-row gap-x-1 whitespace-nowrap items-center">
                      <div className={`${step.run_only ? 'text-blue-500' : 'text-red-500'}`}>
                        <FontAwesomeIcon
                          icon="strikethrough"
                          aria-label={`${step.run_only ? 'Blueline' : 'Redline'}`}
                        />
                      </div>
                      <div className="font-bold text-gray-600 italic">Added Step</div>
                    </div>
                  </Tooltip>
                )}
                {step.requires_previous && (
                  <span className="font-semibold text-gray-600 whitespace-nowrap">Requires Previous Step</span>
                )}
                {step['duration'] && typeof step['duration'] === 'object' && (
                  <Tooltip
                    content={step.duration.started_at ? 'Step Started' : 'Requires Step to be Started'}
                    visible={true}
                  >
                    <FontAwesomeIcon icon="stopwatch" />
                  </Tooltip>
                )}
                {step['timer'] && typeof step['timer'] === 'object' && (
                  <div className="flex flex-row items-center gap-x-1">
                    <FontAwesomeIcon icon="hourglass-start" />
                    <span className="text-gray-600">{timerMessage}</span>
                  </div>
                )}
                {!isSingleCardEnabled && hasDependencies && (
                  <DisplayDependencies dependencies={step.dependencies} blockId={step.id} />
                )}
              </div>
              <div className="flex flex-row items-center gap-x-1">
                {step['duration'] && typeof step['duration'] === 'object' && (
                  <FieldStepDuration
                    value={step['duration']}
                    disabled={!baseRunningCondition}
                    hideStartButton={typeof step['timer'] === 'object'}
                    updateStepDetail={updateStepDetail}
                    isPaused={!isStepActive}
                  />
                )}
                {step['timer'] && typeof step['timer'] === 'object' && (
                  <FieldStepTimer
                    value={step['timer']}
                    disabled={!baseRunningCondition}
                    updateStepDetail={updateStepDetail}
                  />
                )}
                {repeatKey && (
                  <div className="flex flex-row flex-nowrap gap-x-1 items-center">
                    <FontAwesomeIcon className="text-gray-600" icon="redo" />
                    <div className="font-semibold text-gray-600 italic">Repeat {repeatKey}</div>
                  </div>
                )}
                {stepStatus}
              </div>
            </div>
          </div>
          <div className="h-px bg-gray-200"></div>
        </td>
      </tr>
    </tbody>
  );
};

export default ProcedureStepBanner;
