import React, { useCallback, useMemo, SVGProps, FC } from 'react';
import { cloneDeep } from 'lodash';
import stepConditionals, { CONDITIONAL_TYPE } from 'shared/lib/stepConditionals';
import { useRunContext } from '../../contexts/RunContext';
import { useProcedureContext } from '../../contexts/ProcedureContext';
import ConditionalStateTag from '../StepConditionals/ConditionalStateTag';
import useProcedureAdapter from '../../hooks/useProcedureAdapter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import {
  Dependency,
  DiffArrayChangeSymbol,
  DiffField,
  StepConditionalDiffElement,
  WithDiffChange,
} from 'shared/lib/types/views/procedures';

export type ConditionalDependency = {
  id: string;
  dependent_ids: Array<DiffField<string>>;
  dependent_conditionals: Array<StepConditionalDiffElement>;
  diff_change_state?: DiffArrayChangeSymbol;
};

interface DisplayDependenciesProps {
  dependencies?: Array<Dependency>;
  blockId: string;
  SvgIcon?: FC<SVGProps<SVGSVGElement>>; // if provided, replaces default leading icon
}

const DisplayDependencies = ({ dependencies, blockId, SvgIcon }: DisplayDependenciesProps) => {
  const { getContentBlock, getSectionAndStepIdPair } = useRunContext();
  const { scrollTo } = useProcedureContext();

  const { stepIdsToLabelsMap, sourceStepConditionalsMap } = useProcedureAdapter();

  const onLabelClick = useCallback(
    (dependentId) => {
      const { sectionId, stepId } = getSectionAndStepIdPair(dependentId);

      scrollTo({
        sectionId,
        stepId,
      });
    },
    [getSectionAndStepIdPair, scrollTo]
  );

  const sourceName = useCallback(
    (conditional) => {
      if (!conditional) {
        return '';
      }
      if (conditional.source_type === CONDITIONAL_TYPE.STEP) {
        return '';
      } else if (stepConditionals.isContentConditional(conditional)) {
        return getContentBlock(conditional.source_id, conditional.content_id)?.name;
      }
      return '';
    },
    [getContentBlock]
  );

  // Validate which dependencies are still in the doc
  const validDependencies = useMemo(() => {
    // Compute step conditional dependencies
    const conditionals = sourceStepConditionalsMap?.[blockId]
      ? [...Object.values(sourceStepConditionalsMap[blockId])]
      : [];

    const conditionalDependency = {
      id: 'step_conditionals',
      dependent_ids: conditionals.map((conditional) => conditional.source_id),
      dependent_conditionals: conditionals,
    };

    // Remove step dependencies already covered by conditionals
    const deps: Array<WithDiffChange<Dependency> | ConditionalDependency> = cloneDeep(dependencies || []);
    let i = deps.length;
    while (i--) {
      deps[i].dependent_ids = deps[i].dependent_ids.filter(
        (id) => stepIdsToLabelsMap[id as string] && !conditionalDependency.dependent_ids.includes(id)
      );
      if (deps[i].dependent_ids.length === 0) {
        deps.splice(i, 1);
      }
    }

    if (conditionalDependency.dependent_ids.length > 0) {
      deps.push(conditionalDependency);
    }

    return deps;
  }, [dependencies, sourceStepConditionalsMap, stepIdsToLabelsMap, blockId]);

  const validDependenciesToDisplay = useMemo(
    () =>
      validDependencies.filter(
        (dependency) => !dependency.diff_change_state || dependency.diff_change_state !== ARRAY_CHANGE_SYMBOLS.REMOVED
      ),
    [validDependencies]
  );

  const showConditionalDependency = (dependency: Dependency | ConditionalDependency) =>
    'dependent_conditionals' in dependency &&
    dependency.dependent_conditionals &&
    dependency.dependent_conditionals.length > 0;

  if (validDependencies.length === 0) {
    return null;
  }

  return (
    <div>
      {
        <div className="flex items-center text-xs">
          {SvgIcon && <SvgIcon className="w-[14px] text-gray-500 self-center mr-1" />}
          {!SvgIcon && <FontAwesomeIcon className="text-gray-500 self-center mr-1" icon="info-circle" />}
          <div className="flex flex-row gap-x-1 py-0.5 items-center">
            <div>Requires</div>
            <div className="flex flex-row items-center">
              {validDependenciesToDisplay.map((dependency, dependencyIndex) => {
                return (
                  <div key={dependency.id} className="flex flex-row items-center">
                    {dependency.dependent_ids.map((dependentId, dependentIdIndex) => {
                      return (
                        <div key={`${dependentId}${dependentIdIndex}`} className="flex flex-row gap-x-1 items-center">
                          <button
                            className="link"
                            onClick={() => onLabelClick(dependentId)}
                            disabled={typeof scrollTo !== 'function'} // Disables for viewing in edit procedure
                            aria-label="Go to Dependency"
                          >
                            {stepIdsToLabelsMap[dependentId]}
                          </button>

                          {/* Show required state for conditional dependencies*/}
                          {showConditionalDependency(dependency) && (
                            <>
                              <div>
                                {sourceName(
                                  (dependency as ConditionalDependency).dependent_conditionals[dependentIdIndex]
                                )}
                              </div>
                              <div className="font-normal">
                                <ConditionalStateTag
                                  conditional={
                                    (dependency as ConditionalDependency).dependent_conditionals[dependentIdIndex]
                                  }
                                  size="sm"
                                  conditionalStepId={dependentId}
                                />
                              </div>
                            </>
                          )}

                          {/* Show OR (/) */}
                          {dependentIdIndex !== dependency.dependent_ids.length - 1 && <div className="mx-1">/</div>}
                        </div>
                      );
                    })}

                    {/* Show AND (&) */}
                    {dependencyIndex !== validDependencies.length - 1 && <div className="mx-1">&</div>}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default React.memo(DisplayDependencies);
