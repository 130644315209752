const stickyHeaderUtil = {
  getButtonClassNames: (isDisabled?: boolean, isSelected?: boolean): string => {
    return `${isSelected ? 'bg-slate-300' : ''} ${
      isDisabled
        ? 'text-slate-400 pointer-events-none'
        : 'text-slate-700 hover:bg-slate-300'
    } p-2 rounded flex flex-none whitespace-nowrap items-center`;
  },
};

export default stickyHeaderUtil;
