import FormRunVariable from './FormRunVariable';
import { useRunContext } from '../contexts/RunContext';
import { RunVariable, V2RunVariable, VariableDiffElement } from 'shared/lib/types/views/procedures';
import FormReviewVariable from './Review/FormReviewVariable';
import { useMemo } from 'react';
import diffUtil from '../lib/diffUtil';

interface RunProcedureVariablesProps {
  isEnabled: boolean;
  variables: Array<RunVariable>;
  onSaveVariable?: (variable: V2RunVariable) => void;
  onRefChanged: (id: string, element: HTMLElement) => void;
  scrollMarginTopValueRem: number;
  diff?: boolean;
}

const RunProcedureVariables = ({
  isEnabled,
  variables,
  onSaveVariable,
  onRefChanged,
  scrollMarginTopValueRem,
  diff = false,
}: RunProcedureVariablesProps) => {
  const { isRun, isIntroductionVisible } = useRunContext();

  const labelMap = useMemo(() => {
    if (!diff) {
      return {};
    }

    const presentVariables = diffUtil.getForVersion(variables as Array<VariableDiffElement>, 'new');
    return presentVariables.reduce((map, variable, index) => {
      const id = (variable as V2RunVariable).id;
      if (id && !map[id]) {
        map[id] = `${index + 1}`;
      }
      return map;
    }, {});
  }, [diff, variables]);

  if (isRun && !isIntroductionVisible()) {
    return null;
  }

  if (!variables || !variables.length) {
    return null;
  }

  return (
    <div className="space-y-2">
      {variables.map((variable, index) =>
        diff ? (
          <FormReviewVariable
            key={`variables[${variable.name}]`}
            isEnabled={isEnabled}
            variable={variable}
            label={labelMap[(variable as V2RunVariable).id] ?? '--'}
            onSaveVariable={onSaveVariable}
            onRefChanged={onRefChanged}
            scrollMarginTopValueRem={scrollMarginTopValueRem}
          />
        ) : (
          <FormRunVariable
            key={`variables[${variable.name}]`}
            isEnabled={isEnabled}
            variable={variable}
            label={`${index + 1}`}
            onSaveVariable={onSaveVariable}
            onRefChanged={onRefChanged}
            scrollMarginTopValueRem={scrollMarginTopValueRem}
          />
        )
      )}
    </div>
  );
};

export default RunProcedureVariables;
