import { useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import procedureUtil from '../../../lib/procedureUtil';
import { useProcedureContext } from '../../../contexts/ProcedureContext';
import useProcedureAdapter from '../../../hooks/useProcedureAdapter';
import InvalidMessage from '../../InvalidMessage';
import { useBlockState } from '../../../contexts/BlockContext';
import {
  ExpressionTokenDiffElement,
  FieldInputBlock,
  FieldInputTableBlockDiffElement,
  RowMetadata,
  RowMetadataDiffElement,
  TableColumn,
  TableColumnDiffElement,
  WithDiffChange,
  WithDiffChangeI,
} from 'shared/lib/types/views/procedures';
import ReviewExpressionTokenDisplay from '../Expression/ReviewExpressionTokenDisplay';
import { faTable } from '@fortawesome/free-solid-svg-icons';
import tableInputUtil from '../../../lib/tableInputUtil';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import diffUtil from '../../../lib/diffUtil';
import { ExpressionReferenceTargetBlock } from 'shared/lib/types/expressions';
import isNumber from '../../../lib/number';
import { isNil } from 'lodash';

interface ReviewReferenceTokenProps {
  token: ExpressionTokenDiffElement;
  isLight?: boolean;
}

const ReviewReferenceToken = ({ token, isLight = false }: ReviewReferenceTokenProps) => {
  const { scrollTo, getContentItemPath } = useProcedureContext();
  const { getReferencedContentContext } = useProcedureAdapter();
  const { isValid } = useBlockState();

  const referencedContentContext = getReferencedContentContext<WithDiffChange<ExpressionReferenceTargetBlock>>(
    token.reference_id ?? ''
  );
  const { referencedFromStepKey, referencedFromSectionKey, referencedContent, isVariable, substepKeyMap } =
    referencedContentContext ?? {};

  const version = token.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED ? 'old' : 'new';
  const goToContent = useCallback(
    (destinationContentId) => {
      const contentPath = getContentItemPath(destinationContentId);
      // If contentPath is null, it could mean a procedure variable is referenced.
      const { sectionId, stepId, stepHeaderId } = procedureUtil.parseContentPath(contentPath);
      scrollTo({
        sectionId,
        stepId,
        stepHeaderId,
        contentId: destinationContentId,
      });
    },
    [getContentItemPath, scrollTo]
  );

  const goToSourceContent = useCallback(
    () => referencedContent && goToContent(referencedContent.id),
    [goToContent, referencedContent]
  );

  const referenceName = useMemo(() => {
    if (!referencedContent) {
      return '';
    }
    if (referencedContent.type === 'table_input' && token.table_reference) {
      const referencedContentVersion =
        referencedContent.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED ? 'old' : 'new';

      return tableInputUtil.getCellCoordinates({
        rowId: sharedDiffUtil.getDiffValue(token.table_reference, 'row_id', version),
        columnId: sharedDiffUtil.getDiffValue(token.table_reference, 'column_id', version),
        rowMetadata: diffUtil.getForVersion(
          (referencedContent.row_metadata ?? []) as Array<RowMetadataDiffElement>,
          referencedContentVersion
        ) as Array<RowMetadata>,
        columnMetadata: diffUtil.getForVersion(
          (referencedContent.columns ?? []) as Array<TableColumnDiffElement>,
          referencedContentVersion
        ) as Array<TableColumn>,
      });
    }

    if (referencedContent.type === 'field_input_table' && !isNil(token.field_index) && isNumber(token.field_index)) {
      const fields = diffUtil.getForVersion(
        (referencedContent as FieldInputTableBlockDiffElement).fields as Array<WithDiffChangeI>,
        version
      ) as FieldInputTableBlockDiffElement['fields'];
      const fieldIndex = sharedDiffUtil.getDiffValue<number>(token, 'field_index', version);
      return sharedDiffUtil.getDiffValue<string>(fields[fieldIndex] ?? {}, 'name', version) ?? '';
    }

    return (referencedContent as FieldInputBlock).name;
  }, [referencedContent, token, version]);

  const referenceIcon = useMemo(() => {
    if (referencedContent?.type === 'table_input') {
      return faTable;
    }

    return;
  }, [referencedContent?.type]);

  return (
    <>
      {isValid && referencedContentContext && referencedContent && referenceName && (
        <ReviewExpressionTokenDisplay
          referenceName={referenceName}
          referenceIcon={referenceIcon}
          onClick={goToSourceContent}
          referencedFromStepKey={substepKeyMap?.[referencedContent.id] ?? referencedFromStepKey}
          referencedFromSectionKey={referencedFromSectionKey}
          isVariable={Boolean(isVariable)}
          diffChangeState={token.diff_change_state}
          isLight={isLight}
        />
      )}
      {isValid && !(referencedContentContext && referencedContent && referenceName) && (
        <div className="flex flex-row items-center gap-x-1 text-xs rounded-full border border-black px-1 py-0.5">
          <FontAwesomeIcon className="text-red-500" icon="exclamation-circle" />
          Reference not found
        </div>
      )}
      {!isValid && (
        <div className="px-2">
          <InvalidMessage>Reference no longer valid</InvalidMessage>
        </div>
      )}
    </>
  );
};

export default ReviewReferenceToken;
