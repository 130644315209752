import React, { Fragment } from 'react';
import useFieldRedlines from '../hooks/useFieldRedlines';
import ProcedureDiffText from './ProcedureDiffText';
import { DiffField, RunHeaderFieldRedline, RunStepFieldRedline } from 'shared/lib/types/views/procedures';
import { useRunContext } from '../contexts/RunContext';

/**
 * Component for rendering a step field when running or viewing a procedure.
 * Supports showing redline history changes.
 *
 * @param fieldName - Name of the field.
 * @param fieldValue - Value of the field.
 * @param redlines - Array of RedlineField objects.
 * @param onLabelClick - Function that will be called when non editable step label is clicked.
 */
interface ProcedureFieldProps {
  fieldName: string;
  fieldValue: DiffField<string>;
  redlines: Array<RunHeaderFieldRedline | RunStepFieldRedline>;
  onLabelClick: () => void;
  isBold?: boolean;
}

const ProcedureFieldNoRedlines = ({ fieldName, fieldValue, redlines, onLabelClick, isBold }: ProcedureFieldProps) => {
  // Keep latestApprovedField for back-compat for old redlines that were accepted.
  const { latestApprovedField } = useFieldRedlines({
    fieldName,
    fieldValue,
    redlines,
  });

  const { isRun } = useRunContext();
  return (
    <div className="min-w-0">
      <button
        className={`max-w-full break-all text-left ${isBold ? 'font-semibold' : ''}`}
        onClick={onLabelClick}
        disabled={typeof onLabelClick !== 'function'}
      >
        {isRun && typeof latestApprovedField === 'string' && (
          <Fragment>
            <span>{latestApprovedField}</span>
          </Fragment>
        )}

        {/* Show saved field for a procedure. */}
        {(!isRun || (isRun && typeof latestApprovedField === 'object')) && <ProcedureDiffText diffValue={fieldValue} />}
      </button>
    </div>
  );
};

export default ProcedureFieldNoRedlines;
