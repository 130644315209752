import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import isNil from 'lodash.isnil';
import { isReleased, getPendingProcedureIndex } from 'shared/lib/procedureUtil';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import useProcedureObserver from '../hooks/useProcedureObserver';
import { Draft, Procedure, Release } from 'shared/lib/types/views/procedures';
import { isEmpty } from 'lodash';
import { ProcedureState } from 'shared/lib/types/couch/procedures';

interface UseProcedureVersionsProps {
  id: string;
}

export type VersionMetadata = {
  _id: string;
  procedure_id: string;
  procedure_rev_num: number;
  code: string;
  name: string;
  version?: string;
  state?: ProcedureState;
};

export type UseProcedureVersionsReturns = {
  released: { procedure: Release; loading: boolean };
  pending: { procedure: Draft; loading: boolean };
  versions: Array<VersionMetadata>;
  latestReleasedVersion: VersionMetadata | undefined;
  isLatestRelease: (procedure: Procedure) => boolean;
  loading: boolean;
};

const useProcedureVersions = ({
  id,
}: UseProcedureVersionsProps): UseProcedureVersionsReturns => {
  const isMounted = useRef(true);
  const { services } = useDatabaseServices();
  // For backwards compatibility, observe the two documents in the procedures db.
  const released = useProcedureObserver({ id });
  const pending = useProcedureObserver({
    id: getPendingProcedureIndex(id),
  });
  const [versions, setVersions] = useState<Array<VersionMetadata>>([]);

  // Flag for detecting when component is unmounted.
  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  const mergeVersions = useCallback((released, pending, versions) => {
    const merged = [...versions];
    merged.sort(
      (first, second) => first.procedure_rev_num - second.procedure_rev_num
    );
    // Add the current release if there is no procedure revision document for it (backwards compatibility).
    if (released && isNil(released.procedure_rev_num)) {
      merged.push({
        _id: released._id,
        procedure_id: released._id,
        state: released.state,
        code: released.code,
        name: released.name,
        version: released.version,
      });
    }
    // Add the current pending document.
    if (pending) {
      merged.push({
        _id: pending._id,
        procedure_id: pending.procedure_id,
        procedure_rev_num: pending.procedure_rev_num,
        code: pending.code,
        name: pending.name,
        version: pending.version,
        state: pending.state,
      });
    }
    return merged;
  }, []);

  useEffect(() => {
    if (!services.revisions) {
      return;
    }
    if (released.loading || pending.loading) {
      return;
    }
    services.revisions
      .getProcedureRevisionsByState(id, 'released')
      .then((newVersions) => {
        if (!isMounted.current) {
          return;
        }
        const merged = mergeVersions(
          released.procedure,
          pending.procedure,
          newVersions
        );
        setVersions(merged);
      })
      .catch(() => undefined);
  }, [
    id,
    services,
    released.loading,
    released.procedure,
    pending.loading,
    pending.procedure,
    mergeVersions,
  ]);

  const latestReleasedVersion: VersionMetadata | undefined = useMemo(() => {
    if (!versions || isEmpty(versions)) {
      return;
    }
    // Iterate in reverse.
    for (let i = versions.length - 1; i >= 0; i--) {
      if (isReleased(versions[i])) {
        return versions[i];
      }
    }
  }, [versions]);

  const isLatestRelease = useCallback(
    (procedure) => {
      // Wait for documents to load.
      if (!procedure || released.loading) {
        return false;
      }
      if (!isReleased(procedure)) {
        return false;
      }
      /**
       * To help with offline support, check if this is the latest released version by
       * comparing document ids. This works because the latest released procedure
       * will always be in the procedures_[team_id] db, with an _id of [procedure_id].
       * When opening the procedure detail page, this procedure doc is the first
       * one to be fetched and displayed, so we want to allow the 'Run Procedure'.
       * button to work.
       */
      if (released.procedure._id === procedure._id) {
        return true;
      }
      // Wait for versions to load (procedure versions not supported offline).
      if (!latestReleasedVersion) {
        return false;
      }
      if (
        isNil(procedure.procedure_rev_num) ||
        isNil(latestReleasedVersion.procedure_rev_num)
      ) {
        // For backwards compatibility.
        return procedure._id === latestReleasedVersion._id;
      } else {
        return (
          procedure.procedure_rev_num ===
          latestReleasedVersion.procedure_rev_num
        );
      }
    },
    [released.loading, released.procedure, latestReleasedVersion]
  );

  const loading = useMemo(() => {
    return released.loading || pending.loading || versions === null;
  }, [released.loading, pending.loading, versions]);

  return {
    released,
    pending,
    versions,
    latestReleasedVersion,
    isLatestRelease,
    loading,
  };
};

export default useProcedureVersions;
